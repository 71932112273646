import Widgets from "./components/Widgets";
import SalesOvertime from "./components/SalesOvertime";
import ExpensesAndPurchasesOvertime  from "./components/ExpensesAndPurchasesOvertime";
import InventoryStockLevels from "./components/InventoryStockLevels";
import SalesByDiningMethod from "./components/SalesByDiningMethod";
import SalesByPaymentMethod from "./components/SalesByPaymentMethod";
import HighestSellingMenuItems from "./components/HighestSellingMenuItems";
import withPermissions from "components/hoc";

const Dashboard = () => {
  return (
    <div>
      <div>
        <Widgets />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <SalesOvertime />
        <ExpensesAndPurchasesOvertime />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <InventoryStockLevels />
        <SalesByDiningMethod />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <HighestSellingMenuItems />
        <SalesByPaymentMethod />
      </div>
    </div>
  );
};

export default withPermissions(Dashboard);
