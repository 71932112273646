import {compose, isEmpty, isNil, not, trim} from 'ramda';

export const isNotEmpty = compose(not, (v: any) => isEmpty(v) || isNil(v));
//
// export const removeUndefinedOrNull = (obj: any) => {
//   const filteredObject: any = {};
//   for (const key in obj) {
//     const value = obj[key];
//
//     if (value !== undefined && value !== null) {
//       if (Array.isArray(value) && value.length > 0) {
//         filteredObject[key] = value;
//       } else if (typeof value === 'string') {
//         const trimmedValue = value.trim();
//         if (trimmedValue !== '') {
//           filteredObject[key] = trimmedValue;
//         }
//       } else {
//         filteredObject[key] = value;
//       }
//     }
//   }
//   return filteredObject;
// };


export const removeUndefinedOrNull = (obj: any) => {
  const filteredObject: any = {};

  for (const key in obj) {
    const value = obj[key];

    // Check for various cases in a single condition
    if (value !== undefined && value !== null && (typeof value !== 'string' || trim(value) !== '')) {
      filteredObject[key] = typeof value === 'string' ? trim(value) : value;
    }
  }

  return filteredObject;
};

export const deleteFields = (obj: any, fields: string[]): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      const newObj = { ...item }; // Create a copy
      fields.forEach((field) => delete newObj[field]);
      return newObj; // Return the modified object
    });
  } else {
    const newObj = { ...obj };
    fields.forEach((field) => delete newObj[field]);
    return newObj;
  }
}

export const pickFields = (obj: any, fields: string[]): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => pickFields(item, fields));
  } else {
    const newObj: any = {};
    for (const key of Object.keys(obj)) {
      if (fields.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
}
