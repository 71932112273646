import React, { createContext, useContext, useEffect, useState } from 'react';

interface AuthStateContextProps {
  user: any;
  login: (user: any) => void;
  logout: () => void;
  loading: boolean;
  setUserAfterSignup: (user: any) => void;
}

const AuthStateContext = createContext<AuthStateContextProps | undefined>(undefined);

export const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (!context) {
    throw new Error('useAuthState must be used within an AuthStateProvider');
  }
  return context;
};

export const AuthStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous check for the user's authentication status
    const checkAuthStatus = async () => {
      // You can perform any asynchronous logic here, such as fetching user data from a server
      // For demonstration purposes, let's assume the user is not authenticated initially
      setUser(null);
      setLoading(false);
    };

    checkAuthStatus();
  }, []);

  const login = (newUser: any) => {
    setUser(newUser);
  };

  const logout = () => {
    setUser(null);
  };

  const setUserAfterSignup = (newUser: any) => {
    setUser(newUser);
  };

  const contextValue: AuthStateContextProps = {
    user,
    login,
    logout,
    loading,
    setUserAfterSignup,
  };

  return (
    <AuthStateContext.Provider value={contextValue}>
      {children}
    </AuthStateContext.Provider>
  );
};
