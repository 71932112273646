import { pathOr } from "ramda";
import { getAuthorizationHeader, client, handleAxiosError } from "./apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createRestaurantAPI = createAsyncThunk(
  "restaurants/create-restaurant",
  async (payload: { restaurant: any }, thunkAPI) => {
    try {
      const response = await client(`/restaurants`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.restaurant,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createRestaurantAdminAPI = createAsyncThunk(
  "restaurants/create-restaurant-admin",
  async (payload: { restaurant: any }, thunkAPI) => {
    try {
      const response = await client(`/restaurants`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.restaurant,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateRestaurantAPI = createAsyncThunk(
  "restaurants/update-restaurant",
  async (payload: { restaurant: any; restaurantId: string }, thunkAPI) => {
    try {
      const response = await client(`/restaurants/${payload.restaurantId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.restaurant,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteRestaurantAPI = createAsyncThunk(
  "restaurants/delete-restaurant",
  async (payload: { restaurantId: string }, thunkAPI) => {
    try {
      const response = await client(`/restaurants/${payload.restaurantId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const archiveRestaurantAPI = createAsyncThunk(
  "restaurants/archive-restaurant",
  async (payload: { restaurantId: string }, thunkAPI) => {
    try {
      const response = await client(`/restaurants/${payload.restaurantId}/archive`, {
        method: "PATCH",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchRestaurantAPI = createAsyncThunk(
  "restaurants/fetch-restaurant",
  async (payload: { restaurantId: string }, thunkAPI) => {
    try {
      const response = await client(`/restaurants/${payload.restaurantId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchRestaurantsByBusinessAPI = createAsyncThunk(
  "restaurants/fetch-restaurants-by-business",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      const response = await client(`/business/restaurants?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
