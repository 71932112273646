import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  createCustomerAPI,
  fetchCustomerAPI,
  fetchCustomersByLocationAPI,
  updateCustomerAPI,
} from '../../api/customers';
import { pathOr } from 'ramda';


interface CustomersState {
  customers: any;
  customer: any;
  error: string | null;
  success: string | null;
  loading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: CustomersState = {
  customers: [],
  customer: null,
  error: null,
  success: null,
  loading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 10,
  currentPage: 1
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    clearSuccessAndError: (state) => {
      state.error = null;
      state.success = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersByLocationAPI.fulfilled, (state, action) => {
      state.customers = pathOr([], ['customers'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.loading = false;
    });
    builder.addCase(fetchCustomersByLocationAPI.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchCustomersByLocationAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createCustomerAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.success = pathOr('', ['message'], action.payload);
      state.loading = false;
    });
    builder.addCase(createCustomerAPI.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createCustomerAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCustomerAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.success = pathOr('', ['message'], action.payload);
      state.loading = false;
    });
    builder.addCase(updateCustomerAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = pathOr('', ['message'], action.payload);
    });
    builder.addCase(updateCustomerAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchCustomerAPI.fulfilled, (state, action) => {
      state.customer = pathOr(null, ['customer'], action.payload);
      state.loading = false;
    });
    builder.addCase(fetchCustomerAPI.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchCustomerAPI.pending, (state, action) => {
      state.loading = true;
    });
  },
});


export const { 
  setLoading,
  setCurrentPage,
  setTotalPerPage,
  clearSuccessAndError,
 } = employeesSlice.actions;

export default employeesSlice.reducer;
