import React, { FC } from "react";
import {Button, Input, message, Select, Switch} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { getCookie, removeUndefinedOrNull } from "../../../../../helpers/utilities";
import { pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {pickFields} from "../../../../../utils/is-not-empty";
import {
  createMenuModifierGroupAPI,
  fetchMenuModifierGroupsByLocationAPI,
  updateMenuModifierGroupAPI
} from "../../../../../api/menu-modifier-groups";
import {setMenuModifierGroup, updateMenuModifierGroup} from "../../../../../store/menus/menu-modifier-groups";

interface MenuModifierGroupFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const MenuModifierGroupForm: FC<MenuModifierGroupFormProps> = ({ isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const menuModifierGroup = useAppSelector((state: any) => state.menuModifierGroups.menuModifierGroup);
  const menus= useAppSelector((state: any) => state.menus.menus);
  const isLoading = useAppSelector((state: any) => state.menuModifierGroups.isLoading);

  const handleInputChange = (val: string, name: string) => {
    dispatch(updateMenuModifierGroup({
      [name]: val,
    }))
  }

  const onFinish = async () => {
    if (!pathOr(null, ['name'], menuModifierGroup)
       || !pathOr(null, ['menuId'], menuModifierGroup)) {
      message.error('please provide menu modifier group name and menu');
      return;
    }
    const restaurantId = getCookie('currentRestaurant');
    const locationId = getCookie('currentLocation');
    const menuModifierGroupObj = {
      ...pickFields(menuModifierGroup, ['name', 'description']),
    }
    const menuModifierGroupPayload = removeUndefinedOrNull(menuModifierGroupObj);

    if (!pathOr(null, ['id'], menuModifierGroup)) {
      const obj = {
        ...menuModifierGroupPayload,
        locationId,
        restaurantId,
      }
      await dispatch(createMenuModifierGroupAPI({
        menuModifierGroup: obj,
        menuId: pathOr('', ['menuId'], menuModifierGroup) }));
    } else {
      await dispatch(updateMenuModifierGroupAPI({
        menuModifierGroupId: pathOr('', ['id'], menuModifierGroup),
        menuModifierGroup: menuModifierGroupPayload
      }));
    }

    clear();
    await dispatch(fetchMenuModifierGroupsByLocationAPI({}));
  };

  const handleShowOnWeb = () => {
    dispatch(updateMenuModifierGroup({
      showOnWeb: !pathOr(false, ['showOnWeb'], menuModifierGroup),
    }))
  }

  const clear = () => {
    dispatch(setMenuModifierGroup(null));
    handleClose()
  }

  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">{pathOr('', ['id'], menuModifierGroup)
            ? "Edit Menu Modifier Group" : "Create Menu Modifier Group"}</h3>
         <div className="w-full">
            <div className="flex justify-end items-end">
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Show on web</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['showOnWeb'], menuModifierGroup)}
                    onChange={handleShowOnWeb} 
                  />
                </div>
              </div>
            </div>
           <div className="text-left w-full pt-3">
             <span>Menu<span className="text-red-500">*</span></span>
             <Select
                options={menus.map((menu: any) => {
                  return {
                    label: pathOr('', ['name'], menu),
                    value: pathOr('', ['id'], menu),
                  }
                })}
                className="w-full"
                value={pathOr('', ['menuId'], menuModifierGroup)}
                onChange={(val) => handleInputChange(val,"menuId")}
             />
           </div>
           <div className="text-left w-full pt-3">
             <span>Name<span className="text-red-500">*</span></span>
             <Input
                name="name"
                className="w-full"
                value={pathOr('', ['name'], menuModifierGroup)}
                required
                onChange={(e) => handleInputChange(e.target.value,"name")}
             />
           </div>
           <div className="text-left w-full pt-3">
             <span>Description</span>
             <Input.TextArea
                rows={4}
                name="description"
                className="w-full"
                value={pathOr('', ['description'], menuModifierGroup)}
                onChange={(e) =>
                   handleInputChange(e.target.value,"description")}
             />
           </div>
           <div className="mt-5 space-x-3 flex justify-end">
             <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={isLoading}
                className="bg-blue-750">
               {pathOr('', ['id'], menuModifierGroup) ? "Update" : "Create"}
             </Button>
             <Button onClick={clear} type="default">
               Cancel
             </Button>
           </div>
         </div>
       </div>
     </ParentModal>
  );
};

export default MenuModifierGroupForm;
