import { createSlice } from "@reduxjs/toolkit";
import { pathOr } from "ramda";
import {
  fetchMenuModifierItemsReportByBusinessAPI,
  fetchMenuModifierItemsReportByLocationAPI,
  fetchMenuModifierItemsReportByRestaurantAPI,
  fetchModifierItemsOverTimeReportByLocationAPI,
} from "../../api/reports/menu-modifier-items";
import { getTimeframeDates } from "helpers/utilities";
import moment from "moment";

interface MenuModifierItemsReportsState {
  menuModifierItemsReport: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: MenuModifierItemsReportsState = {
  menuModifierItemsReport: [],
  status: "idle",
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  filters: {
    fromDate: getTimeframeDates('this_month').fromDate,
    toDate: getTimeframeDates('this_month').toDate
  },
};

const menuModifierItemsReportsSlice = createSlice({
  name: "menu-modifier-items-reports",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenuModifierItemsReportByLocationAPI.fulfilled, (state, action) => {
      state.menuModifierItemsReport = pathOr([], ["menuModifierItemsReport"], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchModifierItemsOverTimeReportByLocationAPI.fulfilled, (state, action) => {
      state.menuModifierItemsReport = pathOr([], ["menuModifierItemsReport"], action.payload).flatMap(
        (report) => {
        return {
          ...report,
          key: pathOr(moment().valueOf(), ['date'], report)
        }
      });
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchModifierItemsOverTimeReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchModifierItemsOverTimeReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierItemsReportByRestaurantAPI.fulfilled, (state, action) => {
      state.menuModifierItemsReport = pathOr([], ["menuModifierItemsReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierItemsReportByBusinessAPI.fulfilled, (state, action) => {
      state.menuModifierItemsReport = pathOr([], ["menuModifierItemsReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = menuModifierItemsReportsSlice.actions;

export default menuModifierItemsReportsSlice.reducer;
