import { ApiResponse, client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { getCookie } from "../helpers/utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const createExpenseApi = async (expense: any): Promise<ApiResponse> => {
  try {
    const response = await client(`/expenses`, {
      method: "POST",
      headers: getAuthorizationHeader(),
      data: expense,
    });

    return response.data;
  } catch (error: any) {
    throw new Error(`Error during creating expense: ${error.message}`);
  }
};
export const createExpenseAPI = createAsyncThunk(
  "expenses/create-expense",
  async (payload: { expense: any }, thunkAPI) => {
    try {
      const response = await client(`/expenses`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: payload.expense,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateExpensesAPI = createAsyncThunk(
  "expenses/update-expense",
  async (payload: { expense: any; expenseId: string }, thunkAPI) => {
    try {
      const response = await client(`/expenses/${payload.expenseId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.expense,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchExpenseByIdAPI = createAsyncThunk(
  "expenses/fetch-expense",
  async (payload: { expenseId: string }, thunkAPI) => {
    try {
      const response = await client(`/expenses/${payload.expenseId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchExpensesByRestaurantAPI = createAsyncThunk(
  "expenses/fetch-expenses-by-restaurant",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/expenses?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchExpensesByLocationAPI = createAsyncThunk(
  "expenses/fetch-expenses-by-location",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/expenses?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteExpensesAPI = createAsyncThunk(
  "expenses/delete-expenses",
  async (payload: { expenseId: string }, thunkAPI) => {
    try {
      const response = await client(`/expenses/${payload.expenseId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
