import { createSlice } from '@reduxjs/toolkit';
import {pathOr} from 'ramda';
import {
  fetchEmployeeReportById,
  fetchEmployeesReportByBusinessAPI,
  fetchEmployeesReportByLocationAPI,
  fetchEmployeesReportByRestaurantAPI,
  fetchMenuItemsSoldByEmployeesAPI
} from "../../api/reports/employees";

interface EmployeesReportsState {
  employeesReport: any[];
  employeeReport: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  menuItemsSoldOvertimeByEmployeesReport: any[];
}

const initialState: EmployeesReportsState = {
  employeesReport: [],
  status: 'idle',
  error: null,
  employeeReport: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  menuItemsSoldOvertimeByEmployeesReport: []
};

const employeesReportsSlice = createSlice({
  name: 'employees-reports',
  initialState,
  reducers: {
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployeesReportByLocationAPI.fulfilled, (state, action) => {
      state.employeesReport = pathOr([], ['employeesReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsSoldByEmployeesAPI.fulfilled, (state, action) => {
      state.menuItemsSoldOvertimeByEmployeesReport = pathOr([], ['menuItemsSoldOvertimeByEmployeesReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsSoldByEmployeesAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsSoldByEmployeesAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEmployeesReportByRestaurantAPI.fulfilled, (state, action) => {
      state.employeesReport = pathOr([], ['employeesReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEmployeesReportByBusinessAPI.fulfilled, (state, action) => {
      state.employeesReport = pathOr([], ['employeesReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEmployeeReportById.fulfilled, (state, action) => {
      state.employeeReport = pathOr(null, ['employeeReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeeReportById.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeeReportById.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage } = employeesReportsSlice.actions;


export default employeesReportsSlice.reducer;
