import React, { FC } from "react";

export interface TableDisplayProps {
  tableNumber: string;
  seatCapacity: number;
  status:
     | "OCCUPIED"
     | "AVAILABLE"
     | "BLOCKED"
     | "RESERVED"
     | "UNDER_MAINTENANCE"
     | "DIRTY"
     | "WAITING"
     | "SERVED";
  onClick: () => void;
}
const TableDisplay: FC<TableDisplayProps> = ({ tableNumber, seatCapacity, status, onClick }) => {
  if (status === "AVAILABLE") {
    return (
       <div
          onClick={onClick}
          className="relative flex justify-center items-center basis-1/2 md:basis-[25%] aspect-[3/2.5]">
         <div className="border-2 border-gray-500 hover:border-brand-500 w-28 h-20 relative rounded-lg flex justify-center items-center group duration-300 transition-colors cursor-pointer">
           <div className="text-center font-medium text-gray-750 group-hover:text-brand-500">
             <p>{tableNumber}</p>
             <p>{seatCapacity}</p>
             <p>{status}</p>
           </div>
           <div className="border-2 border-gray-500 group-hover:border-brand-500 w-12 h-6 rounded-lg absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[130%] duration-300 transition-colors"></div>
           <div className="border-2 border-gray-500 group-hover:border-brand-500 w-6 h-12 rounded-lg absolute top-1/2 right-0 translate-x-[130%] -translate-y-1/2 duration-300 transition-colors"></div>
           <div className="border-2 border-gray-500 group-hover:border-brand-500 w-12 h-6 rounded-lg absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[130%] duration-300 transition-colors"></div>
           <div className="border-2 border-gray-500 group-hover:border-brand-500 w-6 h-12 rounded-lg absolute top-1/2 left-0 -translate-x-[130%] -translate-y-1/2 duration-300 transition-colors"></div>
         </div>
       </div>
    );
  }

  return (
     <div
        onClick={onClick}
        className="relative flex justify-center items-center basis-1/2 md:basis-[25%] aspect-[3/2.5]">
       <div className="bg-brand-500 w-28 h-20 relative rounded-lg flex justify-center items-center group duration-300 transition-colors cursor-pointer">
         <div className="text-center font-medium text-gray-50">
           <p>{tableNumber}</p>
           <p>{seatCapacity}</p>
           <p>{status}</p>
         </div>
         <div className="bg-brand-500 w-12 h-6 rounded-lg absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[130%]"></div>
         <div className="bg-brand-500 w-6 h-12 rounded-lg absolute top-1/2 right-0 translate-x-[130%] -translate-y-1/2"></div>
         <div className="bg-brand-500 w-12 h-6 rounded-lg absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[130%]"></div>
         <div className="bg-brand-500 w-6 h-12 rounded-lg absolute top-1/2 left-0 -translate-x-[130%] -translate-y-1/2"></div>
       </div>
     </div>
  );
};

export default TableDisplay;
