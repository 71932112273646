import React, { useState } from 'react';
import {Button, Card, Input, message, Select} from 'antd';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {head, length, pathOr, replace} from 'ramda'
import { RestaurantTypes } from 'types/RestaurantTypes';
import {countries, currencies} from "../../../../data";
import {restaurantTypes} from "../../../../constants";
import {validateSchema} from "../validations/schema";
import {createRestaurantAPI} from "../../../../api/restaurants";
import {removeUndefinedOrNull} from "../../../../utils/is-not-empty";
import { signOutAPI } from 'api/auth';

const CreateRestaurant = () => {
  const dispatch = useAppDispatch();
  const business = useAppSelector((state: any) => pathOr({},
     ["auth", "user", "business"], state));
  const isLoading = useAppSelector((state: any) => pathOr(false,
     ["auth", "isLoading"], state));
  const [errors, setErrors] = useState<any>({});
  const [formValues, setFormValues] = useState<RestaurantTypes>({
    name: pathOr("", ["name"], business),
    type: "",
    location: {
      name: "",
      openingTime: "",
      closingTime: "",
      daysOfOperation: "",
      city: "",
      town: "",
      country: "",
      address: "",
      zipcode: "",
      currencyName: "",
      currencySymbol: "",
      currencyIsoCode: "",
      countryCode: "",
    },
  });

  const handleChange = (val: string,  name: string, type?: 'location' | 'country' | 'currency') => {
    if (type === 'location') {
      setFormValues({
        ...formValues,
        location: {
          ...formValues.location,
          [name]: val
        }
      })
      return
    }

    if (type === 'country') {
      const country: any = head(countries.filter(
         (count: {name: string; code: string;}) => count.name === val));

      setFormValues({
        ...formValues,
        location: {
          ...formValues.location,
          country: pathOr('', ['name'], country),
          countryCode: pathOr('', ['code'], country)
        }
      })
      return;
    }

    if (type === 'currency') {
      setFormValues({
        ...formValues,
        location: {
          ...formValues.location,
          currencyName: pathOr('', [val, 'name'], currencies),
          currencySymbol: pathOr('', [val, 'symbol_native'], currencies),
          currencyIsoCode: pathOr('', [val, 'code'], currencies),
        }
      })
      return;
    }

    setFormValues({
      ...formValues,
      [name]: val
    })
    return;
  };

  const onFinish = async () => {
    setErrors({})
    const { errors } = validateSchema(formValues);
    setErrors(errors);
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return
    }

    const formObj: any = {
      ...formValues,
      locations: [
         formValues.location
      ],
      location: undefined,
    }
    await dispatch(createRestaurantAPI({restaurant: removeUndefinedOrNull(formObj)}));
  };
  return (
    <Card className="w-full mt-5 lg:w-3/5 mb-6 mx-auto ">
      <p className="mb-3 font-bold text-2xl">Set up your restaurant</p>
      <div className="w-full">
        <div className="flex flex-col space-y-2">
          <div>
            <span>Restaurant Name<span className='text-red-500'>*</span></span>
            <Input
               name="name"
               value={formValues.name}
               required
               status={errors['Restaurant name'] ? 'error' : ''}
               onChange={(e) => handleChange(e.target.value, 'name')}
            />
            { errors['Restaurant name'] ? <span className='text-red-500'>
              {pathOr('', ['Restaurant name', 0, 'message'], errors)}
            </span> : null}
          </div>

          <div>
            <span>Restaurant Type<span className='text-red-500'>*</span></span>
            <Select
               className="w-full"
               value={formValues.type}
               onChange={(val) => handleChange(val, 'type')}
               status={errors['Restaurant type'] ? 'error' : ''}
               options={restaurantTypes.map((restaurantType) => {
                 return {
                   label: replace(/_/g, ' ', restaurantType),
                   value: restaurantType,
                 }
               })}
            />
            { errors['Restaurant type'] ? <span className='text-red-500'>
              {pathOr('', ['Restaurant type', 0, 'message'], errors)}
            </span> : null}
          </div>
        </div>

        <div>
          <p className="mt-5 font-bold text-2xl">Location</p>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div style={{width: '32%'}}>
              <span>Location Name<span className='text-red-500'>*</span></span>
              <Input
                 name="locationName"
                 required
                 status={errors['Location name'] ? 'error' : ''}
                 value={pathOr('', ['location', 'name'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'name',
                    'location'
                 )}
              />
              { errors['Location name'] ? <span className='text-red-500'>
              {pathOr('', ['Location name', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Country<span className='text-red-500'>*</span></span>
              <Select
                 className="w-full"
                 status={errors['Country'] ? 'error' : ''}
                 onChange={(val) => handleChange(val, 'country', 'country')}
                 options={countries.map((country: {name: string; code: string;}) => {
                   return {
                     label: country.name,
                     value: country.name,
                   }
                 })}
                 value={pathOr('', ['location', 'country'], formValues)}
              />
              { errors['Country'] ? <span className='text-red-500'>
              {pathOr('', ['Country', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Currency<span className='text-red-500'>*</span></span>
              <Select
                 className="w-full"
                 status={errors['Currency name'] ? 'error' : ''}
                 onChange={(val) => handleChange(val, 'currency', 'currency')}
                 options={Object.keys(currencies).map((currency) => {
                   return {
                     label: `${pathOr('', [currency, 'name'], currencies)}`,
                     value: currency
                   }
                 })}
                 value={pathOr('', ['location', 'currencyName'], formValues)}
              />
              { errors['Currency name'] ? <span className='text-red-500'>
              {pathOr('', ['Currency name', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div style={{width: '32%'}}>
              <span>Address<span className='text-red-500'>*</span></span>
              <Input
                 name="address"
                 className="w-full"
                 status={errors['Address'] ? 'error' : ''}
                 value={pathOr('', ['location', 'address'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'address',
                    'location'
                 )}
              />
              { errors['Address'] ? <span className='text-red-500'>
              {pathOr('', ['Address', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>City<span className='text-red-500'>*</span></span>
              <Input
                 name="city"
                 className="w-full"
                 status={errors['City'] ? 'error' : ''}
                 value={pathOr('', ['location', 'city'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'city',
                    'location'
                 )}
              />
              { errors['City'] ? <span className='text-red-500'>
              {pathOr('', ['City', 0, 'message'], errors)}
            </span> : null}
            </div>
            <div style={{width: '32%'}}>
              <span>Town<span className='text-red-500'>*</span></span>
              <Input
                 name="town"
                 status={errors['Town'] ? 'error' : ''}
                 value={pathOr('', ['location', 'town'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'town',
                    'location'
                 )}
              />
              { errors['Town'] ? <span className='text-red-500'>
              {pathOr('', ['Town', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div className="w-full">
              <span>Zipcode<span className='text-red-500'>*</span></span>
              <Input
                 name="zipcode"
                 status={errors['Zipcode'] ? 'error' : ''}
                 value={pathOr('', ['location', 'zipcode'], formValues)}
                 required
                 onChange={(e) => handleChange(
                    e.target.value,
                    'zipcode',
                    'location'
                 )}
              />
              { errors['Zipcode'] ? <span className='text-red-500'>
              {pathOr('', ['Zipcode', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>
          <div className="mt-3 flex flex-row items-start justify-between">
            <div style={{width: '32%'}}>
              <span>Days Of Operation</span>
              <Input
                 name="daysOfOperation"
                 value={pathOr('', ['location', 'daysOfOperation'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'daysOfOperation',
                    'location'
                 )}
              />
            </div>
            <div style={{width: '32%'}}>
              <span>Opening Time</span>
              <Input
                 name="openingTime"
                 value={pathOr('', ['location', 'openingTime'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'openingTime',
                    'location'
                 )}
              />
            </div>
            <div style={{width: '32%'}}>
              <span>Closing Time</span>
              <Input
                 name="closingTime"
                 value={pathOr('', ['location', 'closingTime'], formValues)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'closingTime',
                    'location'
                 )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 flex flex-row space-x-4">
        <Button
           type="primary"
           onClick={onFinish}
           loading={isLoading}
           className="w-[200px] basis-9 h-9 bg-blue-750">
          Create Restaurant
        </Button>

        <Button
           type="primary"
           onClick={() => dispatch(signOutAPI())}
           className="w-[200px] basis-9 h-9 bg-red-500">
          Log out
        </Button>
      </div>
    </Card>
  );
};

export default CreateRestaurant;
