import {getCookie} from "helpers/utilities";
import axios from "axios";
import {length, pathOr} from "ramda";
import {message} from "antd";
import { logOutUser } from "store/auth/auth";

export interface ApiResponse {
  // Define the structure of the API response data
  // Adjust the types according to your API response structure
}

export const getAuthorizationHeader = (): Record<string, string> => {
  const bearerToken = getCookie('accessToken');

  if (!bearerToken) {
    throw new Error('Access token not found');
  }

  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearerToken}`,
  };
};

export const handleAxiosError = (error: any, thunkAPI: any) => {
  if (axios.isAxiosError(error)) {
    const status = pathOr(null, ['response', 'data', 'statusCode'], error);
    const mess: any =
      pathOr('', ['response', 'data', 'error'], error) ||
      pathOr('', ['message'], error);
    const errorsArray = pathOr([], ['response', 'data', 'message'], error);

    if (status === 401) {
      thunkAPI.dispatch(logOutUser());
    }

    if (errorsArray && Array.isArray(errorsArray) && length(errorsArray) > 0) {
      let errors = '';
      for (const err of errorsArray) {
        errors += `${err} \n`;
      }
      message.error(errors);
      throw thunkAPI.rejectWithValue(pathOr('', [0], errorsArray));
    }

    if (mess && Array.isArray(mess) && length(mess) > 0) {
      let errors = '';
      for (const err of mess) {
        errors += `${err} \n`;
      }
      message.error(errors);
      throw thunkAPI.rejectWithValue(pathOr('', [0], mess));
    }

    message.error(mess);
    throw thunkAPI.rejectWithValue(mess);
  } else {
    if (typeof error === 'string') {
      message.error(error);
      throw thunkAPI.rejectWithValue(error);
    }
    if (error && error.message) {
      message.error(error.message);
      throw thunkAPI.rejectWithValue(error.message);
    }
    message.error('failed to make request, try again later');
    throw thunkAPI.rejectWithValue('failed to make request, try again later');
  }
};

export const handleApiResponse = async (response: Response): Promise<ApiResponse> => {
  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  return await response.json();
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    Authorization: `Bearer ${getCookie('accessToken')}`,
    'Cache-Control': 'no-cache',
  },
});
