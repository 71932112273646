import React, { useState, useEffect } from "react";
import {message, Button, Input} from "antd";
import {length, pathOr} from "ramda";
import { UserTypes } from "types/UserTypes";
import {
  removeUndefinedOrNull,
} from "helpers/utilities";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import {sendEmailVerificationLinkAPI, updateUserAccountAPI} from "../../../../api/auth";
import {validateSchema} from "views/admin/settings/profile/validations/schema";

interface userProfileTypes extends Partial<UserTypes> {
  alias: string;
}

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const isLoading = useAppSelector((state: RootState) => state.auth.isLoading);
  const isEmailVerified = pathOr(false, ['isEmailVerified'], user);
  const [errors, setErrors] = useState(null);
  const [formValues, setFormValues] = useState<userProfileTypes | any>({
    firstname: "",
    lastname: "",
    phoneNumber: "",
    password: "",
    passcode: "",
    alias: "",
  });

  useEffect(() => {
    setFormValues({
      firstname: pathOr("", ["profile", "firstname"], user),
      lastname: pathOr("", ["profile", "lastname"], user),
      phoneNumber: pathOr("", ["phoneNumber"], user),
      alias: pathOr("", ["profile", "alias"], user),
    });
  }, [user]);

  const handleChange = (value: any, name: string) => {
    setFormValues( {
      ...formValues,
      [name]: value,
    });
  };

  const onFinish = async () => {
    const {errors} = validateSchema(removeUndefinedOrNull(formValues));
    setErrors(errors);
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return;
    }
    const payload = removeUndefinedOrNull(formValues)
    await dispatch(updateUserAccountAPI({user: payload}));
  };

  const sendEmailVerification = async () => {
    await dispatch(sendEmailVerificationLinkAPI());
  }

  return (
     <div>
       <div className="flex flex-row items-center justify-between">
        <div>
          <p className="text-base font-medium">Profile Settings</p>
          <p className="text-sm text-gray-750">View/Update your profile details.</p>
        </div>
        { 
          !isEmailVerified ? (
            <div>
              <Button 
                loading={isLoading} 
                onClick={sendEmailVerification} 
                type="primary"
                className="bg-blue-750"
              >
                Verify Email
              </Button>
          </div>
          ) : null
        }
       </div>
       <div className="mt-5 flex flex-col space-y-4">
         <div className="flex flex-wrap flex-row items-start justify-between">
           <div className='w-full md:w-[48%] lg:w-[32%]'>
             <span>Firstname</span>
             <Input
                name="firstname"
                className="w-full"
                value={formValues.firstname}
                status={pathOr('', ['Firstname'], errors) ? 'error' : ''}
                onChange={(e) => handleChange(e.target.value, 'firstname')}
             />
             {pathOr('', ['Firstname'], errors) ?
                <span className="text-red-500">
                  {pathOr('', ['Firstname', 0, 'message'], errors)}
                </span> : null}
           </div>
           <div className='w-full md:w-[48%] lg:w-[32%]'>
             <span>Alias</span>
             <Input
                name="alias"
                className="w-full"
                value={formValues.alias}
                status={pathOr('', ['Alias'], errors) ? 'error' : ''}
                onChange={(e) => handleChange(e.target.value, 'alias')}
             />
             {pathOr('', ['Alias'], errors) ?
                <span className="text-red-500">
                  {pathOr('', ['Alias', 0, 'message'], errors)}
                </span> : null}
           </div>
           <div className='w-full md:w-[48%] lg:w-[32%]'>
             <span>Lastname</span>
             <Input
                name="lastname"
                className="w-full"
                value={formValues.lastname}
                status={pathOr('', ['Lastname'], errors) ? 'error' : ''}
                onChange={(e) => handleChange(e.target.value, 'lastname')}
             />
             {pathOr('', ['Lastname'], errors) ?
                <span className="text-red-500">
                  {pathOr('', ['Lastname', 0, 'message'], errors)}
                </span> : null}
           </div>
         </div>

         <div className="flex flex-wrap flex-row items-start justify-between">
           <div className="w-full md:w-[48%]">
             <span>Password</span>
             <Input
                name="password"
                className="w-full"
                value={formValues.password}
                status={pathOr('', ['Firstname'], errors) ? 'error' : ''}
                onChange={(e) => handleChange(e.target.value, 'password')}
             />
             {pathOr('', ['Password'], errors) ?
                <span className="text-red-500">
                  {pathOr('', ['Password', 0, 'message'], errors)}
                </span> : null}
           </div>
           <div className="w-full md:w-[48%]">
             <span>Passcode</span>
             <Input
                name="passcode"
                className="w-full"
                value={formValues.passcode}
                status={pathOr('', ['Passcode'], errors) ? 'error' : ''}
                onChange={(e) => handleChange(e.target.value, 'passcode')}
             />
             {pathOr('', ['Passcode'], errors) ?
                <span className="text-red-500">
                  {pathOr('', ['Passcode', 0, 'message'], errors)}
                </span> : null}
           </div>
         </div>

         <div>
           <Button
              type="primary"
              className="bg-blue-750"
              onClick={onFinish}
              loading={isLoading}
           >
             Update Profile
           </Button>
         </div>
       </div>
     </div>
  );
};

export default Profile;
