import { Breadcrumb, message, Tabs } from "antd";
import RegisterSessions from "./register-sessions";
import RegisterTransactions from "./register-transactions";
import { SETTINGS_ROUTE } from "../../../../../constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import RegisterDetails from "./register-details";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import { pathOr } from "ramda";
import { useEffect } from "react";
import { fetchRegisterSessionsAPI } from "api/registers/register-sessions";
import { fetchRegisterTransactionsAPI } from "api/registers/register-transactions";
import { fetchRegisterAPI } from "api/registers/registers";
import { setSuccessMessage } from "store/registers";

const RegisterForm = ({ mode }: { mode: 'edit' | 'create' }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const register = useAppSelector((state: RootState) => state.registers.register);
  const successMessage = useAppSelector((state: RootState) => state.registers.successMessage);

  useEffect(() => {
    if (successMessage) {
      message.success(successMessage);
      dispatch(setSuccessMessage(null));
     
      if (id) {
        dispatch(fetchRegisterAPI({ registerId: id }));
        dispatch(fetchRegisterSessionsAPI({ registerId: id }));
        dispatch(fetchRegisterTransactionsAPI({ registerId: id }))
      }

      if (!id) {
        navigate(SETTINGS_ROUTE);
      }
    }
  }, [dispatch, id, navigate, successMessage])
  
  useEffect(() => {
    if (id) {
      dispatch(fetchRegisterAPI({ registerId: id }));
      dispatch(fetchRegisterSessionsAPI({ registerId: id }));
      dispatch(fetchRegisterTransactionsAPI({ registerId: id }))
    }
  }, [dispatch, id])

  const items = [
    {
      key: "1",
      label: "Register details",
      children: <RegisterDetails />,
    },
    {
      key: "2",
      label: "Register sessions",
      children: <RegisterSessions />,
    },
    {
      key: "3",
      label: "Register transactions",
      children: <RegisterTransactions />,
    },
  ];
  const breadcrumbItems = [
    { title: <Link to={SETTINGS_ROUTE}>Registers</Link> },
    { title: <span>{id ? `Edit register` : "Create register"}</span> },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">
            {id ? pathOr(null, ['name'], register) : 'Register Form' }
          </p>
          <p className="text-base text-gray-750">
            <Breadcrumb items={breadcrumbItems} />
          </p>
        </div>
      </div>
      <div className="bg-white p-3 rounded-xl">
        <Tabs type="card" defaultActiveKey="1" items={items} />
      </div>
    </div>
  )
}

export default RegisterForm;