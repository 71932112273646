import React, {useEffect, useState} from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import Filters from "./components/filters";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {pathOr} from "ramda";
import { setCurrentPage,setTotalPerPage } from "store/reports/menu-items-reports";
import { AppDispatch, RootState } from "store";
import moment from "moment";
import { fetchMenuItemsSoldByEmployeesAPI } from "api/reports/employees";
import withPermissions from "components/hoc";

interface DataItem {
  key: React.Key;
  firstname: string;
  lastname: string;
  role: string;
  employeeId: string;
  location: string,
}

interface MenuItemDataItem {
  key: React.Key;
  name: string;
  quantity: number;
  unitOfMeasure: string;
  revenue: number;
  location: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

interface MenuItemTableColumn extends ColumnType<MenuItemDataItem> {
  dataIndex: string;
}

const MenuItemsOverTimeReport: React.FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const menuItemsSoldOvertimeByEmployeesReport = useAppSelector(
    (state: RootState) => state.employeesReports.menuItemsSoldOvertimeByEmployeesReport);
  const currencySymbol = useAppSelector((state: any) =>
     pathOr('', ['currencySymbol'], state.auth.currentLocation));
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [filtersVisible, setFiltersVisible] = useState(false);
  const loading = useAppSelector((state: RootState) => state.employeesReports.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.employeesReports.currentPage);
  const total = useAppSelector((state: RootState) => state.employeesReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.employeesReports.totalPerPage);

  useEffect(() => {
    dispatch(fetchMenuItemsSoldByEmployeesAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage])

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const onRefresh = async () => {
    await dispatch(fetchMenuItemsSoldByEmployeesAPI({ refresh: true, page: currentPage, perPage: totalPerPage }))
  }

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenuItemsSoldByEmployeesAPI({ page: current, perPage: size }));
  }

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const dateColumns: any = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "whitespace-nowrap",
      sorter: (a: any, b: any) =>
        pathOr("", ["date"], a).localeCompare(pathOr("", ["date"], b)),
      render: (_: any, record: any) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment.utc(pathOr(null, ['date'], record)).format('Do ddd, MMM YYYY')}
        </p>
      ),
    },
  ]

  const expandedEmployeesTable = (record: any) => {
    return (
      <Table
        columns={employeeColumns}
        dataSource={pathOr([], ["employees"], record).map((employee, index) => {
          return {
            ...employee,
            key: index
          }
        })}
        pagination={false}
        expandable={{
          expandedRowRender: (record: any) => expandedMenuItemsTable(record),
        }}
      />
    );
  }

  const expandedMenuItemsTable = (record: any) => {
    return (
      <Table
        columns={menuItemsColumns}
        dataSource={pathOr([], ["menuItems"], record).map((menuItem, index) => {
          return {
            ...menuItem,
            key: index
          }
        })}
        pagination={false}
      />
    );
  }

  const employeeColumns: TableColumn[] = [
    {
      title: "Id",
      dataIndex: "employeeId",
      key: "employeeId",
      className: "whitespace-nowrap",
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["employeeId"], record)}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["user", "profile", "firstname"], a).localeCompare(pathOr("", ["user", "profile", "lastname"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["user", "profile", "firstname"], record)} {pathOr("", ["user", "profile", "lastname"], record)}
        </p>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["user", "phoneNumber"], record)}
        </p>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["role"], a).localeCompare(pathOr("", ["role"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["role"], record)}
        </p>
      ),
    },
  ];

  const menuItemsColumns: MenuItemTableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: MenuItemDataItem, b: MenuItemDataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: MenuItemDataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["name"], record)}
        </p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: MenuItemDataItem, b: MenuItemDataItem) => pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
      render: (text, record: MenuItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantity"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Expected Revenue",
      dataIndex: "expectedRevenue",
      key: "expectedRevenue",
      className: "whitespace-nowrap",
      sorter: (a: MenuItemDataItem, b: MenuItemDataItem) =>
        pathOr(0, ["expectedRevenue"], a) - pathOr(0, ["expectedRevenue"], b),
      render: (text, record: MenuItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["expectedRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Actual Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      className: "whitespace-nowrap",
      sorter: (a: MenuItemDataItem, b: MenuItemDataItem) =>
        pathOr(0, ["actualRevenue"], a) - pathOr(0, ["actualRevenue"], b),
      render: (text, record: MenuItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["actualRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Lost Revenue",
      dataIndex: "lostRevenue",
      key: "lostRevenue",
      className: "whitespace-nowrap",
      sorter: (a: MenuItemDataItem, b: MenuItemDataItem) =>
        pathOr(0, ["lostRevenue"], a) - pathOr(0, ["lostRevenue"], b),
      render: (text, record: MenuItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["lostRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      className: "whitespace-nowrap",
      sorter: (a: MenuItemDataItem, b: MenuItemDataItem) =>
        pathOr("", ["location"], a).localeCompare(pathOr("", ["location"], b)),
      render: (text, record: MenuItemDataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["location"], record)}
        </p>
      ),
    },
  ]
  return (
     <div>
       <div className="flex items-center justify-between mb-6">
         <div>
           <p className="text-lg font-bold">Menu Items Sold By Employees Overtime Report</p>
           <p className="text-base text-gray-750">Manage your Reports</p>
         </div>
       </div>
       <div className="bg-white p-4 rounded-xl">
       <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
         <Filters onFilterChange={handleFilterChange} visible={filtersVisible} />
         <Table 
            scroll={{ x: 'max-content' }}
            columns={dateColumns}
            loading={loading}
            dataSource={menuItemsSoldOvertimeByEmployeesReport.map((report, index: number) => {
              return {
                ...report,
                key: index,
              }
            })}
            pagination={{
                current: currentPage,
                pageSize: totalPerPage,
                total,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: () => `Total ${total} items`,
                onChange: onPageSizeChange,
                onShowSizeChange: onPageSizeChange,
                pageSizeOptions: [10, 20, 30],
            }}
            expandable={{
              expandedRowRender: (record: any) => expandedEmployeesTable(record),
            }}
          />
       </div>
     </div>
  );
};

export default withPermissions(MenuItemsOverTimeReport);
