import React, { useState } from "react";
import { Button, Modal } from "antd";
import type { ModalProps } from "antd";

export interface modalProps extends ModalProps {
  isOpen: boolean;
  handleClose: (state: boolean) => void;
  children?: React.ReactNode;
}
const ParentModal: React.FC<modalProps> = ({ isOpen, handleClose, children }) => {
  return (
    <div>
      <Modal
        centered
        open={isOpen}
        onOk={() => handleClose(false)}
        onCancel={() => handleClose(false)}
        closeIcon={null}
        footer={false}>
        {children}
      </Modal>
    </div>
  );
};

export default ParentModal;
