import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string()
     .trim()
     .required()
     .label('Restaurant name'),
  type: Joi.string().trim().required().label('Restaurant type'),
  location: Joi.object({
    name: Joi.string().trim().required().label('Location name'),
    openingTime: Joi.optional().label('Opening time'),
    closingTime: Joi.optional().label('Closing time'),
    daysOfOperation: Joi.optional().label('Days of operation'),
    city: Joi.string().trim().required().label('City'),
    town: Joi.string().trim().required().label('Town'),
    country: Joi.string().trim().required().label('Country'),
    address: Joi.string().trim().required().label('Address'),
    zipcode: Joi.string().trim().required().label('Zipcode'),
    currencyName: Joi.string().trim().required().label('Currency name'),
    currencySymbol: Joi.string().trim().label('Currency symbol'),
    currencyIsoCode: Joi.string().trim().label('Currency iso code'),
    countryCode: Joi.string().trim().label('Country code'),
  }).required().label('location'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
