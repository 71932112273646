import { DeleteOutlined, EditOutlined, FolderOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { REGISTERS_ROUTE } from "../../../../constants";
import { pathOr, slice } from "ramda";
import { IoEye } from "react-icons/io5";
import { Link } from "react-router-dom";

interface DataItem {
  id: string;
  name: string;
  type: string;
  website: string;
}

export const fetchRegisterColoumns = (handleModalOpen: any) => [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (text: any, record: DataItem) => (
      <Link 
        to={`${REGISTERS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
        className="underline uppercase font-medium text-[15px] text-gray-750">
        #{slice(0, 5, pathOr('', ['id'], record))}
      </Link>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text: any, record: DataItem) => (
      <p className="font-medium text-[15px] text-gray-750">
        {pathOr('', ['name'], record)}
      </p>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: any, record: DataItem) => (
      <p className="font-medium text-[15px] text-gray-750">
        {pathOr('', ['status'], record)}
      </p>
    ),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    render: (text: any, record: DataItem) => (
      <p className="font-medium text-[15px] text-gray-750">
        {pathOr('', ['location', 'name'], record)}
      </p>
    ),
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    render: (text: any, record: DataItem) => (
       <Space size="middle">
        <Button
          icon={<IoEye style={{ fontSize: 20 }} />}
          type="link"
          onClick={() => handleModalOpen(record, 'archive')}
         />
         <Button
          icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
          type="link"
          href={`${REGISTERS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
         />
         <Button
          icon={<FolderOutlined style={{ fontSize: 20 }} />}
          type="link"
          onClick={() => handleModalOpen(record, 'archive')}
         />
         <Button
          icon={<DeleteOutlined style={{ fontSize: 20 }} />}
          danger
          onClick={() => handleModalOpen(record, 'delete')}
         />
       </Space>
    ),
    width: "10%",
  },
]