import { Checkbox, Input, Select, InputNumber } from "antd";
import React from "react";
import { pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateProduct } from "../../../../store/inventory/inventory";
import { RootState } from "store";

import { unitsOfMeasure } from "../../../../constants";

const ProductDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const product = useAppSelector((state: RootState) => state.inventory.product);
  const errors = useAppSelector((state: RootState) => state.inventory.errors);
  const categories = useAppSelector((state: RootState) => state.categories.categories);

  const handleChange = (val: any, key: string) => {
    dispatch(
      updateProduct({
        [key]: val,
      }),
    );
  };

  // console
  return (
    <div className="">
      <div className="flex flex-row space-x-4 items-end justify-end">
        <div className="">
          <Checkbox
            onChange={(e) => handleChange(e.target.checked, "isPerishable")}
            checked={pathOr(false, ["isPerishable"], product)}
            className="text-base">
            Is Perishable
          </Checkbox>
        </div>
        <div className="">
          <Checkbox
            onChange={(e) => handleChange(e.target.checked, "isPerishable")}
            checked={pathOr(true, ["isTrackable"], product)}
            className="text-base">
            Is Trackable
          </Checkbox>
        </div>
      </div>

      <div className="flex flex-col space-y-4">
        <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
          <div className="w-full md:w-[32%]">
            <span>
              Name<span className="text-red-500">*</span>
            </span>
            <Input
              name="name"
              required
              status={pathOr(null, ["Name"], errors) ? "error" : ""}
              value={pathOr("", ["name"], product)}
              onChange={(e) => handleChange(e.target.value, "name")}
            />
            {pathOr(null, ["Name"], errors) ? (
              <span className="text-red-500">{pathOr("", ["Name", 0, "message"], errors)}</span>
            ) : null}
          </div>
          <div className="w-full md:w-[32%]">
            <span>
              Quantity in stock<span className="text-red-500">*</span>
            </span>
            <InputNumber
              name="quanityInStock"
              required
              className="w-full"
              status={pathOr(null, ["Quantity in stock"], errors) ? "error" : ""}
              value={pathOr("", ["quantityInStock"], product)}
              onChange={(val) => handleChange(Number(val), "quantityInStock")}
            />
            {pathOr(null, ["Quantity in stock"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Quantity in stock", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
          <div className="w-full md:w-[32%]">
            <span>
              Unit of measure<span className="text-red-500">*</span>
            </span>
            <Select
              className="w-full"
              status={pathOr(null, ["Unit of measure"], errors) ? "error" : ""}
              value={pathOr("", ["unitOfMeasure"], product)}
              onChange={(val) => handleChange(val, "unitOfMeasure")}
              options={unitsOfMeasure.map((unit) => {
                return {
                  label: unit,
                  value: unit,
                };
              })}
            />
            {pathOr(null, ["Unit of measure"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Unit of measure", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
        </div>
        <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
          <div className="w-full md:w-[32%]">
            <span>
              Cost Price (each) <span className="text-red-500">*</span>
            </span>
            <InputNumber
              name="costPrice"
              required
              className="w-full"
              status={pathOr(null, ["Cost price"], errors) ? "error" : ""}
              value={pathOr("", ["costPrice"], product)}
              onChange={(val) => handleChange(Number(val), "costPrice")}
            />
            {pathOr(null, ["Cost price"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Cost price", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
          <div className="w-full md:w-[32%]">
            <span>SKU</span>
            <Input
              name="sku"
              status={pathOr(null, ["SKU"], errors) ? "error" : ""}
              value={pathOr("", ["sku"], product)}
              onChange={(e) => handleChange(e.target.value, "sku")}
            />
            {pathOr(null, ["SKU"], errors) ? (
              <span className="text-red-500">{pathOr("", ["SKU", 0, "message"], errors)}</span>
            ) : null}
          </div>
          <div className="w-full md:w-[32%]">
            <span>Barcode</span>
            <Input
              name="barcode"
              status={pathOr(null, ["Barcode"], errors) ? "error" : ""}
              value={pathOr("", ["barcode"], product)}
              onChange={(e) => handleChange(e.target.value, "barcode")}
            />
            {pathOr(null, ["Barcode"], errors) ? (
              <span className="text-red-500">{pathOr("", ["Barcode", 0, "message"], errors)}</span>
            ) : null}
          </div>
        </div>
        <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
          <div className="w-full md:w-[48%]">
            <span>Supplier</span>
            <Input
              name="supplier"
              status={pathOr(null, ["Supplier"], errors) ? "error" : ""}
              value={pathOr("", ["supplier"], product)}
              onChange={(e) => handleChange(e.target.value, "supplier")}
            />
            {pathOr(null, ["Supplier"], errors) ? (
              <span className="text-red-500">{pathOr("", ["Supplier", 0, "message"], errors)}</span>
            ) : null}
          </div>
          <div className="w-full md:w-[48%]">
            <span>Category</span>
            <Select
              className="w-full"
              status={pathOr(null, ["Category"], errors) ? "error" : ""}
              value={pathOr("", ["category"], product)}
              options={categories.map((category: any) => {
                return {
                  label: pathOr("", ["name"], category),
                  value: pathOr("", ["name"], category),
                };
              })}
              onChange={(val) => handleChange(val, "category")}
            />
            {pathOr(null, ["Category"], errors) ? (
              <span className="text-red-500">{pathOr("", ["Category", 0, "message"], errors)}</span>
            ) : null}
          </div>
        </div>
        <div className="mt-3 flex flex-row items-start justify-between">
          <div className="w-full">
            <span>Description</span>
            <Input.TextArea
              rows={4}
              name="description"
              className="w-full"
              status={pathOr(null, ["Description"], errors) ? "error" : ""}
              value={pathOr("", ["description"], product)}
              onChange={(e) => handleChange(e.target.value, "description")}
            />
            {pathOr(null, ["Description"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Description", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
