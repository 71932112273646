import {getAuthorizationHeader, ApiResponse, client} from "./apiUtils";

export const getBusinessApi = async (): Promise<ApiResponse> => {
  try {
    const response = await client(`/business/current`, {
      method: "GET",
      headers: getAuthorizationHeader(),
    });
    return response.data
  } catch (error: any) {
    throw new Error(`Error during fetching business: ${error.message}`);
  }
};

export const updateBusinessApi = async (businessData: any): Promise<ApiResponse> => {
  try {
    const response = await client(`/business`, {
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: businessData,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(`Error during updating business: ${error.message}`);
  }
};
