import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchSalesReportByLocationAPI } from "../../../../api/reports/sales";
import { pathOr } from "ramda";
import moment from "moment";
import { setCurrentPage, setTotalPerPage, updateFilters } from "store/reports/sales-reports";
import { RootState } from "store";
import withPermissions from "components/hoc";

interface DataItem {
  key: React.Key;
  date: string;
  grossSales: number;
  netSales: number;
  discounts: number;
  taxes: number;
  tips: number;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const { RangePicker } = DatePicker;

const SalesReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const salesReport = useAppSelector((state: any) => state.salesReports.salesReport);
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const [filtersVisible, setFiltersVisible] = useState(false);
  const loading = useAppSelector((state: RootState) => state.salesReports.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.salesReports.currentPage);
  const total = useAppSelector((state: RootState) => state.salesReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.salesReports.totalPerPage);
  const filters = useAppSelector((state: RootState) => state.salesReports.filters);

  useEffect(() => {
    dispatch(fetchSalesReportByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const onRefresh = async () => {
    await dispatch(
      fetchSalesReportByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchSalesReportByLocationAPI({ page: current, perPage: size }));
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onDateChange = (date: any, value: any) => {
    dispatch(updateFilters({
     fromDate: value[0] ? moment.utc(value[0]).format('YYYY-MM-DD') : null,
     toDate: value[1] ? moment.utc(value[1]).format('YYYY-MM-DD') : null,
   }));
   if (!value[0] && !value[1]) {
     dispatch(
        fetchSalesReportByLocationAPI({ 
          page: currentPage,
          perPage: totalPerPage,
          ...filters,
          fromDate: null,
          toDate: null
        }
      )
     );
   }
 }

 const confirmDates = async () => {
   await dispatch(
    fetchSalesReportByLocationAPI({ 
         page: currentPage,
         perPage: totalPerPage,
         ...filters,
       }
     )
   );
 }

  const columns: TableColumn[] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["date"], a).localeCompare(pathOr("", ["date"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment(pathOr("", ["rawDate"], record)).format("Do ddd MMMM, YYYY")}
        </p>
      ),
    },
    {
      title: "Gross Sales",
      dataIndex: "grossSales",
      key: "grossSales",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["grossSales"], a) - pathOr(0, ["grossSales"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["grossSales"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Net Sales",
      dataIndex: "netSales",
      key: "netSales",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0, ["netSales"], a) - pathOr(0, ["netSales"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["netSales"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Refunds",
      dataIndex: "refunds",
      key: "refunds",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["refunds"], a) - pathOr(0, ["refunds"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["refunds"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Discounts",
      dataIndex: "discounts",
      key: "discounts",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["discounts"], a) - pathOr(0, ["discounts"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["discounts"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Taxes",
      dataIndex: "taxes",
      key: "taxes",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0, ["taxes"], a) - pathOr(0, ["taxes"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["taxes"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Tips",
      dataIndex: "tips",
      key: "tips",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0, ["tips"], a) - pathOr(0, ["tips"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["tips"], record).toLocaleString()}
        </p>
      ),
    },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Sales Report</p>
          <p className="text-base text-gray-750">Manage your Reports</p>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Space>
            <Button
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              onClick={toggleFilters}
              icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
            />
           <RangePicker onChange={onDateChange} allowClear />
           {filters.fromDate && filters.toDate ? <Button 
           onClick={confirmDates}
           className="ml-2">Confirm</Button> : null}
          </Space>
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Table
          scroll={{ x: "max-content" }}
          loading={loading}
          columns={columns}
          dataSource={salesReport}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default withPermissions(SalesReport);
