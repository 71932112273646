import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  SearchOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import { useNavigate } from "react-router-dom";
import DeleteModal from "components/modal/DeleteModal";
import { PERMISSION_SETS_ROUTE } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Link } from "react-router-dom";
import { length, pathOr } from "ramda";
import { deletePermissionSetAPI, fetchPermissionSetsByBusinessAPI } from "../../../api/permissions";
import { setCurrentPage, setTotalPerPage } from "../../../store/permissions/permissions";
import { RootState } from "store";
import withPermissions from "components/hoc";
import ProtectedWrapper from "components/protected-wrapper";
import { 
  CAN_CREATE_PERMISSION_SET,
  CAN_DELETE_PERMISSION_SET,
  CAN_UPDATE_PERMISSION_SET
 } from "permissions";
interface DataItem {
  id: string;
  name: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const PermissionSets = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const permissionSets = useAppSelector((state: any) => state.permissions.permissionSets);
  const currentPage = useAppSelector((state: RootState) => state.permissions.currentPage);
  const total = useAppSelector((state: RootState) => state.permissions.total);
  const totalPerPage = useAppSelector((state: RootState) => state.permissions.totalPerPage);
  const isLoading = useAppSelector((state: any) => state.permissions.isLoading);
  const isSubmitting = useAppSelector((state: any) => state.permissions.isSubmitting);
  const [permission, setPermission] = useState<any>({});
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    dispatch(
      fetchPermissionSetsByBusinessAPI({ page: currentPage, perPage: totalPerPage }),
    );
  }, [currentPage, dispatch, totalPerPage]);

  const columns: TableColumn[] = [
    {
      title: "Permission Set",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Link to={`${PERMISSION_SETS_ROUTE}/${record.id}/edit`}>
          <p className="font-medium text-base capitalize">{pathOr("", ["name"], record)}</p>
        </Link>
      ),
    },
    {
      title: "Employees",
      dataIndex: "employees",
      key: "employees",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        length(pathOr([], ["employees"], a)) - length(pathOr([], ["employees"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base">{length(pathOr([], ["employees"], record))}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => (
        <Space size="middle">
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_PERMISSION_SET]}>
            <Button
              onClick={() => navigate(`${PERMISSION_SETS_ROUTE}/${record.id}/edit`)}
              icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
              type="link"
            />
          </ProtectedWrapper>

          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_DELETE_PERMISSION_SET]}>
            <Button
              icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => handleDeletePermission(record)}
            />
          </ProtectedWrapper>
        </Space>
      ),
      width: "10%",
    },
  ];

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };

  const handleDeletePermission = (permission: any) => {
    setPermission(permission);
    setModalState({ isOpen: true, type: "deleteModal" });
  };

  const confirmDeletePermission = async () => {
    await dispatch(
      deletePermissionSetAPI({
        permissionSetId: pathOr("", ["id"], permission),
      }),
    );
    await dispatch(
      fetchPermissionSetsByBusinessAPI({ page: currentPage, perPage: totalPerPage }),
    );
  };

  const onRefresh = async () => {
    await dispatch(
      fetchPermissionSetsByBusinessAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchPermissionSetsByBusinessAPI({ page: current, perPage: size }));
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap">
        <div>
          <p className="text-lg font-bold">Permission Sets</p>
          <p className="text-base text-gray-750">Manage your permission sets here</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_PERMISSION_SET]}>
            <Button
              onClick={() => navigate(`${PERMISSION_SETS_ROUTE}/create`)}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Create Permission Set
            </Button>
          </ProtectedWrapper>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <div className="overflow-x-scroll no-scroll">
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={permissionSets}
            loading={isLoading}
            pagination={{
              current: currentPage,
              pageSize: totalPerPage,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: () => `Total ${total} items`,
              onChange: onPageSizeChange,
              onShowSizeChange: onPageSizeChange,
              pageSizeOptions: [10, 20, 30],
            }}
          />
          <DeleteModal
            isOpen={modalState.isOpen && modalState.type === "deleteModal"}
            handleClose={handleClose}
            cancelDelete={handleClose}
            confirmDelete={confirmDeletePermission}
            isDeleting={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default withPermissions(PermissionSets);
