import React, {useEffect, useState} from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { Button, DatePicker, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {pathOr} from "ramda";
import {fetchMenuItemsOverTimeReportByLocationAPI} from "../../../../api/reports/menu-items";
import { setCurrentPage,setTotalPerPage, updateFilters } from "store/reports/menu-items-reports";
import { AppDispatch, RootState } from "store";
import moment from "moment";
import { getTimeframeDates } from "helpers/utilities";
import withPermissions from "components/hoc";
interface DataItem {
  key: React.Key;
  name: string;
  quantity: number,
  unitOfMeasure: string,
  revenue: number,
  location: string,
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const { RangePicker } = DatePicker;

const MenuItemsOverTimeReport: React.FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const menuItemsReport = useAppSelector((state: RootState) => state.menuItemsReports.menuItemsReport);
  const currencySymbol = useAppSelector((state: RootState) =>
     pathOr('', ['currencySymbol'], state.auth.currentLocation));
  const [filtersVisible, setFiltersVisible] = useState(false);
  const loading = useAppSelector((state: RootState) => state.menuItemsReports.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.menuItemsReports.currentPage);
  const total = useAppSelector((state: RootState) => state.menuItemsReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.menuItemsReports.totalPerPage);
  const filters = useAppSelector((state: RootState) => state.menuItemsReports.filters);
  const { fromDate, toDate } = getTimeframeDates('this_month');

  useEffect(() => {
    dispatch(fetchMenuItemsOverTimeReportByLocationAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      ...filters,
    }));
  }, [currentPage, dispatch, totalPerPage])

  const onRefresh = async () => {
    await dispatch(fetchMenuItemsOverTimeReportByLocationAPI({ 
      refresh: true,
      page: currentPage,
      perPage: totalPerPage,
      ...filters
    }))
  }

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenuItemsOverTimeReportByLocationAPI({ 
      page: current,
      perPage: size,
      ...filters
    }));
  }

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onDateChange = (date: any, value: any) => {
     dispatch(updateFilters({
      fromDate: value[0] ? moment.utc(value[0]).format('YYYY-MM-DD') : null,
      toDate: value[1] ? moment.utc(value[1]).format('YYYY-MM-DD') : null,
    }));
    if (!value[0] && !value[1]) {
      dispatch(
        fetchMenuItemsOverTimeReportByLocationAPI({ 
            page: currentPage,
            perPage: totalPerPage,
            ...filters,
            fromDate: null,
            toDate: null
          }
        )
      );
    }
  }

  const confirmDates = async () => {
    await dispatch(
      fetchMenuItemsOverTimeReportByLocationAPI({ 
          page: currentPage,
          perPage: totalPerPage,
          ...filters,
        }
      )
    );
  }

  const dateColumns: any = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "whitespace-nowrap",
      sorter: (a: any, b: any) =>
        pathOr("", ["date"], a).localeCompare(pathOr("", ["date"], b)),
      render: (_: any, record: any) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment.utc(pathOr(null, ['date'], record)).format('Do ddd, MMM YYYY')}
        </p>
      ),
    },
  ]

  const expanded = (record: any) => {
    return (
      <Table
        columns={columns}
        dataSource={pathOr([], ["menuItemsReport"], record)}
        pagination={false}
      />
    );
  }

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr('',['name'],a).localeCompare(pathOr('',['name'],b)),
      render: (text, record: DataItem) => (
         <p className="font-medium text-base text-gray-750 whitespace-nowrap">
           {pathOr('',['name'],record)}
         </p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0,['quantity'],a) - pathOr(0,['quantity'],b),
      render: (text, record: DataItem) => <p className="font-medium text-base text-gray-750">
        {pathOr(0,['quantity'],record).toLocaleString()}
      </p>,
    },
    {
      title: "Expected Revenue",
      dataIndex: "expectedRevenue",
      key: "expectedRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0,['expectedRevenue'],a) - pathOr(0,['expectedRevenue'],b),
      render: (text, record: DataItem) => <p className="font-medium text-base text-gray-750">
        {currencySymbol} {pathOr(0,['expectedRevenue'],record).toLocaleString()}
      </p>,
    },
    {
      title: "Actual Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0,['actualRevenue'],a) - pathOr(0,['actualRevenue'],b),
      render: (text, record: DataItem) => <p className="font-medium text-base text-gray-750">
        {currencySymbol} {pathOr(0,['actualRevenue'],record).toLocaleString()}
      </p>,
    },
    {
      title: "Lost Revenue",
      dataIndex: "lostRevenue",
      key: "lostRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0,['lostRevenue'],a) - pathOr(0,['lostRevenue'],b),
      render: (text, record: DataItem) => <p className="font-medium text-base text-gray-750">
        {currencySymbol} {pathOr(0,['lostRevenue'],record).toLocaleString()}
      </p>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr('',['location'],a).localeCompare(pathOr('',['location'],b)),
      render: (text, record: DataItem) => (
         <p className="font-medium text-base text-gray-750 whitespace-nowrap">
           {pathOr('',['location'],record)}
         </p>
      ),
    },
  ];
  return (
     <div>
       <div className="flex items-center justify-between mb-6">
         <div>
           <p className="text-lg font-bold">Menu Items Overtime Report</p>
           <p className="text-base text-gray-750">Manage your Reports</p>
         </div>
       </div>
       <div className="bg-white p-4 rounded-xl">
       <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Space>
            <Button
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              onClick={toggleFilters}
              icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
            />
           <RangePicker onChange={onDateChange} allowClear />
           {filters.fromDate && filters.toDate ? <Button 
           onClick={confirmDates}
           className="ml-2">Confirm</Button> : null}
          </Space>
          
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
         <Table 
            scroll={{ x: 'max-content' }}
            columns={dateColumns}
            loading={loading}
            dataSource={menuItemsReport}
            pagination={{
                current: currentPage,
                pageSize: totalPerPage,
                total,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: () => `Total ${total} items`,
                onChange: onPageSizeChange,
                onShowSizeChange: onPageSizeChange,
                pageSizeOptions: [10, 20, 30],
            }}
            expandable={{
                expandedRowRender: (record: any) => expanded(record),
              }}
          />
       </div>
     </div>
  );
};

export default withPermissions(MenuItemsOverTimeReport);
