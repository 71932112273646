import { createSlice } from "@reduxjs/toolkit";
import { pathOr } from "ramda";
import {
  fetchOrdersByRestaurantLocationsAPI,
  fetchOrdersReportByBusinessAPI,
  fetchOrdersReportByLocationAPI,
  fetchOrdersReportByRestaurantAPI,
} from "../../api/reports/orders";

interface OrdersReportsState {
  ordersReport: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: OrdersReportsState = {
  ordersReport: [],
  status: "idle",
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  filters: {
    fromDate: null,
    toDate: null
  },
};

const ordersReportsSlice = createSlice({
  name: "orders-reports",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersReportByLocationAPI.fulfilled, (state, action) => {
      state.ordersReport = pathOr([], ["ordersReport"], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrdersReportByRestaurantAPI.fulfilled, (state, action) => {
      state.ordersReport = pathOr([], ["ordersReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrdersReportByBusinessAPI.fulfilled, (state, action) => {
      state.ordersReport = pathOr([], ["ordersReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrdersByRestaurantLocationsAPI.fulfilled, (state, action) => {
      state.ordersReport = pathOr([], ["ordersReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersByRestaurantLocationsAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersByRestaurantLocationsAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = ordersReportsSlice.actions;

export default ordersReportsSlice.reducer;
