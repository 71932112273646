import {getAuthorizationHeader, client, handleAxiosError} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const createRegisterTransactionAPI = createAsyncThunk(
   'registers/create-register-transaction',
   async (
      payload: { transaction: any; sessionId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/register-sessions/${payload.sessionId}/register-transactions`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: {
          ...payload.transaction,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateRegisterTransactionAPI = createAsyncThunk(
   'registers/update-register-transaction',
   async (
      payload: { session: any; sessionId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/register-sessions/${payload.sessionId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.session,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchRegisterTransactionAPI = createAsyncThunk(
   'registers/fetch-register-transaction',
   async (
      payload: { sessionId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/register-sessions/${payload.sessionId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchRegisterTransactionsAPI = createAsyncThunk(
  'registers/fetch-register-transactions',
  async (
     payload: { 
      page?: number;
      perPage?: number;
      refresh?: boolean;
      registerId: string;
    }, thunkAPI
  ) => {
    try {
      const response = await client(`/registers/${payload.registerId}/register-transactions`, {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);
