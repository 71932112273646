import React, {useState} from "react";
import ParentModal from "components/modal/Modal";
import {Button, Input, InputNumber, message, Select, Space} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {pathOr} from "ramda";
import { refundMethods } from "../../../../constants";
import { createRefund } from "store/orders/orders";

const OrderRefundModal = ({isOpen, onClose}: { isOpen: boolean; onClose: () => void; }) => {
  const dispatch = useAppDispatch();
  const transaction = useAppSelector((state: any) => pathOr(null, ['order', 'transaction'], state.orders));
  const totalAmountRefundable =  pathOr(0, ['amountRefundable'], transaction);
  const [refundMethod, setRefundMethod] = useState<'CASH' | 'MOBILE_MONEY' | 'BANK_CARD' | 'CREDIT' | ''>('');
  const [amountRefunded, setAmountRefunded] = useState<any>('');
  const [transactionId, setTransactionId] = useState<any>('');
  const currencySymbol = useAppSelector((state: any) =>
     pathOr('', ['currentLocation', 'currencySymbol'], state.auth));

  const confirm = () => {
    if (Number(amountRefunded) < 1) {
      return message.error('please refund an amount above 0');
    }
    if (Number(amountRefunded) > totalAmountRefundable) {
      return message.error('amount refunded should not be higher than refundable amount');
    }
    if (!refundMethod) {
      return message.error('please select a refund method');
    }
    dispatch(createRefund({
      amountRefunded: Number(amountRefunded),
      refundMethod,
      transactionId
    })) 
    clearAndClose()
  }

  const clearAndClose = () => {
    setRefundMethod('');
    setAmountRefunded('');
    setTransactionId('');
    onClose();
  }

  const cancel = () => {
    clearAndClose();
  }
  return (
     <ParentModal isOpen={isOpen} handleClose={onClose}>
       <div className="w-full">
         <div>
           <h3 className="text-2xl font-medium">Make a refund</h3>
         </div>
         <div className="mt-5 flex flex-col space-y-2">
          <div className="w-full">
            <span>Refund Method</span>
            <Select
              showSearch
              placeholder="Search refund Method"
              onChange={(val) => setRefundMethod(val)}
              value={refundMethod}
              options={refundMethods.map((method: any) => {
                return {
                  label: method.label,
                  value: method.value,
                  }
                })}
              
              className="w-full"
            />
          </div>

           <div className="w-full">
             <span>Amount Refunded</span>
             <InputNumber value={amountRefunded} onChange={(val) => setAmountRefunded(val)} className="w-full" />
           </div>

           <div className="w-full">
             <span>Transaction ID</span>
             <Input value={transactionId} onChange={(e) =>
                setTransactionId(e.target.value)} />
           </div>
         </div>

         <div className="mt-5 space-x-3 flex justify-between">
           <div>
             <Button>
               Amount Refundable: {currencySymbol} {totalAmountRefundable.toLocaleString()}
             </Button>
           </div>
           <div>
             <Space>
               <Button onClick={cancel}>Cancel</Button>
               <Button onClick={confirm} type="primary" className="bg-blue-750">Confirm</Button>
             </Space>
           </div>
         </div>
       </div>
     </ParentModal>
  )
}

export default OrderRefundModal;

