import React, { useState } from "react";
import {Card, Form, Checkbox, Row, Button} from "antd";
import { Link } from "react-router-dom";
import { FormInput } from "components/formInput/FormInput";
import { UserTypes } from "types/UserTypes";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {signUpAPI} from "../../../../api/auth";
import { FormSelect } from "components/formSelect/FormSelect";

interface FormErrors {
  [key: string]: string;
}

const SignupForm = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const isLoading = useAppSelector((state: any) => state.auth.isLoading);
  const [signInError, setSignInError] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [formValues, setFormValues] = useState<UserTypes>({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    password: "",
    confirmPassword: "",
    productType: "",
  });

  const handleCheckboxChange = (e: CheckboxChangeEvent) => setCheckboxChecked(e.target.checked);

  const handleChange = (name: string) => (value: string) =>
    setFormValues((prev) => ({ ...prev, [name]: value }));

  const onFinish = async () => {
    // Validate each field manually
    const newErrors: FormErrors = {};
    Object.keys(formValues).forEach((fieldName) => {
      const value = formValues[fieldName];
      if (
        inputConfigs.some((config) => config.name === fieldName && config.isRequired) &&
        (value === undefined || value.trim() === "")
      ) {
        newErrors[fieldName] = "This field is required";
      }
    });

    // Set errors and return if there are validation errors
    form.setFields(
      Object.keys(newErrors).map((fieldName) => ({
        name: fieldName,
        errors: [newErrors[fieldName]],
      })),
    );

    if (Object.keys(newErrors).length > 0 || !checkboxChecked) {
      return;
    }

    const { confirmPassword, ...restFormValues } = formValues;
    await dispatch(signUpAPI({user: restFormValues}));
  };

  const inputConfigs = [
    { label: "First name", name: "firstname", isRequired: true },
    { label: "Last name", name: "lastname", isRequired: true },
    { label: "Email", name: "email", placeholder: "mail@simmmple.com", isRequired: true },
    { label: "Phone number", name: "phoneNumber", isRequired: false }, // Example of a non-required field
    { label: "Password", name: "password", isPassword: true, isRequired: true },
    { label: "Confirm Password", name: "confirmPassword", isPassword: true, isRequired: true },
    { label: "Business", name: "businessName", isRequired: true },
    {
      label: "Product Type",
      name: "productType",
      options: [
        { value: "RETAIL", label: "Retail" },
        { value: "RESTAURANT", label: "Restaurant" },
      ],
      isRequired: true,
    },
  ];

  return (
    <Card className="w-full lg:w-3/5 mb-6 mx-auto ">
      <p className="mb-3 font-bold text-4xl text-center">Sign Up</p>
      <p className="mb-9 text-base font-normal text-center">
        Enter your email and password to sign up!
        <br />
        {signInError && (
          <span className="text-sm text-red-400">Email or Phone already in use!</span>
        )}
      </p>
      <Form layout="vertical" form={form} className="w-full">
        {inputConfigs.map((config) => (
          <div key={config.name} className="p-2 w-full">
            {config.name === "productType" ? (
              <FormSelect
                {...config}
                className={"mb-0"}
                value={formValues[config.name]}
                onChange={handleChange(config.name)}
              />
            ) : (
              <FormInput
                {...config}
                value={formValues[config.name]}
                onChange={handleChange(config.name)}
              />
            )}
          </div>
        ))}
        <Form.Item>
          <Checkbox onChange={handleCheckboxChange} className='text-base'>
            By creating an account you agree to the{' '}
            <Link className="text-blue-750" to="">Terms and Conditions</Link> and our{' '}
            <Link className="text-blue-750" to="">Privacy Policy</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item className="w-full flex flex-row items-center justify-center">
          <Button
             loading={isLoading}
             className="w-[200px] basis-9 h-9 bg-blue-750"
             type="primary"
             disabled={!checkboxChecked}
             onClick={onFinish}
          >Signup</Button>
        </Form.Item>
      </Form>
      <Row justify="center" align="middle" style={{ marginBottom: "24px" }}>
        <span style={{ color: "secondaryGray.600", fontWeight: "400", fontSize: "sm" }}>
          Already a member?
        </span>
        <Link to="/auth/sign-in">
          <span className="text-blue-750 ml-2">Sign in</span>
        </Link>
      </Row>
    </Card>
  );
};

export default SignupForm;
