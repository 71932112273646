import {ColumnType} from "antd/lib/table";
import React from "react";
import moment from "moment/moment";
import {pathOr, replace} from "ramda";

interface OrdersDataItem {
  key: React.Key;
  createdAt: string;
  orderNumber: number;
  totalAmount: number;
  tipAmount: number;
  discount: number;
  discountAmount: number;
  stage: string;
  isPaid: boolean;
  items: number;
}

interface InventoryDataItem {
  key: React.Key;
  name: string;
  quantity: number;
  unitOfMeasure: string;
  revenue: number
}

interface MenuItemDataItem {
  key: React.Key;
  name: string;
  total: number;
  revenue: number
}

interface MenuModifierItemDataItem {
  key: React.Key;
  name: string;
  total: number;
  revenue: number
}

interface TableColumn extends ColumnType<OrdersDataItem & MenuModifierItemDataItem & InventoryDataItem & MenuItemDataItem> {
  dataIndex: string;
}

const inventoryColumns: TableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    className: "whitespace-nowrap",
    render: (text, record: InventoryDataItem) => (
       <p className="font-medium text-base text-gray-750 whitespace-nowrap">{text}</p>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    className: "whitespace-nowrap",
    render: (text, record: InventoryDataItem) => (
       <p className="font-medium text-base text-gray-750 whitespace-nowrap">{text}</p>
    ),
  },
  {
    title: "Unit",
    dataIndex: "unitOfMeasure",
    key: "unitOfMeasure",
    className: "whitespace-nowrap",
    render: (text, record: InventoryDataItem) => (
       <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">{text}</p>
    ),
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    className: "whitespace-nowrap",
    render: (text, record: InventoryDataItem) => (
       <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">{text.toLocaleString()}</p>
    ),
  }
]

const menuItemColumns: TableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr('', ['name'], record)}
      </p>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr(0, ['quantity'], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Expected Revenue",
    dataIndex: "total",
    key: "total",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
       <p className="font-medium text-base text-gray-750 whitespace-nowrap">
         {pathOr(0, ['expectedRevenue'], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Actual Revenue",
    dataIndex: "revenue",
    key: "revenue",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
      <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">
         {pathOr(0, ['actualRevenue'], record).toLocaleString()}
      </p>
    ),
  }
];

const menuModifierItemColumns: TableColumn[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr('', ['name'], record)}
      </p>
    ),
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr(0, ['quantity'], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Expected Revenue",
    dataIndex: "total",
    key: "total",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
       <p className="font-medium text-base text-gray-750 whitespace-nowrap">
         {pathOr(0, ['expectedRevenue'], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Actual Revenue",
    dataIndex: "revenue",
    key: "revenue",
    className: "whitespace-nowrap",
    render: (text, record: MenuItemDataItem) => (
      <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">
         {pathOr(0, ['actualRevenue'], record).toLocaleString()}
      </p>
    ),
  }
];

const orderColumns: TableColumn[] = [
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    className: "whitespace-nowrap",
    sorter: (a: OrdersDataItem, b: OrdersDataItem) => pathOr('', ['createdAt'], a).localeCompare(
       pathOr('', ['createdAt'], b)),
    render: (text, record: OrdersDataItem) => (
       <p className="font-medium text-base text-gray-750 whitespace-nowrap">
         {moment(pathOr('', ['createdAt'], record)).format('Do ddd MMM, YYYY hh:mm A')}
       </p>
    ),
  },
  {
    title: "Order Number",
    dataIndex: "orderNumber",
    key: "orderNumber",
    className: "whitespace-nowrap",
    render: (text, record: OrdersDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        #{pathOr('', ['orderNumber'], record)}
      </p>
    ),
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    className: "whitespace-nowrap",
    render: (text, record: OrdersDataItem) => (
       <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr(0, ['totalAmount'], record).toLocaleString()}
        </p>
    ),
  },
  {
    title: "Tip Amount",
    dataIndex: "tipAmount",
    key: "tipAmount",
    className: "whitespace-nowrap",
    render: (text, record: OrdersDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr(0, ['tipAmount'], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Stage",
    dataIndex: "stage",
    key: "stage",
    className: "whitespace-nowrap",
    render: (text, record: OrdersDataItem) => (
      <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">
        {replace(/_/g, ' ', pathOr('', ['stage'], record))}
      </p>
    ),
  },
  {
    title: "Payment Status",
    dataIndex: "isPaid",
    key: "isPaid",
    className: "whitespace-nowrap",
    render: (text, record: OrdersDataItem) => (
       <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">
         {pathOr(false, ['stage'], record) ? 'Paid': 'Unpaid'}
       </p>
    ),
  },
];

export const employeeReportTableColumns: any = {
  createdOrders: orderColumns,
  servedOrders: orderColumns,
  inventory: inventoryColumns,
  menuItemsSold: menuItemColumns,
  menuModifierItemsSold: menuModifierItemColumns,
}