import React, { useEffect, useState } from "react";
import {
  EyeOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import Filters from "./components/filters";
import { Link } from "react-router-dom";
import { pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchEmployeesReportByLocationAPI } from "../../../../api/reports/employees";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "store/reports/employees-reports";

interface DataItem {
  key: React.Key;
  id: string;
  name: string;
  phoneNumber: string;
  totalOrders: number;
  employeeId: string;
  role: string;
  sex: string;
  totalMenuItemsSold: number;
  totalMenuModifierItemsSold: number;
  locations: string;
  restaurants: string;
  revenue: number;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const EmployeesReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const employeesReport = useAppSelector((state: any) => state.employeesReports.employeesReport);
  const loading = useAppSelector((state: RootState) => state.employeesReports.isLoading);
  const currencySymbol = useAppSelector((state: RootState) =>
    pathOr("", ["currentLocation", "currencySymbol"], state.auth),
  );
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const currentPage = useAppSelector((state: RootState) => state.employeesReports.currentPage);
  const total = useAppSelector((state: RootState) => state.employeesReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.employeesReports.totalPerPage);

  useEffect(() => {
    dispatch(
      fetchEmployeesReportByLocationAPI({
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  }, [currentPage, dispatch, totalPerPage]);

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onRefresh = async () => {
    await dispatch(
      fetchEmployeesReportByLocationAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchEmployeesReportByLocationAPI({ page: current, perPage: size }));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const columns: TableColumn[] = [
    {
      title: "Id",
      dataIndex: "employeeId",
      key: "employeeId",
      className: "whitespace-nowrap",
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["employeeId"], record)}
        </p>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["name"], record)}
        </p>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["phoneNumber"], record)}
        </p>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["role"], a).localeCompare(pathOr("", ["role"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["role"], record)}
        </p>
      ),
    },
    {
      title: "Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["actualRevenue"], a) - pathOr(0, ["actualRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {Number(pathOr("", ["actualRevenue"], record)).toLocaleString()}
        </p>
      ),
    },
    {
      title: "locations",
      dataIndex: "locations",
      key: "locations",
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr([], ["locations"], record).map((location) => (
            <p>{location}</p>
          ))}
        </p>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => (
        <Space size="middle">
          <Link
            to={`/admin/reports/employees-report/${pathOr("", ["id"], record)}/view`}
            onClick={(e) => e.stopPropagation()}
            className="font-medium text-[15px]">
            <EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />
          </Link>
        </Space>
      ),
      width: "10%",
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Employees Report</p>
          <p className="text-base text-gray-750">Manage your Reports</p>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Filters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={employeesReport}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default EmployeesReport;
