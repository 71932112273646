import React, {useEffect} from 'react';
import LocationForm from '../components/location-form';
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../../../../store/hooks";
import {fetchLocationAPI} from "../../../../../api/locations";
import withPermissions from 'components/hoc';

const EditLocation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(fetchLocationAPI({locationId: id}));
    }
  }, [dispatch, id])
  return <LocationForm mode="edit" />;
};

export default withPermissions(EditLocation);
