import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import { AppDispatch } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pathOr } from "ramda";
import { fetchMenuItemsReportByLocationAPI } from "api/reports/menu-items";

interface DataItem {
  key: React.Key;
  name: string;
  quantity: number;
  unitOfMeasure: string;
  revenue: number;
  location: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const HighestSellingMenuItems = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const menuItemsReport = useAppSelector(
    (state: any) => state.menuItemsReports.menuItemsReport);

  useEffect(() => {
    dispatch(fetchMenuItemsReportByLocationAPI({ 
      page: 1,
      perPage: 10,
      actualRevenue: 'sort_high_to_low',
    }));
  }, [dispatch]);

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["name"], record)}
        </p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantity"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Actual Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["actualRevenue"], a) - pathOr(0, ["actualRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["actualRevenue"], record).toLocaleString()}
        </p>
      ),
    },
  ];

  return (
    <div className="bg-white p-4 rounded-xl">
      <div>
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Highest Selling Menu Items
        </h4>
      </div>
      <div className="overflow-x-scroll no-scroll mt-5">
        <Table 
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={menuItemsReport.filter((menuItem: any) => pathOr(0, ['quantity'], menuItem) > 0)}
          pagination={false}
          className="h-[320px]"
        />
      </div>
    </div>
  );
};

export default HighestSellingMenuItems;
