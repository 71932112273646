import moment from "moment";
import {getCookie} from "../../helpers/utilities";
import {client, getAuthorizationHeader} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchMenuItemsReportByLocationAPI = createAsyncThunk(
   'menu-items-report/location',
   async (
    payload: { 
      refresh?: boolean;
      page?: number;
      perPage?: number;
      actualRevenue?: string;
    }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      if (payload.actualRevenue) {
        params.append('actualRevenue', payload.actualRevenue);
      }
      params.append("type", "location");
      params.append("id", getCookie('currentLocation'));
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(`/menu-items-report?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
      });
      return response.data
     } catch (error) {
       throw new Error(`failed to fetch menuItems`);
     }
   }
);

export const fetchMenuItemsOverTimeReportByLocationAPI = createAsyncThunk(
  'menu-items-overtime-report/location',
  async (
   payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    fromDate: any;
    toDate: any
  }, thunkAPI
  ) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("fromDate", payload.fromDate);
      params.append("toDate", payload.toDate);
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("id", getCookie('currentLocation'));
      params.append("type", "location");
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const url = `/menu-items-report/overtime?` + params.toString();
      const response = await client(url, {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch menu items overtime report`);
    }
  }
);

export const fetchMenuItemsReportByRestaurantAPI = createAsyncThunk(
   'menu-items-report/restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/menu-items-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch menuItems`);
     }
   }
)

export const fetchMenuItemsReportByBusinessAPI = createAsyncThunk(
   'menu-items-report/business',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-items-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch menuItems`);
     }
   }
)
