import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { pathOr } from "ramda";
import {
  archiveMenuItemAPI,
  createMenuItemAPI,
  createMenuItemIngredientAPI,
  deleteMenuItemAPI,
  deleteMenuItemIngredientAPI,
  deleteMenuItemModifierGroupAPI,
  fetchMenuItemAPI,
  fetchMenuItemIngredientAPI,
  fetchMenuItemsByLocationAPI,
  fetchMenuItemsByMenuGroupAPI,
  fetchMenuItemsByRestaurantAPI,
  updateMenuItemAPI,
  updateMenuItemIngredientAPI,
} from "../../api/menu-items";
import { MENU_GROUPS_ROUTE } from "../../constants";
import moment from "moment";
import { UploadFile } from "antd/lib";

interface MenuItemsState {
  menuItems: any;
  menuItem: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  menuItemIngredient: any;
  errors: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  menuItemImageFiles: UploadFile[];
}

const initialState: MenuItemsState = {
  menuItems: [],
  menuItem: null,
  menuItemImageFiles: [],
  status: "idle",
  error: null,
  isLoading: false,
  isSubmitting: false,
  menuItemIngredient: null,
  errors: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
};

const menuItemsSlice = createSlice({
  name: "menuItems",
  initialState,
  reducers: {
    setMenuItemLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setMenuItemImageFiles: (state, action) => {
      state.menuItemImageFiles = action.payload;
    },
    setMenuItem: (state, action) => {
      state.menuItem = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    updateMenuItem: (state, action) => {
      state.menuItem = {
        ...state.menuItem,
        ...action.payload,
      };
    },
    setMenuItemIngredient: (state, action) => {
      state.menuItemIngredient = action.payload;
    },
    updateMenuItemIngredient: (state, action) => {
      state.menuItemIngredient = {
        ...state.menuItemIngredient,
        ...action.payload,
      };
      const ingredients = pathOr([], ["ingredients"], state.menuItem).map((ingredient) => {
        if (pathOr(null, ["id"], ingredient) === pathOr("", ["id"], state.menuItemIngredient)) {
          return {
            ...state.menuItemIngredient,
            ...action.payload,
          };
        }
        return {
          ...ingredient,
        };
      });

      state.menuItem = {
        ...state.menuItem,
        ingredients,
      };
    },
    addMenuItemIngredient: (state, action) => {
      const ingredients = pathOr([], ["ingredients"], state.menuItem);
      state.menuItem = {
        ...state.menuItem,
        ingredients: [
          ...ingredients,
          {
            id: moment().valueOf(),
            ...action.payload,
          },
        ],
      };
    },
    removeMenuItemIngredient: (state, action) => {
      const menuItemIngredientId = action.payload;
      const ingredients = pathOr([], ["ingredients"], state.menuItem).filter(
        (ingredient: any) => pathOr(null, ["id"], ingredient) !== menuItemIngredientId,
      );
      state.menuItem = {
        ...state.menuItem,
        ingredients,
      };
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenuItemsByRestaurantAPI.fulfilled, (state, action) => {
      state.menuItems = pathOr([], ["menuItems"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsByRestaurantAPI.rejected, (state, action) => {
      state.menuItems = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsByLocationAPI.fulfilled, (state, action) => {
      state.menuItems = pathOr([], ["menuItems"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsByLocationAPI.rejected, (state, action) => {
      state.menuItems = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemAPI.fulfilled, (state, action) => {
      state.menuItem = pathOr(null, ["menuItem"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemAPI.rejected, (state, action) => {
      state.menuItem = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMenuItemAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.menuItem = pathOr(null, ["menuItem"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
      window.location.href = `${MENU_GROUPS_ROUTE}/${pathOr("", ["menuGroupId"], state.menuItem)}/menu-items`;
    });
    builder.addCase(createMenuItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuItemAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.menuItem = pathOr(null, ["menuItem"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
      window.location.href = `${MENU_GROUPS_ROUTE}/${pathOr("", ["menuGroupId"], state.menuItem)}/menu-items`;
    });
    builder.addCase(updateMenuItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsByMenuGroupAPI.rejected, (state, action) => {
      state.menuItems = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsByMenuGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsByMenuGroupAPI.fulfilled, (state, action) => {
      state.menuItems = pathOr([], ["menuItems"], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveMenuItemAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMenuItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(createMenuItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.menuItemIngredient = pathOr(null, ["ingredient"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updateMenuItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.menuItemIngredient = pathOr(null, ["ingredient"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuItemModifierGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr("", ["message"], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemModifierGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuItemModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setMenuItem,
  updateMenuItem,
  setMenuItemIngredient,
  updateMenuItemIngredient,
  setErrors,
  addMenuItemIngredient,
  removeMenuItemIngredient,
  setCurrentPage,
  setTotalPerPage,
  setMenuItemImageFiles,
  setMenuItemLoading,
} = menuItemsSlice.actions;

export default menuItemsSlice.reducer;
