import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, DatePicker } from "antd";
import type { ColumnType } from "antd/lib/table";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { fetchPurchasesReportByLocationAPI } from "../../../../api/reports/purchases";
import { pathOr } from "ramda";
import moment from "moment";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage, updateFilters } from "store/reports/purchases-reports";
import withPermissions from "components/hoc";

interface DataItem {
  key: React.Key;
  date: string;
  totalAmount: number;
  amountPaid: number;
  amountDue: number;
  totalPurchases: number;
  totalCompletedPurchases: number;
  totalInCompletePurchases: number;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const { RangePicker } = DatePicker;

const PurchasesReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const purchasesReport = useAppSelector((state: any) => state.purchasesReports.purchasesReport);
  const loading = useAppSelector((state: RootState) => state.purchasesReports.isLoading);
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const [filtersVisible, setFiltersVisible] = useState(false);
  const currentPage = useAppSelector((state: RootState) => state.purchasesReports.currentPage);
  const total = useAppSelector((state: RootState) => state.purchasesReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.purchasesReports.totalPerPage);
  const filters = useAppSelector((state: RootState) => state.purchasesReports.filters);

  useEffect(() => {
    dispatch(fetchPurchasesReportByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);


  const onRefresh = async () => {
    await dispatch(
      fetchPurchasesReportByLocationAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchPurchasesReportByLocationAPI({ page: current, perPage: size }));
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onDateChange = (date: any, value: any) => {
    dispatch(updateFilters({
      fromDate: value[0] ? moment.utc(value[0]).format('YYYY-MM-DD') : null,
      toDate: value[1] ? moment.utc(value[1]).format('YYYY-MM-DD') : null,
    }));
    if (!value[0] && !value[1]) {
      dispatch(
        fetchPurchasesReportByLocationAPI({ 
            page: currentPage,
            perPage: totalPerPage,
            ...filters,
            fromDate: null,
            toDate: null
          }
        )
      );
    }
  }

  const confirmDates = async () => {
    await dispatch(
      fetchPurchasesReportByLocationAPI({ 
          page: currentPage,
          perPage: totalPerPage,
          ...filters,
        }
      )
    );
  }

  const columns: TableColumn[] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["date"], a).localeCompare(pathOr("", ["date"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment(pathOr("", ["rawDate"], record)).format("Do ddd MMMM, YYYY")}
        </p>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["totalAmount"], a) - pathOr(0, ["totalAmount"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["totalAmount"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["amountPaid"], a) - pathOr(0, ["amountPaid"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["amountPaid"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Amount Due",
      dataIndex: "amountDue",
      key: "amountDue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["amountDue"], a) - pathOr(0, ["amountDue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["amountDue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Total",
      dataIndex: "totalPurchases",
      key: "totalPurchases",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["totalPurchases"], a) - pathOr(0, ["totalPurchases"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["totalPurchases"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Completed",
      dataIndex: "totalCompletedPurchases",
      key: "totalCompletedPurchases",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["totalCompletedPurchases"], a) - pathOr(0, ["totalCompletedPurchases"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["totalCompletedPurchases"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Incomplete",
      dataIndex: "totalIncompletePurchases",
      key: "totalIncompletePurchases",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["totalIncompletePurchases"], a) - pathOr(0, ["totalIncompletePurchases"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["totalIncompletePurchases"], record).toLocaleString()}
        </p>
      ),
    },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Purchases Report</p>
          <p className="text-base text-gray-750">Manage your Reports</p>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Space>
            <Button
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              onClick={toggleFilters}
              icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
            />
            <RangePicker onChange={onDateChange} allowClear />
            {filters.fromDate && filters.toDate ? <Button 
            onClick={confirmDates} className="ml-2">Confirm</Button> : null}
          </Space>
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={purchasesReport}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default withPermissions(PurchasesReport);
