import { getCookie } from "helpers/utilities";
import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const createPurchaseAPI = createAsyncThunk(
  "purchases/create-purchase",
  async (payload: { purchase: any }, thunkAPI) => {
    try {
      const response = await client(`/purchases`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: payload.purchase,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updatePurchaseAPI = createAsyncThunk(
  "purchases/update-purchase",
  async (payload: { purchase: any; purchaseId: string }, thunkAPI) => {
    try {
      const response = await client(`/purchases/${payload.purchaseId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.purchase,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchPurchaseAPI = createAsyncThunk(
  "purchases/fetch-purchase",
  async (payload: { purchaseId: string }, thunkAPI) => {
    try {
      const response = await client(`/purchases/${payload.purchaseId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchPurchasesByRestaurantAPI = createAsyncThunk(
  "purchases/fetch-purchases-by-restaurant",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/purchases?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchPurchasesByLocationAPI = createAsyncThunk(
  "purchases/fetch-purchases-by-location",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/purchases?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deletePurchaseAPI = createAsyncThunk(
  "purchases/delete-purchase",
  async (payload: { purchaseId: string }, thunkAPI) => {
    try {
      const response = await client(`/purchase/${payload.purchaseId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
