import { createSlice } from '@reduxjs/toolkit';
import {pathOr, replace} from 'ramda';
import {
  fetchSalesByDiningMethodAPI,
  fetchSalesByPaymentMethodAPI,
  fetchSalesByRestaurantLocationsAPI,
  fetchSalesReportByBusinessAPI,
  fetchSalesReportByLocationAPI,
  fetchSalesReportByRestaurantAPI
} from "../../api/reports/sales";

interface SalesReportsState {
  salesReport: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  salesByDiningMethod: any[];
  salesReportGraphSeries: any[];
  salesByDiningMethodSeriesData: any[];
  salesByDiningMethodSeriesLabels: any[];
  salesByDiningMethodLoading: boolean;
  salesByPaymentMethod: any[];
  salesByPaymentMethodLoading: boolean;
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: SalesReportsState = {
  salesReport: [],
  status: 'idle',
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  salesReportGraphSeries: [],
  salesByDiningMethod: [],
  salesByDiningMethodSeriesData: [],
  salesByDiningMethodSeriesLabels: [],
  salesByDiningMethodLoading: false,
  salesByPaymentMethod: [],
  salesByPaymentMethodLoading: false,
  filters: {
    fromDate: null,
    toDate: null
  },
};

const salesReportsSlice = createSlice({
  name: 'sales-reports',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesReportByLocationAPI.fulfilled, (state, action) => {
      state.salesReport = pathOr([], ['salesReport'], action.payload);
      state.salesReportGraphSeries = [
        {
          name: "Gross Sales",
          data: state.salesReport.map((sale) => {
            return {
              x: new Date(pathOr(null, ['date'], sale)).getTime(),
              y: pathOr(0, ['grossSales'], sale),
            }  
          }),
          color: "#4318FF",
        },
        {
          name: "Total Orders",
          data: state.salesReport.map((sale, index) => {
            return {
              x: new Date(pathOr(null, ['date'], sale)).getTime(),
              y: pathOr(0, ['totalOrders'], sale),
            }  
          }),
          color: "#6AD2FF",
        },
      ];
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchSalesReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSalesReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSalesByPaymentMethodAPI.fulfilled, (state, action) => {
      state.salesByPaymentMethod = pathOr([], ['salesByPaymentMethod'], action.payload);
      state.status = 'succeeded';
      state.salesByPaymentMethodLoading = false;
    });
    builder.addCase(fetchSalesByPaymentMethodAPI.rejected, (state, action) => {
      state.salesByPaymentMethodLoading = false;
    });
    builder.addCase(fetchSalesByPaymentMethodAPI.pending, (state, action) => {
      state.salesByPaymentMethodLoading = true;
    });
    builder.addCase(fetchSalesByDiningMethodAPI.fulfilled, (state, action) => {
      state.salesByDiningMethod = pathOr([], ['salesByDiningMethod'], action.payload);
      const labels: any[] = [];
      const colors = ['#4318FF', '#6AD2FF', '#18ff88'];
      const totalOrders = state.salesByDiningMethod.reduce(
        (sum, sale) => sum + pathOr(0, ['totalOrders'], sale), 0);
      state.salesByDiningMethodSeriesData = state.salesByDiningMethod.map((sale, index) => {
        const orders = pathOr(0, ['totalOrders'], sale);
        const calc = (orders / totalOrders) * 100;
        const percentage = Number(calc.toFixed(0));
        const opt =  replace(/_/g, ' ', pathOr('', ['diningOption'], sale));
        labels.push({ 
          diningOption: opt,
          color: colors[index],
          percentage,
        });
        return percentage;
      })
      state.salesByDiningMethodSeriesLabels = labels
      state.status = 'succeeded';
      state.salesByDiningMethodLoading = false;
    });
    builder.addCase(fetchSalesByDiningMethodAPI.rejected, (state, action) => {
      state.salesByDiningMethodLoading = false;
    });
    builder.addCase(fetchSalesByDiningMethodAPI.pending, (state, action) => {
      state.salesByDiningMethodLoading = true;
    });
    builder.addCase(fetchSalesReportByRestaurantAPI.fulfilled, (state, action) => {
      state.salesReport = pathOr([], ['salesReport'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchSalesReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSalesReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSalesReportByBusinessAPI.fulfilled, (state, action) => {
      state.salesReport = pathOr([], ['salesReport'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchSalesReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSalesReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSalesByRestaurantLocationsAPI.fulfilled, (state, action) => {
      state.salesReport = pathOr([], ['salesReport'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchSalesByRestaurantLocationsAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSalesByRestaurantLocationsAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = salesReportsSlice.actions;


export default salesReportsSlice.reducer;
