import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {length, pathOr, replace} from "ramda";
import {Button, Input, message, Select, Space} from "antd";
import { useNavigate } from "react-router-dom";
import {
  createRestaurantAdminAPI,
  fetchRestaurantAPI,
  updateRestaurantAPI
} from "../../../../../api/restaurants";
import { AppDispatch, RootState } from "../../../../../store";
import { RestaurantTypes } from "../../../../../types/RestaurantTypes";
import {restaurantTypes, SETTINGS_ROUTE} from "../../../../../constants";
import {fetchOrderStages} from "../../../../../store/orders/orders";
import {removeUndefinedOrNull} from "../../../../../utils/is-not-empty";
import {validateSchema} from "views/admin/business/restaurants/validations/schema";

interface MenuItemFormProps {
  mode: "create" | "edit";
}

const RestaurantForm: React.FC<MenuItemFormProps> = ({ mode }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [formValues, setFormValues] = useState<RestaurantTypes | any>({
    name: "",
    type: "",
    website: "",
    orderStageToTrackInventory: "Ready",
  });
  const dispatch: AppDispatch = useAppDispatch();
  const restaurant = useAppSelector((state: RootState) => state.restaurants.restaurant);
  const isLoading = useAppSelector((state: RootState) => state.restaurants.isLoading);
  const orderStages = useAppSelector((state: RootState) => state.orders.orderStages);

  useEffect(() => {
    if (id) {
      dispatch(fetchRestaurantAPI({ restaurantId: id }))
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchOrderStages())
  }, [dispatch])

  
  useEffect(() => {
    if (restaurant) {
      setFormValues({
        name: pathOr("", ["name"], restaurant),
        type: pathOr("", ["type"], restaurant),
        website: pathOr("", ["website"], restaurant),
        orderStageToTrackInventory: pathOr("", ["orderStageToTrackInventory"], restaurant)
      });
    }
  }, [restaurant])

  const handleChange = (value: string, name: string) => {
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const onFinish = async () => {
    const restaurantObj = removeUndefinedOrNull(formValues);
    const { errors } = validateSchema(restaurantObj);
    setErrors(errors);
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors')
      return
    }

    if (!id) {
      dispatch(createRestaurantAdminAPI({restaurant: restaurantObj}));
    } else {
      dispatch(updateRestaurantAPI({ restaurant: restaurantObj, restaurantId: id }));
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold capitalize">{pathOr('', ['name'], restaurant) ? pathOr('', ['name'], restaurant) : 'Restaurant'}</p>
          <p className="text-base text-gray-750">
            {pathOr('', ['id'], restaurant) ? `Edit Restaurant` : "Create Restaurant"}
          </p>
        </div>

        <div>
          <Space>
            <div>
              <Button className="bg-blue-750" loading={isLoading} type="primary" onClick={onFinish}>
                {id ? 'Update Restaurant' : 'Create Restaurant'}
              </Button>
            </div>

            <div>
              <Button onClick={() => navigate(SETTINGS_ROUTE)}>Cancel</Button>
            </div>
          </Space>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-col space-y-2">
          <div>
            <span>Restaurant Name<span className='text-red-500'>*</span></span>
            <Input
               name="name"
               value={formValues.name}
               required
               status={errors['Restaurant name'] ? 'error' : ''}
               onChange={(e) => handleChange(e.target.value, 'name')}
            />
            { errors['Restaurant name'] ? <span className='text-red-500'>
              {pathOr('', ['Restaurant name', 0, 'message'], errors)}
            </span> : null}
          </div>

          <div>
            <span>Restaurant Type<span className='text-red-500'>*</span></span>
            <Select
               className="w-full"
               value={formValues.type}
               onChange={(val) => handleChange(val, 'type')}
               status={errors['Restaurant type'] ? 'error' : ''}
               options={restaurantTypes.map((restaurantType) => {
                 return {
                   label: replace(/_/g, ' ', restaurantType),
                   value: restaurantType,
                 }
               })}
            />
            { errors['Restaurant type'] ? <span className='text-red-500'>
              {pathOr('', ['Restaurant type', 0, 'message'], errors)}
            </span> : null}
          </div>

          <div>
            <span>Order Stage To Track Inventory</span>
            <Select
               className="w-full"
               value={formValues.orderStageToTrackInventory}
               defaultValue="Ready"
               onChange={(val) => handleChange(val, 'orderStageToTrackInventory')}
               status={errors['Restaurant order stage to track inventory'] ? 'error' : ''}
               options={orderStages.map((orderStage: {name: string; value: string}) => {
                 return {
                   label: orderStage.name,
                   value: orderStage.value
                 }
               })}
            />
            { errors['Restaurant order stage to track inventory'] ? <span className='text-red-500'>
              {pathOr('', ['Restaurant order stage to track inventory', 0, 'message'], errors)}
            </span> : null}
          </div>

          <div>
            <span>Website</span>
            <Input
               name="name"
               value={formValues.website}
               required
               status={errors['Restaurant website'] ? 'error' : ''}
               onChange={(e) => handleChange(e.target.value, 'website')}
            />
            { errors['Restaurant website'] ? <span className='text-red-500'>
              {pathOr('', ['Restaurant website', 0, 'message'], errors)}
            </span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantForm;
