import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Location name'),
  city: Joi.string().trim().required().label('Location city'),
  town: Joi.string().trim().required().label('Location town'),
  country: Joi.string().trim().required().label('Location country'),
  address: Joi.string().trim().required().label('Location address'),
  zipcode: Joi.string().trim().required().label('Location zipcode'),
  currencyName: Joi.string().trim().required().label('Location zipcode'),
  currencySymbol: Joi.string().trim().label('Location currency symbol'),
  currencyIsoCode: Joi.string().trim().label('Location currency iso code'),
  countryCode: Joi.string().trim().label('Location country code'),
  longitude: Joi.number().allow(null).optional().label('Location longitude'),
  latitude: Joi.number().allow(null).optional().label('Location latitude'),
  costPerKilometer: Joi.number().allow(null).optional().label('Location cost per kilomter'),
  email: Joi.string().allow(null).optional().label('Location email'),
  timezone: Joi.string().trim().allow(null).optional().label('Location timezone'),
  helpLine: Joi.string().trim().allow(null).optional().label('Location helpline'),
  // tiktok: Joi.string().allow(null).optional().label('Location Tiktok'),
  // instagram: Joi.string().trim().allow(null).optional().label('Location Instagram'),
  // twitter: Joi.string().trim().allow(null).optional().label('Location Twitter'),
  // facebook: Joi.string().trim().allow(null).optional().label('Location Facebook'),
  // whatsapp: Joi.string().trim().allow(null).optional().label('Location Whatsapp'),
  coverImageURL: Joi.string().trim().allow(null).optional().label('Location cover image url'),
  hoursOfOperation: Joi.array().optional().label('Location hours of operation'),
  shouldChargeSalesTaxOnOrder: Joi.boolean().optional().label('Should charge sales tax on order'),
  orderSalesTaxPercentage: Joi.number().positive().allow(0).optional().label('Order sales tax percentage'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
