import { getCookie } from "helpers/utilities";
import {getAuthorizationHeader, client, handleAxiosError} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const createRegisterAPI = createAsyncThunk(
   'registers/create-register',
   async (
      payload: { register: any; restaurantId?: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/registers`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.register,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateRegisterAPI = createAsyncThunk(
   'registers/update-register',
   async (
      payload: { register: any; registerId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/registers/${payload.registerId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.register,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchRegisterAPI = createAsyncThunk(
   'registers/fetch-register',
   async (
      payload: { registerId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/registers/${payload.registerId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchRegistersAPI = createAsyncThunk(
  'registers/fetch-registers',
  async (
     payload: { page: number, perPage: number, refresh?: boolean  }, thunkAPI
  ) => {
    try {
      const locationId = getCookie('currentLocation');
      const fetchType = 'location';
      const params = new URLSearchParams();
      params.append('id', locationId);
      params.append('fetchType', fetchType);

      if (payload.refresh) {
        params.append('refresh', 'true');
      }

      const response = await client(`/registers?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);
