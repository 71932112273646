import React, { FC } from "react";
import {Button, Input, Switch} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { getCookie } from "helpers/utilities";
import {pathOr} from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateMenu } from "../../../../store/menus/menus";
import {pickFields} from "../../../../utils/is-not-empty";
import {createMenuAPI, fetchMenusByLocationAPI, updateMenuAPI} from "../../../../api/menus";

interface MenuFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const  MenuForm: FC<MenuFormProps> = ({ isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) => state.menus.isLoading);
  const menu = useAppSelector((state: any) => state.menus.menu);

  const handleInputChange = (value: any, name: string) => {
    dispatch(updateMenu({
      [name]: value,
    }))
  }

  const handleShowOnWeb = () => {
    dispatch(updateMenu({
      showOnWeb: !pathOr(false, ['showOnWeb'], menu),
    }))
  }

  const onFinish = async () => {
    const restaurantId = getCookie('currentRestaurant');
    const locationId = getCookie('currentLocation');
    const menuObj = {
      ...pickFields(menu, ['name', 'description', 'showOnWeb']),
    }
    const menuPayload = menuObj;
    if (!pathOr(null, ['id'], menu)) {
      const obj = {
        ...menuPayload,
        locations: [
          {
            locationId,
            restaurantId,
          }
        ]
      }
      await dispatch(createMenuAPI({ menu: obj }));
    } else {
      await dispatch(updateMenuAPI({ menu: menuPayload, menuId: pathOr(null, ['id'], menu) }));
    } 
    await dispatch(fetchMenusByLocationAPI({}));
    handleClose();
  };
  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">{
           pathOr(null, ['id'], menu) ? "Edit Menu" : "Create menu"}</h3>
          <div className="w-full">
            <div className="flex justify-end items-end">
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Show on web</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['showOnWeb'], menu)}
                    onChange={handleShowOnWeb} 
                  />
                </div>
              </div>
            </div>
           <div className="text-left w-full pt-3">
             <span>Menu Name<span className="text-red-500">*</span></span>
             <Input
                name="name"
                className="w-full"
                value={pathOr('', ['name'], menu)}
                required
                onChange={(e) => handleInputChange(e.target.value,"name")}
             />
           </div>
           <div className="text-left w-full pt-3">
             <span>Menu Description</span>
             <Input
                name="description"
                className="w-full"
                value={pathOr('', ['description'], menu)}
                onChange={(e) =>
                   handleInputChange(e.target.value,"description")}
             />
           </div>
           <div className="mt-5 space-x-3 flex justify-end">
             <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={isLoading}
                className="bg-blue-750">
               {pathOr('', ['id'], menu) ? "Update" : "Create"}
             </Button>
             <Button onClick={handleClose} type="default">
               Cancel
             </Button>
           </div>
         </div>
       </div>
     </ParentModal>
  );
};

export default MenuForm;
