import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../helpers/utilities";
import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { pathOr } from "ramda";

export const fetchMenuModifierGroupsByRestaurantAPI = createAsyncThunk(
  "menu-modifier-groups/fetch-menu-modifier-group-by-restaurant",
  async (_, thunkAPI) => {
    try {
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/menu-modifier-groups`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuModifierGroupsByLocationAPI = createAsyncThunk(
  "menu-modifier-groups/fetch-menu-modifier-group-by-location",
  async (payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    isArchived?: boolean;
   }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      if (payload.isArchived) {
        params.append('isArchived', 'true');
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const locationId = getCookie("currentLocation");
      const response = await client(
        `/locations/${locationId}/menu-modifier-groups?` + params.toString(),
        {
          method: "GET",
          headers: getAuthorizationHeader(),
        },
      );
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createMenuModifierGroupAPI = createAsyncThunk(
  "menu-modifier-groups/create-menu-modifier-group",
  async (payload: { menuModifierGroup: any; menuId: string }, thunkAPI) => {
    try {
      const response = await client(`/menus/${payload.menuId}/menu-modifier-groups`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: payload.menuModifierGroup,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateMenuModifierGroupAPI = createAsyncThunk(
  "menu-modifier-groups/update-menu-modifier-group",
  async (payload: { menuModifierGroup: any; menuModifierGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-groups/${payload.menuModifierGroupId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.menuModifierGroup,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuModifierGroupAPI = createAsyncThunk(
  "menu-modifier-groups/fetch-menu-modifier-group",
  async (payload: { menuModifierGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-groups/${payload.menuModifierGroupId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteMenuModifierGroupAPI = createAsyncThunk(
  "menu-modifier-groups/delete-menu-modifier-group",
  async (payload: { menuModifierGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-groups/${payload.menuModifierGroupId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const archiveMenuModifierGroupAPI = createAsyncThunk(
  "menu-modifier-groups/archive-menu-modifier-group",
  async (payload: { menuModifierGroupId: string }, thunkAPI) => {
    try {
      const response = await client(
        `/menu-modifier-groups/${payload.menuModifierGroupId}/archive`,
        {
          method: "PATCH",
          headers: getAuthorizationHeader(),
        },
      );
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
