import React, { FC, useEffect, useState } from "react";
import {Button, Input, message} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { getCookie } from "../../../../../helpers/utilities";
import { pathOr } from 'ramda';
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {createCategoryAPI, fetchCategoriesByLocationAPI, updateCategoryAPI} from "../../../../../api/categories";
import {deleteFields, removeUndefinedOrNull} from "../../../../../utils/is-not-empty";
import {updateCategory} from "../../../../../store/categories/categories";

interface CategoryFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void
}

const CategoryForm: FC<CategoryFormProps> = ({
  isOpen,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const category = useAppSelector((state: any) => state.categories.category);

  const handleInputChange = (value: any, name: string)  => {
    dispatch(updateCategory({
      [name]: value
    }))
  }

  const onFinish = async () => {
    if (!pathOr('', ['name'], category)) {
      message.error('name is required');
      return;
    }
    const categoryId = pathOr('', ['id'], category);
    const restaurantId = getCookie('currentRestaurant');
    const locationId = getCookie('currentLocation');

    const categoryObj = {
      ...deleteFields(category, [
        'id',
        'createdAt',
        'updatedAt',
        'updatedById',
        'createdById',
        'updatedById',
        'location',
        'locationId',
        'restaurant',
        'restaurantId',
        'businessId',
        'business',
        'isArchived',
        'products'
      ]),
    }
    const categoryPayload = removeUndefinedOrNull(categoryObj);

    if (categoryId) {
      await dispatch(updateCategoryAPI({ category: categoryPayload, categoryId }));
    } else {
      const createPayload = {
        ...categoryPayload,
        restaurantId,
        locationId
      }
      await dispatch(createCategoryAPI({ category: createPayload }))
    }

    await dispatch(fetchCategoriesByLocationAPI({}));
    handleClose();
  };

  return (
    <ParentModal isOpen={isOpen} handleClose={handleClose}>
      <div className="text-left text-gray-750">
        <h3 className="text-2xl font-medium">{pathOr('', ['id'], category)
           ? 'Edit category' : 'Create category'}</h3>
        <div>
          <div className="text-left w-full pt-3">
            <span>Category Name<span className="text-red-500">*</span></span>
            <Input
               name="name"
               value={pathOr('', ['name'], category)}
               required
               onChange={(e) => handleInputChange(e.target.value,'name')}
            />
          </div>
          <div className="mt-5 space-x-3 flex justify-end">
            <Button
               onClick={onFinish}
               type="primary"
               className="bg-blue-750">
              {pathOr('', ['id'], category) ? "Update" : "Submit"}
            </Button>
            <Button onClick={handleClose} type="default">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </ParentModal>
  );
};

export default CategoryForm;
