import { message, Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { setIsSuccessFull } from 'store/auth/auth';
import { verifyEmailLinkAPI } from 'api/auth';

const VerifyEmailLink = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isError = useAppSelector((state: RootState) => state.auth.isError);
  const isSuccessFull = useAppSelector((state: RootState) => state.auth.isSuccessFull);
  
  useEffect(() => {
    if (!token) {
      message.error('link verification failed');
      navigate('');
    }
    if (token) {
      dispatch(verifyEmailLinkAPI({ token }))
    }
  }, [dispatch, navigate, token])
  useEffect(() => {
   if (isError) {
    navigate('/');
   }
   if (isSuccessFull) {
    dispatch(setIsSuccessFull(false));
    navigate('/')
   }
  }, [isSuccessFull, isError, navigate, dispatch])
  return (
    <div className="mt-10 flex justify-center items-center">
      <Spin tip="Verifying" size="large" />
    </div>
  )
}

export default VerifyEmailLink;
