import React, { useEffect } from "react";
import { DownloadOutlined, PrinterOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Table, Button, Breadcrumb } from "antd";
import { fetchOrderByIdAPI } from "../../../api/orders";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { pathOr } from "ramda";
import {
  orderItemColumns,
  orderItemExpandedRowRender,
} from "views/admin/orders/components/table-columns";
import { ORDERS_ROUTE } from "../../../constants";
import withPermissions from "components/hoc";

const OrderDetailView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const order = useAppSelector((state: any) => state.orders.order);
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchOrderByIdAPI({ orderId: id }));
  }, [dispatch, id]);

  return (
    <div className="h-full overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Order Details</p>
          <Breadcrumb
            items={[
              {
                title: <Link to="/admin/orders">Orders</Link>,
              },
              {
                title: `#${pathOr("", ["orderNumber"], order)}`,
              },
            ]}
          />
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <div>
            <p className="text-base font-semibold">
              Order Number : #{pathOr("", ["orderNumber"], order)}
            </p>
          </div>
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button onClick={() => navigate(`${ORDERS_ROUTE}/${id}/edit`)} icon={<EditOutlined />}>
              Edit Order
            </Button>
            <Button icon={<DownloadOutlined />}>Export to Excel</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
          </Space>
        </div>
        <div className="grid grid-cols-12 mb-6">
          <div className="col-span-12 md:col-span-6 lg:col-span-3 text-base">
            <h4 className="mb-2 text-brand-500 font-semibold">Order Info</h4>
            <div className="flex flex-col space-y-1">
              <div className="flex space-x-4 items-center">
                <span>Order Number:</span>
                <span>#{pathOr("", ["orderNumber"], order)}</span>{" "}
              </div>
              <div className="flex space-x-4 items-center">
                <span>Total Amount:</span>
                <span className="capitalize">
                  {pathOr(0, ["totalAmount"], order).toLocaleString()}
                </span>
              </div>
              <div className="flex space-x-4 items-center">
                <span>Discount:</span>
                <span className="capitalize">
                  {pathOr(0, ["discountAmount"], order).toLocaleString()}
                </span>
              </div>
              <div className="flex space-x-4 items-center">
                <span>Payment Status:</span>
                <span>{pathOr(false, ["isPaid"], order) ? "Unpaid" : "Paid"}</span>
              </div>
              <div className="flex space-x-4 items-center">
                <span>Stage:</span>
                <span className="capitalize">{pathOr("", ["stage"], order)}</span>
              </div>
              <div className="flex space-x-4 items-center">
                <span>Inventory Tracked:</span>
                <span className="capitalize">
                  {pathOr(false, ["isInventoryTracked"], order).toString()}
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 lg:col-span-3 text-base">
            <h4 className="mb-2 text-brand-500 font-semibold">Server Information</h4>
            <div className="flex flex-col space-y-2">
              <span>
                Name: {pathOr("", ["server", "profile", "firstname"], order)}{" "}
                {pathOr("", ["server", "profile", "lastname"], order)}
              </span>
              <span>Email: {pathOr("", ["server", "email"], order)}</span>
              <span>Phone: {pathOr("", ["server", "phoneNumber"], order)}</span>
            </div>
          </div>
          {pathOr(false, ["isPaid"], order) ? (
            <div className="col-span-12 md:col-span-6 lg:col-span-3 text-base">
              <h4 className="mb-2 text-brand-500 font-semibold">Payment Information</h4>
              <div className="flex flex-col space-y-2">
                <span>Payment Method: {pathOr("", ["transaction", "paymentMethod"], order)} </span>
                <span>
                  Amount Paid: {pathOr(0, ["transaction", "amountPaid"], order).toLocaleString()}{" "}
                </span>
                {pathOr("", ["transaction", "mobileMoneyTransactionId"], order) ? (
                  <span>
                    TransactionID: {pathOr("", ["transaction", "mobileMoneyTransactionId"], order)}
                  </span>
                ) : null}
              </div>
            </div>
          ) : null}
          {pathOr(null, ["type"], order) === "DELIVERY" ? (
            <div className="col-span-12 md:col-span-6 lg:col-span-3 text-base">
              <h4 className="mb-2 text-brand-500 font-semibold">Delivery Rider Information</h4>
              <div className="flex flex-col space-y-2">
                <span>
                  Rider Name:{" "}
                  {pathOr("", ["delivery", "deliveryRider", "profile", "firstname"], order)}{" "}
                  {pathOr("", ["delivery", "deliveryRider", "profile", "lastname"], order)}
                </span>
                <span>
                  Rider Email: {pathOr("", ["delivery", "deliveryRider", "email"], order)}
                </span>
                <span>
                  Rider Phone: {pathOr("", ["delivery", "deliveryRider", "phoneNumber"], order)}
                </span>
                <span>Time Of Delivery: {pathOr("", ["delivery", "timeOfDelivery"], order)}</span>
                <span>Distance Between: {pathOr("", ["delivery", "distanceBetween"], order)}</span>
                <span>Cost Of Delivery: {pathOr("", ["delivery", "costOfDelivery"], order)}</span>
              </div>
            </div>
          ) : null}
          {pathOr(null, ["type"], order) === "DELIVERY" ? (
            <div className="col-span-12 md:col-span-6 lg:col-span-3 text-base">
              <h4 className="mb-2 text-brand-500 font-semibold">Delivery Information</h4>
              <div className="flex flex-col space-y-2">
                <span>Recipient Name: {pathOr("", ["delivery", "timeOfDelivery"], order)}</span>
                <span>
                  Recipient Phone: {pathOr("", ["delivery", "recipientPhoneNumber"], order)}
                </span>
                <span>Recipient Email: {pathOr("", ["delivery", "recipientEmail"], order)}</span>
                <span>Street Address: {pathOr("", ["delivery", "streetAddress"], order)}</span>
                <span>City: {pathOr("", ["delivery", "city"], order)}</span>
                <span>Town: {pathOr("", ["delivery", "town"], order)}</span>
              </div>
            </div>
          ) : null}
        </div>

        <div className="overflow-x-scroll no-scroll mb-4">
          <Table
            scroll={{ x: "max-content" }}
            columns={orderItemColumns}
            expandable={{ expandedRowRender: orderItemExpandedRowRender }}
            dataSource={pathOr([], ["orderItems"], order)}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default withPermissions(OrderDetailView);
