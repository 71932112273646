import React from "react";
import {
  SettingOutlined,
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Button, Image, Dropdown, Select } from "antd";
import type { MenuProps } from "antd";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatars/cat.png";
import logo from "../../assets/img/layout/logoWhite.png";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import { head, pathOr } from "ramda";
import { signOutAPI } from "../../api/auth";
import { setCurrentLocation } from "store/auth/auth";

const HeaderNav: React.FC<{ toggleSideBar: () => void }> = ({ toggleSideBar }) => {
  const currentRestaurant = useAppSelector((state: any) => state.auth.currentRestaurant);
  const currentLocation = useAppSelector((state: any) => state.auth.currentLocation);
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state: RootState) => state.auth.user);
  const role = pathOr("", ["employee", "role"], userInfo);
  const name = `${pathOr("", ["profile", "firstname"], userInfo)} 
  ${pathOr("", ["profile", "lastname"], userInfo)}`;

  const handleLogOut = async () => {
    await dispatch(signOutAPI());
  };

  const locationChange = (locationId: string) => {
    const location = head(
      pathOr([], ["locations"], currentRestaurant).filter(
        (location) => pathOr(null, ["id"], location) === locationId,
      ),
    );
    dispatch(setCurrentLocation(location));
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <div className="flex items-center gap-2">
          <Image
            width={36}
            height={36}
            src={avatar}
            alt="User Avatar"
            preview={false}
            className="rounded-full"
          />
          <div className="flex flex-col text-left pr-2 py-1">
            <span className="text-gray-800 font-medium">{name}</span>
            <span className="text-gray-750 text-xs">{role}</span>
          </div>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: <Link to={"/admin/settings"}>My Profile</Link>,
      key: "1",
    },
    {
      label: <Link to={"/admin/settings"}>Settings</Link>,
      key: "2",
    },
    {
      label: (
        <Select
          value={pathOr("", ["id"], currentLocation)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(value) => locationChange(value)}
          style={{ width: 180 }}
          className="h-10 md:hidden"
          options={pathOr([], ["locations"], currentRestaurant).map((location: any) => {
            return {
              label: pathOr("", ["name"], location),
              value: pathOr("", ["id"], location),
            };
          })}
        />
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className="text-red-500" onClick={() => handleLogOut()}>
          <LogoutOutlined /> <span>Logout</span>
        </div>
      ),
      key: "4",
    },
  ];

  return (
    <div className="flex items-center justify-between px-4">
      <div className="flex items-center">
        <div className="flex gap-4 items-center mr-4 lg:w-[240px]">
          <div className="bg-brand-500 h-10 w-10 rounded-lg flex items-center justify-center">
            <Image
              width={30}
              height={30}
              src={logo}
              alt="User Avatar"
              preview={false}
              className="rounded-lg"
            />
          </div>
          <div>
            <Button type="text" onClick={toggleSideBar} className="px-2" icon={<MenuOutlined />} />
          </div>
        </div>
        <div className=" hidden md:inline-block">
          <Select
            value={pathOr("", ["id"], currentLocation)}
            onChange={(value) => locationChange(value)}
            style={{ width: 180 }}
            className="h-10"
            options={pathOr([], ["locations"], currentRestaurant).map((location: any) => {
              return {
                label: pathOr("", ["name"], location),
                value: pathOr("", ["id"], location),
              };
            })}
          />
        </div>
      </div>
      <div className="flex gap-4 justify-end text-right">
        <div className="relative hidden md:inline-block">
          <Button type="text" className="px-2" icon={<BellOutlined className="text-lg" />}>
            <span className="absolute text-xs h-4 w-4 bg-blue-750 text-white rounded-full top-0 right-0">
              2
            </span>
          </Button>
        </div>
        <div className="hover:border-none hover:bg-transparent hidden lg:inline-block">
          <Button type="text" icon={<SettingOutlined className="text-lg" />} />
        </div>
        <div className="flex items-center">
          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button
              type="text"
              className="bg-gray-50 cursor-pointer flex gap-2 items-center px-0 h-10">
              <Image
                width={36}
                height={36}
                src={avatar}
                alt="User Avatar"
                preview={false}
                className="rounded-lg"
              />
              <div className="flex flex-col text-left pr-2 py-1">
                <span className="text-gray-800 font-medium">
                  <span
                    className="hidden lg:inline-block
                  ">
                    {name}
                  </span>{" "}
                  <DownOutlined />
                </span>
                <span className="text-gray-750 text-xs">{role}</span>
              </div>
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
