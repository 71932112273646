import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCookie} from "../helpers/utilities";
import { pathOr } from "ramda";

export const createPermissionSetAPI = createAsyncThunk(
   'permissions/create-permission-set',
   async (
      payload: { permissionSet: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/permissions`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.permissionSet
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchPermissionSetsByBusinessAPI = createAsyncThunk(
   'permissions/fetch-permission-sets-by-business',
   async (
    payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
       const response = await client(`/permissions?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchPermissionSetsByLocationAPI = createAsyncThunk(
   'permissions/fetch-permission-sets-by-location',
   async (
    payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/permissions`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchPermissionSetsByRestaurantAPI = createAsyncThunk(
   'permissions/fetch-permission-set-restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/permissions`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updatePermissionSetAPI = createAsyncThunk(
   'permissions/update-permission-set',
   async (
      payload: { permissionSetId: string; permissionSet: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/permissions/${payload.permissionSetId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.permissionSet
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchPermissionSetAPI = createAsyncThunk(
   'permissions/fetch-permission-set',
   async (
      payload: { permissionSetId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/permissions/${payload.permissionSetId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const deletePermissionSetAPI = createAsyncThunk(
   'permissions/delete-permission-set',
   async (
      payload: { permissionSetId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/permissions/${payload.permissionSetId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);



