import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Button, Input, Space, Table } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import { Link } from "react-router-dom";
import { BadgeProps } from "components/badge/Badge";
import DeleteModal from "components/modal/DeleteModal";
import { EXPENSES_ROUTE } from "../../../constants";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { deleteExpensesAPI, fetchExpensesByLocationAPI } from "api/expenses";
import moment from "moment";
import { pathOr } from "ramda";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "../../../store/restaurants/expenses";
import withPermissions from "components/hoc";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_CREATE_EXPENSE, CAN_DELETE_EXPENSE, CAN_UPDATE_EXPENSE } from "permissions";
interface DataItem {
  id: string;
  name: string;
  reference: string;
  date: string;
  status: BadgeProps;
  amountPaid: number;
  totalAmount: number;
  amountDue: number;
  paymentMethod: string;
  currencySymbol: string;
  description: string;
  type: string;
}

const Expenses: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const expenses = useAppSelector((state: any) => state.expenses.expenses);
  const isLoading = useAppSelector((state: any) => state.expenses.isLoading);
  const currencySymbol = useAppSelector((state: RootState) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const isSubmitting = useAppSelector((state: any) => state.expenses.isSubmitting);
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const currentPage = useAppSelector((state: RootState) => state.expenses.currentPage);
  const total = useAppSelector((state: RootState) => state.expenses.total);
  const totalPerPage = useAppSelector((state: RootState) => state.expenses.totalPerPage);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [expense, setExpense] = useState<any>(null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    dispatch(fetchExpensesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };

  const handleDeleteExpense = (expense: any) => {
    setExpense(expense);
    setModalState({ isOpen: true, type: "deleteModal" });
  };

  const confirmDeleteExpense = async () => {
    try {
      await dispatch(deleteExpensesAPI({ expenseId: pathOr("", ["id"], expense) }));
      handleClose();
      await dispatch(fetchExpensesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    } catch (error: any) {}
  };

  const columns = [
    {
      title: "Expense Date",
      dataIndex: "expenseDate",
      key: "expenseDate",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["expenseDate"], a).localeCompare(pathOr("", ["expenseDate"], b)),
      render: (text: any, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">
          {moment(pathOr('', ['expenseDate'], record)).format("Do ddd MMM, YYYY")}
        </p>
      ),
    },
    {
      title: "Expense Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text: any, record: DataItem) => (
        <Link to={`${EXPENSES_ROUTE}/${record.id}/edit`}>
          <p className="font-medium text-base capitalize">{pathOr("", ["name"], record)}</p>
        </Link>
      ),
    },
    {
      title: "Amount Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["totalAmount"], a) - pathOr(0, ["totalAmount"], b),
      render: (text: any, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">
          {currencySymbol} {pathOr(0, ["totalAmount"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["amountPaid"], a) - pathOr(0, ["amountPaid"], b),
      render: (text: any, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">
          {currencySymbol} {pathOr(0, ["amountPaid"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Amount Due",
      dataIndex: "amountDue",
      key: "amountDue",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["amountDue"], a) - pathOr(0, ["amountDue"], b),
      render: (text: any, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">
          {currencySymbol} {pathOr(0, ["amountDue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (text: any, record: DataItem) => (
        <p className="font-medium capitalize text-[15px] text-gray-750">
          {pathOr("", ["paymentMethod"], record)}
        </p>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: DataItem) => (
        <p className="font-medium capitalize text-base text-gray-750">
          {pathOr("", ["type"], record)}
        </p>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: DataItem) => (
        <Space size="middle">
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_EXPENSE]}>
            <Button
              icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => navigate(`${EXPENSES_ROUTE}/${record.id}/edit`)}
            />
          </ProtectedWrapper>

          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_DELETE_EXPENSE]}>
            <Button
              icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => handleDeleteExpense(record)}
            />
          </ProtectedWrapper>
        </Space>
      ),
      width: "10%",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const onRefresh = async () => {
    await dispatch(
      fetchExpensesByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchExpensesByLocationAPI({ page: current, perPage: size }));
  };

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Expense List</p>
          <p className="text-base text-gray-750">Manage your Expenses</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_EXPENSE]}>
            <Button
              onClick={() => navigate(`${EXPENSES_ROUTE}/create`)}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Add New Expense
            </Button>
          </ProtectedWrapper>
          
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={expenses}
          rowSelection={rowSelection}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
              onChange: onPageSizeChange,
              onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "deleteModal"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={confirmDeleteExpense}
          isDeleting={isSubmitting}
        />
      </div>
    </div>
  );
};

export default withPermissions(Expenses);
