import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Input, Space, Table } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnType } from "antd/lib/table";
import { Link } from "react-router-dom";
import DeleteModal from "components/modal/DeleteModal";
import { pathOr } from "ramda";
import { fetchMenuAPI } from "../../../../api/menus";
import CategoryFilters from "../components/filters";
import MenuGroupForm from "./components/menu-group-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MENU_GROUPS_ROUTE, MENUS_ROUTE } from "../../../../constants";
import {
  archiveMenuGroupAPI,
  deleteMenuGroupAPI,
  fetchMenuGroupsByMenuAPI,
} from "../../../../api/menu-groups";
import { setMenuGroup } from "../../../../store/menus/menu-groups";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "../../../../store/menus/menu-groups";
import withPermissions from "components/hoc";

interface DataItem {
  id: string;
  name: string;
  categoryCode: string;
  description: string;
  createdBy: string;
  imageUrl: any;
  // You might have additional properties here
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const MenuGroups: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const menu = useAppSelector((state: any) => pathOr(null, ["menu"], state.menus));
  const menuGroups = useAppSelector((state: any) => pathOr([], ["menuGroups"], state.menuGroups));
  const isLoading = useAppSelector((state: RootState) => state.menuGroups.isLoading);
  const menuGroup = useAppSelector((state: any) => state.menuGroups.menuGroup);
  const currentPage = useAppSelector((state: RootState) => state.menuGroups.currentPage);
  const total = useAppSelector((state: RootState) => state.menuGroups.total);
  const totalPerPage = useAppSelector((state: RootState) => state.menuGroups.totalPerPage);
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchMenuAPI({ menuId: id }));
      dispatch(fetchMenuGroupsByMenuAPI({ page: currentPage, perPage: totalPerPage, menuId: id }));
    }
  }, [dispatch, id, totalPerPage, currentPage]);

  const columns: TableColumn[] = [
    {
      title: "Menu Group",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Space>
          <Link
            to={`${MENU_GROUPS_ROUTE}/${record.id}/menu-items`}
            onClick={(e) => e.stopPropagation()}
            className="font-medium text-[15px]">
            {pathOr("", ["name"], record)}
          </Link>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["description"], a).localeCompare(pathOr("", ["description"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">{pathOr("", ["description"], record)}</p>
      ),
    },
    {
      title: "Show On Web",
      dataIndex: "showOnWeb",
      key: "showOnWeb",
      render: (text, record: DataItem) => {
        return (
          <p className="capitalize font-medium text-[15px] text-gray-750">
            {String(pathOr(false, ["showOnWeb"], record))}
          </p>
        );
      },
    },
    {
      title: "Is Archived",
      dataIndex: "isArchived",
      key: "isArchived",
      render: (text, record: DataItem) => (
        <p className="capitalize font-medium text-base text-gray-750">
          {String(pathOr("", ["isArchived"], record))}
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => (
        <Space size="middle">
          <Button
            onClick={() => navigate(`${MENU_GROUPS_ROUTE}/${record.id}/menu-items`)}
            icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
          />
          <Button
            icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "edit")}
          />
          <Button
            icon={<FolderOpenOutlined style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "archive")}
          />
          <Button
            icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "delete")}
          />
        </Space>
      ),
      width: "10%",
    },
  ];

  const openModal = (group: any, type: "create" | "edit" | "archive" | "delete") => {
    dispatch(setMenuGroup(group));
    setModalState({ isOpen: true, type });
  };

  const handleClose = () => {
    dispatch(setMenuGroup(null));
    setModalState({ isOpen: false, type: null });
  };

  const deleteMenuGroup = async () => {
    await dispatch(deleteMenuGroupAPI({ menuGroupId: pathOr("", ["id"], menuGroup) }));
    await dispatch(fetchMenuGroupsByMenuAPI({ menuId: id }));
    setModalState({ isOpen: false, type: null });
  };

  const archiveMenuGroup = async () => {
    await dispatch(archiveMenuGroupAPI({ menuGroupId: pathOr("", ["id"], menuGroup) }));
    await dispatch(fetchMenuGroupsByMenuAPI({ menuId: id }));
    setModalState({ isOpen: false, type: null });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const onRefresh = async () => {
    await dispatch(
      fetchMenuGroupsByMenuAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
        menuId: id,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenuGroupsByMenuAPI({ page: current, perPage: size, menuId: id }));
  };

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Menu Groups</p>
          <Breadcrumb
            items={[
              {
                title: <Link to={MENUS_ROUTE}>Menus</Link>,
              },
              {
                title: <p className="capitalize">{pathOr("", ["name"], menu)}</p>,
              },
            ]}
          />
        </div>
        <div>
          <Button
            onClick={() => openModal(null, "create")}
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            icon={<PlusOutlined />}>
            Create Menu Group
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex items-center md:justify-between mb-6 flex-wrap md:flex-nowrap">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="basis-full md:basis-auto md:flex-grow md:justify-end my-4 flex-wrap">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <CategoryFilters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={menuGroups}
          loading={isLoading}
          rowSelection={rowSelection}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <MenuGroupForm
          isOpen={modalState.isOpen && ["create", "edit"].includes(modalState.type)}
          handleClose={handleClose}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteMenuGroup}
          title={"Delete Menu Group"}
          prompt={`Are you sure you want to delete menu group`}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveMenuGroup}
          title={"Archive Menu Group"}
          prompt={`Are you sure you want to archive menu group`}
          deletePrompt={"Archive"}
        />
      </div>
    </div>
  );
};

export default withPermissions(MenuGroups);
