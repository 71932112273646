import { getCookie } from "helpers/utilities";
import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchInventoryByRestaurantAPI = createAsyncThunk(
   'inventory/fetch-inventory-by-restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/inventory`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchInventoryByLocationAPI = createAsyncThunk(
   'inventory/fetch-inventory-by-location',
   async (
    payload: { 
      refresh?: boolean;
      page?: number;
      perPage?: number;
      quantityInStock?: string;
      isArchived?: boolean;
    }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
       if (payload.refresh) {
        params.append('refresh', String(pathOr(false, ['refresh'], payload)));
       }
       if (payload.quantityInStock) {
        params.append('quantityInStock', payload.quantityInStock);
       }
       if (payload.isArchived) {
        params.append('isArchived', 'true');
       }
       params.append('page', String(pathOr(1, ['page'], payload)));
       params.append('perPage', String(pathOr(10, ['perPage'], payload)));
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/inventory?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchProductAPI = createAsyncThunk(
   'inventory/fetch-product',
   async (
      payload: { productId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory/${payload.productId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const createProductAPI = createAsyncThunk(
   'inventory/create-product',
   async (
      payload: { product: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.product
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const updateProductAPI = createAsyncThunk(
   'inventory/update-product',
   async (
      payload: { product: any; productId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory/${payload.productId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: payload.product
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const archiveProductAPI = createAsyncThunk(
   'inventory/archive-product',
   async (
      payload: { productId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory/${payload.productId}/archive`, {
         method: 'PATCH',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const unArchiveProductAPI = createAsyncThunk(
  'inventory/un-archive-product',
  async (
     payload: { productId: string; }, thunkAPI
  ) => {
    try {
      const response = await client(`/inventory/${payload.productId}/un-archive`, {
        method: 'PATCH',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  }
);

export const deleteProductAPI = createAsyncThunk(
   'inventory/delete-product',
   async (
      payload: { productId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory/${payload.productId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const createProductMeasurementAPI = createAsyncThunk(
   'inventory/create-product-measurement',
   async (
      payload: { productMeasurement: any; productId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory/${payload.productId}/inventory-measurements`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.productMeasurement
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const updateProductMeasurementAPI = createAsyncThunk(
   'inventory/update-product-measurement',
   async (
      payload: { productMeasurementId: string; productMeasurement: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory-measurements/${payload.productMeasurementId}`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.productMeasurement,
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const deleteProductMeasurementAPI = createAsyncThunk(
   'inventory/delete-product-measurement',
   async (
      payload: { productMeasurementId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory-measurements/${payload.productMeasurementId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);



