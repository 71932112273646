import React, { useState } from "react";
import { Tabs } from "antd";
import Profile from "./profile";

const Settings = () => {
  const [activeTab, setActiveTab] = useState('profile');

  const items = [
    {
      key: 'profile',
      label: "Profile",
      children: <Profile />,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Settings</p>
          <p className="text-base text-gray-750">General Settings</p>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={activeTab}
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Settings;
