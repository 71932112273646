import React, {useState} from "react";
import {Menu, MenuProps} from "antd";
import {sidebarNavigation} from "routes";
import {pathOr} from "ramda";
import {useNavigate} from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { RootState } from "store";
import { hasPermission } from "components/hoc";

type SidebarProps = {
  toggleSideBar: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ toggleSideBar }) => {
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([]);

  const permissionSet = useAppSelector((state: RootState) => pathOr(null, [
    'employee',
    'permissionSet'
  ], state.auth.user));

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && sidebarNavigation.rootSubMenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleMenuItemClick = (item: any) => {
    navigate(`${pathOr('', ['key'], item)}`)
  }
  return (
    <Menu
     mode="inline"
     openKeys={openKeys}
     onClick={handleMenuItemClick}
     onOpenChange={onOpenChange}
      className="overflow-y-scroll no-scroll"
      style={{ height: "100%", borderRight: 0 }}
      items={pathOr([], ['routes'], sidebarNavigation).map((item) => {
        if (hasPermission(permissionSet, pathOr([], ['permissionsNeeded'], item))) {
          let route = item;
          if (route.children) {
            const newItemChildren = route.children.filter(
              (child: any) => hasPermission(permissionSet, child.permissionsNeeded))
            route.children = newItemChildren;
          }
          return route;
        }
        return null;
      })}
    />
  );
};

export default Sidebar;
