import React, {FC, useEffect, useState} from "react";
import {Button, InputNumber, message, Select} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { addProductMeasurement, updateProductMeasurement } from "../../../../store/inventory/inventory";
import { RootState } from "store";
import {unitsOfMeasure} from "../../../../constants";

interface IngredientFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const MeasurementModal: FC<IngredientFormProps> = ({
 isOpen,
 handleClose,
 }) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: RootState) => state.inventory.isLoading);
  const productMeasurement = useAppSelector((state: RootState) => state.inventory.productMeasurement);
  const productMeasurementId = pathOr("", ["id"], productMeasurement);
  const [formValues, setFormValues] = useState({
    quantityToMakeTrackableUnit: 1,
    unitOfMeasure: ''
  })

  useEffect(() => {
    if (productMeasurement) {
      setFormValues({
        quantityToMakeTrackableUnit: pathOr(1, ['quantityToMakeTrackableUnit'], productMeasurement),
        unitOfMeasure: pathOr('', ['unitOfMeasure'], productMeasurement)
      })
    }
  }, [productMeasurement])

  const handleChange = (value: any, name: string) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  };

  const onFinish = async () => {
    const quantityToMakeTrackableUnit: number = pathOr(1,
       ['quantityToMakeTrackableUnit'], formValues);
    const unitOfMeasure: string = pathOr('',
       ['unitOfMeasure'], formValues)

    if (quantityToMakeTrackableUnit < 0) {
      message.error('Quantity to make trackable unit should be greater than zero');
      return;
    }

    if (!unitOfMeasure) {
      message.error('please select a unit of measure');
      return;
    }

    if (productMeasurementId) {
      dispatch(updateProductMeasurement({
        id: productMeasurementId,
        quantityToMakeTrackableUnit,
        unitOfMeasure
      }))
    } else {
      dispatch(addProductMeasurement({
        quantityToMakeTrackableUnit,
        unitOfMeasure
      }));
    }
    onClose();
  };

  const onClose = () => {
    setFormValues({
      quantityToMakeTrackableUnit: 1,
      unitOfMeasure: ''
    });
    handleClose();
  }
  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">
           {productMeasurementId ? "Edit Measurement" : "Add Measurement"}
         </h3>
         <div>
           <div className="text-left w-full pt-3">
             <span>Quantity To Make Trackable Unit<span className="text-red-500">*</span></span>
             <InputNumber
                name="quantityToMakeTrackableUnit"
                value={pathOr(1, ['quantityToMakeTrackableUnit'], formValues)}
                required
                className="w-full"
                onChange={(val) => handleChange(Number(val), "quantityToMakeTrackableUnit")}
             />
           </div>
           <div className="text-left w-full pt-3">
             <span>Unit Of Measure<span className="text-red-500">*</span></span>
             <Select
                className="w-full"
                value={pathOr('', ['unitOfMeasure'], formValues)}
                onChange={(val) => handleChange(val, "unitOfMeasure")}
                options={unitsOfMeasure.map((unit) => {
                  return {
                    label: unit,
                    value: unit
                  }
                })}
             />
           </div>
           <div className="mt-5 space-x-3 flex">
             <Button
                className="bg-blue-750"
                type="primary"
                onClick={onFinish}
                disabled={isLoading}
             >
               {productMeasurementId ? "Update" : "Create"}
             </Button>
             <Button type="default" onClick={onClose}>Cancel</Button>
           </div>
         </div>
       </div>
     </ParentModal>
  );
};

export default MeasurementModal;
