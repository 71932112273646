import { Button, InputNumber, message, Select } from "antd";
import { 
  createRegisterTransactionAPI,
  fetchRegisterTransactionsAPI
} from "api/registers/register-transactions";
import ParentModal, { modalProps } from "components/modal/Modal";
import { registerTransactionTypes } from "../../../../../constants";
import { pathOr } from "ramda";
import { FC, useEffect, useState } from "react";
import { RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchRegisterSessionsAPI } from "api/registers/register-sessions";
import { setSuccessMessage } from "store/registers";

interface RegisterTransactionsFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
}
 
const RegisterTransactionForm: FC<RegisterTransactionsFormProps> = ({ isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const [type, setType] = useState<null>(null);
  const [amount, setAmount] = useState(0)
  const registerSessionsLoading = useAppSelector(
    (state: RootState) => state.registers.registerSessionsLoading);
  const register = useAppSelector((state: RootState) => state.registers.register);
  const session = useAppSelector((state: RootState) => state.registers.registerSession);
  const successMessage = useAppSelector((state: RootState) => state.registers.successMessage);

  useEffect(() => {
    if (successMessage) {
      dispatch(fetchRegisterSessionsAPI({ registerId: pathOr('', ['id'], register) }))
      dispatch(fetchRegisterTransactionsAPI({ registerId: pathOr('', ['id'], register) }))
      onClose();
      dispatch(setSuccessMessage(null));
    }
  }, [dispatch, register, successMessage])

  const onClose = () => {
    handleClose();
  }
  
  const onFinish = async () => {
    if (amount < 1 || isNaN(amount)) {
      message.error('please enter an amount above 0');
      return;
    }

    if (!type) {
      message.error('transaction type must be provided');
      return;
    }
  
    await dispatch(createRegisterTransactionAPI({ 
      transaction: { amount, type },
      sessionId: pathOr('', ['id'], session)
    }))
  };
  return (
    <ParentModal isOpen={isOpen} handleClose={handleClose}>
      <div className="text-left text-gray-750">
        <h3 className="text-2xl font-medium">
          Creating Transaction
        </h3>
        <div>
          <div className="text-left w-full pt-3">
            <span>Amount<span className="text-red-500">*</span></span>
            <InputNumber
              name="quantityToMakeTrackableUnit"
              value={amount}
              required
              className="w-full"
              onChange={(val) => setAmount(Number(val))}
            />
          </div>
          <div className="text-left w-full pt-3">
            <span>Type<span className="text-red-500">*</span></span>
            <Select
              className="w-full"
              value={type}
              onChange={(val) => setType(val)}
              options={registerTransactionTypes.map((transactionType) => {
                return {
                  label: transactionType,
                  value: transactionType
                }
              })}
            />
           </div>
          <div className="mt-5 space-x-3 flex">
            <Button
              className="bg-blue-750"
              type="primary"
              onClick={onFinish}
              disabled={registerSessionsLoading}
            >
              Create Transaction
            </Button>
            <Button type="default" onClick={onClose}>Cancel</Button>
          </div>
        </div>
       </div>
    </ParentModal>
  );
}

export default RegisterTransactionForm;