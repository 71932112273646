import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import AuthReducer from './auth/auth';
import BusinessReducer from './business/bussiness';
import LocationReducer from './locations/locations';
import InventoryReducer from './inventory/inventory';
import MenusReducer from './menus/menus';
import MenuModifierItemsReducer from './menus/menu-modifier-items';
import MenuModifierGroupsReducer from './menus/menu-modifier-groups';
import MenuGroupsReducer from './menus/menu-groups';
import MenuItemsReducer from './menus/menu-items';
import SuppliersReducer from './suppliers/suppliers';
import ExpensesReducer from './restaurants/expenses';
import PurchasesReducer from './restaurants/purchases';
import PermissionsReducer from './permissions/permissions';
import EmployeesReducer from './employees/employees';
import RestaurantReducer from './restaurants/restaurants';
import OrdersReducer from './orders/orders';
import FloorsReducer from './floors/floors';
import OthersReducer from './others';
import CategoriesReducer from './categories/categories';
import SalesReportsReducer from './reports/sales-reports';
import OrdersReportsReducer from './reports/orders-reports'
import InventoryReportsReducer from './reports/inventory-reports';
import PurchasesReportsReducer from './reports/purchases-reports';
import ExpensesReportsReducer from './reports/expenses-reports';
import EmployeesReportsReducer from './reports/employees-reports';
import MenuItemsReportsReducer from './reports/menu-items-reports';
import RegistersReducer from './registers';
import StatsReducer from './reports/stats';
import CustomersReducer from './customers/customers';
import MenuModifierItemsReportsReducer from './reports/menu-modifier-items';

const rootReducer = combineReducers({
  auth: AuthReducer,
  business: BusinessReducer,
  locations: LocationReducer,
  restaurants: RestaurantReducer,
  orders: OrdersReducer,
  inventory: InventoryReducer,
  menus: MenusReducer,
  menuItems: MenuItemsReducer,
  menuGroups: MenuGroupsReducer,
  menuModifierItems: MenuModifierItemsReducer,
  menuModifierGroups: MenuModifierGroupsReducer,
  suppliers: SuppliersReducer,
  expenses: ExpensesReducer,
  purchases: PurchasesReducer,
  permissions: PermissionsReducer,
  employees: EmployeesReducer,
  salesReports: SalesReportsReducer,
  ordersReports: OrdersReportsReducer,
  inventoryReports: InventoryReportsReducer,
  menuItemsReports: MenuItemsReportsReducer,
  menuModifierItemsReports: MenuModifierItemsReportsReducer,
  expensesReports: ExpensesReportsReducer,
  purchasesReports: PurchasesReportsReducer,
  employeesReports: EmployeesReportsReducer,
  floors: FloorsReducer,
  others: OthersReducer,
  categories: CategoriesReducer,
  stats: StatsReducer,
  registers: RegistersReducer,
  customers: CustomersReducer,
});

const store = configureStore({
  reducer: rootReducer
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
