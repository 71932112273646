import React, { useEffect, useState } from "react";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Table, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import DeleteModal from "components/modal/DeleteModal";
import { pathOr } from "ramda";
import {
  updateRegisterAPI,
  fetchRegistersAPI,
} from "../../../../api/registers/registers";
import { REGISTERS_ROUTE } from "../../../../constants";
import { setCurrentPage, setTotalPerPage, setRegister } from "../../../../store/registers";
import { fetchRegisterColoumns } from "./columns";


const Registers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: RootState) => state.registers.isLoading);
  const isSubmitting = useAppSelector((state: RootState) => state.registers.isSubmitting);
  const registers = useAppSelector((state: RootState) => state.registers.registers);
  const register = useAppSelector((state: RootState) => state.registers.register);
  const currentPage = useAppSelector((state: RootState) => state.registers.currentPage);
  const total = useAppSelector((state: RootState) => state.registers.total);
  const totalPerPage = useAppSelector((state: RootState) => state.registers.totalPerPage);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });
  useEffect(() => {
    dispatch(fetchRegistersAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };
  const openModal = (RegisterItem: any, type: "delete" | "archive") => {
    dispatch(setRegister(RegisterItem));
    setModalState({ isOpen: true, type });
  };

  const archiveRegister = async () => {
    await dispatch(updateRegisterAPI({ 
      registerId: pathOr("", ["id"], register),
      register: { isArchived: true } 
    }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchRegistersAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const onRefresh = async () => {
    await dispatch(
      fetchRegistersAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchRegistersAPI({ page: current, perPage: size }));
  };

  return (
    <div>
      <div className="flex flex-wrap justify-between items-center mb-6">
        <div className="mb-2">
          <p className="text-base font-medium">Registers</p>
          <p className="text-sm text-gray-750">
            View and update your registers.
          </p>
        </div>
        <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
          <Button onClick={() => navigate(`${REGISTERS_ROUTE}/create`)} icon={<PlusOutlined />}>
            Create Register
          </Button>
          <Button onClick={onRefresh} icon={<ReloadOutlined />}>
            Refresh
          </Button>
        </Space>
      </div>
      <div>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={registers}
          columns={fetchRegisterColoumns(openModal)}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveRegister}
          isDeleting={isSubmitting}
          title={"Archive Register"}
          prompt={`Are you sure you want to archive register`}
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default Registers;
