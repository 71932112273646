import React, {useEffect} from 'react';
import MenuItemForm from '../components/menu-item-form';
import {fetchInventoryByLocationAPI} from "../../../../../api/inventory";
import {useAppDispatch} from "../../../../../store/hooks";
import {useParams} from "react-router-dom";
import {fetchMenuGroupAPI} from "../../../../../api/menu-groups";
import {fetchMenuModifierGroupsByLocationAPI} from "../../../../../api/menu-modifier-groups";
import {setMenuItem} from "../../../../../store/menus/menu-items";
import withPermissions from 'components/hoc';

const CreateMenuItem: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(setMenuItem({}));
    dispatch(fetchInventoryByLocationAPI({ 
      page: 1,
      perPage: 1000,
      isArchived: false,
      refresh: true
    }));
    dispatch(fetchMenuModifierGroupsByLocationAPI({ page: 1, perPage: 1000, isArchived: false, refresh: true  }));
    if (id) {
      dispatch(fetchMenuGroupAPI({ menuGroupId: id }))
    }
  }, [dispatch, id])

  return <MenuItemForm />;
};

export default withPermissions(CreateMenuItem);
