import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCookie} from "../helpers/utilities";
import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import { pathOr } from "ramda";

export const fetchMenuItemsByRestaurantAPI = createAsyncThunk(
   'menu-items/fetch-menu-items-by-restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/menu-items`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchMenuItemsByLocationAPI = createAsyncThunk(
   'menu-items/fetch-menu-items-by-location',
   async (
      _, thunkAPI
   ) => {
     try {
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/menu-items`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchMenuItemsByMenuGroupAPI = createAsyncThunk(
  'menu-items/fetch-menu-items-by-menu-group',
  async (
     payload: { refresh?: boolean, page?: number, perPage?: number, menuGroupId: string; }, thunkAPI
  ) => {
    try {
      const params = new URLSearchParams();
       if (payload.refresh) {
        params.append('refresh', String(pathOr(false, ['refresh'], payload)));
       }
       params.append('page', String(pathOr(1, ['page'], payload)));
       params.append('perPage', String(pathOr(10, ['perPage'], payload)));
      const response = await client(`/menu-groups/${payload.menuGroupId}/menu-items?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  }
);

export const createMenuItemAPI = createAsyncThunk(
   'menu-items/create-menu-item',
   async (
      payload: {menuItem: any, menuGroupId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-groups/${payload.menuGroupId}/menu-items`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.menuItem,
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const updateMenuItemAPI = createAsyncThunk(
   'menu-items/update-menu-item',
   async (
      payload: {menuItem: any, menuItemId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-items/${payload.menuItemId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: payload.menuItem,
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchMenuItemAPI = createAsyncThunk(
   'menu-items/fetch-menu-item',
   async (
      payload: {menuItemId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-items/${payload.menuItemId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const deleteMenuItemAPI = createAsyncThunk(
   'menu-items/delete-menu-item',
   async (
      payload: {menuItemId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-items/${payload.menuItemId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const deleteMenuItemModifierGroupAPI = createAsyncThunk(
   'menu-items/delete-menu-item-modifier-group',
   async (
      payload: {menuItemId: string, menuModifierGroupId: string}, thunkAPI
   ) => {
     try {
       const response = await client(
          `/menu-items/${payload.menuItemId}/menu-modifier-groups/${payload.menuModifierGroupId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const archiveMenuItemAPI = createAsyncThunk(
   'menu-items/archive-menu-item',
   async (
      payload: {menuItemId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-items/${payload.menuItemId}/archive`, {
         method: 'PATCH',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const createMenuItemIngredientAPI = createAsyncThunk(
   'menu-items/create-menu-item-ingredient',
   async (
      payload: {ingredient: any, menuItemId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-items/${payload.menuItemId}/ingredients`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.ingredient,
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const updateMenuItemIngredientAPI = createAsyncThunk(
   'menu-item-ingredients/update-menu-item-ingredient',
   async (
      payload: {ingredientId: string, ingredient: any}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-item-ingredients/${payload.ingredientId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: payload.ingredient
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchMenuItemIngredientAPI = createAsyncThunk(
   'menu-item-ingredients/fetch-menu-item-ingredient',
   async (
      payload: {ingredientId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-item-ingredients/${payload.ingredientId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const deleteMenuItemIngredientAPI = createAsyncThunk(
   'menu-item-ingredients/delete-menu-item-ingredient',
   async (
      payload: {ingredientId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menu-item-ingredients/${payload.ingredientId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);
