import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnType } from "antd/lib/table";
import MenuFilters from "./components/filters";
import { Link } from "react-router-dom";
import DeleteModal from "components/modal/DeleteModal";
import { pathOr } from "ramda";
import { archiveMenuAPI, deleteMenuAPI, fetchMenusByLocationAPI } from "../../../api/menus";
import MenuForm from "./components/menu-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MENUS_ROUTE } from "../../../constants";
import { setCurrentPage, setMenu, setTotalPerPage } from "../../../store/menus/menus";
import withPermissions from "components/hoc";

interface DataItem {
  id: string;
  name: string;
  categoryCode: string;
  description: string;
  location: string;
  createdBy: string;
  imageUrl: any;
  // You might have additional properties here
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const Menu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const menus = useAppSelector((state: any) => state.menus.menus);
  const currentPage = useAppSelector((state: any) => state.menus.currentPage);
  const total = useAppSelector((state: any) => state.menus.total);
  const totalPerPage = useAppSelector((state: any) => state.menus.totalPerPage);
  const menu = useAppSelector((state: any) => state.menus.menu);
  const isLoading = useAppSelector((state: any) => state.menus.isLoading);
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    dispatch(fetchMenusByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [dispatch, currentPage, totalPerPage]);

  const columns: TableColumn[] = [
    {
      title: "Menu Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Space>
          <Link
            to={`${MENUS_ROUTE}/${record.id}/menu-groups`}
            onClick={(e) => e.stopPropagation()}
            className="font-medium text-[15px] capitalize">
            {pathOr("", ["name"], record)}
          </Link>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["description"], a).localeCompare(pathOr("", ["description"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">{pathOr("", ["description"], record)}</p>
      ),
    },
    {
      title: "Show On Web",
      dataIndex: "showOnWeb",
      key: "showOnWeb",
      render: (text, record: DataItem) => {
        return (
          <p className="capitalize font-medium text-[15px] text-gray-750">
            {String(pathOr(false, ["showOnWeb"], record))}
          </p>
        );
      },
    },
    {
      title: 'Is Archived',
      dataIndex: 'isArchived',
      key: 'isArchived',
      render: (text, record: DataItem) => <p className="font-medium capitalize text-[15px] text-gray-750">
        {String(pathOr('',['isArchived'],record))}
      </p>,
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["location", "name"], a).localeCompare(pathOr("", ["location", "name"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr("", ["location", "name"], record)}
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => (
        <Space size="middle">
          <Button
            onClick={() => navigate(`${MENUS_ROUTE}/${record.id}/menu-groups`)}
            icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
          />
          <Button
            icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "edit")}
          />
          <Button
            icon={<FolderOpenOutlined style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "archive")}
          />
          <Button
            icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "delete")}
          />
        </Space>
      ),
      width: "10%",
    },
  ];

  const openModal = (menu: any, type: "create" | "edit" | "archive" | "delete") => {
    dispatch(setMenu(menu));
    setModalState({ isOpen: true, type });
  };

  const handleClose = () => {
    dispatch(setMenu(null));
    setModalState({ isOpen: false, type: null });
  };

  const deleteMenu = async () => {
    await dispatch(deleteMenuAPI({ menuId: pathOr("", ["id"], menu) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchMenusByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const archiveMenu = async () => {
    await dispatch(archiveMenuAPI({ menuId: pathOr("", ["id"], menu) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchMenusByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const onRefresh = async () => {
    await dispatch(
      fetchMenusByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenusByLocationAPI({ page: current, perPage: size }));
  };

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div>
      <div className="flex justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Menus</p>
          <p className="text-base text-gray-750">View Menus</p>
        </div>
        <div>
          <Button
            onClick={() => openModal(null, "create")}
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            icon={<PlusOutlined />}>
            Create Menu
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex items-center md:justify-between mb-6 flex-wrap md:flex-nowrap">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="basis-full md:basis-auto md:flex-grow md:justify-end my-4 flex-wrap">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <MenuFilters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={menus}
          loading={isLoading}
          rowSelection={rowSelection}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <MenuForm
          isOpen={modalState.isOpen && ["create", "edit"].includes(modalState.type)}
          handleClose={handleClose}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteMenu}
          title={"Delete Menu"}
          prompt={`Are you sure you want to delete ${pathOr("", ["name"], menu)}`}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveMenu}
          title={"Archive Menu"}
          prompt={`Are you sure you want to delete ${pathOr("", ["name"], menu)}`}
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default withPermissions(Menu);
