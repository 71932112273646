import { Action, createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { getBusinessApi, updateBusinessApi } from "../../api/business";
import { pathOr } from "ramda";
import { message } from "antd";

interface BusinessProps {
  business: {};
  currentRestaurant: {};
  currentLocation: {};
  isLoading: boolean;
  isSubmitting: boolean;
}

const initialState: BusinessProps = {
  business: null,
  currentRestaurant: null,
  currentLocation: null,
  isLoading: false,
  isSubmitting: false,
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessData: (state, action: PayloadAction<any>) => {
      state.business = action.payload;
      state.isLoading = false;
    },
    setCurrentRestaurant: (state, action: PayloadAction<any>) => {
      state.currentRestaurant = action.payload;
      state.isLoading = false;
    },
    setCurrentLocation: (state, action: PayloadAction<any>) => {
      state.currentLocation = action.payload;
      state.isLoading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
  },
});

export const {
  setBusinessData,
  setCurrentRestaurant,
  setCurrentLocation,
  setLoading,
  setSubmitting,
} = businessSlice.actions;
export default businessSlice.reducer;

export const fetchCurrentBusiness = () => async (dispatch: Dispatch<Action>) => {
  dispatch(setLoading(true));
  try {
    const response = await getBusinessApi();
    const business = pathOr({}, ["business"], response);
    dispatch(setBusinessData(business));
    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(setLoading(false));
  }
};

export const updateCurrentBusiness = (businessData: any) => async (dispatch: any) => {
  dispatch(setSubmitting(true));
  try {
    const response = await updateBusinessApi(businessData);
    message.success(pathOr("", ["message"], response));
    dispatch(fetchCurrentBusiness());
  } catch (error: any) {
  } finally {
    dispatch(setSubmitting(false));
  }
};

