import Card from "components/card";
import Chart from "react-apexcharts";
import { useEffect } from "react";
import { fetchSalesByDiningMethodAPI } from "api/reports/sales";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store";
import { Spin } from "antd";
import { pathOr } from "ramda";

const OrdersByDiningMethod = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const salesByDiningMethodSeriesData = useAppSelector(
    (state: RootState) => state.salesReports.salesByDiningMethodSeriesData);
  const salesByDiningMethodSeriesLabels = useAppSelector(
    (state: RootState) => state.salesReports.salesByDiningMethodSeriesLabels);
  const salesByDiningMethodLoading = useAppSelector(
    (state: RootState) => state.salesReports.salesByDiningMethodLoading);

  useEffect(() => {
    dispatch(fetchSalesByDiningMethodAPI({ refresh: true }));
  }, [dispatch]);

  const options: any = {
    labels: salesByDiningMethodSeriesLabels.map((sale) => pathOr('', ['diningOption'], sale)),
    colors: salesByDiningMethodSeriesLabels.map((sale) => pathOr('', ['color'], sale)),
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: salesByDiningMethodSeriesLabels.map((sale) => pathOr('', ['color'], sale)),
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
    },
  };
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Sales By Dining Method
          </h4>
        </div>
      </div>

      <div className="mb-auto flex h-[300px] w-full items-center justify-center">
        { salesByDiningMethodLoading ? (
            <div>
              <Spin size="large" />
            </div>
          ) : (
            <Chart
              options={options}
              series={salesByDiningMethodSeriesData}
              type="pie"
              width="100%"
              height="100%"
            />
          ) }
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 
      py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        { salesByDiningMethodSeriesLabels.map((sale) => {
          return (
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center">
                <div className="h-4 w-4 rounded-full" style={{backgroundColor: pathOr('', ['color'], sale)}} />
                <p className="ml-1 text-sm font-normal text-gray-600">
                  {pathOr('', ['diningOption'], sale)}
                </p>
              </div>
              <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
                {pathOr(0, ['percentage'], sale)}%
              </p>
            </div>
          )
        }) }
        {/* <div className="h-11 w-px bg-gray-300 dark:bg-white/10" /> */}
      </div>
    </Card>
  );
};

export default OrdersByDiningMethod;
