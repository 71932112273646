import { pathOr, replace } from "ramda";
import moment from "moment/moment";
import { Button, Popover, Space, Table, TableColumnsType } from "antd";
import { FaEllipsisVertical } from "react-icons/fa6";
import React from "react";
import { ColumnType } from "antd/lib/table";
import { EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { TabsProps } from "antd/lib";
import OrderDetailsForm from "views/admin/orders/order-details-form";
import OrderItemsView from "views/admin/orders/order-items-view";
import OrderDeliveryForm from "views/admin/orders/order-delivery-form";
import { MdAdd, MdEdit } from "react-icons/md";
import { BiMinus, BiTrash } from "react-icons/bi";
import { ORDERS_ROUTE } from "../../../../constants";
import { Link } from "react-router-dom";
import OrderTransactionDetails from "../order-transaction-details";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_UPDATE_ORDER, CAN_VIEW_ORDERS } from "permissions";

export interface OrderTableDataItem {
  id: string;
  key: React.Key;
  createdAt: string;
  orderNumber: string;
  totalAmount: number;
  type: any[];
  stage: string;
  isPaid: boolean;
  location: string;
  currencySymbol: string;
}

interface OrderTableColumn extends ColumnType<OrderTableDataItem> {
  dataIndex: string;
}

interface TableColumn extends ColumnType<OrderTableDataItem & OrderItemDataItem> {
  dataIndex: string;
}

export const orderTableTabItems: TabsProps["items"] = [
  {
    key: "",
    label: "All",
    children: "",
  },
  {
    key: "DINE_IN",
    label: "Dine Ins",
    children: "",
  },
  {
    key: "DELIVERY",
    label: "Deliveries",
    children: "",
  },
  {
    key: "TAKE_OUT",
    label: "Takeouts",
    children: "",
  },
];

export const generateOrderTabs = (order: any): any[] => {
  const orderType = pathOr(null, ['type'], order);
  const orderTransaction = pathOr(null, ['transaction'], order);
  
  const defaultTabs = [
    {
      key: "orderDetails",
      label: "Order Details",
      children: <OrderDetailsForm />,
    },
    {
      key: "orderItems",
      label: "Order Items",
      children: <OrderItemsView />,
    },
  ];

  if (orderType === "DELIVERY") {
    defaultTabs.push({
      key: "deliveryDetails",
        label: "Delivery Details",
        children: <OrderDeliveryForm />,
    });
  }

  if (orderTransaction) {
    defaultTabs.push(
      {
        key: "transactionDetails",
        label: "Transaction Details",
        children: <OrderTransactionDetails />,
      },
    )
  }

  return defaultTabs;
};

interface OrderModifierItemDataItem {
  key: React.Key;
  modifierName: string;
  quantity: number;
  price: number;
  discountAmount: number;
}

interface OrderItemDataItem {
  key: React.Key;
  menuItem: string;
  quantity: number;
  price: number;
  discountAmount: number;
  tax: number;
  subAmount: number;
}

export const generateTransactionDisplayDataItems = ({ 
  currencySymbol,
  transaction,
  order,
 }: { currencySymbol: string; transaction: any; order: any;}): any[] => {
  return [
    {
      key: '1',
      label: 'Amount Paid',
      children: `${currencySymbol} ${pathOr(0, ["amountPaid"], transaction).toLocaleString()}.`,
    },
    {
      key: '2',
      label: 'Amount Due',
      children: `${currencySymbol} ${pathOr(0, ["amountDue"], transaction).toLocaleString()}.`,
    },
    {
      key: '3',
      label: 'Amount Refunded',
      children: `${currencySymbol} ${pathOr(0, ["amountRefunded"], transaction).toLocaleString()}.`,
    },
    {
      key: '4',
      label: 'Amount Refundable',
      children: `${currencySymbol} ${pathOr(0, ["amountRefundable"], transaction).toLocaleString()}.`,
    },
    {
      key: '5',
      label: 'Change',
      children: `${currencySymbol} ${pathOr(0, ["change"], transaction).toLocaleString()}.`,
    },
    {
      key: '6',
      label: 'Payment Methods',
      children: `${pathOr([], ["paymentMethods"], transaction).map((paymentMethod: string) => {
        return ` ${paymentMethod}`
      })}`,
    },
    {
      key: '7',
      label: 'Payment Status',
      children: `${pathOr(false, ['isPaid'], order) ? 'Paid' : 'Unpaid'}`,
    },
  ];
}

export const paymentsColumns = ({ 
  currencySymbol,
  handlePaymentDelete,
 }: { 
  currencySymbol: string;
  handlePaymentDelete: any;
 }) => {
  let columns = [
    {
      title: "Paid On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment(pathOr(null, ['createdAt'], record)).format("Do ddd MMM, YYYY h:mm A")}
        </p>
      ),
    },
    {
      title: "Paid By",
      dataIndex: "createdById",
      key: "createdById",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">
          {pathOr('', ['createdBy', 'profile', 'firstname'], record)}{' '}
          {pathOr('', ['createdBy', 'profile', 'lastname'], record)}
        </p>
      ),
    },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">
         {currencySymbol} {pathOr(0, ["amountPaid"], record).toLocaleString()}.
        </p>
      ),
    },
    {
      title: "Change",
      dataIndex: "change",
      key: "change",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">
         {currencySymbol} {pathOr(0, ["change"], record).toLocaleString()}.
        </p>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">{pathOr('', ['paymentMethod'], record)}</p>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text: any, record: any) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr('', ['transactionId'], record)}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text: any, record: any) => {
        if (pathOr(false, ['new'], record)) {
          return (
            <Button
              onClick={() => handlePaymentDelete(pathOr('', ['id'], record))}
              icon={<BiTrash className="text-gray-750" style={{ fontSize: 20 }} />}
            />
          );
        }
        return (
          <Button
            icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
          />
        );
      }
    }
  ]
  return columns
}

export const refundsColumns = ({ 
  currencySymbol,
  handleRefundDelete,
 }: { 
  currencySymbol: string;
  handleRefundDelete: any;
 }) => {
  let columns = [
    {
      title: "Refunded On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment(pathOr(null, ['createdAt'], record)).format("Do ddd MMM, YYYY h:mm A")}
        </p>
      ),
    },
    {
      title: "Refunded By",
      dataIndex: "createdById",
      key: "createdById",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">
          {pathOr('', ['createdBy', 'profile', 'firstname'], record)}{' '}
          {pathOr('', ['createdBy', 'profile', 'lastname'], record)}
        </p>
      ),
    },
    {
      title: "Amount Refunded",
      dataIndex: "amountRefunded",
      key: "amountPaid",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">
         {currencySymbol} {pathOr(0, ["amountRefunded"], record).toLocaleString()}.
        </p>
      ),
    },
    {
      title: "Refund Method",
      dataIndex: "refundMethod",
      key: "refundMethod",
      render: (_: any, record: any) => (
        <p className="font-medium text-[15px]">{pathOr('', ['refundMethod'], record)}</p>
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text: any, record: any) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr('', ['transactionId'], record)}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (text: any, record: any) => {
        if (pathOr(false, ['new'], record)) {
          return (
            <Button
              onClick={() => handleRefundDelete(pathOr('', ['id'], record))}
              icon={<BiTrash className="text-gray-750" style={{ fontSize: 20 }} />}
            />
          );
        }
        return (
          <Button
            icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
          />
        );
      }
    }
  ]
  return columns;
}

export const orderItemExpandedRowRender = (record: any) => {
  const orderModifierTableColumns: TableColumnsType<OrderModifierItemDataItem> = [
    {
      title: "Modifier Name",
      dataIndex: "modifierName",
      key: "modifierName",
      render: (text, record: OrderModifierItemDataItem) => (
        <Space>
          <p className="font-medium text-[15px]">
            {pathOr("", ["menuModifierItem", "name"], record)}
          </p>
        </Space>
      ),
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: OrderModifierItemDataItem, b: OrderModifierItemDataItem) =>
        pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["menuModifierItem", "quantity"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      className: "whitespace-nowrap",
      sorter: (a: OrderModifierItemDataItem, b: OrderModifierItemDataItem) =>
        pathOr(0, ["price"], a) - pathOr(0, ["price"], b),
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {Number(pathOr(0, ["menuModifierItem", "price"], record)).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discountAmount",
      key: "discountAmount",
      className: "whitespace-nowrap",
      sorter: (a: OrderModifierItemDataItem, b: OrderModifierItemDataItem) =>
        pathOr(0, ["discountAmount"], a) - pathOr(0, ["discountAmount"], b),
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {Number(pathOr(0, ["menuModifierItem", "discountAmount"], record)).toLocaleString()}
        </p>
      ),
    },
  ];

  return (
    <Table
      columns={orderModifierTableColumns}
      dataSource={pathOr([], ["orderModifierItems"], record)}
      pagination={false}
    />
  );
};

export const createUpdateOrderItemExpandedRowRender = (
  record: any,
  {
    currencySymbol,
    addOrderModifierItemQty,
    removeOrderModifierItemQty,
    removeOrderModifierItem,
    openToEditModifierItemModal,
  }: {
    currencySymbol: string;
    addOrderModifierItemQty: (record: any) => void;
    removeOrderModifierItemQty: (record: any) => void;
    removeOrderModifierItem: (record: any) => void;
    openToEditModifierItemModal: (record: any) => void;
  },
) => {
  const createUpdateModifierItemTableColumns: TableColumnsType<OrderModifierItemDataItem> = [
    {
      title: "Modifier Name",
      dataIndex: "modifierName",
      key: "modifierName",
      render: (text, record: OrderModifierItemDataItem) => (
        <Space>
          <p className="font-medium text-[15px]">
            {pathOr("", ["menuModifierItem", "name"], record)}
          </p>
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      className: "whitespace-nowrap",
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["menuModifierItem", "price"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: OrderModifierItemDataItem, b: OrderModifierItemDataItem) =>
        pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["menuModifierItem", "quantity"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      className: "whitespace-nowrap",
      sorter: (a: OrderModifierItemDataItem, b: OrderModifierItemDataItem) =>
        pathOr(0, ["discountAmount"], a) - pathOr(0, ["discountAmount"], b),
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["menuModifierItem", "discount"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      className: "whitespace-nowrap",
      render: (text, record: OrderModifierItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}
          {Number(
            pathOr(0, ["menuModifierItem", "price"], record) * pathOr(1, ["quantity"], record),
          ).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record: OrderModifierItemDataItem) => (
        <Space size="middle">
          <Button
            onClick={() => openToEditModifierItemModal(record)}
            icon={<MdEdit className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => addOrderModifierItemQty(record)}
            icon={<MdAdd className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => removeOrderModifierItemQty(record)}
            icon={<BiMinus className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => removeOrderModifierItem(record)}
            icon={<BiTrash className="text-gray-750" style={{ fontSize: 20 }} />}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={createUpdateModifierItemTableColumns}
      dataSource={pathOr([], ["orderModifierItems"], record)}
      pagination={false}
    />
  );
};

export const orderItemColumns: TableColumn[] = [
  {
    title: "Dish Name",
    dataIndex: "menuItem",
    key: "menuItem",
    sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
      pathOr("", ["menuItem"], a).localeCompare(pathOr("", ["menuItem"], b)),
    render: (text, record: OrderItemDataItem) => (
      <Space>
        <p className="font-medium text-[15px]">{pathOr("", ["menuItem", "name"], record)}</p>
      </Space>
    ),
  },
  {
    title: "QTY",
    dataIndex: "quantity",
    key: "quantity",
    className: "whitespace-nowrap",
    sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
      pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
    render: (text, record: OrderItemDataItem) => (
      <p className="font-medium text-base text-gray-750">
        {pathOr(0, ["menuItem", "quantity"], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    className: "whitespace-nowrap",
    sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
      pathOr(0, ["price"], a) - pathOr(0, ["price"], b),
    render: (text, record: OrderItemDataItem) => (
      <p className="font-medium text-base text-gray-750">
        {Number(pathOr(0, ["menuItem", "price"], record)).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Subtotal",
    dataIndex: "subAmount",
    key: "subAmount",
    sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
      pathOr(0, ["subAmount"], a) - pathOr(0, ["subAmount"], b),
    render: (text, record: OrderItemDataItem) => (
      <p className="font-medium text-base text-gray-750">
        {Number(
          pathOr(0, ["quantity"], record) * pathOr(0, ["menuItem", "price"], record),
        ).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Discount",
    dataIndex: "discountAmount",
    key: "discountAmount",
    className: "whitespace-nowrap",
    sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
      pathOr(0, ["discountAmount"], a) - pathOr(0, ["discountAmount"], b),
    render: (text, record: OrderItemDataItem) => (
      <p className="font-medium text-base text-gray-750">
        {Number(pathOr(0, ["menuItem", "discountAmount"], record)).toLocaleString()}
      </p>
    ),
  },
];

export const createUpdateOrderItemColumns = ({
  currencySymbol,
  openModifierModal,
  addOrderItemQty,
  removeOrderItem,
  openToEditMenuItemModal,
  removeOrderItemQty,
}: {
  currencySymbol: string;
  openModifierModal: (record: any) => void;
  addOrderItemQty: (record: any) => void;
  removeOrderItemQty: (record: any) => void;
  removeOrderItem: (record: any) => void;
  openToEditMenuItemModal: (record: any) => void;
}): TableColumn[] => {
  return [
    {
      title: "Menu Item Name",
      dataIndex: "menuItem",
      key: "menuItem",
      sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
        pathOr("", ["menuItem"], a).localeCompare(pathOr("", ["menuItem"], b)),
      render: (text, record: OrderItemDataItem) => (
        <div>
          <p className="font-medium text-[15px]">{pathOr("", ["menuItem", "name"], record)}</p>
          <p className="font-medium text-[15px]">{pathOr("", ["kitchenNote"], record)}</p>
        </div>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      className: "whitespace-nowrap",
      render: (text, record: OrderItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["menuItem", "price"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
        pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
      render: (text, record: OrderItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantity"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discountAmount",
      key: "discountAmount",
      className: "whitespace-nowrap",
      sorter: (a: OrderItemDataItem, b: OrderItemDataItem) =>
        pathOr(0, ["price"], a) - pathOr(0, ["price"], b),
      render: (text, record: OrderItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["discountAmount"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      className: "whitespace-nowrap",
      render: (text, record: OrderItemDataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}{" "}
          {Number(
            pathOr(0, ["menuItem", "price"], record) * pathOr(1, ["quantity"], record) -
              pathOr(0, ["discountAmount"], record),
          ).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record: OrderTableDataItem) => (
        <Space size="middle">
          <Button
            onClick={() => openToEditMenuItemModal(record)}
            icon={<MdEdit className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => addOrderItemQty(record)}
            icon={<MdAdd className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => removeOrderItemQty(record)}
            icon={<BiMinus className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => removeOrderItem(record)}
            icon={<BiTrash className="text-gray-750" style={{ fontSize: 20 }} />}
          />
          <Button
            onClick={() => openModifierModal(record)}
            icon={<PlusOutlined />}
            type="primary"
            className="bg-blue-750">
            Add Modifier
          </Button>
        </Space>
      ),
    },
  ];
};

export const orderColumns = (currency: string): OrderTableColumn[] => [
  {
    title: "",
    dataIndex: "orderNumber",
    key: "orderNumber",
    sorter: (a: OrderTableDataItem, b: OrderTableDataItem) =>
      pathOr(0, ["orderNumber"], a) - pathOr(0, ["orderNumber"], b),
    render: (text, record: OrderTableDataItem) => (
      <Link 
      to={`${ORDERS_ROUTE}/${pathOr("", ["id"], record)}/edit`}
      className="font-medium text-base text-gray-750 underline whitespace-nowrap">
        #{text}
      </Link>
    ),
  },
  {
    title: "Created On",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a: OrderTableDataItem, b: OrderTableDataItem) =>
      pathOr('',['createdAt'],a).localeCompare(pathOr('',['createdAt'],b)),
    render: (text, record: OrderTableDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {moment(text).format("Do ddd MMM, YYYY h:mm A")}
      </p>
    ),
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
    sorter: (a: OrderTableDataItem, b: OrderTableDataItem) =>
      pathOr(0, ["totalAmount"], a) - pathOr(0, ["totalAmount"], b),
    render: (text, record: OrderTableDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {currency}{" "}
        {pathOr(0, ["totalAmount"], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Amount Refunded",
    dataIndex: "amountRefunded",
    key: "amountRefunded",
    sorter: (a: OrderTableDataItem, b: OrderTableDataItem) =>
      pathOr(0, ["transaction", "amountRefunded"], a) - pathOr(0, ["transaction", "amountRefunded"], b),
    render: (text, record: OrderTableDataItem) => (
      <p className="font-medium text-base text-gray-750 whitespace-nowrap">
        {currency}{" "}
        {pathOr(0, ["transaction", "amountRefunded"], record).toLocaleString()}
      </p>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text, record: OrderTableDataItem) => (
      <p className="normal-case font-medium text-base text-gray-750 whitespace-nowrap">
        {replace(/_/g, " ", pathOr("", ["type"], record))}
      </p>
    ),
  },
  {
    title: "Stage",
    dataIndex: "stage",
    key: "stage",
    render: (text, record: OrderTableDataItem) => (
      <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">{pathOr('',['stage'],record)}</p>
    ),
  },
  {
    title: "Payment Status",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (text, record: OrderTableDataItem) => (
      <p className="uppercase font-medium text-base text-gray-750 whitespace-nowrap">
        {pathOr(false,['isPaid'],record) ? 'Paid' : 'Unpaid'}
      </p>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (text, record: OrderTableDataItem) => (
      <Space size="middle">
        <Popover content={() => <PopOverContent order={record} />} trigger={"click"}>
          <Button
            icon={<FaEllipsisVertical className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
          />
        </Popover>
      </Space>
    ),
    width: "10%",
  },
];

const PopOverContent = ({ order }: any) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <ProtectedWrapper permissionsNeeded={[CAN_VIEW_ORDERS]} shouldRenderNull>
        <Button
          type="text"
          style={{ textAlign: "left" }}
          onClick={() => navigate(`${ORDERS_ROUTE}/${pathOr("", ["id"], order)}/view`)}
          icon={<EyeOutlined className="group-hover:text-brand-500" />}
          className="group text-gray-750 font-medium text-base rounded-none">
          <span className="group-hover:text-brand-500">View Order</span>
        </Button>
      </ProtectedWrapper>

      <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_ORDER]}>
        <Button
          type="text"
          style={{ textAlign: "left" }}
          onClick={() => navigate(`${ORDERS_ROUTE}/${pathOr("", ["id"], order)}/edit`)}
          icon={<EditOutlined className="group-hover:text-brand-500" />}
          className="group text-gray-750 font-medium text-base rounded-none">
          <span className="group-hover:text-brand-500">Edit Order</span>
        </Button>
      </ProtectedWrapper>
    </div>
  );
};
