import React, {useEffect} from 'react';
import ModifierItemForm from '../components/menu-modifier-item-form';
import {useAppDispatch} from "../../../../../store/hooks";
import {useParams} from "react-router-dom";
import {fetchInventoryByLocationAPI} from "../../../../../api/inventory";
import {fetchMenuModifierGroupAPI} from "../../../../../api/menu-modifier-groups";
import {setMenuModifierItem} from "../../../../../store/menus/menu-modifier-items";
import withPermissions from 'components/hoc';

const CreateModifierItem: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(setMenuModifierItem({}));
    dispatch(fetchInventoryByLocationAPI({}));
    if (id) {
      dispatch(fetchMenuModifierGroupAPI({ menuModifierGroupId: id }))
    }
  }, [dispatch, id])

  return <ModifierItemForm />;
};

export default withPermissions(CreateModifierItem);
