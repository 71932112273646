import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';
import {
  archiveSupplierAPI,
  createSupplierAPI, deleteSupplierAPI,
  fetchSuppliersByLocationAPI,
  fetchSupplierAPI,
  updateSupplierAPI
} from "../../api/suppliers";
import {message} from "antd";
import {SUPPLIERS_ROUTE} from "../../constants";

interface Suppliers {
}

interface SuppliersState {
  suppliers: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  supplier: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: SuppliersState = {
  suppliers: [],
  status: 'idle',
  error: null,
  isLoading: false,
  supplier: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setSupplier: (state, action) => {
      state.supplier = action.payload;
    },
    updateSupplier: (state, action) => {
      state.supplier = {
        ...state.supplier,
        ...action.payload,
      }
    },
    fetchSuppliersSuccess: (state, action: PayloadAction<Suppliers[]>) => {
      state.status = 'succeeded';
      state.suppliers = action.payload;
    },
    fetchSuppliersFailure: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliersByLocationAPI.fulfilled, (state, action) => {
      state.suppliers = pathOr([], ['suppliers'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchSuppliersByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSuppliersByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSupplierAPI.fulfilled, (state, action) => {
      state.supplier = pathOr(null, ['supplier'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchSupplierAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchSupplierAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createSupplierAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.supplier = pathOr([], ['supplier'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
      state.supplier = null;
      window.location.href = SUPPLIERS_ROUTE
    });
    builder.addCase(createSupplierAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createSupplierAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateSupplierAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.supplier = pathOr([], ['supplier'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
      state.supplier = null;
      window.location.href = SUPPLIERS_ROUTE
    });
    builder.addCase(updateSupplierAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateSupplierAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSupplierAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deleteSupplierAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteSupplierAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveSupplierAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(archiveSupplierAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveSupplierAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  }
});


export const {
  fetchSuppliersSuccess,
  fetchSuppliersFailure,
  setLoading,
  updateSupplier,
  setSupplier,
  setTotalPerPage,
  setCurrentPage
} = suppliersSlice.actions;

export default suppliersSlice.reducer;
