import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { pathOr } from "ramda";
import {
  archiveMenuModifierItemAPI,
  createMenuModifierItemAPI, createMenuModifierItemIngredientAPI,
  deleteMenuModifierItemAPI, deleteMenuModifierItemIngredientAPI,
  fetchMenuModifierItemAPI, fetchMenuModifierItemIngredientAPI,
  fetchMenuModifierItemsByLocationAPI,
  fetchMenuModifierItemsByMenuModifierGroupAPI,
  fetchMenuModifierItemsByRestaurantAPI,
  updateMenuModifierItemAPI, updateMenuModifierItemIngredientAPI
} from "../../api/menu-modifier-items";
import {MENU_MODIFIER_GROUPS_ROUTE} from "../../constants";
import moment from "moment/moment";


interface MenuModifierItemsState {
  menuModifierItems: any;
  menuModifierItem: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  errors: any;
  isLoading: boolean;
  isSubmitting: boolean;
  menuModifierItemIngredient: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: MenuModifierItemsState = {
  menuModifierItems: [],
  menuModifierItem: null,
  menuModifierItemIngredient: null,
  status: "idle",
  errors: null,
  isLoading: false,
  isSubmitting: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const menuModifierItemsSlice = createSlice({
  name: "menuModifierItems",
  initialState,
  reducers: {
    setMenuModifierItem: (state, action) => {
      state.menuModifierItem = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    updateMenuModifierItem: (state, action) => {
      state.menuModifierItem = {
        ...state.menuModifierItem,
        ...action.payload
      };
    },
    setMenuModifierItemIngredient: (state, action) => {
      state.menuModifierItemIngredient = action.payload;
    },
    updateMenuModifierItemIngredient: (state, action) => {
      state.menuModifierItemIngredient = {
        ...state.menuModifierItemIngredient,
        ...action.payload,
      }
      const ingredients = pathOr([], ['ingredients'], state.menuModifierItem).map((ingredient) => {
        if (pathOr(null, ['id'], ingredient)
           === pathOr('', ['id'], state.menuModifierItemIngredient)) {
          return {
            ...state.menuModifierItemIngredient,
            ...action.payload,
          }
        }
        return {
          ...ingredient
        }
      });

      state.menuModifierItem = {
        ...state.menuModifierItem,
        ingredients
      }
    },
    addMenuModifierItemIngredient: (state, action) => {
      const ingredients = pathOr([], ['ingredients'], state.menuModifierItem);
      state.menuModifierItem = {
        ...state.menuModifierItem,
        ingredients: [...ingredients, {
          id: moment().valueOf(),
          ...action.payload
        }]
      }
    },
    removeMenuModifierItemIngredient: (state, action) => {
      const menuModifierItemIngredientId = action.payload;
      const ingredients = pathOr([], ['ingredients'], state.menuModifierItem).filter(
         (ingredient: any) => pathOr(null, ['id'], ingredient) !== menuModifierItemIngredientId);
      state.menuModifierItem = {
        ...state.menuModifierItem,
        ingredients
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },

  // fetchMenuModifierItemsByMenuModifierGroupAPI
  extraReducers: (builder) => {
    builder.addCase(fetchMenuModifierItemsByRestaurantAPI.fulfilled, (state, action) => {
      state.menuModifierItems = pathOr([], ["menuModifierItems"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsByRestaurantAPI.rejected, (state, action) => {
      state.menuModifierItems = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierItemsByMenuModifierGroupAPI.fulfilled, (state, action) => {
      state.menuModifierItems = pathOr([], ["menuModifierItems"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsByMenuModifierGroupAPI.rejected, (state, action) => {
      state.menuModifierItems = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsByMenuModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierItemsByLocationAPI.fulfilled, (state, action) => {
      state.menuModifierItems = pathOr([], ["menuModifierItems"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsByLocationAPI.rejected, (state, action) => {
      state.menuModifierItems = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemsByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierItemAPI.fulfilled, (state, action) => {
      state.menuModifierItem = pathOr(null, ["menuModifierItem"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemAPI.rejected, (state, action) => {
      state.menuModifierItem = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemAPI.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createMenuModifierItemAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuModifierItem = pathOr(null, ["menuModifierItem"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
      window.location.href =
         `${MENU_MODIFIER_GROUPS_ROUTE}/${pathOr('', ['menuModifierGroupId'], state.menuModifierItem)}/menu-modifier-items`;
    });
    builder.addCase(createMenuModifierItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuModifierItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuModifierItemAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuModifierItem = pathOr(null, ["menuModifierItem"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
      window.location.href =
         `${MENU_MODIFIER_GROUPS_ROUTE}/${pathOr('', ['menuModifierGroupId'], state.menuModifierItem)}/menu-modifier-items`;
    });
    builder.addCase(updateMenuModifierItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuModifierItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuModifierItemAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuModifierItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuModifierItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveMenuModifierItemAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuModifierItemAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuModifierItemAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createMenuModifierItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(createMenuModifierItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuModifierItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuModifierItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuModifierItemIngredient = pathOr(null, ['ingredient'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updateMenuModifierItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuModifierItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuModifierItemIngredient = pathOr(null, ['ingredient'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuModifierItemIngredientAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuModifierItemIngredientAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuModifierItemIngredientAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setMenuModifierItem,
  updateMenuModifierItem,
  setMenuModifierItemIngredient,
   updateMenuModifierItemIngredient,
  setErrors,
   addMenuModifierItemIngredient,
   removeMenuModifierItemIngredient,
   setCurrentPage,
   setTotalPerPage
} = menuModifierItemsSlice.actions;

export default menuModifierItemsSlice.reducer;
