import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { pathOr } from "ramda";
import {
  archiveMenuAPI,
  createMenuAPI,
  deleteMenuAPI,
  fetchMenuAPI,
  fetchMenusByLocationAPI,
  fetchMenusByRestaurantAPI,
  updateMenuAPI,
} from "../../api/menus";

interface MenusState {
  menus: any;
  menu: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  ingredient: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: MenusState = {
  menus: [],
  menu: null,
  status: "idle",
  error: null,
  isLoading: false,
  isSubmitting: false,
  ingredient: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const menusSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    updateMenu: (state, action) => {
      state.menu = {
        ...state.menu,
        ...action.payload
      };
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenusByRestaurantAPI.fulfilled, (state, action) => {
      state.menus = pathOr([], ["menus"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenusByRestaurantAPI.rejected, (state, action) => {
      state.menus = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenusByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenusByLocationAPI.fulfilled, (state, action) => {
      state.menus = pathOr([], ["menus"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenusByLocationAPI.rejected, (state, action) => {
      state.menus = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenusByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuAPI.fulfilled, (state, action) => {
      state.menu = pathOr(null, ["menu"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuAPI.rejected, (state, action) => {
      state.menu = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuAPI.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createMenuAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menu = pathOr(null, ["menu"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(createMenuAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menu = pathOr(null, ["menu"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updateMenuAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveMenuAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setMenu,
  updateMenu,
  setTotalPerPage,
  setCurrentPage
} = menusSlice.actions;

export default menusSlice.reducer;
