import { message } from 'antd';
import { UploadFile } from 'antd/lib';
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

export const s3 = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET },
});

export const deleteFile = async (fileName: string) => {
  try {
    const params = {
      Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET,
      Key: fileName,
    };
    try {
      await s3.deleteObject(params).promise();
      message.success(`successfully delete ${fileName}`)
      return;
    } catch (err) {
      console.error("Error deleting file:", err);
    }
  } catch (error) {
    message.error(`failed to delete file`);
    return;
  }
}

export const uploadFiles = async (files: UploadFile[], folder: string) => {
  const urls = [];
  try {
    for (const file of files) {
      const params = {
        Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET,
        Key: `${folder}/${file.name}`,
        Body: file.originFileObj,
        ContentType: file.type
      }
      const data = await s3.upload(params).promise();
      urls.push(data.Location);
    }
    return urls;
  } catch (error) {
    message.error(`failed to upload some files`);
    return urls;
  }
}