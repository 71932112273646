import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Name'),
  totalAmount: Joi.number().positive().required().label('Total amount'),
  amountPaid: Joi.number().positive().allow(0).optional().label('Amount paid'),
  amountDue: Joi.number().positive().allow(0).optional().label('Amount due'),
  quantity: Joi.number().positive().greater(0).optional().label('Quantity'),
  isComplete: Joi.boolean().optional().label('isComplete'),
  unitOfMeasure: Joi.string().optional().label('Unit of measure'),
  purchaseDate: Joi.string().optional().label('Purchase date'),
  currencyName: Joi.string().optional().label('Currency name'),
  currencyIsoCode: Joi.string().optional().label('Currency Isocode'),
  currencySymbol: Joi.string().optional().label('Currency Symbol'),
  description: Joi.string().optional().label('Description'),
  paymentStatus: Joi.string().optional().label('Payment status'),
  paymentMethod: Joi.string().optional().label('Payment method'),
  supplierId: Joi.string().optional().label('Supplier'),
  productId: Joi.string().optional().label('Inventory'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
