import React, {useEffect} from 'react';
import ModifierItemForm from '../components/menu-modifier-item-form';
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {useParams} from "react-router-dom";
import {fetchInventoryByLocationAPI} from "../../../../../api/inventory";
import {fetchMenuModifierGroupAPI} from "../../../../../api/menu-modifier-groups";
import {pathOr} from "ramda";
import {fetchMenuModifierItemAPI} from "../../../../../api/menu-modifier-items";
import withPermissions from 'components/hoc';

const EditModifierItem: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const menuModifierItem = useAppSelector((state: any) => state.menuModifierItems.menuModifierItem);

  useEffect(() => {
    dispatch(fetchInventoryByLocationAPI({}));
    if (id) {
      dispatch(fetchMenuModifierItemAPI({ menuModifierItemId: id }));
    }
  }, [dispatch, id])

  useEffect(() => {
    if (menuModifierItem && pathOr(null, ['id'], menuModifierItem)) {
      dispatch(fetchMenuModifierGroupAPI({menuModifierGroupId: pathOr('',
           ['menuModifierGroupId'], menuModifierItem)}))
    }
  }, [dispatch, menuModifierItem])
  return <ModifierItemForm />;
};

export default withPermissions(EditModifierItem);
