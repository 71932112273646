import React, { useEffect, useState } from "react";
import { DownloadOutlined, PrinterOutlined, ReloadOutlined } from "@ant-design/icons";
import { GiReceiveMoney } from "react-icons/gi";
import { Button, Space, Table, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { 
  fetchEmployeeReportById,
  fetchMenuItemsSoldByEmployeeAPI,
  fetchOrdersCreatedByEmployeeAPI,
  fetchOrdersServedByEmployeeAPI
} from "../../../../api/reports/employees";
import { pathOr } from "ramda";
import { employeeReportTableColumns } from "views/admin/reports/employees-report/components/columns";
import { IoFastFood } from "react-icons/io5";
import { MdShoppingBag } from "react-icons/md";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "store/reports/employees-reports";
import Widget from "components/widget/Widget";

const items: TabsProps["items"] = [
  {
    key: "servedOrders",
    label: "Served Orders",
    children: "",
  },
  {
    key: "createdOrders",
    label: "Created Orders",
    children: "",
  },
  {
    key: "menuItemsSold",
    label: "Menu Items Sold",
    children: "",
  },
];

const EmployeesReportDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const employeeReport = useAppSelector((state: any) => state.employeesReports.employeeReport);
  const [activeTab, setActiveTab] = useState("servedOrders");
  const currentPage = useAppSelector((state: RootState) => state.employeesReports.currentPage);
  const total = useAppSelector((state: RootState) => state.employeesReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.employeesReports.totalPerPage);
  const currencySymbol = useAppSelector(
    (state: RootState) => pathOr('', ['currencySymbol'], state.auth.currentLocation));
  const employeeServedOrders = useAppSelector((state: RootState) => state.employees.employeeServedOrders);
  const employeeCreatedOrders = useAppSelector((state: RootState) => state.employees.employeeCreatedOrders);
  const employeeMenuItemsSold = useAppSelector((state: RootState) => state.employees.employeeMenuItemsSold);
  const employeeServedOrdersLoading = useAppSelector(
    (state: RootState) => state.employees.employeeServedOrdersLoading);
  const employeeCreatedOrdersLoading = useAppSelector(
    (state: RootState) => state.employees.employeeCreatedOrdersLoading);
  const employeeMenuItemsSoldLoading = useAppSelector(
    (state: RootState) => state.employees.employeeMenuItemsSoldLoading);

  useEffect(() => {
    dispatch(fetchEmployeeReportById({ employeeId: id, refresh: true }));
    dispatch(fetchOrdersServedByEmployeeAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      employeeId: id,
    }));
  }, [dispatch, id]);

  const onRefresh = async () => {
    await dispatch(
      fetchEmployeeReportById({
        refresh: true,
        employeeId: id,
      }),
    );
    await dispatch(fetchOrdersServedByEmployeeAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      employeeId: id,
      refresh: true,
    }));
    await dispatch(fetchOrdersCreatedByEmployeeAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      employeeId: id,
      refresh: true,
    }));
    await dispatch(fetchMenuItemsSoldByEmployeeAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      employeeId: id,
      refresh: true,
    }));
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    if (activeTab === 'servedOrders') {
      await dispatch(fetchOrdersServedByEmployeeAPI({ 
        page: currentPage,
        perPage: totalPerPage,
        employeeId: id,
      }));
    }

    if (activeTab === 'createdOrders') {
      await dispatch(fetchOrdersCreatedByEmployeeAPI({ 
        page: currentPage,
        perPage: totalPerPage,
        employeeId: id,
      }));
    }

    if (activeTab === 'menuItemsSold') {
      await dispatch(fetchMenuItemsSoldByEmployeeAPI({ 
        page: currentPage,
        perPage: totalPerPage,
        employeeId: id,
      }));
    }
  };

  const onChangeTab = async (key: string) => {
    setActiveTab(key);
    if (key === 'servedOrders') {
      await dispatch(fetchOrdersServedByEmployeeAPI({ 
        page: currentPage,
        perPage: totalPerPage,
        employeeId: id,
      }));
    }

    if (key === 'createdOrders') {
      await dispatch(fetchOrdersCreatedByEmployeeAPI({ 
        page: currentPage,
        perPage: totalPerPage,
        employeeId: id,
      }));
    }

    if (key === 'menuItemsSold') {
      await dispatch(fetchMenuItemsSoldByEmployeeAPI({ 
        page: currentPage,
        perPage: totalPerPage,
        employeeId: id,
      }));
    }
  };

  const widgetData: any[] = [
    {
      icon: <GiReceiveMoney className="h-7 w-7" />,
      title: "Revenue",
      value: `${currencySymbol} ${pathOr(0, ["actualRevenue"], employeeReport).toLocaleString()}`,
    },
    {
      icon: <MdShoppingBag className="h-7 w-7" />,
      title: "Orders",
      value: pathOr(0, ["totalOrders"], employeeReport).toLocaleString(),
    },
    {
      icon: <IoFastFood className="h-7 w-7" />,
      title: "Menu Items Sold",
      value: pathOr(0, ["totalMenuItemsSold"], employeeReport).toLocaleString(),
    },
  ];
  const dataSource = activeTab === 'createdOrders' ? employeeCreatedOrders : activeTab === 'servedOrders' ?
   employeeServedOrders : activeTab === 'menuItemsSold' ? employeeMenuItemsSold : [];
  const tableLoading = activeTab === 'createdOrders' ? employeeCreatedOrdersLoading
   : activeTab === 'servedOrders' ? employeeServedOrdersLoading : activeTab === 'menuItemsSold'
    ? employeeMenuItemsSoldLoading : false;
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">
            {pathOr("", ["employee", "user", "profile", "firstname"], employeeReport)}{" "}
            {pathOr("", ["employee", "user", "profile", "lastname"], employeeReport)}
          </p>
          <p className="text-base text-gray-750">
            Showing the employee report for{" "}
            {pathOr("", ["employee", "user", "profile", "firstname"], employeeReport)}{" "}
            {pathOr("", ["employee", "user", "profile", "lastname"], employeeReport)}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-6 mb-6">
        {widgetData.map((widget) => {
          return (
            <Widget
              icon={widget.icon}
              title={widget.title}
              subtitle={widget.value}
            />
          )
        })}
      </div>
      <div className="bg-white p-4 rounded-xl">
        <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />
        <div className="flex flex-warp items-center md:justify-between mb-6">
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Table
          loading={tableLoading}
          scroll={{ x: "max-content" }}
          columns={pathOr([], [activeTab], employeeReportTableColumns)}
          dataSource={dataSource}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default EmployeesReportDetail;
