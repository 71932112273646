import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import type { TableRowSelection } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/lib/table';
import Filters from './components/filters';
import { BadgeProps } from 'components/badge/Badge';
import {EMPLOYEES_REPORT_ROUTE, EMPLOYEES_ROUTE} from '../../../constants';
import DeleteModal from 'components/modal/DeleteModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import {archiveEmployeeAPI, fetchEmployeesByLocationAPI} from "../../../api/employees";
import { RootState } from 'store';
import {setTotalPerPage, setCurrentPage} from '../../../store/employees/employees'
import { BiSolidReport } from 'react-icons/bi';
import withPermissions from 'components/hoc';
import ProtectedWrapper from 'components/protected-wrapper';
import { CAN_ARCHIVE_EMPLOYEE, CAN_CREATE_EMPLOYEE, CAN_UPDATE_EMPLOYEE, CAN_VIEW_EMPLOYEE_REPORT } from 'permissions';

interface DataItem {
  id: string;
  name: string;
  phone: string;
  email: string;
  role: string;
  date: string;
  status: BadgeProps;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const Employees: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const employees = useAppSelector((state: any) => state.employees.employees);
  const isLoading = useAppSelector((state: any) => state.employees.isLoading);
  const isSubmitting = useAppSelector((state: any) => state.employees.isSubmitting);
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [employee, setEmployee] = useState<any>(null);
  const currentPage = useAppSelector((state: RootState) => state.employees.currentPage);
  const total = useAppSelector((state: RootState) => state.employees.total);
  const totalPerPage = useAppSelector((state: RootState) => state.employees.totalPerPage);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });


  useEffect(() => {
    dispatch(fetchEmployeesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const columns: TableColumn[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: DataItem, b: DataItem) => pathOr('', ['user', 'profile', 'firstname'], a).localeCompare(
         pathOr('', ['user', 'profile', 'firstname'], b)),
      render: (text: any, record: DataItem) => {
        const id = pathOr('', ['id'], record);
        const firstname = pathOr('', ['user', 'profile', 'firstname'], record);
        const lastname = pathOr('', ['user', 'profile', 'lastname'], record);
        return (
          <Link to={`${EMPLOYEES_ROUTE}/${id}/edit`}>
            <p className="font-medium text-base capitalize">{`${firstname} ${lastname}`}</p>
          </Link>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: DataItem, b: DataItem) => pathOr('', ['user', 'email'], a).localeCompare(
         pathOr('', ['user', 'email'], b)),
      render: (text: any, record: DataItem) => {
        const email = pathOr('', ['user', 'email'], record);
        return (
          <p className="font-medium text-[15px] text-gray-750">{email}</p>
        )
      }
    },
    {
      title: 'Permission Set',
      dataIndex: 'permissionSet',
      key: 'permissionSet',
      render: (text: any, record: DataItem) => {
        const name = pathOr('', ['permissionSet', 'name'], record);
        return (
          <p className="font-medium text-[15px] text-gray-750">{name}</p>
        )
      }
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record: DataItem) => <p 
      className="font-medium text-base text-gray-750">{text}</p>,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record: DataItem) => {
        return (
          <Space size="middle">
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_VIEW_EMPLOYEE_REPORT]}>
              <Button
                icon={<BiSolidReport className="text-gray-750" style={{ fontSize: 20 }} />}
                type="link"
                href={`${EMPLOYEES_REPORT_ROUTE}/${pathOr('', ['id'], record)}/view`}
              />
            </ProtectedWrapper>

            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_EMPLOYEE]}>
              <Button
                icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
                type="link"
                href={`${EMPLOYEES_ROUTE}/${pathOr('', ['id'], record)}/edit`}
              />
            </ProtectedWrapper>

            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_ARCHIVE_EMPLOYEE]}>
              <Button
                icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
                type="link"
                onClick={()=> handleDeleteEmployee(record)}
              />
            </ProtectedWrapper>
          </Space>
        )
      },
    }
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText('');
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const handleClose = () => {
    setModalState({ isOpen: false, type: null })
  }

  const handleDeleteEmployee = (employee: any) => {
    setEmployee(employee);
    setModalState({ isOpen: true, type: 'deleteModal' });
  };

  const confirmArchiveEmployee = async () => {
    await dispatch(archiveEmployeeAPI(
    { employeeId: pathOr('', ['id'], employee) }
    ));
    await dispatch(fetchEmployeesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    handleClose()
  }

  const onRefresh = async () => {
    await dispatch(fetchEmployeesByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }))
  }

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchEmployeesByLocationAPI({ page: current, perPage: size }));
  }

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap">
        <div>
          <p className="text-lg font-bold">Employees List</p>
          <p className="text-base text-gray-750">Manage your Employees</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_EMPLOYEE]}>
            <Button
              onClick={() => navigate(`${EMPLOYEES_ROUTE}/create`)}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Add Employee
            </Button>
          </ProtectedWrapper>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
          <Button onClick={onRefresh} icon={<ReloadOutlined />}>Refresh</Button>
          </Space>
        </div>
        <Filters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
           scroll={{ x: 'max-content' }}
           columns={columns}
           loading={isLoading}
           dataSource={employees}
           rowSelection={rowSelection}
           pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onShowSizeChange: onPageSizeChange,
            onChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30]
          }}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === 'deleteModal'}
          handleClose={handleClose}
          cancelDelete={handleClose}
          isDeleting={isSubmitting}
          confirmDelete={confirmArchiveEmployee}
          prompt={'Archive employee'}
          deletePrompt={'Archive'}
        />
      </div>
    </div>
  );
};

export default withPermissions(Employees);
