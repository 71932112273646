import React, { useState } from "react";
import { Tabs } from "antd";
import Restaurants from "views/admin/business/restaurants";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_VIEW_BUSINESS, CAN_VIEW_RESTAURANTS } from "permissions";
import { pathOr } from "ramda";
import withPermissions, { hasPermission } from "components/hoc";
import { useAppSelector } from "store/hooks";
import { RootState } from "store";
import BusinessProfile from "./business-profile";


const Business = () => {
  const [activeTab, setActiveTab] = useState('business');
  const permissionSet = useAppSelector((state: RootState) => pathOr(null, [
    'employee',
    'permissionSet'
  ], state.auth.user))

  const items = [
    {
      key: 'business',
      label: "Business",
      permissionsNeeded: [CAN_VIEW_BUSINESS],
      children: (
        <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_VIEW_BUSINESS]}>
          <BusinessProfile />
        </ProtectedWrapper>
      ),
    },
    {
      key: 'restaurants',
      label: "Restaurants",
      permissionsNeeded: [CAN_VIEW_RESTAURANTS],
      children: (
        <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_VIEW_RESTAURANTS]}>
          <Restaurants />
        </ProtectedWrapper>
      ),
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Business</p>
          <p className="text-base text-gray-750">All business specific operations</p>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <Tabs
          type="card"
          defaultActiveKey="1"
          activeKey={activeTab}
          items={items.filter((item) => hasPermission(permissionSet, item.permissionsNeeded))}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default withPermissions(Business);
