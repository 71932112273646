import { getCookie } from "helpers/utilities";
import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchSuppliersByRestaurantAPI = createAsyncThunk(
   'suppliers/fetch-suppliers-by-restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/suppliers`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchSuppliersByLocationAPI = createAsyncThunk(
   'suppliers/fetch-suppliers-by-location',
   async (
    payload: { refresh?: boolean, page?: number, perPage?: number }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
       if (payload.refresh) {
        params.append('refresh', String(pathOr(false, ['refresh'], payload)));
       }
       params.append('page', String(pathOr(1, ['page'], payload)));
       params.append('perPage', String(pathOr(10, ['perPage'], payload)));
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/suppliers?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchSupplierAPI = createAsyncThunk(
   'suppliers/fetch-supplier',
   async (
      payload: { supplierId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/suppliers/${payload.supplierId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const createSupplierAPI = createAsyncThunk(
   'suppliers/create-supplier',
   async (
      payload: { supplier: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/suppliers`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.supplier
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const updateSupplierAPI = createAsyncThunk(
   'suppliers/update-supplier',
   async (
      payload: { supplier: any; supplierId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/suppliers/${payload.supplierId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: payload.supplier
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const archiveSupplierAPI = createAsyncThunk(
   'suppliers/archive-supplier',
   async (
      payload: { supplierId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/suppliers/${payload.supplierId}/archive`, {
         method: 'PATCH',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const deleteSupplierAPI = createAsyncThunk(
   'suppliers/delete-supplier',
   async (
      payload: { supplierId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/suppliers/${payload.supplierId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);


