import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnType } from "antd/lib/table";
import CategoryFilters from "./components/category-filters";
import { length, pathOr } from "ramda";
import CategoryForm from "./components/category-form";
import DeleteModal from "components/modal/DeleteModal";
import { setCategory } from "../../../../store/categories/categories";
import {
  archiveCategoryAPI,
  deleteCategoryAPI,
  fetchCategoriesByLocationAPI,
} from "../../../../api/categories";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setCurrentPage, setTotalPerPage } from "../../../../store/categories/categories";
import { RootState } from "store";
import withPermissions from "components/hoc";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_ARCHIVE_CATEGORY, CAN_CREATE_CATEGORY, CAN_DELETE_CATEGORY, CAN_UPDATE_CATEGORY } from "permissions";

interface DataItem {
  id: string;
  name: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const Categories: React.FC = () => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state: any) => state.categories.categories);
  const category = useAppSelector((state: any) => state.categories.category);
  const isLoading = useAppSelector((state: RootState) => state.categories.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.categories.currentPage);
  const total = useAppSelector((state: RootState) => state.categories.total);
  const totalPerPage = useAppSelector((state: RootState) => state.categories.totalPerPage);
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    dispatch(fetchCategoriesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [dispatch]);

  const columns: TableColumn[] = [
    {
      title: "Category",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Space>
          <p>{pathOr("", ["name"], record)}</p>
        </Space>
      ),
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      sorter: (a: DataItem, b: DataItem) =>
        length(pathOr([], ["products"], a)) - length(pathOr([], ["products"], b)),
      render: (text, record: DataItem) => (
        <Space>
          <p>{length(pathOr([], ["products"], record))}</p>
        </Space>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["location", "name"], a).localeCompare(pathOr("", ["location", "name"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr("", ["location", "name"], record)}
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: any) => (
        <Space size="middle">
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_CATEGORY]}>
            <Button
              icon={
                <EditOutlined
                  onClick={() => openModal(record, "edit")}
                  className="text-gray-750"
                  style={{ fontSize: 20 }}
                />
              }
              type="link"
            />
          </ProtectedWrapper >

          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_ARCHIVE_CATEGORY]}>
            <Button
              icon={<FolderOpenOutlined style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => openModal(record, "archive")}
            />
          </ProtectedWrapper>

          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_DELETE_CATEGORY]}>
            <Button
              icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => openModal(record, "delete")}
            />
          </ProtectedWrapper>
        </Space>
      ),
      width: "10%",
    },
  ];

  const openModal = (category: any, type: "create" | "edit" | "delete" | "archive") => {
    dispatch(setCategory(category));
    setModalState({ isOpen: true, type });
  };

  const handleClose = () => {
    dispatch(setCategory(null));
    setModalState({ isOpen: false, type: null });
  };

  const deleteCategory = async () => {
    try {
      await dispatch(deleteCategoryAPI({ categoryId: pathOr("", ["id"], category) }));
      setModalState({ isOpen: false, type: null });
      await dispatch(fetchCategoriesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    } catch (error: any) {}
  };

  const archiveCategory = async () => {
    try {
      await dispatch(archiveCategoryAPI({ categoryId: pathOr("", ["id"], category) }));
      setModalState({ isOpen: false, type: null });
      await dispatch(fetchCategoriesByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    } catch (error: any) {}
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const onRefresh = async () => {
    await dispatch(
      fetchCategoriesByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchCategoriesByLocationAPI({ page: current, perPage: size }));
  };

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap">
        <div>
          <p className="text-lg font-bold">Categories List</p>
          <p className="text-base text-gray-750">View categories</p>
        </div>
        <div>
          <Button
            onClick={() => openModal(null, "create")}
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            icon={<PlusOutlined />}>
            Create category
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex items-center md:justify-between mb-6 flex-wrap">
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_CATEGORY]}>
            <Button
              className="bg-blue-750 basis-9 h-9 mr-4"
              type="primary"
              onClick={toggleFilters}
              icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
            />
          </ProtectedWrapper>
          
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <CategoryFilters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={categories}
          rowSelection={rowSelection}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <CategoryForm
          isOpen={modalState.isOpen && ["create", "edit"].includes(modalState.type)}
          handleClose={handleClose}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteCategory}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveCategory}
          prompt="Are you sure you want to archive this category"
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default withPermissions(Categories);
