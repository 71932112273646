import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { pathOr } from "ramda";
import moment from "moment";
import { fetchOrdersReportByLocationAPI } from "../../../../api/reports/orders";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage, updateFilters } from "store/reports/orders-reports";
import withPermissions from "components/hoc";
interface DataItem {
  key: React.Key;
  date: string;
  totalAmount: number;
  subAmount: number;
  quantity: number;
  discounts: number;
  taxes: number;
  tips: number;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const { RangePicker } = DatePicker;

const OrdersReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const ordersReport = useAppSelector((state: any) => state.ordersReports.ordersReport);
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const [filtersVisible, setFiltersVisible] = useState(false);
  const loading = useAppSelector((state: RootState) => state.ordersReports.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.ordersReports.currentPage);
  const total = useAppSelector((state: RootState) => state.ordersReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.ordersReports.totalPerPage);
  const filters = useAppSelector((state: RootState) => state.ordersReports.filters);

  useEffect(() => {
    dispatch(fetchOrdersReportByLocationAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      ...filters }));
  }, [currentPage, dispatch, totalPerPage]);

  const onRefresh = async () => {
    await dispatch(
      fetchOrdersReportByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage, ...filters }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchOrdersReportByLocationAPI({ page: current, perPage: size, ...filters }));
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onDateChange = (date: any, value: any) => {
    dispatch(updateFilters({
     fromDate: value[0] ? moment.utc(value[0]).format('YYYY-MM-DD') : null,
     toDate: value[1] ? moment.utc(value[1]).format('YYYY-MM-DD') : null,
   }));
   if (!value[0] && !value[1]) {
     dispatch(
        fetchOrdersReportByLocationAPI({ 
          page: currentPage,
          perPage: totalPerPage,
          ...filters,
          fromDate: null,
          toDate: null
        }
      )
     );
   }
 }

  const confirmDates = async () => {
    await dispatch(
      fetchOrdersReportByLocationAPI({ 
          page: currentPage,
          perPage: totalPerPage,
          ...filters,
        }
      )
    );
  }

  const columns: TableColumn[] = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["date"], a).localeCompare(pathOr("", ["date"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {moment(pathOr("", ["rawDate"], record)).format("Do ddd MMMM, YYYY")}
        </p>
      ),
    },
    {
      title: "Expected Revenue",
      dataIndex: "expectedRevenue",
      key: "expectedRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["expectedRevenue"], a) - pathOr(0, ["expectedRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["expectedRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Actual Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["actualRevenue"], a) - pathOr(0, ["actualRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["actualRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Lost Revenue",
      dataIndex: "lostRevenue",
      key: "lostRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["lostRevenue"], a) - pathOr(0, ["lostRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["lostRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Quantity Paid",
      dataIndex: "quantityPaid",
      key: "quantityPaid",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["quantityPaid"], a) - pathOr(0, ["quantityPaid"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantityPaid"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Quantity UnPaid",
      dataIndex: "quantityUnpaid",
      key: "quantityUnpaid",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["quantityUnpaid"], a) - pathOr(0, ["quantityUnpaid"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantityUnpaid"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Discounts",
      dataIndex: "discounts",
      key: "discounts",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["discounts"], a) - pathOr(0, ["discounts"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["discounts"], record).toLocaleString()}
        </p>
      ),
    },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Orders Report</p>
          <p className="text-base text-gray-750">Manage your Reports</p>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Space>
            <Button
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              onClick={toggleFilters}
              icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
            />
           <RangePicker onChange={onDateChange} allowClear />
           {filters.fromDate && filters.toDate ? <Button 
           onClick={confirmDates}
           className="ml-2">Confirm</Button> : null}
          </Space>
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={ordersReport}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default withPermissions(OrdersReport);
