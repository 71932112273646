import React, { FC } from "react";
import ParentModal, { modalProps } from "./Modal";
import { Button } from "antd";

interface DeleteModalProps extends modalProps {
  confirmDelete: () => void;
  cancelDelete: () => void;
  title?: string;
  prompt?: string;
  isDeleting?: boolean;
  deletePrompt?: string;
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  confirmDelete,
  cancelDelete,
  handleClose,
  title,
  prompt,
  isDeleting,
   deletePrompt,
}) => {

  const handleConfirm = async () => {
    await confirmDelete();
  };

  return (
    <>
      <ParentModal isOpen={isOpen} handleClose={handleClose}>
        <div className="text-center text-gray-750">
          <h3 className="text-2xl font-medium">{title ? `${title}` : "Are you sure?"}</h3>
          <p className="mt-5 text-lg">
            {prompt ? `${prompt}` : `You won't be able to revert this!`}
          </p>
          <div className="mt-5 space-x-3">
            <Button
              className="bg-red-500 border-none text-white "
              danger
              type="primary"
              style={{ color: "#fff", backgroundColor: isDeleting && "#f53939" }}
              onClick={handleConfirm}
              disabled={isDeleting}
              loading={isDeleting}>
              {deletePrompt ? deletePrompt : "Delete"}
            </Button>
            <Button className="" color="#fff" onClick={cancelDelete}>
              Cancel
            </Button>
          </div>
        </div>
      </ParentModal>
    </>
  );
};

export default DeleteModal;
