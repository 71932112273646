import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import Filters from "./components/filters";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { pathOr } from "ramda";
import { fetchInventoryReportByLocationAPI } from "../../../../api/reports/inventory";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "store/reports/inventory-reports";
import withPermissions from "components/hoc";

interface DataItem {
  key: React.Key;
  name: string;
  quantity: number;
  unitOfMeasure: string;
  costPrice: number;
  location: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const InventoryReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const inventoryReport = useAppSelector((state: any) => state.inventoryReports.inventoryReport);
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [filtersVisible, setFiltersVisible] = useState(false);
  const loading = useAppSelector((state: RootState) => state.inventoryReports.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.inventoryReports.currentPage);
  const total = useAppSelector((state: RootState) => state.inventoryReports.total);
  const totalPerPage = useAppSelector((state: RootState) => state.inventoryReports.totalPerPage);

  useEffect(() => {
    dispatch(
      fetchInventoryReportByLocationAPI({
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  }, [currentPage, dispatch, totalPerPage]);

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const onRefresh = async () => {
    await dispatch(
      fetchInventoryReportByLocationAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchInventoryReportByLocationAPI({ page: current, perPage: size }));
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["name"], record)}
        </p>
      ),
    },
    {
      title: "Quantity Used",
      dataIndex: "quantityUsed",
      key: "quantityUsed",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["quantityUsed"], a) - pathOr(0, ["quantityUsed"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantityUsed"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unitOfMeasure",
      key: "unitOfMeasure",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["unitOfMeasure"], a).localeCompare(pathOr("", ["unitOfMeasure"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["unitOfMeasure"], record)}
        </p>
      ),
    },
    {
      title: "Cost Price",
      dataIndex: "costPrice",
      key: "costPrice",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["costPrice"], a) - pathOr(0, ["costPrice"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol}. {pathOr(0, ["costPrice"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["location"], a).localeCompare(pathOr("", ["location"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["location"], record)}
        </p>
      ),
    },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Inventory Report</p>
          <p className="text-base text-gray-750">Manage your Reports</p>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Filters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={inventoryReport}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default withPermissions(InventoryReport);
