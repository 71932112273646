import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EditOutlined,
  DownloadOutlined,
  PrinterOutlined,
  SearchOutlined,
  PlusOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import type { TableRowSelection } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/lib/table';
import { BadgeProps } from 'components/badge/Badge';
import {CUSTOMERS_ROUTE} from '../../../constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import {fetchCustomersByLocationAPI} from "../../../api/customers";
import { RootState } from 'store';
import {setTotalPerPage, setCurrentPage} from '../../../store/customers/customers';
import withPermissions from 'components/hoc';
import ProtectedWrapper from 'components/protected-wrapper';
import { CAN_CREATE_CUSTOMER, CAN_UPDATE_CUSTOMER } from 'permissions';

interface DataItem {
  id: string;
  name: string;
  phone: string;
  email: string;
  role: string;
  date: string;
  status: BadgeProps;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const Customers: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customers = useAppSelector((state: any) => state.customers.customers);
  const loading = useAppSelector((state: any) => state.customers.loading);
  const [searchText, setSearchText] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [customer, setCustomer] = useState<any>(null);
  const currentPage = useAppSelector((state: RootState) => state.customers.currentPage);
  const total = useAppSelector((state: RootState) => state.customers.total);
  const totalPerPage = useAppSelector((state: RootState) => state.customers.totalPerPage);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });


  useEffect(() => {
    dispatch(fetchCustomersByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const columns: TableColumn[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: DataItem, b: DataItem) => pathOr('', ['name'], a).localeCompare(
         pathOr('', ['name'], b)),
      render: (text: any, record: DataItem) => {
        const id = pathOr('', ['id'], record);
        const name = pathOr('', ['name'], record);
        return (
          <Link to={`${CUSTOMERS_ROUTE}/${id}/edit`}>
            <p className="font-medium text-base capitalize">{`${name}`}</p>
          </Link>
        )
      }
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a: DataItem, b: DataItem) => pathOr('', ['phoneNumber'], a).localeCompare(
         pathOr('', ['phoneNumber'], b)),
      render: (text: any, record: DataItem) => {
        const phoneNumber = pathOr('', ['phoneNumber'], record);
        return (
          <p className="font-medium text-[15px] text-gray-750">{phoneNumber}</p>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: DataItem, b: DataItem) => pathOr('', ['email'], a).localeCompare(
         pathOr('', ['email'], b)),
      render: (text: any, record: DataItem) => {
        const email = pathOr('', ['email'], record);
        return (
          <p className="font-medium text-[15px] text-gray-750">{email}</p>
        )
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record: DataItem) => {
        return (
          <Space size="middle">
            {/* <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_VIEW_EMPLOYEE_REPORT]}>
              <Button
                icon={<BiSolidReport className="text-gray-750" style={{ fontSize: 20 }} />}
                type="link"
                href={`${CUSTOMERS_REPORT_ROUTE}/${pathOr('', ['id'], record)}/view`}
              />
            </ProtectedWrapper> */}
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_CUSTOMER]}>
              <Button
                icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
                type="link"
                href={`${CUSTOMERS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
              />
            </ProtectedWrapper>
          </Space>
        )
      },
    }
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClose = () => {
    setModalState({ isOpen: false, type: null })
  }

  const handleDeleteCustomer = (customer: any) => {
    setCustomer(customer);
    setModalState({ isOpen: true, type: 'deleteModal' });
  };

  const onRefresh = async () => {
    await dispatch(fetchCustomersByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }))
  }

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchCustomersByLocationAPI({ page: current, perPage: size }));
  }

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap">
        <div>
          <p className="text-lg font-bold">Customers List</p>
          <p className="text-base text-gray-750">Manage your Customers</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_CUSTOMER]}>
            <Button
              onClick={() => navigate(`${CUSTOMERS_ROUTE}/create`)}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Add customer
            </Button>
          </ProtectedWrapper>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
          <Button onClick={onRefresh} icon={<ReloadOutlined />}>Refresh</Button>
          </Space>
        </div>
        <Table
           scroll={{ x: 'max-content' }}
           columns={columns}
           loading={loading}
           dataSource={customers}
           rowSelection={rowSelection}
           pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onShowSizeChange: onPageSizeChange,
            onChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30]
          }}
        />
      </div>
    </div>
  );
};

export default withPermissions(Customers);
