import React, { useState } from "react";
import { Button, Table, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import MeasurementModal from "./measurement-form";
import { pathOr } from "ramda";
import DeleteModal from "components/modal/DeleteModal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import {
   deleteProductMeasurement, setProductMeasurement,
} from "../../../../store/inventory/inventory";
import {deleteProductMeasurementAPI, fetchProductAPI} from "../../../../api/inventory";
import {useParams} from "react-router-dom";

const ProductMeasurement = () => {
  const dispatch = useAppDispatch();
  const {id} = useParams();
  const product = useAppSelector((state: RootState) => state.inventory.product);
  const productMeasurement = useAppSelector((state: RootState) => state.inventory.productMeasurement);
  const isLoading = useAppSelector((state: RootState) => state.inventory.isLoading);
  const measurements = pathOr([], ["productMeasurements"], product);
  const [modalState, setModalState] = useState({ isOpen: false, type: null });

  const openModal = (record: any, type: 'create' | 'update' | 'delete') => {
    dispatch(setProductMeasurement(record));
    setModalState({ isOpen: true, type });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, type: null });
  };

  const deleteMeasurement = async () => {
    try {
      if (pathOr(null, ['id'], product) && pathOr(null, ['createdAt'], product)) {
          await dispatch(
             deleteProductMeasurementAPI({
               productMeasurementId: pathOr("", ["id"], productMeasurement)
             }),
          );
          await dispatch(fetchProductAPI({productId: id}));
      } else {
        dispatch(deleteProductMeasurement(pathOr('', ['id'], productMeasurement)));
      }
      setModalState({ isOpen: false, type: null });
    } catch (error: any) {}
  };

  const columns = [
    {
      title: "Quantity",
      dataIndex: "quantityToMakeTrackableUnit",
      key: "quantityToMakeTrackableUnit",
      render: (text: any, record: any, index: number) => `${text}`,
    },
    {
      title: "Unit",
      dataIndex: "unitOfMeasure",
      key: "unitOfMeasure",
      render: (text: any, record: any, index: number) => `${text}`,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
         <Space size="middle">
           <Button
              icon={
                <EditOutlined
                   onClick={() => openModal(record, 'update')}
                   className="text-gray-750"
                   style={{ fontSize: 20 }}
                />
              }
              type="link"
           />
           <Button
              icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => openModal(record, 'delete')}
           />
         </Space>
      ),
      width: "15%",
    },
  ];

  return (
     <div className="pb-8">
       <div className="flex flex-wrap justify-end mb-4">
         <Button type="primary" className="bg-blue-750" onClick={() => openModal(null, 'create')}>
           Add Measurements
         </Button>
       </div>
       <Table
        scroll={{ x: "max-content" }}
        dataSource={measurements}
        columns={columns}
        pagination={false}
        loading={isLoading}
       />
       <MeasurementModal
          isOpen={modalState.isOpen && ['create', 'update'].includes(modalState.type)}
          handleClose={closeModal}
       />
       <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={closeModal}
          cancelDelete={closeModal}
          confirmDelete={deleteMeasurement}
          isDeleting={isLoading}
       />
     </div>
  );
};

export default ProductMeasurement;
