import { concat, flatten, isEmpty, length, map, omit, uniq } from 'ramda';

import { isNotEmpty } from './is-not-empty';

export interface QueueProps<T> {
  _store: any[];
  push: (val: T) => T;
  shift: () => T;
  dequeue: () => T;
}

export class Queue<T> {
  _store: T[] = [];
  push(val: T) {
    this._store.push(val);
  }
  shift(): T | undefined {
    return this._store.shift();
  }
  dequeue(): T | undefined {
    return this._store[0];
  }
}

export class Node<T> {
  data: any = {};
  parent: any = {};
  children: T[] = [];

  constructor(data: any) {
    this.data = data;
    this.parent = null;
    this.children = [];
  }
}

export interface NodeProp {
  data: any;
  parent: any;
  children: any[];
}

export class Tree {
  _root: NodeProp = { data: {}, parent: {}, children: [] };
  constructor(data: any) {
    this._root = new Node(data);
  }
}

export const traverseBF = (treeRoot: any) => {
  let queue: QueueProps<any> = new Queue();
  queue.push(treeRoot);
  let tree = [treeRoot];
  let currentTree = queue._store[0];
  while (currentTree) {
    let i = 0;
    for (i = 0; i < currentTree.children.length; i++) {
      queue.push(currentTree.children[i]);
      tree.push(currentTree.children[i]);
      if (i === 13) {
        return;
      }
    }
    queue.shift();
    currentTree = queue._store[0];

    traverseBF(currentTree);
  }
  return uniq(flatten(map((x: any) => omit(['children', 'parent'], x), tree)));
};

export const getAdjacentNodes = (rootNode: any, nodeName: string) => {
  let que: QueueProps<any> = new Queue();
  if (rootNode == null) {
    return 0;
  }
  let items: any = [];
  que.push(rootNode);
  while (!isEmpty(que)) {
    /* initially root node will be there ,but while in process nodes will get added up in que*/
    const p = que._store[0] || [];
    /*after taking first node ,first node will be removed from que */
    que.shift();
    /*if first node children is itself empty,then there is no children for the root nodes */
    if (!isNotEmpty(p)) {
      return items;
    }
    let i;
    /* checking for the name of the node, if it equals then children of the node will be returned
        if it not equals then ,children of checked nodes will be pushed into que for analysis of
        children nodes
        */
    for (i = 0; i <= length(p.children); i++) {
      if (p.children[i]?.data.value.toLowerCase() === nodeName.toLowerCase()) {
        items = concat(uniq([...p.children[i].children]), items);
        return items;
      }
      if (isNotEmpty(p?.children[i]?.children)) {
        que.push(p.children[i]);
      }
    }
    /*if root node equals then root nodes children will be returned */
    if (p?.data?.value.toLowerCase() === nodeName.toLowerCase()) {
      items = concat(p.children, items);
      return items;
    }
  }
};
