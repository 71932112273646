import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { pathOr } from "ramda";
import {
  archiveMenuModifierGroupAPI,
  createMenuModifierGroupAPI,
  deleteMenuModifierGroupAPI,
  fetchMenuModifierGroupAPI,
  fetchMenuModifierGroupsByLocationAPI,
  fetchMenuModifierGroupsByRestaurantAPI,
  updateMenuModifierGroupAPI,
} from "../../api/menu-modifier-groups";


interface MenuModifierGroupsState {
  menuModifierGroups: any;
  menuModifierGroup: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  ingredient: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: MenuModifierGroupsState = {
  menuModifierGroups: [],
  menuModifierGroup: null,
  status: "idle",
  error: null,
  isLoading: false,
  isSubmitting: false,
  ingredient: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const menuModifierGroupsSlice = createSlice({
  name: "menuModifierGroups",
  initialState,
  reducers: {
    setMenuModifierGroup: (state, action) => {
      state.menuModifierGroup = action.payload;
    },
    updateMenuModifierGroup: (state, action) => {
      state.menuModifierGroup = {
        ...state.menuModifierGroup,
        ...action.payload
      };
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenuModifierGroupsByRestaurantAPI.fulfilled, (state, action) => {
      state.menuModifierGroups = pathOr([], ["menuModifierGroups"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierGroupsByRestaurantAPI.rejected, (state, action) => {
      state.menuModifierGroups = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierGroupsByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierGroupsByLocationAPI.fulfilled, (state, action) => {
      state.menuModifierGroups = pathOr([], ["menuModifierGroups"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierGroupsByLocationAPI.rejected, (state, action) => {
      state.menuModifierGroups = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierGroupsByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuModifierGroupAPI.fulfilled, (state, action) => {
      state.menuModifierGroup = pathOr(null, ["menuModifierGroup"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierGroupAPI.rejected, (state, action) => {
      state.menuModifierGroup = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createMenuModifierGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuModifierGroup = pathOr(null, ["menuModifierGroup"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(createMenuModifierGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuModifierGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuModifierGroup = pathOr(null, ["menuModifierGroup"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updateMenuModifierGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuModifierGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuModifierGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveMenuModifierGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuModifierGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuModifierGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setMenuModifierGroup,
  updateMenuModifierGroup,
  setCurrentPage,
  setTotalPerPage
} = menuModifierGroupsSlice.actions;

export default menuModifierGroupsSlice.reducer;
