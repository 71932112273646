import { ReactElement } from "react";
import withPermissions from "./hoc"

const ProtectedWrapper = ({ children }: { children: ReactElement }) => {
  return (
    <>
      {children}
    </>
  )
}

export default withPermissions(ProtectedWrapper);