import Joi from "joi";
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  firstname: Joi.string().trim().optional().label('Firstname'),
  lastname: Joi.string().trim().optional().label('Lastname'),
  phoneNumber: Joi.string().trim().optional().label('Phone number'),
  alias: Joi.string().trim().optional().label('Alias'),
  password: Joi.string().trim().optional().label('Password'),
  passcode: Joi.number().positive().min(1000).max(9999).optional().label('Passcode'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
