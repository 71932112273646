import { Button, InputNumber } from "antd";
import { createRegisterSessionAPI, fetchRegisterSessionsAPI } from "api/registers/register-sessions";
import ParentModal, { modalProps } from "components/modal/Modal";
import { pathOr } from "ramda";
import { FC, useEffect, useState } from "react";
import { RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setSuccessMessage } from "store/registers";

interface RegisterSessionFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const RegisterSessionForm: FC<RegisterSessionFormProps> = ({ isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const [openingBalance, setOpeningBalance] = useState(0);
  const registerSessionsLoading = useAppSelector(
    (state: RootState) => state.registers.registerSessionsLoading);
  const register = useAppSelector((state: RootState) => state.registers.register);
  const successMessage = useAppSelector((state: RootState) => state.registers.successMessage);

  useEffect(() => {
    if (successMessage) {
      dispatch(fetchRegisterSessionsAPI({ registerId: pathOr('', ['id'], register) }))
      handleClose();
      dispatch(setSuccessMessage(null));
    }
  }, [dispatch, handleClose, register, successMessage])

  const onClose = () => {
    setOpeningBalance(0);
    handleClose();
  }

  const onFinish = async () => {
    await dispatch(createRegisterSessionAPI({ 
      session: { openingBalance },
      registerId: pathOr('', ['id'], register)
    }))
  };
  return (
    <ParentModal isOpen={isOpen} handleClose={handleClose}>
      <div className="text-left text-gray-750">
        <h3 className="text-2xl font-medium">
          Opening {pathOr('', ['name'], register)}
        </h3>
        <div>
          <div className="text-left w-full pt-3">
            <span>Opening Balance<span className="text-red-500">*</span></span>
            <InputNumber
              name="quantityToMakeTrackableUnit"
              value={openingBalance}
              required
              className="w-full"
              onChange={(val) => setOpeningBalance(Number(val))}
            />
          </div>
          <div className="mt-5 space-x-3 flex">
            <Button
              className="bg-blue-750"
              type="primary"
              onClick={onFinish}
              disabled={registerSessionsLoading}
            >
              Open Register
            </Button>
            <Button type="default" onClick={onClose}>Cancel</Button>
          </div>
        </div>
       </div>
    </ParentModal>
  );
}

export default RegisterSessionForm;