import React, { useState, useEffect } from "react";
import { Input, Form } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

interface FormInputProps {
  label: string;
  name: string;
  required?: boolean;
  message?: string;
  className?: string;
  placeholder?: string;
  isPassword?: boolean;
  disabled?: boolean;
  isTextarea?: boolean; // New prop to determine if it's a textarea
  value?: string;
  type?: string;
  onChange?: (value: string) => void;
  error?: string;
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  type,
  name,
  required = false,
  disabled = false,
  message = "This field is required",
  isPassword = false,
  isTextarea = false,
  value,
  onChange,
  error,
   className,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (error) {
      // Handle errors, e.g., display error messages
    }
  }, [error]);

  const renderIcon = (visible: boolean) => {
    return visible ? (
      <EyeOutlined onClick={() => setPasswordVisible(!passwordVisible)} />
    ) : (
      <EyeInvisibleOutlined onClick={() => setPasswordVisible(!passwordVisible)} />
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    onChange(inputValue);
  };

  return (
    <Form.Item name={name} className="w-full mb-2">
      <div className="text-[15px]" style={{ paddingBottom: "2px" }}>
        {label} {required && <span className="text-red-500">*</span>}
      </div>
      {isPassword ? (
        <Input.Password
          className="h-10"
          placeholder={placeholder}
          iconRender={(visible) => renderIcon(visible)}
          value={value}
          required={required}
          disabled={disabled}
          onChange={handleInputChange}
        />
      ) : isTextarea ? (
        <Input.TextArea
          className="h-10"
          placeholder={placeholder}
          autoSize={{ minRows: 3, maxRows: 6 }} // Adjust rows as needed
          value={value}
          required={required}
          disabled={disabled}
          onChange={handleInputChange as (e: React.ChangeEvent<HTMLTextAreaElement>) => void}
        />
      ) : (
        <Input
          required={required}
          type={type}
          className="h-10"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleInputChange}
        />
      )}
    </Form.Item>
  );
};
