// Filters.tsx

import React from 'react';
import {Select, Button} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {useAppSelector} from "../../../../store/hooks";
import {pathOr} from "ramda";

interface FiltersProps {
  onFilterChange: (filters: Record<string, string | number>) => void;
  visible: boolean;
}

const MenuFilters: React.FC<FiltersProps> = ({ onFilterChange, visible }) => {
  const currentRestaurant = useAppSelector((state: any) => state.auth.currentRestaurant);
  const handleChange = (value: string[]) => {};

  if (!visible) {
    return null; // Don't render anything if not visible
  }
  return (
    <div className="flex space-x-4 mb-6">
      <Select
         allowClear
         mode="tags"
        style={{ width: 250 }}
        className="h-10"
        onChange={handleChange}
         options={pathOr([], ['locations'], currentRestaurant).map((location) => {
           return {
             label: pathOr('', ['name'], location),
             value: pathOr('', ['name'], location),
           }
         })}
      />
      <Button className="bg-blue-750 h-10" type="primary" icon={<SearchOutlined />}>
        Search
      </Button>
    </div>
  );
};

export default MenuFilters;
