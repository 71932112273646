import React, {useState} from "react";
import ParentModal from "components/modal/Modal";
import {Button, InputNumber, Select, Space} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {head, pathOr} from "ramda";
import {addModifierItem} from "../../../../store/orders/orders";

const ModifierItemModal = ({
 isOpen,
 onClose,
 }: { isOpen: boolean; onClose: () => void; }) => {
  const dispatch = useAppDispatch();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [quantity, setQuantity] = useState<any>(1);
  const menuModifierItems = useAppSelector((state: any) => state.menuModifierItems.menuModifierItems);
  const currencySymbol = useAppSelector((state: any) =>
     pathOr('', ['currentLocation', 'currencySymbol'], state.auth));

  const handleSelect = (val: any) => {
    const item = head(menuModifierItems.filter((modifierItem: any) =>
       pathOr(null, ['id'], modifierItem) === val));
    setSelectedItem(item)
  }

  const confirm = () => {
    dispatch(addModifierItem({
      quantity: Number(quantity),
      menuModifierItem: selectedItem,
    }))
    onClose();
  }
  return (
     <ParentModal isOpen={isOpen} handleClose={onClose}>
       <div className="w-full">
         <div>
           <h3 className="text-2xl font-medium">Add Modifier Item</h3>
         </div>
         <div className="mt-5 flex flex-col space-y-2">
           <div className="w-full">
             <span>Modifier Item</span>
             <Select
                showSearch
                placeholder="Search Modifier Items to Select"
                onSelect={handleSelect}
                options={menuModifierItems.map((modifierItem: any) => {
                  return {
                    label: `${pathOr('', ['name'], modifierItem)} (
                   ${pathOr('', ['menuModifierGroup', 'name'], modifierItem)})`,
                    value: pathOr('', ['id'], modifierItem),
                  }
                })}
                filterOption={(input, option) => (
                   pathOr('', ['label'], option) ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                   (pathOr('', ['label'], optionA) ?? '').toLowerCase().localeCompare(
                      (pathOr('', ['label'], optionB) ?? '').toLowerCase())
                }
                className="w-full"
             />
           </div>

           <div className="w-full">
             <span>Quantity</span>
             <InputNumber value={quantity} onChange={(val) => setQuantity(val)} className="w-full" />
           </div>
         </div>

         <div className="mt-5 space-x-3 flex justify-between">
           <div>
             <Button>
               Price: {currencySymbol} {(Number(quantity) *
                pathOr(0, ['price'], selectedItem)).toLocaleString()}
             </Button>
           </div>
           <div>
             <Space>
               <Button onClick={onClose}>Cancel</Button>
               <Button onClick={confirm} type="primary" className="bg-blue-750" >Add Modifier Item</Button>
             </Space>
           </div>
         </div>
       </div>
     </ParentModal>
  )
}

export default ModifierItemModal
