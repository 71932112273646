import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchRestaurantsByBusinessAPI,
  updateRestaurantAPI,
  deleteRestaurantAPI,
  archiveRestaurantAPI,
  fetchRestaurantAPI, createRestaurantAdminAPI
} from "../../api/restaurants";
import { message } from "antd";
import { pathOr } from "ramda";
import {SETTINGS_ROUTE} from "../../constants";

interface RestaurantProps {
  restaurants: any[];
  isLoading: boolean;
  isSubmitting: boolean;
  restaurant: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: RestaurantProps = {
  restaurants: [],
  isLoading: false,
  isSubmitting: false,
  restaurant: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    setRestaurantData: (state, action: PayloadAction<any>) => {
      state.restaurants = action.payload;
      state.isLoading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setRestaurant: (state, action) => {
      state.restaurant = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRestaurantsByBusinessAPI.fulfilled, (state, action) => {
      state.restaurants = pathOr([], ['restaurants'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchRestaurantsByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRestaurantsByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRestaurantAdminAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.restaurant = pathOr([], ['restaurant'], action.payload);
      state.isLoading = false;
      window.location.href = SETTINGS_ROUTE;
    });
    builder.addCase(createRestaurantAdminAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createRestaurantAdminAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateRestaurantAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.restaurant = pathOr(null, ['restaurant'], action.payload);
      state.isLoading = false;
      window.location.href = SETTINGS_ROUTE;
    });
    builder.addCase(updateRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteRestaurantAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.isLoading = false;
    });
    builder.addCase(deleteRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveRestaurantAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.isLoading = false;
    });
    builder.addCase(archiveRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRestaurantAPI.fulfilled, (state, action) => {
      state.restaurant = pathOr(null, ['restaurant'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  }
});

export const {
  setRestaurantData,
  setLoading,
  setSubmitting,
   setRestaurant,
   setCurrentPage,
   setTotalPerPage
} =
  restaurantSlice.actions;
export default restaurantSlice.reducer;

