import {Button, Space, Table, TableColumnsType} from "antd";
import {Link} from "react-router-dom";
import {EditOutlined, EyeOutlined, FolderOutlined, PlusOutlined} from "@ant-design/icons";
import {pathOr, replace} from "ramda";
import {LOCATIONS_ROUTE, RESTAURANTS_ROUTE} from "../../../../../constants";

interface DataItem {
  id: string;
  name: string;
  type: string;
  website: string;
}

interface LocationDataItem {
  id: string;
  name: string;
  city: string;
  town: string;
  country: string;
  posSetupCode: string;
}

export const fetchRestaurantTableColumns = ({ handleModalOpen }: any) => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) => pathOr('',['name'],a).localeCompare(pathOr('',['name'],b)),
      render: (text: any, record: DataItem) => (
         <Space>
           <Link
              to={`${RESTAURANTS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
              onClick={(e) => e.stopPropagation()}
              className="font-medium text-[15px]">
             {pathOr('',['name'],record)}
           </Link>
         </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: DataItem) => (
         <p className="font-medium text-[15px] text-gray-750">
           {replace(/_/g, ' ', pathOr('', ['type'], record))}
         </p>
      ),
    },
    {
      title: "Order Inventory Tracking Stage",
      dataIndex: "orderInventoryTrackingStage",
      key: "orderInventoryTrackingStage",
      render: (text: any, record: DataItem) => (
         <p className="uppercase font-medium text-[15px] text-gray-750">
           {pathOr('', ['orderStageToTrackInventory'], record)}
         </p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: DataItem) => (
         <Space size="middle">
            <Button
              icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
              type="link"
              href={`${RESTAURANTS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
            />
           <Button
              icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
              type="link"
              href={`${RESTAURANTS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
           />
           <Button
              icon={<FolderOutlined style={{ fontSize: 20 }} />}
              onClick={() => handleModalOpen(record, 'archive')}
           >
            {pathOr(false, ['isArchived'], record) ? 'Un Archive' : 'Archive'}
           </Button>
           <Button
              icon={<PlusOutlined />}
              href={`${RESTAURANTS_ROUTE}/${pathOr('', ['id'], record)}/locations/create`}
              type="primary"
           >
             Add New Location
           </Button>
         </Space>
      ),
      width: "10%",
    },
  ]
}

export const restaurantExpandedRowsRender = (record: any, { openLocationsModal }: any) => {
  const locationTableColumns: TableColumnsType<LocationDataItem>  = [
    {
      title: "Location",
      dataIndex: "name",
      key: "name",
      sorter: (a: LocationDataItem, b: LocationDataItem) => pathOr('',['name'],a).localeCompare(pathOr('',['name'],b)),
      render: (text: any, record: LocationDataItem) => (
         <Space>
           <Link
              to={`${LOCATIONS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
              onClick={(e) => e.stopPropagation()}
              className="font-medium text-[15px]">
             {pathOr('',['name'],record)}
           </Link>
         </Space>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: (a: LocationDataItem, b: LocationDataItem) => pathOr('',['address'],a).localeCompare(pathOr('',['address'],b)),
      render: (text: any, record: LocationDataItem) => (
         <p className="font-medium text-[15px] text-gray-750">{pathOr('',['address'],record)}</p>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: (a: LocationDataItem, b: LocationDataItem) => pathOr('',['country'],a).localeCompare(
        pathOr('',['country'],b)),
      render: (text: any, record: LocationDataItem) => (
         <p className="font-medium text-[15px] text-gray-750">{pathOr('',['country'],record)}</p>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currencyName",
      key: "currencyName",
      render: (text: any, record: LocationDataItem) => (
         <p className="font-medium text-[15px] text-gray-750">{pathOr('',['currencyName'],record)}</p>
      ),
    },
    {
      title: "Setup Code",
      dataIndex: "posSetupCode",
      key: "posSetupCode",
      render: (text: any, record: LocationDataItem) => (
         <p className="font-medium text-[15px] text-gray-750">
           {pathOr("", ["pointOfSale", "setupCode"], record)}
         </p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: LocationDataItem) => (
         <Space size="middle">
            <Button
              icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
              type="link"
              href={`${LOCATIONS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
            />
           <Button
              icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
              type="link"
              href={`${LOCATIONS_ROUTE}/${pathOr('', ['id'], record)}/edit`}
           />
            <Button
              icon={<FolderOutlined style={{ fontSize: 20 }} />}
              onClick={() => openLocationsModal(record, pathOr(false, ['isArchived'], record) ?
                 'un-archive-location' : 'archive-location')}
           >
            {pathOr(false, ['isArchived'], record) ? 'Un Archive' : 'Archive'}
           </Button>
         </Space>
      ),
      width: "10%",
    },
  ];

  return (
     <Table
        columns={locationTableColumns}
        dataSource={pathOr([], ['locations'], record)}
        pagination={false}
     />
  );
}
