import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnType } from "antd/lib/table";
import SuppliersFilter from "./components/filters";
import { SUPPLIERS_ROUTE } from "../../../constants";
import DeleteModal from "components/modal/DeleteModal";
import {
  archiveSupplierAPI,
  deleteSupplierAPI,
  fetchSuppliersByLocationAPI,
} from "../../../api/suppliers";
import { pathOr } from "ramda";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "store/suppliers/suppliers";
import withPermissions from "components/hoc";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_CREATE_SUPPLIER, CAN_DELETE_SUPPLIER, CAN_UPDATE_SUPPLIER } from "permissions";
interface DataItem {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const Suppliers: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState("");
  const suppliers = useAppSelector((state: any) => state.suppliers.suppliers);
  const isLoading = useAppSelector((state: any) => state.suppliers.isLoading);
  const currentPage = useAppSelector((state: RootState) => state.suppliers.currentPage);
  const total = useAppSelector((state: RootState) => state.suppliers.total);
  const totalPerPage = useAppSelector((state: RootState) => state.suppliers.totalPerPage);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [supplier, setSupplier] = useState<any>(null);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  const columns: TableColumn[] = [
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Link to={`${SUPPLIERS_ROUTE}/${record.id}/edit`}>
          <p className="font-medium text-[15px]">{pathOr("", ["name"], record)}</p>
        </Link>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["phoneNumber"], a).localeCompare(pathOr("", ["phoneNumber"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">
          {pathOr("", ["phoneNumber"], record)}
        </p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["email"], a).localeCompare(pathOr("", ["email"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">{pathOr("", ["email"], record)}</p>
      ),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["address"], a).localeCompare(pathOr("", ["address"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">{pathOr("", ["address"], record)}</p>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => {
        return (
          <Space size="middle">
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_SUPPLIER]}>
              <Button
                icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
                type="link"
                onClick={() => navigate(`${SUPPLIERS_ROUTE}/${record.id}/edit`)}
              />
            </ProtectedWrapper>

            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_DELETE_SUPPLIER]}>
              <Button
                icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
                type="link"
                onClick={() => openModal(record, "delete")}
              />
            </ProtectedWrapper>
          </Space>
        );
      },
      width: "10%",
    },
  ];

  useEffect(() => {
    dispatch(fetchSuppliersByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [dispatch]);

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };

  const openModal = (supplier: any, type: "create" | "edit" | "delete" | "archive") => {
    setSupplier(supplier);
    setModalState({ isOpen: true, type });
  };

  const archiveSupplier = async () => {
    await dispatch(archiveSupplierAPI({ supplierId: pathOr("", ["id"], supplier) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchSuppliersByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const deleteSupplier = async () => {
    await dispatch(deleteSupplierAPI({ supplierId: pathOr("", ["id"], supplier) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchSuppliersByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSearch = (value: string, dataIndex: keyof DataItem) => {
    // Implement the search logic here or pass it down to DynamicTable as needed
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const onRefresh = async () => {
    await dispatch(
      fetchSuppliersByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchSuppliersByLocationAPI({ page: current, perPage: size }));
  };

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Supplier List</p>
          <p className="text-base text-gray-750">Manage your Supplier</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_SUPPLIER]}>
            <Button
              onClick={() => navigate(`${SUPPLIERS_ROUTE}/create`)}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Add Supplier
            </Button>
          </ProtectedWrapper>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <SuppliersFilter onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={suppliers}
          loading={isLoading}
          rowSelection={rowSelection}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteSupplier}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveSupplier}
          prompt="Are you sure you want to archive supplier"
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default withPermissions(Suppliers);
