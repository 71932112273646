import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../helpers/utilities";
import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { pathOr } from "ramda";

export const fetchMenuModifierItemsByRestaurantAPI = createAsyncThunk(
  "menu-modifier-items/fetch-menu-modifier-items-by-restaurant",
  async (_, thunkAPI) => {
    try {
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/menu-modifier-items`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuModifierItemsByLocationAPI = createAsyncThunk(
  "menu-modifier-items/fetch-menu-modifier-items-by-location",
  async (_, thunkAPI) => {
    try {
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/menu-modifier-items`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuModifierItemsByMenuModifierGroupAPI = createAsyncThunk(
  "menu-modifier-items/fetch-menu-modifier-items-by-menu-modifier-group",
  async (
    payload: { menuModifierGroupId: string; refresh?: boolean; page?: number; perPage?: number },
    thunkAPI,
  ) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(
        `/menu-modifier-groups/${payload.menuModifierGroupId}/menu-modifier-items?` +
          params.toString(),
        {
          method: "GET",
          headers: getAuthorizationHeader(),
        },
      );
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createMenuModifierItemAPI = createAsyncThunk(
  "menu-modifier-items/create-menu-modifier-item",
  async (payload: { menuModifierItem: any; menuModifierGroupId: string }, thunkAPI) => {
    try {
      const response = await client(
        `/menu-modifier-groups/${payload.menuModifierGroupId}/menu-modifier-items`,
        {
          method: "POST",
          headers: getAuthorizationHeader(),
          data: payload.menuModifierItem,
        },
      );
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateMenuModifierItemAPI = createAsyncThunk(
  "menu-modifier-items/update-menu-modifier-item",
  async (payload: { menuModifierItem: any; menuModifierItemId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-items/${payload.menuModifierItemId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.menuModifierItem,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuModifierItemAPI = createAsyncThunk(
  "menu-modifier-items/fetch-menu-modifier-item",
  async (payload: { menuModifierItemId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-items/${payload.menuModifierItemId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteMenuModifierItemAPI = createAsyncThunk(
  "menu-modifier-items/delete-menu-modifier-item",
  async (payload: { menuModifierItemId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-items/${payload.menuModifierItemId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const archiveMenuModifierItemAPI = createAsyncThunk(
  "menu-modifier-items/archive-menu-modifier-item",
  async (payload: { menuModifierItemId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-items/${payload.menuModifierItemId}/archive`, {
        method: "PATCH",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createMenuModifierItemIngredientAPI = createAsyncThunk(
  "menu-modifier-items/create-menu-modifier-item-ingredient",
  async (payload: { ingredient: any; menuModifierItemId: string }, thunkAPI) => {
    try {
      const response = await client(
        `/menu-modifier-items/${payload.menuModifierItemId}/ingredients`,
        {
          method: "POST",
          headers: getAuthorizationHeader(),
          data: payload.ingredient,
        },
      );
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateMenuModifierItemIngredientAPI = createAsyncThunk(
  "menu-modifier-item-ingredients/update-menu-modifier-item-ingredient",
  async (payload: { ingredientId: string; ingredient: any }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-item-ingredients/${payload.ingredientId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.ingredient,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuModifierItemIngredientAPI = createAsyncThunk(
  "menu-modifier-item-ingredients/fetch-menu-modifier-item-ingredient",
  async (payload: { ingredientId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-item-ingredients/${payload.ingredientId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteMenuModifierItemIngredientAPI = createAsyncThunk(
  "menu-modifier-item-ingredients/delete-menu-modifier-item-ingredient",
  async (payload: { ingredientId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-modifier-item-ingredients/${payload.ingredientId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
