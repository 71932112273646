import { createAsyncThunk } from "@reduxjs/toolkit";
import { client, getAuthorizationHeader } from "api/apiUtils";
import { pathOr, groupBy as group, length } from "ramda";
import moment from "moment";
import { getCookie } from "helpers/utilities";

export const fetchWeeklyPurchasesAndExpensesReportGraphAPI = createAsyncThunk(
    'purchases-report/location',
    async (
     payload: { 
       refresh?: boolean;
       fromDate?: string;
       toDate?: string;
       groupBy?: string;
     }, thunkAPI
    ) => {
      try {
        const params = new URLSearchParams();
        const fromDate = payload.fromDate;
        const toDate = payload.toDate;
        const groupBy = payload.groupBy;
        const refresh = payload.refresh;

        if (payload.refresh) {
          params.append("refresh", String(refresh));
        }
  
        if (fromDate && toDate) {
          params.append("fromDate", fromDate);
          params.append('toDate', toDate);
        }
  
        if (groupBy) {
          params.append('groupBy', groupBy);
        }

        params.append("id", getCookie('currentLocation'));
        params.append("type", "location");

        const purchasesReportResponse = await client(`/purchases-report?` + params.toString(), {
          method: 'GET',
          headers: getAuthorizationHeader(),
        });
        
        const purchasesReport = pathOr([], ['purchasesReport'], purchasesReportResponse.data);
       
        
        const expensesReportResponse = await client(`/expenses-report?` + params.toString(), {
          method: 'GET',
          headers: getAuthorizationHeader(),
        });
        
        const expensesReport = pathOr([], ['expensesReport'], expensesReportResponse.data);

        const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const groupByDay = group((item) => {
          return moment(pathOr('', ['date'], item)).format('dddd');
        });

        const purchasesGroup = groupByDay(purchasesReport);
        const expensesGroup = groupByDay(expensesReport);

        const chartSeries = [
          {
            name: 'Purchases',
            data: week.map((weekDay) => {
              if (length(purchasesGroup[weekDay]) > 0) {
                return pathOr(0, [weekDay, 0, 'amountPaid'], purchasesGroup) / 1000;
              }
              return 0;
            }),
            color: "#6AD2Fa",
          },
          {
            name: 'Expenses',
            data: week.map((weekDay) => {
              if (length(expensesGroup[weekDay]) > 0) {
                return pathOr(0, [weekDay, 0, 'amountPaid'], expensesGroup) / 1000;
              }
              return 0;
            }),
            color: "#4318FF",
          }
        ]

        return {
          chartCategories: week,
          chartSeries,
        }
      } catch (error) {
        throw new Error(`failed to fetch week expenses and purchases graph data`);
      }
    }
 );