import { createSlice } from '@reduxjs/toolkit';
import {pathOr} from 'ramda';
import {
  fetchInventoryOverTimeReportByLocationAPI,
  fetchInventoryReportByBusinessAPI,
  fetchInventoryReportByLocationAPI,
  fetchInventoryReportByRestaurantAPI
} from "../../api/reports/inventory";
import moment from 'moment';
import { getTimeframeDates } from 'helpers/utilities';

interface InventoryReportsState {
  inventoryReport: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  inventoryOverTimeReport: any[];
  inventoryOverTimeDate: any;
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: InventoryReportsState = {
  inventoryReport: [],
  inventoryOverTimeReport: [],
  status: 'idle',
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  inventoryOverTimeDate: null,
  filters: {
    fromDate: getTimeframeDates('this_month').fromDate,
    toDate: getTimeframeDates('this_month').toDate
  },
};

const inventoryReportsSlice = createSlice({
  name: 'inventory-reports',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryReportByLocationAPI.fulfilled, (state, action) => {
      state.inventoryReport = pathOr([], ['inventoryReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventoryOverTimeReportByLocationAPI.fulfilled, (state, action) => {
      state.inventoryReport = pathOr([], ['inventoryReport'], action.payload).flatMap((report) => {
        return {
          ...report,
          key: pathOr(moment().valueOf(), ['date'], report)
        }
      });;
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryOverTimeReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryOverTimeReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventoryReportByRestaurantAPI.fulfilled, (state, action) => {
      state.inventoryReport = pathOr([], ['inventoryReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventoryReportByBusinessAPI.fulfilled, (state, action) => {
      state.inventoryReport = pathOr([], ['inventoryReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = inventoryReportsSlice.actions;


export default inventoryReportsSlice.reducer;
