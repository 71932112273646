import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "antd";
import Sidebar from "layouts/sidebar";
import CreateSupplier from "views/admin/suppliers/createSupplier";
import HeaderNav from "components/navbar/HeaderNav";
import CreateExpense from "views/admin/expenses/create-expense/create-expense";
import EditSupplier from "views/admin/suppliers/editSupplier";
import EditProduct from "views/admin/inventory/edit-product";
import ModifierItems from "views/admin/menus/menu-modifier-items";
import CreateModifierItem from 'views/admin/menus/menu-modifier-items/create-modifier-item'
import CreateMenuItem from "views/admin/menus/menu-items/create-menu-item";
import MenuItems from "views/admin/menus/menu-items";
import EditMenuItem from "views/admin/menus/menu-items/edit-menu-item";
import EditModifierItem from "views/admin/menus/menu-modifier-items/edit-modifier-item";
import EditExpense from "views/admin/expenses/edit-expense/edit-expense";
import EditPurchase from "views/admin/purchases/edit-purchase/edit-purchase";
import CreatePurchase from "views/admin/purchases/create-purchase/create-purchase";
import CreatePermission from "views/admin/permission-sets/create-permission-set";
import CreateEmployee from "views/admin/employees/create-employee";
import EditEmployee from "views/admin/employees/edit-employee";
import CreateLocationItem from "views/admin/business/locations/create-location";
import EditLocationItem from "views/admin/business/locations/edit-location";
import OrderDetailView from "views/admin/orders/order-detail-view";
import OrderEditView from "views/admin/orders/order-edit-view";
import EmployeesReportDetail from "views/admin/reports/employees-report/employees-report-detail";
import CreateUpdateOrderView from "views/admin/orders/create-update-order-view";
import {
  CATEGORIES_ROUTE, DASHBOARD_ROUTE,
  EMPLOYEES_REPORT_ROUTE,
  EMPLOYEES_ROUTE, EXPENSES_REPORT_ROUTE,
  EXPENSES_ROUTE, FLOORS_ROUTE, INVENTORY_REPORT_ROUTE,
  INVENTORY_ROUTE,
  LOCATIONS_ROUTE,
  MENU_GROUPS_ROUTE, MENU_ITEMS_REPORT_ROUTE,
  MENU_ITEMS_ROUTE,
  MENU_MODIFIER_GROUPS_ROUTE,
  MENU_MODIFIER_ITEMS_ROUTE,
  MENUS_ROUTE, MODIFIER_ITEMS_REPORT_ROUTE, ORDERS_REPORT_ROUTE,
  ORDERS_ROUTE,
  PERMISSIONS_ROUTE, PURCHASES_REPORT_ROUTE,
  PURCHASES_ROUTE,
  REPORTS_ROUTE, PERMISSION_SETS_ROUTE, SALES_REPORT_ROUTE,
  SETTINGS_ROUTE,
  SUPPLIERS_ROUTE, RESTAURANTS_ROUTE,
  INVENTORY_OVERTIME_REPORT_ROUTE,
  MENU_ITEMS_OVERTIME_REPORT_ROUTE,
  MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT_ROUTE,
  MODIFIER_ITEMS_OVERTIME_REPORT_ROUTE,
  REGISTERS_ROUTE,
  CUSTOMERS_ROUTE,
  BUSINESS_ROUTE
} from "../../constants";
import Menus from "views/admin/menus";
import Dashboard from "views/admin/dashboard";
import MenuGroups from "views/admin/menus/menu-groups";
import Orders from "views/admin/orders";
import Floors from "views/admin/floors";
import OrdersReport from "views/admin/reports/orders-report";
import EmployeesReport from "views/admin/reports/employees-report";
import InventoryReport from "views/admin/reports/inventory-report";
import SalesReport from "views/admin/reports/sales-report";
import ModifierItemsReport from "views/admin/reports/modifier-items-report";
import MenuItemsReport from "views/admin/reports/menu-items-report";
import PurchasesReport from "views/admin/reports/purchases-report";
import ExpensesReport from "views/admin/reports/expenses-report";
import Settings from "views/admin/settings";
import PermissionSets from "views/admin/permission-sets";
import Employees from "views/admin/employees";
import Purchases from "views/admin/purchases";
import Expenses from "views/admin/expenses";
import Categories from "views/admin/inventory/categories/categories";
import Suppliers from "views/admin/suppliers";
import CreateProduct from "views/admin/inventory/create-product";
import Inventory from "views/admin/inventory";
import ModifierGroups from "views/admin/menus/menu-modifier-groups";
import EditPermissionSet from "views/admin/permission-sets/edit-permission-set";
import CreatePermissionSet from "views/admin/permission-sets/create-permission-set";
import CreateRestaurant from "views/admin/business/restaurants/create-restaurant";
import EditRestaurant from "views/admin/business/restaurants/edit-restaurant";
import CreateLocation from "views/admin/business/locations/create-location";
import EditLocation from "views/admin/business/locations/edit-location";
import InventoryOverTimeReport from "views/admin/reports/inventory-report/inventory-overtime-report";
import MenuItemsOverTimeReport from "views/admin/reports/menu-items-report/menu-items-overtime-report";
import MenuItemsSoldByEmployeesReport from "views/admin/reports/employees-report/menu-items-sold-by-employees";
import MenuModifierItemsOverTimeReport from "views/admin/reports/modifier-items-report/modifier-items-overtime-report";
import Registers from "views/admin/business/registers";
import CreateRegister from "views/admin/business/registers/create-register";
import EditRegister from "views/admin/business/registers/edit-register";
import { 
  CAN_CREATE_CUSTOMER,
  CAN_CREATE_EMPLOYEE, 
  CAN_CREATE_EXPENSE,
  CAN_CREATE_INVENTORY,
  CAN_CREATE_LOCATION,
  CAN_CREATE_MENU,
  CAN_CREATE_ORDER,
  CAN_CREATE_PERMISSION_SET,
  CAN_CREATE_PURCHASE,
  CAN_CREATE_RESTAURANT,
  CAN_CREATE_SUPPLIER,
  CAN_UPDATE_CUSTOMER,
  CAN_UPDATE_EMPLOYEE,
  CAN_UPDATE_EXPENSE,
  CAN_UPDATE_INVENTORY,
  CAN_UPDATE_LOCATION,
  CAN_UPDATE_MENU,
  CAN_UPDATE_ORDER,
  CAN_UPDATE_PERMISSION_SET,
  CAN_UPDATE_PURCHASE,
  CAN_UPDATE_RESTAURANT,
  CAN_UPDATE_SUPPLIER,
  CAN_VIEW_BUSINESS,
  CAN_VIEW_CATEGORIES,
  CAN_VIEW_CUSTOMERS,
  CAN_VIEW_DASHBOARD,
  CAN_VIEW_EMPLOYEES,
  CAN_VIEW_EXPENSES,
  CAN_VIEW_EXPENSES_REPORT,
  CAN_VIEW_FLOORS_AND_TABLES,
  CAN_VIEW_INVENTORY,
  CAN_VIEW_INVENTORY_OVERTIME_REPORT,
  CAN_VIEW_INVENTORY_REPORT,
  CAN_VIEW_MENU_ITEMS_REPORT,
  CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT,
  CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT,
  CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT,
  CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT,
  CAN_VIEW_MENUS,
  CAN_VIEW_ORDERS,
  CAN_VIEW_ORDERS_REPORT,
  CAN_VIEW_PERMISSION_SETS,
  CAN_VIEW_PURCHASES,
  CAN_VIEW_PURCHASES_REPORT,
  CAN_VIEW_SALES_REPORT,
  CAN_VIEW_SUPPLIERS
 } from "permissions";
import withPermissions from "components/hoc";
import Customers from "views/admin/customers";
import CreateCustomer from "views/admin/customers/create-customer";
import EditCustomer from "views/admin/customers/edit-customer";
import Business from "views/admin/business";
const { Header, Content, Sider } = Layout;

const Admin: React.FC = () => {
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  const [sideBarOpen, setSideBarOpen] = useState(true);

  const handleToggleSideBar = () => {
    setSideBarOpen((prev) => !prev);
  };
  const handleCloseSideBar = () => {
    if (screenSize >= 992) return;
    setSideBarOpen(false);
  };

  useEffect(() => {
    if (screenSize < 992) setSideBarOpen(false);

    const handleResize = () => {
      setScreenSize(window.innerWidth);
      if (screenSize > 992) setSideBarOpen(true);
      if (screenSize < 992) setSideBarOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ background: "white", padding: 0, borderBottom: "1px solid #edf2f7" }}>
        <HeaderNav toggleSideBar={handleToggleSideBar} />
      </Header>
      <Layout>
        <Sider
          className="sidebar"
          width={sideBarOpen ? 260 : 0}
          style={{ background: "white", height: "calc(100vh - 64px)" }}>
          <Sidebar toggleSideBar={handleCloseSideBar} />
        </Sider>
        <Content style={{ padding: "24px", height: "calc(100vh - 64px)" }}>
          <div className="h-full overflow-y-scroll no-scroll">
            <Routes>
              <Route path={`${DASHBOARD_ROUTE.replace('/admin', '')}`} element={<Dashboard 
                permissionsNeeded={[CAN_VIEW_DASHBOARD]} />} 
              />
              <Route path={`${FLOORS_ROUTE.replace('/admin', '')}`} element={<Floors 
              permissionsNeeded={[CAN_VIEW_FLOORS_AND_TABLES]} />} />
              <Route
                 path={`${RESTAURANTS_ROUTE.replace('/admin', '')}/create`}
                 element={<CreateRestaurant permissionsNeeded={[CAN_CREATE_RESTAURANT]} />}
              />
              <Route
                 path={`${RESTAURANTS_ROUTE.replace('/admin', '')}/:id/edit`}
                 element={<EditRestaurant permissionsNeeded={[CAN_UPDATE_RESTAURANT]} />}
              />
              <Route
                 path={`${RESTAURANTS_ROUTE.replace('/admin', '')}/:id/locations/create`}
                 element={<CreateLocation permissionsNeeded={[CAN_CREATE_LOCATION]} />}
              />
              <Route
                 path={`${LOCATIONS_ROUTE.replace('/admin', '')}/:id/edit`}
                 element={<EditLocation permissionsNeeded={[CAN_UPDATE_LOCATION]}  />}
              />
              <Route path={`${BUSINESS_ROUTE.replace('/admin', '')}`} element={<Business permissionsNeeded={[CAN_VIEW_BUSINESS]} />} />
              <Route path={`${CUSTOMERS_ROUTE.replace('/admin', '')}`} element={<Customers permissionsNeeded={[CAN_VIEW_CUSTOMERS]} />} />
              <Route path={`${CATEGORIES_ROUTE.replace('/admin', '')}`} element={<Categories permissionsNeeded={[CAN_VIEW_CATEGORIES]} />} />
              <Route path={`${SUPPLIERS_ROUTE.replace('/admin', '')}`} element={<Suppliers permissionsNeeded={[CAN_VIEW_SUPPLIERS]} />} />
              <Route path={`${EXPENSES_ROUTE.replace('/admin', '')}`} element={<Expenses permissionsNeeded={[CAN_VIEW_EXPENSES]} />} />
              <Route path={`${PURCHASES_ROUTE.replace('/admin', '')}`} element={<Purchases permissionsNeeded={[CAN_VIEW_PURCHASES]} />} />
              <Route path={`${EMPLOYEES_ROUTE.replace('/admin', '')}`} element={<Employees permissionsNeeded={[CAN_VIEW_EMPLOYEES]} />} />
              <Route path={`${PERMISSION_SETS_ROUTE.replace('/admin', '')}`} element={<PermissionSets permissionsNeeded={[CAN_VIEW_PERMISSION_SETS]} />} />
              <Route
                 path={`${PERMISSION_SETS_ROUTE.replace('/admin', '')}/:id/edit`}
                 element={<EditPermissionSet permissionsNeeded={[CAN_UPDATE_PERMISSION_SET]} />} />
              <Route
                 path={`${PERMISSION_SETS_ROUTE.replace('/admin', '')}/create`}
                 element={<CreatePermissionSet permissionsNeeded={[CAN_CREATE_PERMISSION_SET]} />} />
              <Route path={`${SETTINGS_ROUTE.replace('/admin', '')}`} element={<Settings />} />
              <Route path={`${REGISTERS_ROUTE.replace('/admin', '')}`} element={<Registers />} />
              <Route path={`${REGISTERS_ROUTE.replace('/admin', '')}/create`} element={<CreateRegister />} />
              <Route path={`${REGISTERS_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditRegister />} />
              <Route path={`${EXPENSES_REPORT_ROUTE.replace('/admin', '')}`} element={<ExpensesReport permissionsNeeded={[CAN_VIEW_EXPENSES_REPORT]} />} />
              <Route path={`${PURCHASES_REPORT_ROUTE.replace('/admin', '')}`} element={<PurchasesReport permissionsNeeded={[CAN_VIEW_PURCHASES_REPORT]} />} />
              <Route path={`${MENU_ITEMS_REPORT_ROUTE.replace('/admin', '')}`} element={<MenuItemsReport permissionsNeeded={[CAN_VIEW_MENU_ITEMS_REPORT]} />} />
              <Route path={`${MENU_ITEMS_OVERTIME_REPORT_ROUTE.replace('/admin', '')}`} element={<MenuItemsOverTimeReport permissionsNeeded={[CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT]} />} />
              <Route path={`${MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT_ROUTE.replace('/admin', '')}`} element={<MenuItemsSoldByEmployeesReport 
              permissionsNeeded={[CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT]} />} />
              <Route path={`${MODIFIER_ITEMS_REPORT_ROUTE.replace('/admin', '')}`} element={<ModifierItemsReport permissionsNeeded={[CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT]} />} />
              <Route path={`${MODIFIER_ITEMS_OVERTIME_REPORT_ROUTE.replace('/admin', '')}`} element={<MenuModifierItemsOverTimeReport 
              permissionsNeeded={[CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT]} />} />
              <Route path={`${SALES_REPORT_ROUTE.replace('/admin', '')}`} element={<SalesReport permissionsNeeded={[CAN_VIEW_SALES_REPORT]} />} />
              <Route path={`${INVENTORY_REPORT_ROUTE.replace('/admin', '')}`} element={<InventoryReport permissionsNeeded={[CAN_VIEW_INVENTORY_REPORT]} />} />
              <Route path={`${INVENTORY_OVERTIME_REPORT_ROUTE.replace('/admin', '')}`} element={<InventoryOverTimeReport permissionsNeeded={[CAN_VIEW_INVENTORY_OVERTIME_REPORT]} />} />
              <Route path={`${EMPLOYEES_REPORT_ROUTE.replace('/admin', '')}`} element={<EmployeesReport />} />
              <Route path={`${ORDERS_REPORT_ROUTE.replace('/admin', '')}`} element={<OrdersReport permissionsNeeded={[CAN_VIEW_ORDERS_REPORT]} />} />
              <Route path={`${MENUS_ROUTE.replace('/admin', '')}`} element={<Menus permissionsNeeded={[CAN_VIEW_MENUS]} />} />
              <Route path={`${MENUS_ROUTE.replace('/admin', '')}/:id/menu-groups`} element={<MenuGroups permissionsNeeded={[CAN_VIEW_MENUS]} />} />
              <Route path={`${ORDERS_ROUTE.replace('/admin', '')}`} element={<Orders permissionsNeeded={[CAN_VIEW_ORDERS]} />} />
              <Route path={`${ORDERS_ROUTE.replace('/admin', '')}/create`} element={<CreateUpdateOrderView permissionsNeeded={[CAN_CREATE_ORDER]} />} />
              <Route path={`${ORDERS_ROUTE.replace('/admin', '')}/:id/view`} element={<OrderDetailView permissionsNeeded={[CAN_VIEW_ORDERS]} />} />
              <Route path={`${ORDERS_ROUTE.replace('/admin', '')}/:id/edit`} element={<OrderEditView permissionsNeeded={[CAN_UPDATE_ORDER]} />} />
              <Route path={`${MENU_MODIFIER_GROUPS_ROUTE.replace('/admin', '')}`} element={<ModifierGroups permissionsNeeded={[CAN_VIEW_MENUS]} />} />
              <Route
                 path={`${MENU_MODIFIER_GROUPS_ROUTE.replace('/admin', '')}/:id/menu-modifier-items/create`}
                 element={<CreateModifierItem permissionsNeeded={[CAN_CREATE_MENU]} />} />
              <Route
                 path={`${MENU_MODIFIER_GROUPS_ROUTE.replace('/admin', '')}/:id${MENU_MODIFIER_ITEMS_ROUTE.replace('/admin', '')}`}
                 element={<ModifierItems permissionsNeeded={[CAN_VIEW_MENUS]} />} />
              <Route path={`${MENU_MODIFIER_ITEMS_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditModifierItem 
              permissionsNeeded={[CAN_UPDATE_MENU]} />} />
              <Route path={`${MENU_GROUPS_ROUTE.replace('/admin', '')}/:id${MENU_ITEMS_ROUTE.replace('/admin', '')}`} element={<MenuItems 
              permissionsNeeded={[CAN_VIEW_MENUS]} />} />
              <Route path={`${MENU_GROUPS_ROUTE.replace('/admin', '')}/:id/menu-items/create`} element={<CreateMenuItem permissionsNeeded={[CAN_CREATE_MENU]} />} />
              <Route path={`${MENU_ITEMS_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditMenuItem permissionsNeeded={[CAN_UPDATE_MENU]} />} />
              <Route path={`${SETTINGS_ROUTE.replace('/admin', '')}/${LOCATIONS_ROUTE.replace('/admin', '')}/create/`} element={<CreateLocationItem 
              permissionsNeeded={[CAN_CREATE_LOCATION]} />} />
              <Route path={`${SETTINGS_ROUTE.replace('/admin', '')}/${LOCATIONS_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditLocationItem 
              permissionsNeeded={[CAN_UPDATE_LOCATION]} />} />
              <Route path={`${EXPENSES_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditExpense permissionsNeeded={[CAN_UPDATE_EXPENSE]} />} />
              <Route path={`${REPORTS_ROUTE.replace('/admin', '')}${EMPLOYEES_REPORT_ROUTE.replace('/admin/reports', '')}/:id/view`} element={<EmployeesReportDetail />} />
              <Route path={`${PURCHASES_ROUTE.replace('/admin', '')}/create`} element={<CreatePurchase permissionsNeeded={[CAN_CREATE_PURCHASE]} />} />
              <Route path={`${PURCHASES_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditPurchase permissionsNeeded={[CAN_UPDATE_PURCHASE]} />} />
              <Route path={`${SUPPLIERS_ROUTE.replace('/admin', '')}/create`} element={<CreateSupplier permissionsNeeded={[CAN_CREATE_SUPPLIER]} />} />
              <Route path={`${SUPPLIERS_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditSupplier permissionsNeeded={[CAN_UPDATE_SUPPLIER]} />} />
              <Route path={`${INVENTORY_ROUTE.replace('/admin', '')}`} element={<Inventory permissionsNeeded={[CAN_VIEW_INVENTORY]} />} />
              <Route path={`${INVENTORY_ROUTE.replace('/admin', '')}/create`} element={<CreateProduct permissionsNeeded={[CAN_CREATE_INVENTORY]} />} />
              <Route path={`${INVENTORY_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditProduct permissionsNeeded={[CAN_UPDATE_INVENTORY]} />} />
              <Route path={`${EXPENSES_ROUTE.replace('/admin', '')}/create`} element={<CreateExpense permissionsNeeded={[CAN_CREATE_EXPENSE]} />} />
              <Route path={`${PERMISSIONS_ROUTE.replace('/admin', '')}/create`} element={<CreatePermission permissionsNeeded={[CAN_CREATE_PERMISSION_SET]} />} />
              <Route path={`${EMPLOYEES_ROUTE.replace('/admin', '')}/create`} element={<CreateEmployee permissionsNeeded={[CAN_CREATE_EMPLOYEE]} />} />
              <Route path={`${EMPLOYEES_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditEmployee permissionsNeeded={[CAN_UPDATE_EMPLOYEE]} />} />
              <Route path={`${CUSTOMERS_ROUTE.replace('/admin', '')}/create`} element={<CreateCustomer permissionsNeeded={[CAN_CREATE_CUSTOMER]} />} />
              <Route path={`${CUSTOMERS_ROUTE.replace('/admin', '')}/:id/edit`} element={<EditCustomer permissionsNeeded={[CAN_UPDATE_CUSTOMER]} />} />
              <Route path={"/"} element={<Navigate to="/admin/dashboard" replace />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withPermissions(Admin);
