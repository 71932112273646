import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {fetchOrderByIdAPI} from "../../../api/orders";
import {useParams} from "react-router-dom";
import CreateUpdateOrderView from "views/admin/orders/create-update-order-view";
import {pathOr} from "ramda";
import {Flex, Spin} from "antd";
import withPermissions from "components/hoc";
import { CAN_CREATE_ORDER, CAN_UPDATE_ORDER } from "permissions";

const OrderEditView = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) =>
     pathOr(false, ['isLoading'], state.orders));

  useEffect(() => {
    dispatch(fetchOrderByIdAPI({orderId: id}));
  }, [dispatch, id])

  if (isLoading) {
    return (
       <Flex align="center" gap="middle">
         <Spin size="large" />
       </Flex>
    )
  }
  return (
     <CreateUpdateOrderView permissionsNeeded={[CAN_CREATE_ORDER, CAN_UPDATE_ORDER]} />
  )
}

export default withPermissions(OrderEditView);
