import {getCookie} from "../../helpers/utilities";
import {client, getAuthorizationHeader} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";


export const fetchExpensesReportByLocationAPI = createAsyncThunk(
   'expenses-report/location',
   async (
    payload: { 
      refresh?: boolean;
      page?: number;
      perPage?: number;
      fromDate?: string;
      toDate?: string;
      groupBy?: string;
    }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      const fromDate = payload.fromDate;
      const toDate = payload.toDate;
      const groupBy = payload.groupBy;
      const page = payload.page;
      const perPage = payload.perPage;
      
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }

      if (fromDate && toDate) {
        params.append("fromDate", fromDate);
        params.append('toDate', toDate);
      }

      if (groupBy) {
        params.append('groupBy', groupBy);
      }

      if (page && perPage) {
        params.append("page", String(pathOr(1, ["page"], payload)));
        params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      }
      
      params.append("id", getCookie('currentLocation'));
      params.append("type", "location");

      const response = await client(`/expenses-report?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
     } catch (error) {
       throw new Error(`failed to fetch expenses`);
     }
   }
);

export const fetchExpensesReportByRestaurantAPI = createAsyncThunk(
   'expenses-report/restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/expenses-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch expenses`);
     }
   }
)

export const fetchExpensesReportByBusinessAPI = createAsyncThunk(
   'expenses-report/business',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/expenses-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch expenses`);
     }
   }
)
