import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../helpers/utilities";
import { pathOr } from "ramda";

export const fetchEmployeesByLocationAPI = createAsyncThunk(
  "employees/fetch-by-location",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(50, ["perPage"], payload)));
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/employees?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch employees`);
    }
  },
);

export const fetchEmployeesByBusinessAPI = createAsyncThunk(
  "employees/fetch-by-business",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(`/business/employees?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch employees`);
    }
  },
);

export const createEmployeeAPI = createAsyncThunk(
  "employees/create-employee",
  async (payload: { employee: any }, thunkAPI) => {
    try {
      const response = await client(`/employees`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.employee,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateEmployeeAPI = createAsyncThunk(
  "employees/update-employee",
  async (payload: { employee: any; employeeId: string }, thunkAPI) => {
    try {
      const response = await client(`/employees/${payload.employeeId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.employee,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchEmployeeAPI = createAsyncThunk(
  "employees/fetch-employee",
  async (payload: { employeeId: string }, thunkAPI) => {
    try {
      const response = await client(`/employees/${payload.employeeId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const archiveEmployeeAPI = createAsyncThunk(
  "employees/archive-employee",
  async (payload: { employeeId: string }, thunkAPI) => {
    try {
      const response = await client(`/employees/${payload.employeeId}/archive`, {
        method: "PATCH",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
