import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Supplier name'),
  email: Joi.string().trim().email({ tlds: { allow: false } }).optional().label('Supplier email'),
  phoneNumber: Joi.string().trim().optional().label('Supplier phone number'),
  address: Joi.string().trim().optional().label('Supplier address'),
  city: Joi.string().trim().optional().label('Supplier city'),
  country: Joi.string().trim().optional().label('Supplier country'),
  postalCode: Joi.string().trim().optional().label('Supplier postal code'),
  state: Joi.string().trim().optional().label('Supplier state'),
  taxIdentificationNumber: Joi.string().trim().optional().label('State tax identification number'),
  deliveryMethod: Joi.string().optional().label('Supplier delivery method'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
