import { useState } from 'react';
import { Button, Input, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Footer from "components/footer/Footer";
import authImg2 from "assets/img/auth/auth2.jpg";
import { resetPasswordAPI } from 'api/auth';

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const isLoading = useAppSelector((state: any) => state.auth.isLoading);

  const onFinish = async () => {
    if (!password && !confirmPassword) {
      message.error('please provide password');
      return;
    }
    if (password !== confirmPassword) {
      message.error('passwords should match');
      return;
    }
    await dispatch(resetPasswordAPI({ password }));
  }
  return (
    <div>
     <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
                  <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
                      Reset Password
                    </h4>
                    <p className="text-base text-neutral-300">
                      Please set a new password
                    </p>
                    
                    <div className="mt-5 flex flex-col space-y-2">
                      <div>
                        <span>Password</span>
                        <Input
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div>
                        <span>Confirm Password</span>
                        <Input
                          type="password"
                          name="confirm-password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>

                      <div>
                        <Button
                          loading={isLoading}
                          onClick={onFinish}
                          type="primary"
                          className="bg-blue-750"
                          disabled={!password && !confirmPassword === true}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg2})` }}
                  />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default ResetPassword;