import { message, Spin } from 'antd';
import { verifyPasswordResetLinkAPI } from 'api/auth';
import { AUTH_SIGN_IN_ROUTE, RESET_PASSWORD_ROUTE } from '../../../constants';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { setIsSuccessFull } from 'store/auth/auth';

const VerifyPasswordLink = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isError = useAppSelector((state: RootState) => state.auth.isError);
  const isSuccessFull = useAppSelector((state: RootState) => state.auth.isSuccessFull);
  
  useEffect(() => {
    if (!token) {
      message.error('link verification failed');
      navigate(AUTH_SIGN_IN_ROUTE);
    }
    if (token) {
      dispatch(verifyPasswordResetLinkAPI({ token }))
    }
  }, [dispatch, navigate, token])
  useEffect(() => {
   if (isError) {
    navigate(AUTH_SIGN_IN_ROUTE);
   }
   if (isSuccessFull) {
    dispatch(setIsSuccessFull(false));
    navigate(RESET_PASSWORD_ROUTE)
   }
  }, [isSuccessFull, isError, navigate, dispatch])
  return (
    <div className="mt-10 flex justify-center items-center">
      <Spin tip="Verifying" size="large" />
    </div>
  )
}

export default VerifyPasswordLink;
