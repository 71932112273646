import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  archiveProductAPI,
  createProductAPI,
  createProductMeasurementAPI,
  deleteProductAPI,
  deleteProductMeasurementAPI,
  fetchInventoryByLocationAPI,
  fetchInventoryByRestaurantAPI, fetchProductAPI,
  unArchiveProductAPI,
  updateProductAPI,
  updateProductMeasurementAPI,
} from '../../api/inventory';
import { pathOr } from 'ramda';
import {message} from "antd";
import moment from "moment";
import {INVENTORY_ROUTE} from "../../constants";

interface Product {}

interface InventoryState {
  products: Product[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  productItem: any;
  inventory: any;
  product: any;
  errors: any;
  productMeasurement: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: InventoryState = {
  inventory: [],
  products: [],
  productItem: null,
  status: 'idle',
  error: null,
  isLoading: false,
  product: {},
  errors: null,
  productMeasurement: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setProduct: (state, action) => {
      state.product = {
        ...action.payload,
      };
    },
    updateProduct: (state, action) => {
      state.product = {
        ...state.product,
        ...action.payload,
      };
    },
    setProductMeasurement: (state, action) => {
      state.productMeasurement = action.payload;
    },
    updateProductMeasurement: (state, action) => {
      const productMeasurements = pathOr([], ['productMeasurements'], state.product).map(
         (productMeasurement) => {
           if (pathOr(null, ['id'], productMeasurement)
              === pathOr('', ['id'], action.payload)) {
             state.productMeasurement = {
               ...state.productMeasurement,
               ...action.payload
             };
             return {
               ...action.payload
             }
           }
           return productMeasurement
         }
      )
      state.product = {
        ...state.product,
        productMeasurements,
      }
    },
    addProductMeasurement: (state, action) => {
      const productMeasurements = pathOr([], ['productMeasurements'], state.product)
      state.product = {
        ...state.product,
        productMeasurements: [...productMeasurements, {
          id: moment().valueOf(),
          ...action.payload
        }]
      }
    },
    deleteProductMeasurement: (state, action) => {
      const productMeasurementId = action.payload;
      const productMeasurements = pathOr([], ['productMeasurements'], state.product)
      state.product = {
        ...state.product,
        productMeasurements: productMeasurements.filter(
           (productMeasurement) => productMeasurement.id  !== productMeasurementId)
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventoryByLocationAPI.fulfilled, (state, action) => {
      state.inventory = pathOr([], ['inventory'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInventoryByRestaurantAPI.fulfilled, (state, action) => {
      state.inventory = pathOr([], ['inventory'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchInventoryByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductAPI.fulfilled, (state, action) => {
      state.product = pathOr(null, ['inventory'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchProductAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchProductAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createProductAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.product = pathOr(null, ['inventory'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
      window.location.href = INVENTORY_ROUTE;
    });
    builder.addCase(createProductAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createProductAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateProductAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.product = pathOr(null, ['inventory'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
      window.location.href = INVENTORY_ROUTE;
    });
    builder.addCase(updateProductAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateProductAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveProductAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(archiveProductAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveProductAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(unArchiveProductAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(unArchiveProductAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(unArchiveProductAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProductAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deleteProductAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProductAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createProductMeasurementAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(createProductMeasurementAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createProductMeasurementAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateProductMeasurementAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(updateProductMeasurementAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateProductMeasurementAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProductMeasurementAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deleteProductMeasurementAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProductMeasurementAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  }
});

export const {
  setLoading,
  updateProduct,
  setProduct,
  addProductMeasurement,
  deleteProductMeasurement,
  setErrors,
  setProductMeasurement,
  updateProductMeasurement,
  setTotalPerPage,
  setCurrentPage
} = inventorySlice.actions;

export default inventorySlice.reducer;
