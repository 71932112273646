import { createSlice } from '@reduxjs/toolkit';
import { fetchWeeklyPurchasesAndExpensesReportGraphAPI } from 'api/reports/others';
import { pathOr } from 'ramda';

interface OthersState {
  navigationFunc: any;
  isLoading: boolean;
  weeklyExpensesAndPurchasesChartSeries: any[];
  weeklyExpensesAndPurchasesLoading: boolean;
  weeklyExpensesAndPurchasesChartCategories: any[];
}

const initialState: OthersState = {
  navigationFunc: null,
  isLoading: false,
  weeklyExpensesAndPurchasesLoading: false,
  weeklyExpensesAndPurchasesChartCategories: [],
  weeklyExpensesAndPurchasesChartSeries: [],
};

const othersSlice = createSlice({
  name: 'others',
  initialState,
  reducers: {
    setNavigationFunc: (state, action) => {
      state.navigationFunc = action.payload;
    },
    callNavigationFunc: (state, action) => {
      state.navigationFunc(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWeeklyPurchasesAndExpensesReportGraphAPI.fulfilled, (state, action) => {
      state.weeklyExpensesAndPurchasesChartSeries = pathOr([], ['chartSeries'], action.payload);
      state.weeklyExpensesAndPurchasesChartCategories = pathOr([], ['chartCategories'], action.payload);
      state.weeklyExpensesAndPurchasesLoading = false;
    });
    builder.addCase(fetchWeeklyPurchasesAndExpensesReportGraphAPI.rejected, (state, action) => {
      state.weeklyExpensesAndPurchasesLoading = false;
    });
    builder.addCase(fetchWeeklyPurchasesAndExpensesReportGraphAPI.pending, (state, action) => {
      state.weeklyExpensesAndPurchasesLoading = true;
    });
  },
});


export const {
  setNavigationFunc,
   callNavigationFunc
} = othersSlice.actions;

export default othersSlice.reducer;
