import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  EyeOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Input, Space, Table } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import type { ColumnType } from "antd/lib/table";
import ProductFilters from "./components/filters";
import { Link } from "react-router-dom";
import DeleteModal from "components/modal/DeleteModal";
import { pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MENU_GROUPS_ROUTE, MENU_ITEMS_ROUTE, MENUS_ROUTE } from "../../../../constants";
import { fetchMenuGroupAPI } from "../../../../api/menu-groups";
import { setMenuItem } from "../../../../store/menus/menu-items";
import {
  archiveMenuItemAPI,
  deleteMenuItemAPI,
  fetchMenuItemsByMenuGroupAPI,
} from "../../../../api/menu-items";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "../../../../store/menus/menu-items";
import withPermissions from "components/hoc";

interface DataItem {
  id: string;
  name: string;
  price: number;
  preparationTimeInMinutes: number;
  description: string;
  status: any;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const MenuItems: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isLoading = useAppSelector((state: RootState) => state.menuItems.isLoading);
  const menuGroup = useAppSelector((state: any) => state.menuGroups.menuGroup);
  const menuItem = useAppSelector((state: any) => state.menuItems.menuItem);
  const menuItems = useAppSelector((state: any) => state.menuItems.menuItems);
  const currentPage = useAppSelector((state: RootState) => state.menuItems.currentPage);
  const total = useAppSelector((state: RootState) => state.menuItems.total);
  const totalPerPage = useAppSelector((state: RootState) => state.menuItems.totalPerPage);
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchMenuGroupAPI({ menuGroupId: id }));
      dispatch(
        fetchMenuItemsByMenuGroupAPI({ menuGroupId: id, page: currentPage, perPage: totalPerPage }),
      );
    }
  }, [dispatch, id]);

  const handleClose = () => {
    dispatch(setMenuItem(null));
    setModalState({ isOpen: false, type: null });
  };

  const openModal = (menuItem: any, type: "delete" | "archive") => {
    dispatch(setMenuItem(menuItem));
    setModalState({ isOpen: true, type });
  };

  const deleteMenuItem = async () => {
    await dispatch(deleteMenuItemAPI({ menuItemId: pathOr("", ["id"], menuItem) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchMenuItemsByMenuGroupAPI({ menuGroupId: id }));
  };

  const archiveMenuItem = async () => {
    await dispatch(archiveMenuItemAPI({ menuItemId: pathOr("", ["id"], menuItem) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchMenuItemsByMenuGroupAPI({ menuGroupId: id }));
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    if (filtersVisible) setSearchText("");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onRefresh = async () => {
    await dispatch(
      fetchMenuItemsByMenuGroupAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
        menuGroupId: id,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenuItemsByMenuGroupAPI({ page: current, perPage: size, menuGroupId: id }));
  };

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns: TableColumn[] = [
    {
      title: "Menu Item",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Space>
          <Link
            to={`${MENU_ITEMS_ROUTE}/${record.id}/edit`}
            onClick={(e) => e.stopPropagation()}
            className="capitalize font-medium text-[15px]">
            {pathOr("", ["name"], record)}
          </Link>
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a: DataItem, b: DataItem) => pathOr(0, ["price"], a) - pathOr(0, ["price"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-[15px] text-gray-750">
          {pathOr(0, ["price"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Show On Web",
      dataIndex: "showOnWeb",
      key: "showOnWeb",
      render: (text, record: DataItem) => {
        return (
          <p className="capitalize font-medium text-[15px] text-gray-750">
            {String(pathOr(false, ["showOnWeb"], record))}
          </p>
        );
      },
    },
    {
      title: "Is Available",
      dataIndex: "isAvailable",
      key: "isAvailable",
      render: (text, record: DataItem) => {
        return (
          <p className="capitalize font-medium text-[15px] text-gray-750">
            {String(pathOr(false, ["isAvailable"], record))}
          </p>
        );
      },
    },
    {
      title: "Is Archived",
      dataIndex: "isArchived",
      key: "isArchived",
      render: (text, record: DataItem) => {
        return (
          <p className="capitalize font-medium text-[15px] text-gray-750">
            {String(pathOr(false, ["isArchived"], record))}
          </p>
        );
      },
    },
    {
      title: "Menu group",
      dataIndex: "menuGroup",
      key: "menuGroup",
      render: (text, record: DataItem) => {
        return (
          <p className="font-medium text-[15px] text-gray-750">{pathOr("", ["name"], menuGroup)}</p>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => (
        <Space size="middle">
          <Button
            onClick={() => navigate(`${MENU_ITEMS_ROUTE}/${record.id}/edit`)}
            icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
          />
          <Button
            icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => navigate(`${MENU_ITEMS_ROUTE}/${record.id}/edit`)}
          />
          <Button
            icon={<FolderOpenOutlined style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "archive")}
          />
          <Button
            icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "delete")}
          />
        </Space>
      ),
      width: "10%",
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Menu Items</p>
          <Breadcrumb
            items={[
              {
                title: <Link to={MENUS_ROUTE}>Menus</Link>,
              },
              {
                title: (
                  <Link to={`${MENUS_ROUTE}/${pathOr("", ["menuId"], menuGroup)}/menu-groups`}>
                    {pathOr("", ["menu", "name"], menuGroup)}
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={`${MENU_GROUPS_ROUTE}/${pathOr("", ["id"], menuGroup)}/menu-items`}>
                    {pathOr("", ["name"], menuGroup)}
                  </Link>
                ),
              },
              {
                title: <p>Menu Items</p>,
              },
            ]}
          />
        </div>
        <div>
          <Button
            onClick={() =>
              navigate(`${MENU_GROUPS_ROUTE}/${pathOr("", ["id"], menuGroup)}/menu-items/create`)
            }
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            icon={<PlusOutlined />}>
            Add Menu Item
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl group">
        <div className="flex items-center md:justify-between mb-6 flex-wrap md:flex-nowrap">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="basis-full md:basis-auto my-4 md:flex-grow md:justify-end flex-wrap">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <ProductFilters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={menuItems}
          rowSelection={rowSelection}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteMenuItem}
          title={"Delete Menu Item"}
          prompt={`Are you sure you want to delete menu item`}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveMenuItem}
          title={"Archive Menu Item"}
          prompt={`Are you sure you want to archive menu item`}
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default withPermissions(MenuItems);
