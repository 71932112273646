import { Dispatch, SetStateAction } from 'react';
import { Currency, ExpenseTypes } from 'types/AllTypes';
import { compose, split, map, toLower, tail, toUpper, head, join, length } from 'ramda';
import { unitOfMeasureArray } from '../constants';
import { message } from 'antd';
import moment from 'moment';

export const unitOfMeasureObjects = unitOfMeasureArray.map(unit => ({
  value: unit,
  label: unit
}));


export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
};

export const getCookie = (cname: any) => {
  const value = `; ${document.cookie}`;
  const parts: any = value.split(`; ${cname}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const deleteCookie = (cname: string) => {
  const date = new Date();
  date.setTime(date.getTime() - 1000 * 60 * 60 * 24);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = cname + "=" + expires + "; path=/";
};

export const deleteCookies = (cookieNames: any) => {
  cookieNames.forEach((cookieName: any) => {
    deleteCookie(cookieName)
  });
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    deleteCookie(cookie)
  }
};


export const setLocalStorageItem = (key: any, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {}
};

export const getLocalStorageItem = (key: any) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return null;
  }
};

export const clearAllLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {}
};

export const validateFormFieldsOne = (formValues: any) => {
  const errors: any = {};
  Object.keys(formValues).forEach((fieldName) => {
    const value = formValues[fieldName];

    // Skip validation for booleans and numbers
    if (typeof value === 'boolean' || typeof value === 'number') {
      return;
    }

    // Check for empty strings
    if (typeof value === 'string' && value.trim() === '') {
      errors[fieldName] = 'This field is required';
    }
  });

  return errors;
};

export const validateFormFieldsTwo = (formValues: any, inputConfigs: any) => {
  const errors: any = {};
  Object.keys(formValues).forEach((fieldName) => {
    const value = formValues[fieldName];
    if (
      inputConfigs.some(
        (config: any) => config.name === fieldName && config.isRequired
      ) && !value) {
      errors[fieldName] = 'This field is required';
    }
  });
  return errors;
};

export const handleValidationErrors = (form: any, errors: any) => {
  form.setFields(
    Object.keys(errors).map((fieldName) => ({
      name: fieldName,
      errors: [errors[fieldName]],
    }))
  );
  if (Object.keys(errors).length > 0) {
    message.error('Please fill in the required fields')
    return true;
  }

  return false;
};

export const handleCurrencyChange = (
  selectedCurrencyName: string,
  currencies: Currency[],
  setFormValues: Dispatch<SetStateAction<ExpenseTypes | any>> // Update the type of setFormValues
): void => {
  const selectedCurrency = currencies.find(currency => currency.currencyName === selectedCurrencyName);
  if (selectedCurrency) {
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      currencyName: selectedCurrencyName,
      currencySymbol: selectedCurrency.currencySymbol,
      currencyIsoCode: selectedCurrency.currencyIsoCode
    }));
  }
};

export const capitalizeText = compose(
  join(' '),
  map(word => toUpper(head(word)) + toLower(tail(word))),
  split(' ')
);

export const calculatePaymentStatus = (amountPaid: number, amountDue: number) => {
  if (amountPaid > 0 && amountDue > 0) {
    return 'PARTIALLY_PAID';
  } else if (amountPaid === 0) {
    return 'UNPAID';
  } else if (amountDue === 0) {
    return 'PAID';
  } else {
    return ''; // Handle any other cases if needed
  }
}

export const removeUndefinedOrNull = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === undefined ||obj[key] === null ||obj[key] === "" || length(obj[key]) < 1)
     {
      delete obj[key];
    }
  }
  return obj;
};

export const getTimeframeDates = (
  timeframe: string
): { fromDate: any; toDate: any } => {
  switch (timeframe) {
    case 'yesterday':
      return {
        fromDate: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
        toDate: moment
          .utc()
          .subtract(1, 'day')
          .endOf('day')
          .format('YYYY-MM-DD hh:mm A'),
      };
    case 'today':
      return {
        fromDate: moment.utc().startOf('day').format('YYYY-MM-DD HH:mm Z'),
        toDate: moment.utc().endOf('day').format('YYYY-MM-DD HH:mm Z'),
      };
    case 'this_week':
      return {
        fromDate: moment.utc().startOf('week').format('YYYY-MM-DD'),
        toDate: moment.utc().endOf('week').format('YYYY-MM-DD HH:mm Z'),
      };
    case 'last_week':
      return {
        fromDate: moment
          .utc()
          .subtract(1, 'week')
          .startOf('week')
          .format('YYYY-MM-DD'),
        toDate: moment
          .utc()
          .subtract(1, 'week')
          .endOf('week')
          .format('YYYY-MM-DD HH:mm Z'),
      };
    case 'this_month':
      return {
        fromDate: moment.utc().startOf('month').format('YYYY-MM-DD'),
        toDate: moment.utc().endOf('month').format('YYYY-MM-DD HH:mm Z'),
      };
    case 'last_month':
      return {
        fromDate: moment
          .utc()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        toDate: moment
          .utc()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD HH:mm Z'),
      };
    case 'last_3_months':
      return {
        fromDate: moment
          .utc()
          .subtract(3, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        toDate: moment
          .utc()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD HH:mm Z'),
      };
    case 'last_6_months':
      return {
        fromDate: moment
          .utc()
          .subtract(6, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        toDate: moment
          .utc()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD HH:mm Z'),
      };
    case 'this_year':
      return {
        fromDate: moment.utc().startOf('year').format('YYYY-MM-DD'),
        toDate: moment.utc().endOf('year').format('YYYY-MM-DD HH:mm Z'),
      };
    case 'last_year':
      return {
        fromDate: moment
          .utc()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DD'),
        toDate: moment
          .utc()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY-MM-DD HH:mm Z'),
      };
    case 'all_time':
      return {
        fromDate: '',
        toDate: '',
      };
    default:
      throw new Error(`Invalid timeframe attribute: ${timeframe}`);
  }
};

export const generateDatesBetween = (fromDate: Date, toDate: Date, interval?: 'day' | 'hour') => {
  const dates: Date[] = [];
  const currentDate = new Date(fromDate.getTime());
  const endTime = toDate.getTime();

  while (currentDate.getTime() <= endTime) {
    dates.push(new Date(currentDate.getTime()));

    if (interval === 'hour') {
      currentDate.setHours(currentDate.getHours() + 1); // Increment hour
    } else {
      currentDate.setDate(currentDate.getDate() + 1); // Default to incrementing day
    }
  }

  return dates;
};