import {Breadcrumb, Button, message, Tabs} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import IngredientForm from "../../components/ingredient-form";
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import MenuModifierItemDetails from "./menu-modifier-item-details";
import {length, pathOr} from "ramda";
import {MENU_MODIFIER_GROUPS_ROUTE} from "../../../../../constants";
import {
  createMenuModifierItemAPI,
  updateMenuModifierItemAPI
} from "../../../../../api/menu-modifier-items";
import {getCookie} from "../../../../../helpers/utilities";
import {pickFields} from "../../../../../utils/is-not-empty";
import {validateSchema} from "views/admin/menus/menu-modifier-items/validations/schema";
import {setErrors} from "../../../../../store/menus/menu-modifier-items";


const MenuModifierItemForm = () => {
  const dispatch = useAppDispatch();
  const menuModifierItem = useAppSelector((state: any) => state.menuModifierItems.menuModifierItem);
  const menuModifierGroup = useAppSelector((state: any) => state.menuModifierGroups.menuModifierGroup);
  const menuModifierGroupId = pathOr("", ["id"], menuModifierGroup);

  const onChange = (key: string) => {};

  const onFinish = async () => {
    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");

    const menuModifierItemObj = {
      ...pickFields(menuModifierItem, ['name', 'price', 'description', 'isAvailable', 'isArchived', 'showOnWeb']),
      ingredients: pickFields(pathOr([], ['ingredients'], menuModifierItem), [
        'productId',
        'quantity',
        'unitOfMeasure',
      ])
    }

    const menuModifierItemPayload = menuModifierItemObj;
    const {errors} = validateSchema(menuModifierItemPayload);
    dispatch(setErrors(errors))
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return;
    }

    if (!pathOr(null, ['id'], menuModifierItem)) {
      const obj = {
        ...menuModifierItemPayload,
        restaurantId,
        locationId
      }
      await dispatch(createMenuModifierItemAPI({ menuModifierItem: obj, menuModifierGroupId }))
    } else {
      await dispatch(updateMenuModifierItemAPI({
        menuModifierItem: menuModifierItemPayload,
        menuModifierItemId: pathOr('', ['id'], menuModifierItem),
      }))
    }
  };

  const items = [
    {
      key: "1",
      label: "Item details",
      children: <MenuModifierItemDetails />,
    },
    {
      key: "2",
      label: "Ingredients",
      children: <IngredientForm type="menuModifierItem" />,
    },
  ];

  return (
     <div>
       <div className="flex items-center justify-between mb-6">
         <div>
           <p className="text-lg font-bold">Menu Modifier Item</p>
           <Breadcrumb
              items={[
                {
                  title: <Link to={MENU_MODIFIER_GROUPS_ROUTE}>Modifier Groups</Link>
                },
                {
                  title: <Link to={`${MENU_MODIFIER_GROUPS_ROUTE}/${menuModifierGroupId}/menu-modifier-items`}>
                    {pathOr('', ['name'], menuModifierGroup)}
                  </Link>
                },
                {
                  title: <p>{pathOr(null, ['name'], menuModifierItem) ?
                     pathOr(null, ['name'], menuModifierItem) : 'Menu Modifier Item'}</p>
                }
              ]}
           />
         </div>
         <div className="p-2">
           <Button type="primary" onClick={onFinish} className="bg-blue-750">
             {pathOr(null, ['id'], menuModifierItem) ? "Update Modifier Item" : "Create Modifier Item"}
           </Button>
         </div>
       </div>
       <div className="bg-white p-5 rounded-xl">
         <Tabs type="card" defaultActiveKey="1" items={items} onChange={onChange} />
       </div>
     </div>
  );
};

export default MenuModifierItemForm;
