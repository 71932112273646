// Filters.tsx

import React from "react";
import { Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface FiltersProps {
  onFilterChange: (filters: Record<string, string | number>) => void;
  visible: boolean;
}

const Filters: React.FC<FiltersProps> = ({ onFilterChange, visible }) => {
  const handleFilterChange = (value: string | number, field: string) => {
    onFilterChange({ [field]: value });
  };

  if (!visible) {
    return null; // Don't render anything if not visible
  }

  return (
    <div className="flex space-x-4 mb-6">
      <Select
        defaultValue="Choose Locations"
        style={{ width: 180 }}
        className="h-10"
        onChange={(value) => handleFilterChange(value, "customer")}>
        {/* Populate with your product options */}
      </Select>
      <Button className="bg-blue-750 h-10" type="primary" icon={<SearchOutlined />}>
        Search
      </Button>
    </div>
  );
};

export default Filters;
