import { getCookie } from "helpers/utilities";
import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const signInWithEmailAndPasswordAPI = createAsyncThunk(
   'auth/sign-in-user',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/login/email-password`, {
         method: 'POST',
         data: {
           ...payload.user,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const signUpAPI = createAsyncThunk(
   'auth/sign-up-user',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/signup`, {
         method: 'POST',
         data: {
           ...payload.user
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const setupAccountAPI = createAsyncThunk(
   'auth/set-up-account',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/setup-account`, {
         method: 'PATCH',
         data: {
           ...payload.user
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateUserAccountAPI = createAsyncThunk(
   'auth/update-user-account',
   async (
      payload: { user: any; }, thunkAPI
   ) => {
     try {
       const response = await client(`/users`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.user
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const signOutAPI = createAsyncThunk(
   'auth/sign-out-user',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/auth/sign-out`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const sendPasswordResetLinkAPI = createAsyncThunk(
  'auth/send-password-reset-email',
  async (
    { email }: { email: string }, thunkAPI
  ) => {
    try {
      const response = await client(`/auth/send-password-reset-email`, {
        method: 'POST',
        data: {
          email,
          type: 'link'
        }
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);

export const verifyPasswordResetLinkAPI = createAsyncThunk(
  'auth/verify-password-reset-link',
  async (
    { token }: { token: string }, thunkAPI
  ) => {
    try {
      const response = await client(`/auth/verify-password-reset-email`, {
        method: 'POST',
        data: {
          token,
          type: 'link'
        }
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);

export const sendEmailVerificationLinkAPI = createAsyncThunk(
  'auth/send-email-verification-link',
  async (
    _, thunkAPI
  ) => {
    try {
      const response = await client(`/auth/send-email-verification `, {
        method: 'POST',
        headers: getAuthorizationHeader(),
        data: {
          type: 'link'
        }
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);

export const verifyEmailLinkAPI = createAsyncThunk(
  'auth/verify-email-link',
  async (
    { token }: { token: string }, thunkAPI
  ) => {
    try {
      const response = await client(`/auth/verify-email `, {
        method: 'PUT',
        data: {
          token,
          type: 'link'
        }
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);

export const resetPasswordAPI = createAsyncThunk(
  'auth/reset-password',
  async (
    { password }: { password: string }, thunkAPI
  ) => {
    try {
      const token = getCookie('password-reset-token');
      const response = await client(`/auth/reset-password`, {
        method: 'PUT',
        data: {
          password,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      return response.data
    } catch (error) {
      return handleAxiosError(error, thunkAPI)
    }
  }
);