import React, { FC, useEffect, useState } from "react";
import { Form } from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { FormInput } from "components/formInput/FormInput";
import { FormSelect } from "components/formSelect/FormSelect";
import FormInputRenderer from "components/formInputRenderer/FormInputRenderer";
import { FloorTypes } from "types/AllTypes";
import { occupancyStatuses } from "../../../../constants";
import {
  getCookie,
  handleValidationErrors,
  removeUndefinedOrNull,
  validateFormFieldsTwo,
} from "helpers/utilities";
import {
  createFloorAPI,
  fetchFloorsByLocationAPI,
  updateFloorAPI,
} from "../../../../api/floors";
import { pathOr } from "ramda";
import { CustomButton } from "components/customButton/customButton";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

interface FloorFormProps extends modalProps {
  mode: "create" | "edit";
  isOpen: boolean;
  handleClose: () => void;
}

const FloorForm: FC<FloorFormProps> = ({ mode, isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const floor = useAppSelector((state: any) => state.floors.floor);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<FloorTypes | any>({
    name: "",
    seatingCapacity: 0,
    occupancyStatus: "",
  });

  const handleInputChange = (name: keyof FloorTypes, type?: any) => (value: any) => {
    const processedValue = type === "number" ? parseFloat(value) || 0 : value;
    setFormValues((prev: any) => ({ ...prev, [name]: processedValue }));
  };

  const onFinish = async () => {
    const inputConfigs = [
      { name: "name", isRequired: true },
      { name: "seatingCapacity", isRequired: false },
      { name: "occupancyStatus", isRequired: false },
    ];
    const newErrors = validateFormFieldsTwo(formValues, inputConfigs);

    if (handleValidationErrors(form, newErrors)) {
      return;
    }

    const floorId = pathOr("", ["id"], floor);
    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");
    const createFormValues = {
      ...formValues,
      restaurantId,
      locationId,
    };

    const updatedFormValues = {
      ...formValues,
    };

    if (mode === "create") {
      await dispatch(createFloorAPI({floor: removeUndefinedOrNull(createFormValues)}));
    } else if (mode === "edit") {
      await dispatch(updateFloorAPI({floor: removeUndefinedOrNull(updatedFormValues), floorId}));
    }
    await dispatch(fetchFloorsByLocationAPI())
    handleClose();
  };

  useEffect(() => {
    if (floor && mode === "edit") {
      setFormValues({
        name: pathOr("", ["name"], floor),
        seatingCapacity: pathOr(0, ["seatingCapacity"], floor),
        occupancyStatus: pathOr("", ["occupancyStatus"], floor),
      });
    } else {
      setFormValues({ name: "", seatingCapacity: 0, occupancyStatus: "" });
    }
  }, [floor, mode]);

  const formInputProps = (
     label: string,
     name: keyof FloorTypes,
     type = "text",
     required = false,
  ) => ({
    label,
    name,
    required,
    onChange: handleInputChange(name, type),
  });

  const renderFormInputs = (
     inputs: {
       label: string;
       name: keyof FloorTypes;
       required?: boolean;
       type?: string;
       styling?: string;
     }[],
  ) =>
     inputs.map((input) => (
        <FormInputRenderer
           key={input.name}
           label={input.label}
           name={input.name as string}
           required={input.required}
           styling={input.styling}
           type={input.type}
           value={formValues[input.name]}
           onChange={handleInputChange(input.name, input.type)}
        />
     ));

  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">{mode === "edit" ? "Edit floor" : "Create floor"}</h3>
         <Form layout="vertical" form={form} className="w-full">
           <div className="text-left w-full pt-3">
             <FormInput
                label="Floor Name"
                name="name"
                value={formValues.name}
                required
                onChange={handleInputChange("name")}
             />
           </div>
           <div className="flex -m-2">
             {renderFormInputs([
               {
                 label: "Seating Capacity",
                 name: "seatingCapacity",
                 type: "number",
                 styling: "w-full md:w-1/2 p-2",
               },
             ])}
             <div className="text-left w-full md:w-1/2 p-2">
               <FormSelect
                  {...formInputProps("Occupancy Status", "occupancyStatus")}
                  options={occupancyStatuses}
                  value={formValues.occupancyStatus}
                  onChange={handleInputChange("occupancyStatus")}
               />
             </div>
           </div>
           <div className="mt-5 space-x-3 flex">
             <CustomButton
                type="primary"
                onClick={onFinish}
                label={mode === "edit" ? "Update" : "Submit"}
                disabled={submitting}
                submitting={submitting}
             />
             <CustomButton type="default" label="Cancel" onClick={handleClose} />
           </div>
         </Form>
       </div>
     </ParentModal>
  );
};

export default FloorForm;
