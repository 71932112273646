// Filters.tsx

import React from "react";
import { Select, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

interface FiltersProps {
  onFilterChange: (filters: Record<string, string | number>) => void;
  visible: boolean;
}

const Filters: React.FC<FiltersProps> = ({ onFilterChange, visible }) => {
  const handleFilterChange = (value: string | number, field: string) => {
    onFilterChange({ [field]: value });
  };

  if (!visible) {
    return null; // Don't render anything if not visible
  }

  return (
    <div className="flex flex-wrap gap-4 mb-6">
      <Select
        defaultValue="Choose Customer"
        className="h-10 w-full md:w-[45%] lg:w-[180px]"
        onChange={(value) => handleFilterChange(value, "customer")}>
        {/* Populate with your product options */}
      </Select>
      <Select
        defaultValue="Choose Product"
        className="h-10 w-full md:w-[45%] lg:w-[180px]"
        onChange={(value) => handleFilterChange(value, "product")}>
        {/* Populate with your category options */}
      </Select>
      <Select
        defaultValue="Choose Date"
        className="h-10 w-full md:w-[45%] lg:w-[180px]"
        onChange={(value) => handleFilterChange(value, "date")}>
        {/* Populate with your category options */}
      </Select>
      <Button className="bg-blue-750 h-10" type="primary" icon={<SearchOutlined />}>
        Search
      </Button>
    </div>
  );
};

export default Filters;
