import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  createPurchaseAPI,
  deletePurchaseAPI,
  fetchPurchasesByRestaurantAPI,
  fetchPurchaseAPI,
  updatePurchaseAPI,
  fetchPurchasesByLocationAPI
} from "../../api/purchases";
import { pathOr } from "ramda";
import { PURCHASES_ROUTE, unitsOfMeasure } from "../../constants";
import dayjs from "dayjs";

interface Purchases {}

interface PurchaseState {
  purchases: any;
  purchase: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: PurchaseState = {
  purchases: [],
  purchase: {
    purchaseDate: dayjs().format('YYYY-MM-DD'),
    amountPaid: 0,
    amountDue: 0,
    unitOfMeasure: unitsOfMeasure[1]
  },
  status: "idle",
  error: null,
  isLoading: false,
  isSubmitting: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
};

const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    fetchPurchasesSuccess: (state, action: PayloadAction<Purchases[]>) => {
      state.status = "succeeded";
      state.purchases = action.payload;
    },
    fetchPurchasesFailure: (state, action: PayloadAction<string>) => {
      state.status = "failed";
      state.error = action.payload;
    },
    fetchPurchaseByIdSuccess: (state, action) => {
      state.status = "succeeded";
      state.purchase = action.payload;
    },
    fetchPurchaseByIdFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updatePurchase: (state, action) => {
      state.purchase = {
        ...state.purchase,
        ...action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPurchasesByRestaurantAPI.fulfilled, (state, action) => {
      state.purchases = pathOr([], ["purchases"], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesByRestaurantAPI.rejected, (state, action) => {
      state.purchases = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPurchasesByLocationAPI.fulfilled, (state, action) => {
      state.purchases = pathOr([], ["purchases"], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesByLocationAPI.rejected, (state, action) => {
      state.purchases = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPurchaseAPI.fulfilled, (state, action)=>{
      state.purchase = pathOr(null, ["purchase"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchPurchaseAPI.rejected, (state, action) => {
      state.purchase = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchPurchaseAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createPurchaseAPI.fulfilled, (state, action)=>{
      message.success(pathOr('', ['message'], action.payload));
      state.purchase = pathOr(null, ["purchase"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
      window.location.href = PURCHASES_ROUTE;
    });
    builder.addCase(createPurchaseAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createPurchaseAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePurchaseAPI.fulfilled, (state, action)=>{
      message.success(pathOr('', ['message'], action.payload));
      state.purchase = pathOr(null, ["purchase"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updatePurchaseAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updatePurchaseAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePurchaseAPI.fulfilled, (state, action)=>{
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deletePurchaseAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deletePurchaseAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  fetchPurchasesSuccess,
  setSubmitting,
  fetchPurchaseByIdSuccess,
  fetchPurchaseByIdFailure,
  fetchPurchasesFailure,
  setLoading,
  setCurrentPage,
  setTotalPerPage,
  updatePurchase,
} = purchasesSlice.actions;

export default purchasesSlice.reducer;
