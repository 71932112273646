import { createSlice } from '@reduxjs/toolkit';
import {pathOr} from 'ramda';
import {
  fetchMenuItemsOverTimeReportByLocationAPI,
  fetchMenuItemsReportByBusinessAPI,
  fetchMenuItemsReportByLocationAPI,
  fetchMenuItemsReportByRestaurantAPI
} from "../../api/reports/menu-items";
import moment from 'moment';
import { getTimeframeDates } from 'helpers/utilities';

interface MenuItemsReportsState {
  menuItemsReport: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: MenuItemsReportsState = {
  menuItemsReport: [],
  status: 'idle',
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  filters: {
    fromDate: getTimeframeDates('this_month').fromDate,
    toDate: getTimeframeDates('this_month').toDate
  },
};

const menuItemsReportsSlice = createSlice({
  name: 'menu-items-reports',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenuItemsReportByLocationAPI.fulfilled, (state, action) => {
      state.menuItemsReport = pathOr([], ['menuItemsReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsOverTimeReportByLocationAPI.fulfilled, (state, action) => {
      state.menuItemsReport = pathOr([], ['menuItemsReport'], action.payload).flatMap((report) => {
        return {
          ...report,
          key: pathOr(moment().valueOf(), ['date'], report)
        }
      });
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsOverTimeReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsOverTimeReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsReportByRestaurantAPI.fulfilled, (state, action) => {
      state.menuItemsReport = pathOr([], ['menuItemsReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItemsReportByBusinessAPI.fulfilled, (state, action) => {
      state.menuItemsReport = pathOr([], ['menuItemsReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchMenuItemsReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = menuItemsReportsSlice.actions;

export default menuItemsReportsSlice.reducer;
