import { Button, Descriptions, Table } from "antd";
import { pathOr } from "ramda";
import React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { generateTransactionDisplayDataItems, paymentsColumns, refundsColumns } from "./components/table-columns";
import { RootState } from "store";
import { deleteTransaction, removePayment, removeRefund } from "store/orders/orders";

const OrderTransactionDetails = () => {
    const dispatch = useAppDispatch();
    const order = useAppSelector((state: any) => pathOr(null, ['order'], state.orders));
    const transaction = pathOr(null, ['transaction'], order);
    const payments = pathOr([], ['payments'], transaction);
    const refunds = pathOr([], ['refunds'], transaction);
    const currencySymbol = useAppSelector((state: RootState) =>
        pathOr("", ["currentLocation", "currencySymbol"], state.auth),
    );

    const handleTransactionDelete = () => {
      dispatch(deleteTransaction());
    }

    const handlePaymentDelete = (paymentId: string) => {
      dispatch(removePayment(paymentId))  
    }

    const handleRefundDelete = (refundId: string) => {
        dispatch(removeRefund(refundId));  
    }
    return (
        <div className="flex flex-col space-y-4">
            {!pathOr(null, ['createdAt'], order) ? <div className="flex flex-row justify-end">
                <div>
                    <Button onClick={handleTransactionDelete}>Delete Transaction</Button>
                </div>
            </div> : null}
            <div>
                <Descriptions 
                    title="Transaction Details"
                    items={generateTransactionDisplayDataItems({ 
                        currencySymbol,
                        transaction,
                        order
                    })} 
                />
            </div>

            <div>
                <h2 className="text-base font-semibold">Payments</h2>
                <Table
                    scroll={{ x: "max-content" }}
                    columns={paymentsColumns({ currencySymbol, handlePaymentDelete })}
                    dataSource={payments}
                    pagination={false}
                />
            </div>

            <div>
                <h2 className="text-base font-semibold">Refunds</h2>
                <Table
                    scroll={{ x: "max-content" }}
                    columns={refundsColumns({ currencySymbol, handleRefundDelete })}
                    dataSource={refunds}
                    pagination={false}
                />
            </div> 
        </div>
    )
}

export default OrderTransactionDetails;
