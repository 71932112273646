import React, { useState } from "react";
import {Button, Table, Space, Alert} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import IngredientModal from "./ingredient-modal";
import { pathOr } from "ramda";
import DeleteModal from "components/modal/DeleteModal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import {
  removeMenuModifierItemIngredient,
  setMenuModifierItemIngredient
} from "../../../../store/menus/menu-modifier-items";
import {removeMenuItemIngredient, setMenuItemIngredient} from "../../../../store/menus/menu-items";
import {deleteMenuItemIngredientAPI} from "../../../../api/menu-items";
import {deleteMenuModifierItemIngredientAPI} from "../../../../api/menu-modifier-items";

interface IngredientFormProps {
  type: 'menuItem' | 'menuModifierItem';
}

const IngredientForm: React.FC<IngredientFormProps> = ({type}) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector((state: any) => state.menus.isSubmitting);
  const isLoading = useAppSelector((state: any) => state.menus.isLoading);
  const menuModifierItem = useAppSelector(
     (state: RootState) => state.menuModifierItems.menuModifierItem);
  const menuItem = useAppSelector(
     (state: RootState) => state.menuItems.menuItem);
  const menuItemErrors = useAppSelector(
     (state: RootState) => state.menuItems.errors);
  const modifierItemErrors = useAppSelector(
     (state: RootState) => state.menuModifierItems.errors);
  const errors = type === 'menuItem' ? menuItemErrors : modifierItemErrors;
  const menuModifierItemIngredient = useAppSelector(
     (state: RootState) => state.menuModifierItems.menuModifierItemIngredient);
  const menuItemIngredient = useAppSelector(
     (state: RootState) => state.menuItems.menuItemIngredient);
  const item = type === 'menuItem' ? menuItem : menuModifierItem;
  const ingredient = type === 'menuItem' ? menuItemIngredient : menuModifierItemIngredient;
  const ingredients = pathOr([], ["ingredients"], item);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  const openModal = (ingredient: any, mode: 'create' | 'edit' | 'delete') => {
    if (type === 'menuItem') {
      dispatch(setMenuItemIngredient(ingredient));
    } else {
      dispatch(setMenuModifierItemIngredient(ingredient));
    }
    setModalState({ isOpen: true, type: mode });
  };

  const handleClose = () => {
    if (type === 'menuItem') {
      dispatch(setMenuItemIngredient(null));
    } else {
      dispatch(setMenuModifierItemIngredient(null));
    }
    setModalState({ isOpen: false, type: null });
  };

  const deleteIngredient = async () => {
    if (type === 'menuItem') {
      if (pathOr('', ['createdAt'], ingredient)) {
        await dispatch(deleteMenuItemIngredientAPI({
          ingredientId: pathOr('', ['id'], ingredient)
        }));
      }
      dispatch(removeMenuItemIngredient(pathOr('', ['id'], ingredient)))
    } else {
      if (pathOr('', ['createdAt'], ingredient)) {
        await dispatch(deleteMenuModifierItemIngredientAPI({
          ingredientId: pathOr('', ['id'], ingredient)
        }));
      }
      dispatch(removeMenuModifierItemIngredient(pathOr('', ['id'], ingredient)))
    }

    dispatch(setMenuItemIngredient(null));
    dispatch(setMenuModifierItemIngredient(null));
    handleClose();
  };

  const columns = [
    {
      title: "Ingredient",
      dataIndex: "ingredient",
      key: "ingredient",
      render: (text: any, record: any) => {
        return <p className="text-[15px]">{pathOr('', ['product', 'name'], record)}</p>;
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => (
         <p>{text}</p>
      )
    },
    {
      title: "Unit",
      dataIndex: "unitOfMeasure",
      key: "unitOfMeasure",
      render: (text: any, record: any) => (
         <p>{text}</p>
      )
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
         <Space size="middle">
           <Button
              icon={
                <EditOutlined
                   onClick={() => openModal(record, 'edit')}
                   className="text-gray-750"
                   style={{ fontSize: 20 }}
                />
              }
              type="link"
           />
           <Button
              icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
              type="link"
              onClick={() => openModal(record, 'delete')}
           />
         </Space>
      ),
      width: "15%",
    },
  ];

  return (
     <div className="pb-8">
       <div className="flex flex-wrap justify-end mb-4">
         <Button type="primary" className="bg-blue-750" onClick={() => openModal(null, 'create')}>
           Add Ingredients
         </Button>
       </div>

       { pathOr('', ['Ingredients'], errors) ?
          (
             <div>
              <Alert
                 message={pathOr('', ['Ingredients', 0, 'message'], errors)}
                 type="error"
                 closable
              />
            </div>
          ) : null
       }
       <Table
          scroll={{ x: "max-content" }}
          dataSource={ingredients}
          columns={columns}
          pagination={false}
          loading={isLoading}
       />
       <IngredientModal
          isOpen={modalState.isOpen && ['create', 'edit'].includes(modalState.type)}
          handleClose={handleClose}
          ingredient={ingredient}
          type={type}
       />
       <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteIngredient}
          isDeleting={isSubmitting}
       />
     </div>
  );
};

export default IngredientForm;
