import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  FilterFilled,
  CloseOutlined,
  PlusOutlined,
  ReloadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Space, Table, Tabs } from "antd";
import type { TableRowSelection } from 'antd/es/table/interface';
import type { OrderTableDataItem } from './components/table-columns';
import Filters from "./components/filters";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchOrdersByLocationAPI } from "../../../api/orders";
import { orderColumns, orderTableTabItems } from "views/admin/orders/components/table-columns";
import { ORDERS_ROUTE } from "../../../constants";
import { fetchOrderStages, setCurrentPage, setOrder, setTotalPerPage, updateFilters } from "store/orders/orders";
import { RootState } from "store";
import { fetchEmployeesByLocationAPI } from "api/employees";
import moment from "moment";
import { length, pathOr } from "ramda";
import MoveOrderStageModal from "./components/move-order-stage-modal";
import withPermissions from "components/hoc";
import ProtectedWrapper from "components/protected-wrapper";
import { CAN_CREATE_ORDER } from "permissions";

const { RangePicker } = DatePicker;

const Orders: React.FC = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state: any) => state.orders.orders);
  const isLoading = useAppSelector((state: any) => state.orders.isLoading);
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState<string>("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const currentPage = useAppSelector((state: RootState) => state.orders.currentPage);
  const filters = useAppSelector((state: RootState) => state.orders.filters);
  const total = useAppSelector((state: RootState) => state.orders.total);
  const totalPerPage = useAppSelector((state: RootState) => state.orders.totalPerPage);
  const [modal, setModal] = useState('');
  const currencyIsoCode = useAppSelector((state: any) =>
    pathOr("", ["currencyIsoCode"], state.auth.currentLocation),
  );

  useEffect(() => {
    dispatch(fetchOrdersByLocationAPI({ page: currentPage, perPage: totalPerPage, ...filters }));
    dispatch(fetchEmployeesByLocationAPI({}));
    dispatch(fetchOrderStages())
  }, [currentPage, dispatch, totalPerPage]);

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<OrderTableDataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRefresh = async () => {
    await dispatch(
      fetchOrdersByLocationAPI({ 
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
        ...filters
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchOrdersByLocationAPI({ 
      page: current,
      perPage: size,
      ...filters
    }));
  };

  const onChangeTab = async (key: string) => {
    setActiveTabKey(key);
    if (key) {
      await dispatch(
        fetchOrdersByLocationAPI({ page: currentPage, perPage: totalPerPage, type: key, ...filters }),
      );
    } else {
      await dispatch(
        fetchOrdersByLocationAPI({ page: currentPage, perPage: totalPerPage, ...filters }),
      );
    }
  };

  const onDateChange = (dates: any, value: any) => {
    dispatch(updateFilters({
      fromDate: value[0] ? moment.utc(value[0]).format('YYYY-MM-DD') : null,
      toDate: value[1] ? moment.utc(value[1]).format('YYYY-MM-DD') : null,
    }));
    if (value[0] === null && value[1] === null) {
      dispatch(
        fetchOrdersByLocationAPI({ 
            page: currentPage,
            perPage: totalPerPage,
            ...filters,
            fromDate: '',
            toDate: '',
          }
        )
      );
    }
  }

  const confirmDates = async () => {
    await dispatch(
      fetchOrdersByLocationAPI({ 
          page: currentPage,
          perPage: totalPerPage,
          ...filters,
        }
      )
    );
  }

  const toCreateOrder = () => {
    dispatch(setOrder(null));
    navigate(`${ORDERS_ROUTE}/create`)
  }
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Orders List</p>
          <p className="text-base text-gray-750">Manage your orders</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull={true} permissionsNeeded={[CAN_CREATE_ORDER]}>
            <Button
              onClick={toCreateOrder}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Create Order
            </Button>
          </ProtectedWrapper>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <Tabs
          activeKey={activeTabKey}
          defaultActiveKey=""
          items={orderTableTabItems}
          onChange={onChangeTab}
        />
        <div className="flex items-center md:justify-between mb-6 flex-wrap">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
           <RangePicker onChange={onDateChange} allowClear />
          {filters.fromDate && filters.toDate ? <Button onClick={confirmDates} className="ml-2">Confirm</Button> : null}
          <Space className="basis-full md:basis-auto md:flex-grow md:justify-end my-4 flex-wrap">
            <Button icon={<DownloadOutlined />}>Export</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Filters visible={filtersVisible} />
        { length(selectedRowKeys) > 0 ? (
          <div className="mb-6 flex flex-row items-center space-x-4">
            <div>
              <p className="text-base">Selected {length(selectedRowKeys)} orders</p>
            </div>

            <div>
              <Button onClick={() => setModal('moveOrderStage')} icon={<ArrowRightOutlined />}>Move Stage</Button>
            </div>
          </div>
        ) : null }
        <div className="overflow-x-scroll no-scroll">
          <Table
            scroll={{ x: "max-content" }}
            columns={orderColumns(currencyIsoCode)}
            dataSource={orders}
            loading={isLoading}
            rowSelection={rowSelection}
            pagination={{
              current: currentPage,
              pageSize: totalPerPage,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: () => `Total ${total} items`,
              onShowSizeChange: onPageSizeChange,
              pageSizeOptions: [10, 20, 30, 50, 70, 100],
              onChange: onPageSizeChange,
            }}
          />
          <MoveOrderStageModal 
            isOpen={modal === 'moveOrderStage'}
            handleClose={() => setModal('')}
            selectedOrders={selectedRowKeys}
            onOrderUpdate={onRefresh}
          />
        </div>
      </div>
    </div>
  );
};

export default withPermissions(Orders);
