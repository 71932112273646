import React, { useEffect, useState } from "react";
import {
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  CloseOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import Filters from "./components/filters";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { pathOr } from "ramda";
import { fetchMenuModifierItemsReportByLocationAPI } from "../../../../api/reports/menu-modifier-items";
import { RootState } from "store";
import { setCurrentPage, setTotalPerPage } from "store/reports/menu-modifier-items";
import withPermissions from "components/hoc";
interface DataItem {
  key: React.Key;
  name: string;
  quantity: number;
  unitOfMeasure: string;
  revenue: number;
  location: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const MenuModifierItemsReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const menuModifierItemsReport = useAppSelector(
    (state: any) => state.menuModifierItemsReports.menuModifierItemsReport,
  );
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currencySymbol"], state.auth.currentLocation),
  );
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const [filtersVisible, setFiltersVisible] = useState(false);
  const loading = useAppSelector((state: RootState) => state.menuModifierItemsReports.isLoading);
  const currentPage = useAppSelector(
    (state: RootState) => state.menuModifierItemsReports.currentPage,
  );
  const total = useAppSelector((state: RootState) => state.menuModifierItemsReports.total);
  const totalPerPage = useAppSelector(
    (state: RootState) => state.menuModifierItemsReports.totalPerPage,
  );

  useEffect(() => {
    dispatch(
      fetchMenuModifierItemsReportByLocationAPI({ page: currentPage, perPage: totalPerPage }),
    );
  }, [currentPage, dispatch, totalPerPage]);

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const onRefresh = async () => {
    await dispatch(
      fetchMenuModifierItemsReportByLocationAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenuModifierItemsReportByLocationAPI({ page: current, perPage: size }));
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const columns: TableColumn[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["name"], record)}
        </p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) => pathOr(0, ["quantity"], a) - pathOr(0, ["quantity"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {pathOr(0, ["quantity"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Expected Revenue",
      dataIndex: "expectedRevenue",
      key: "expectedRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["expectedRevenue"], a) - pathOr(0, ["expectedRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["expectedRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Actual Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["actualRevenue"], a) - pathOr(0, ["actualRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["actualRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Lost Revenue",
      dataIndex: "lostRevenue",
      key: "lostRevenue",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr(0, ["lostRevenue"], a) - pathOr(0, ["lostRevenue"], b),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750">
          {currencySymbol} {pathOr(0, ["lostRevenue"], record).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      className: "whitespace-nowrap",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["location"], a).localeCompare(pathOr("", ["location"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-base text-gray-750 whitespace-nowrap">
          {pathOr("", ["location"], record)}
        </p>
      ),
    },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Menu Modifier Items Report</p>
          <p className="text-base text-gray-750">Manage your Reports</p>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex flex-wrap items-center md:justify-between mb-6">
          <Button
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Space className="my-4 flex-wrap basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <Filters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          loading={loading}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={menuModifierItemsReport}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
      </div>
    </div>
  );
};

export default withPermissions(MenuModifierItemsReport);
