import React, { useEffect, useState } from 'react';
import {Button, Input, message, Select, Space} from 'antd';
import { getCookie } from 'helpers/utilities';
import {createSupplierAPI, fetchSupplierAPI, updateSupplierAPI} from '../../../../api/suppliers';
import { useNavigate, useParams } from 'react-router-dom';
import {length, pathOr} from 'ramda';
import {SUPPLIERS_ROUTE} from "../../../../constants";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {updateSupplier} from "../../../../store/suppliers/suppliers";
import {deleteFields, removeUndefinedOrNull} from "../../../../utils/is-not-empty";
import {validateSchema} from "../validations/schema";
import {countries} from "../../../../data";

const SupplierForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setErrors] = useState<any>({});
  const supplier = useAppSelector((state: any) => state.suppliers.supplier);

  useEffect(() => {
    if (id) {
      dispatch(fetchSupplierAPI({supplierId: id}));
    }
  }, [dispatch, id])

  const handleChange = (val: any,  key: string) => {
    dispatch(updateSupplier({
      [key]: val,
    }));
  };

  const onFinish = async () => {
    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");

    const supplierObj = {
      ...deleteFields(supplier, [
        'id',
        'createdAt',
        'updatedAt',
        'updatedById',
        'createdById',
        'updatedById',
        'location',
        'locationId',
        'restaurant',
        'restaurantId',
        'businessId',
        'business',
        'isArchived',
        'products'
      ]),
    };

    const supplierPayload = removeUndefinedOrNull(supplierObj);
    const { errors } = validateSchema(supplierPayload);
    setErrors(errors);

    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check the errors and try again');
      return
    }

    if (!id) {
      const obj = {
        ...supplierPayload,
        restaurantId,
        locationId,
      };
      await dispatch(createSupplierAPI({supplier: obj}));
    } else {
      await dispatch(updateSupplierAPI({supplierId: id, supplier: supplierPayload}));
    }
  };

  return (
    <div className="h-full overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between my-6">
        <div>
          <p className="text-lg font-bold">
            {pathOr('', ['id'], supplier) ?
               `Edit ${pathOr('', ['name'], supplier) || 'Supplier'}` : 'Add new supplier'}
          </p>
          <p className="text-base text-gray-750">{
            pathOr('', ['id'], supplier) ? "Update" : "Add"} Supplier</p>
        </div>

        <div>
          <Space>
            <Button
               onClick={onFinish}
               type="primary"
               className="bg-blue-750">
              {pathOr('', ['id'], supplier) ? "Update Supplier" : "Create Supplier"}
            </Button>
            <Button onClick={() => navigate(SUPPLIERS_ROUTE)} type="default">
              Cancel
            </Button>
          </Space>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-wrap flex-row items-start justify-between">
            <div className='w-full md:w-[48%] lg:w-[32%]'>
              <span>Supplier Name<span className="text-red-500">*</span></span>
              <Input
                 name="name"
                 required
                 status={pathOr(null, ['Supplier name'], errors) ? 'error' : ''}
                 value={pathOr('', ['name'], supplier)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'name'
                 )}
              />
              { pathOr(null, ['Supplier name'], errors) ? <span className='text-red-500'>
              {pathOr('', ['Supplier name', 0, 'message'], errors)}
            </span> : null}
            </div>

            <div className='w-full md:w-[48%] lg:w-[32%]'>
              <span>Email</span>
              <Input
                 name="email"
                 status={pathOr(null, ['Supplier email'], errors) ? 'error' : ''}
                 value={pathOr('', ['email'], supplier)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'email'
                 )}
              />
              { pathOr(null, ['Supplier email'], errors) ? <span className='text-red-500'>
              {pathOr('', ['Supplier email', 0, 'message'], errors)}
            </span> : null}
            </div>

            <div className='w-full md:w-[48%] lg:w-[32%]'>
              <span>Phone Number</span>
              <Input
                 name="phoneNumber"
                 status={pathOr(null, ['Supplier phone number'], errors) ? 'error' : ''}
                 value={pathOr('', ['phoneNumber'], supplier)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'phoneNumber'
                 )}
              />
              { pathOr(null, ['Supplier phone number'], errors) ? <span className='text-red-500'>
              {pathOr('', ['Supplier phone number', 0, 'message'], errors)}
            </span> : null}
            </div>
          </div>

          <div>
            <span>Address</span>
            <Input
               name="address"
               status={pathOr(null, ['Supplier address'], errors) ? 'error' : ''}
               value={pathOr('', ['address'], supplier)}
               onChange={(e) => handleChange(
                  e.target.value,
                  'address'
               )}
            />
            { pathOr(null, ['Supplier address'], errors) ? <span className='text-red-500'>
              {pathOr('', ['Supplier address', 0, 'message'], errors)}
            </span> : null}
          </div>

          <div className="flex flex-wrap flex-row items-start justify-between">
            <div className='w-full md:w-[48%] lg:w-[32%]'>
              <span>City</span>
                <Input
                   name="city"
                   status={pathOr(null, ['Supplier city'], errors) ? 'error' : ''}
                   value={pathOr('', ['city'], supplier)}
                   onChange={(e) => handleChange(
                      e.target.value,
                      'city'
                   )}
                />
                { pathOr(null, ['Supplier city'], errors) ? <span className='text-red-500'>
                {pathOr('', ['Supplier city', 0, 'message'], errors)}
              </span> : null}
            </div>

            <div className='w-full md:w-[48%] lg:w-[32%]'>
              <span>State</span>
              <Input
                 name="state"
                 status={pathOr(null, ['Supplier state'], errors) ? 'error' : ''}
                 value={pathOr('', ['state'], supplier)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'state'
                 )}
              />
              { pathOr(null, ['Supplier state'], errors) ? <span className='text-red-500'>
                {pathOr('', ['Supplier state', 0, 'message'], errors)}
              </span> : null}
            </div>

            <div className='w-full md:w-[48%] lg:w-[32%]'>
              <span>Postal Code</span>
              <Input
                 name="postalCode"
                 status={pathOr(null, ['Supplier postal code'], errors) ? 'error' : ''}
                 value={pathOr('', ['postalCode'], supplier)}
                 onChange={(e) => handleChange(
                    e.target.value,
                    'postalCode'
                 )}
              />
              { pathOr(null, ['Supplier postal code'], errors) ? <span className='text-red-500'>
                {pathOr('', ['Supplier postal code', 0, 'message'], errors)}
              </span> : null}
            </div>
          </div>

          <div>
            <div>
              <span>Country</span>
              <Select
                 className="w-full"
                 status={pathOr(null, ['Supplier country'], errors) ? 'error' : ''}
                 value={pathOr('', ['country'], supplier)}
                 onChange={(val) => handleChange(val, 'country')}
                 options={countries.map((country: {name: string; code: string;}) => {
                   return {
                     label: country.name,
                     value: country.name,
                   }
                 })}
              />
              {pathOr(null, ['Supplier country'], errors) ? <span className='text-red-500'>
              {pathOr('', ['Supplier country', 0, 'message'], errors)}
             </span> : null}
            </div>
          </div>

          <div>
            <span>Delivery Method</span>
            <Input
               name="deliveryMethod"
               status={pathOr(null, ['Supplier delivery method'], errors) ? 'error' : ''}
               value={pathOr('', ['deliveryMethod'], supplier)}
               onChange={(e) => handleChange(
                  e.target.value,
                  'deliveryMethod'
               )}
            />
            { pathOr(null, ['Supplier delivery method'], errors) ? <span className='text-red-500'>
                {pathOr('', ['Supplier delivery method', 0, 'message'], errors)}
              </span> : null}
          </div>

          <div>
            <span>Tax ID Number</span>
            <Input
               name="taxIdentificationNumber"
               status={pathOr(null, ['Supplier tax identification number'], errors) ? 'error' : ''}
               value={pathOr('', ['taxIdentificationNumber'], supplier)}
               onChange={(e) => handleChange(
                  e.target.value,
                  'taxIdentificationNumber'
               )}
            />
            { pathOr(null, ['Supplier tax identification number'], errors)
               ? <span className='text-red-500'>
                {pathOr('', ['Supplier tax identification number', 0, 'message'], errors)}
              </span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierForm;
