import { Button, message, Space, Table } from "antd";
import moment from "moment";
import { pathOr } from "ramda";
import { RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useState } from "react";
import RegisterSessionForm from "./register-session-form";
import RegisterTransactionForm from "./register-transaction-form";
import { setRegisterSession } from "store/registers";
import { updateRegisterSessionAPI } from "api/registers/register-sessions";

const RegisterSessions = () => {
  const dispatch = useAppDispatch();
  const sessions = useAppSelector((state: RootState) => state.registers.registerSessions);
  const sessionsLoading = useAppSelector((state: RootState) => state.registers.registerSessionsLoading);
  const [modalState, setModalState] = useState({ 
    isOpen: false,
    type: null
  });
  const register = useAppSelector((state: RootState) => state.registers.register);

  const closeModal = () => {
    setModalState({ isOpen: false, type: null });
  };

  const openTransactionModal = (session: any) => {
    dispatch(setRegisterSession(session));
    setModalState({ isOpen: true, type: 'transaction' })
  }

  const closeRegister = async () => {
    const session = pathOr(null, [0], sessions);

    if (!session || pathOr(null, ['closedAt'], session)) {
      message.error('register must be open in order to be closed');
      return;
    }

    await dispatch(updateRegisterSessionAPI({ 
      sessionId: pathOr('', ['id'], session),
      session: {
        closedAt: new Date(),
      }
    }));
  }
  
  const columns = [
    {
      title: "Opened At",
      dataIndex: "openedAt",
      key: "openedAt",
      render: (text: any, record: any) => (
        <p>{moment(pathOr(null, ['createdAt'], record)).format('Do ddd, MMM YYYY hh:mm A')}</p>
      ),
    },
    {
      title: "Opening Balance",
      dataIndex: "openedBalance",
      key: "openedBalance",
      render: (text: any, record: any, index: number) => (
        <p>{pathOr(0, ['openingBalance'], record).toLocaleString()}</p>
      ),
    },
    {
      title: "Current Balance",
      dataIndex: "currentBalance",
      key: "currentBalance",
      render: (text: any, record: any, index: number) => (
        <p>{pathOr(0, ['currentBalance'], record).toLocaleString()}</p>
      ),
    },
    {
      title: "Closed At",
      dataIndex: "closedAt",
      key: "closedAt",
      render: (text: any, record: any, index: number) => (
        <p>{pathOr(null, ['closedAt'], record) ? 
        moment(pathOr(null, ['closedAt'], record)).format('Do ddd, MMM YYYY hh:mm A') : null}</p>
      ),
    },
    {
      title: "Closing Balance",
      dataIndex: "closingBalance",
      key: "closingBalance",
      render: (text: any, record: any, index: number) => (
        <p>{pathOr(null, ['closingBalance'], record) ? pathOr(0,
          ['closingBalance'], record).toLocaleString() : null}</p>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {!pathOr(null, ['closedAt'], record) ? <Button onClick={() => openTransactionModal(record)}>
            Create Transaction</Button> : null}
        </Space>
      ),
      width: "15%",
    },
  ];
  return (
    <div>
      <div className="flex flex-row justify-end">
        {pathOr(null, ['status'], register) === 'CLOSED' ? (
          <div className="p-2">
            <Button
              onClick={() => setModalState({ isOpen: true, type: 'session' })}
              className="w-[150px]">
              Open register
            </Button>
          </div>
        ) : pathOr(null, ['status'], register) === 'OPENED'
         || pathOr(null, ['status'], register) === 'SUSPENDED' ? (
          <div className="p-2">
            <Button
              onClick={closeRegister}
              className="w-[150px]">
              Close register
            </Button>
          </div>
        ) : null}
      </div>

      <div>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={sessions}
          columns={columns}
          pagination={false}
          loading={sessionsLoading}
        />
      </div>

      <RegisterSessionForm
        isOpen={modalState.isOpen && modalState.type === 'session'}
        handleClose={closeModal}
      />

      <RegisterTransactionForm 
        isOpen={modalState.isOpen && modalState.type === 'transaction'}
        handleClose={closeModal}
      />
    </div>
  )
}

export default RegisterSessions;