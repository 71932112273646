import { Button, message, Table } from "antd";
import moment from "moment";
import { pathOr } from "ramda";
import { RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import RegisterTransactionForm from "./register-transaction-form";
import { useState } from "react";
import { setRegisterSession } from "store/registers";

const RegisterTransactions = () => {
  const dispatch = useAppDispatch();
  const sessions = useAppSelector((state: RootState) => state.registers.registerSessions);
  const transactions = useAppSelector((state: RootState) => state.registers.registerTransactions);
  const transactionsLoading = useAppSelector(
    (state: RootState) => state.registers.registerTransactionsLoading);
  const [modalState, setModalState] = useState({ 
    isOpen: false,
    type: null
  });

  const closeModal = () => {
    setModalState({ isOpen: false, type: null });
  };

  const createTransaction = async () => {
    const session = pathOr(null, [0], sessions);

    if (!session || pathOr(null, ['closedAt'], session)) {
      message.error('register must be open in order to make transaction');
      return;
    }

    dispatch(setRegisterSession(session));
    setModalState({ ...modalState, isOpen: true });
  }
  
  const columns = [
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any, record: any) => (
        <p>{moment(pathOr(null, ['createdAt'], record)).format('Do ddd, MMM YYYY hh:mm A')}</p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, record: any) => (
        <p>{pathOr(0, ['amount'], record).toLocaleString()}</p>
      ),
    },
    {
      title: "Created By",
      dataIndex: "openedAt",
      key: "openedAt",
      render: (text: any, record: any) => (
        <p>{`${pathOr('', ['createdBy', 'profile', 'firstname'], record)} 
        ${pathOr('', ['createdBy', 'profile', 'lastname'], record)}`}</p>
      ),
    },
  ];
  return (
    <div>
      <div className="flex flex-row justify-end">
        <div className="p-2">
          <Button
            onClick={createTransaction}
            type="primary"
            className="bg-blue-750 w-[150px]">
            Create Transaction
          </Button>
        </div>
      </div>
      <div>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={transactions}
          columns={columns}
          pagination={false}
          loading={transactionsLoading}
        />
      </div>
      <RegisterTransactionForm
        isOpen={modalState.isOpen}
        handleClose={closeModal}
      />
    </div>
  )
}

export default RegisterTransactions;