import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  archiveEmployeeAPI,
  createEmployeeAPI,
  fetchEmployeeAPI, fetchEmployeesByBusinessAPI,
  fetchEmployeesByLocationAPI,
  updateEmployeeAPI,
} from '../../api/employees';
import { pathOr } from 'ramda';
import { fetchMenuItemsSoldByEmployeeAPI, fetchOrdersCreatedByEmployeeAPI, fetchOrdersServedByEmployeeAPI } from 'api/reports/employees';

interface Employees {
}

interface EmployeesState {
  employees: any;
  employee: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  success: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  employeeServedOrders: any[];
  employeeCreatedOrders: any[];
  employeeMenuItemsSold: any[];
  employeeCreatedOrdersLoading: boolean;
  employeeServedOrdersLoading: boolean;
  employeeMenuItemsSoldLoading: boolean;
}

const initialState: EmployeesState = {
  employees: [],
  employee: null,
  status: 'idle',
  error: null,
  success: null,
  isLoading: false,
  isSubmitting: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  employeeServedOrders: [],
  employeeCreatedOrders: [],
  employeeMenuItemsSold: [],
  employeeCreatedOrdersLoading: false,
  employeeServedOrdersLoading: false,
  employeeMenuItemsSoldLoading: false,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    fetchEmployeesSuccess: (state, action: PayloadAction<Employees[]>) => {
      state.status = 'succeeded';
    },
    fetchEmployeesFailure: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchEmployeeByIdSuccess: (state, action) => {
      state.status = 'succeeded';
    },
    fetchEmployeeByIdFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    clearSuccessAndError: (state) => {
      state.error = null;
      state.success = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployeesByLocationAPI.fulfilled, (state, action) => {
      state.employees = pathOr([], ['employees'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEmployeesByBusinessAPI.fulfilled, (state, action) => {
      state.employees = pathOr([], ['employees'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeesByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchOrdersCreatedByEmployeeAPI.fulfilled, (state, action) => {
      state.employeeCreatedOrders = pathOr([], ['orders'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.employeeCreatedOrdersLoading = false;
    });
    builder.addCase(fetchOrdersCreatedByEmployeeAPI.rejected, (state, action) => {
      state.employeeCreatedOrdersLoading = false;
    });
    builder.addCase(fetchOrdersCreatedByEmployeeAPI.pending, (state, action) => {
      state.employeeCreatedOrdersLoading = true;
    });

    builder.addCase(fetchOrdersServedByEmployeeAPI.fulfilled, (state, action) => {
      state.employeeServedOrders = pathOr([], ['orders'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.employeeServedOrdersLoading = false;
    });
    builder.addCase(fetchOrdersServedByEmployeeAPI.rejected, (state, action) => {
      state.employeeServedOrdersLoading = false;
    });
    builder.addCase(fetchOrdersServedByEmployeeAPI.pending, (state, action) => {
      state.employeeServedOrdersLoading = true;
    });

    builder.addCase(fetchMenuItemsSoldByEmployeeAPI.fulfilled, (state, action) => {
      state.employeeMenuItemsSold = pathOr([], ['menuItems'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.employeeMenuItemsSoldLoading = false;
    });
    builder.addCase(fetchMenuItemsSoldByEmployeeAPI.rejected, (state, action) => {
      state.employeeMenuItemsSoldLoading = false;
    });
    builder.addCase(fetchMenuItemsSoldByEmployeeAPI.pending, (state, action) => {
      state.employeeMenuItemsSoldLoading = true;
    });

    builder.addCase(createEmployeeAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.success = pathOr('', ['message'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(createEmployeeAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createEmployeeAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmployeeAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.success = pathOr('', ['message'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateEmployeeAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateEmployeeAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchEmployeeAPI.fulfilled, (state, action) => {
      state.employee = pathOr(null, ['employee'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeeAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchEmployeeAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveEmployeeAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.isLoading = false;
    });
    builder.addCase(archiveEmployeeAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveEmployeeAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});


export const { 
  fetchEmployeesSuccess,
  setSubmitting,
  fetchEmployeeByIdSuccess,
  fetchEmployeeByIdFailure,
  fetchEmployeesFailure,
  setLoading,
  setCurrentPage,
  setTotalPerPage,
  clearSuccessAndError,
 } = employeesSlice.actions;

export default employeesSlice.reducer;
