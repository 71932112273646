import React from 'react';
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Spin } from 'antd';
import { equals } from "ramda";

interface CustomButtonProps {
  type?: 'primary' | 'default' | 'dashed' | 'link' | 'text';
  label: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => Promise<void> | void;
  submitting?: boolean;
}

export const CustomButton: React.FC<CustomButtonProps> = ({ type, label, onClick, disabled, submitting }) => {

  return (
    <Button
      className={`basis-9 h-9 ${type === 'primary' ? 'bg-blue-750' : 'bg-white'}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      icon={equals(type, 'primary') && <CheckCircleOutlined />}
    >
      {submitting ? (
        <Spin size="small" style={{ marginRight: '8px' }} />
      ) : null}
      {label}
    </Button>
  );
};
