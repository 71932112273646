import { createSlice } from "@reduxjs/toolkit";
import { pathOr } from "ramda";
import {
  fetchExpensesReportByBusinessAPI,
  fetchExpensesReportByLocationAPI,
  fetchExpensesReportByRestaurantAPI,
} from "../../api/reports/expenses";

interface ExpensesReportsState {
  expensesReport: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  expensesGraphData: number[];
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: ExpensesReportsState = {
  expensesReport: [],
  status: "idle",
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  expensesGraphData: [],
  filters: {
    fromDate: '',
    toDate: ''
  },
};

const expensesReportsSlice = createSlice({
  name: "expenses-reports",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExpensesReportByLocationAPI.fulfilled, (state, action) => {
      state.expensesReport = pathOr([], ["expensesReport"], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(30, ["meta", "perPage"], action.payload);
      state.expensesGraphData = state.expensesReport.map((expense) => pathOr(0, ['amountPaid'], expense));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpensesReportByRestaurantAPI.fulfilled, (state, action) => {
      state.expensesReport = pathOr([], ["expensesReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpensesReportByBusinessAPI.fulfilled, (state, action) => {
      state.expensesReport = pathOr([], ["expensesReport"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = expensesReportsSlice.actions;

export default expensesReportsSlice.reducer;
