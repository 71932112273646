import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Name'),
  quantityInStock: Joi.number().positive().required().label('Quantity in stock'),
  unitOfMeasure: Joi.string().trim().required().label('Unit of measure'),
  costPrice: Joi.number().positive().required().label('Cost price'),
  sku: Joi.string().trim().optional().label('SKU'),
  barcode: Joi.string().trim().optional().label('Barcode'),
  supplierId: Joi.string().trim().optional().label('Supplier'),
  categoryId: Joi.string().trim().optional().label('Category'),
  description: Joi.string().trim().optional().label('Description'),
  isTrackable: Joi.boolean().optional().label('Is trackable'),
  isPerishable: Joi.boolean().optional().label('Is perishable'),
  expiryDate: Joi.date().optional().label('Expiry date'),
  imageURL: Joi.string().uri().optional().label('Image URL'),
  dimensions: Joi.string().optional().label('Dimensions'),
  sellingPrice: Joi.number().positive().allow(0).optional().label('Selling price'),
  manufactureDate: Joi.date().optional().label('Manufacture date'),
  reOrderLevel: Joi.number().positive().allow(0).optional().label('Re-order level'),
  productMeasurements: Joi.array().items(
     Joi.object({
       id: Joi.any().optional().label('Id'),
       quantityToMakeTrackableUnit: Joi.number().required().positive().label('Quantity to make trackable unit'),
       unitOfMeasure: Joi.string().trim().required().label('Unit of measure'),
     })
  ).optional().label('Product measurements'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
