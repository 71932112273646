import React, { FC, useEffect, useState } from "react";
import {Button, InputNumber, message, Select, Space} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import {pathOr, propOr, map, uniq, pipe, propEq, find, head, toLower} from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import {
  addMenuItemIngredient,
  setMenuItemIngredient,
  updateMenuItemIngredient
} from "../../../../store/menus/menu-items";
import {
  addMenuModifierItemIngredient,
  setMenuModifierItemIngredient, updateMenuModifierItemIngredient
} from "../../../../store/menus/menu-modifier-items";

interface IngredientModalProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
  ingredient: any;
  type: 'menuItem' | 'menuModifierItem';
}

const IngredientModal: FC<IngredientModalProps> = ({
 isOpen,
   type,
   ingredient,
 handleClose,
 }) => {
  const dispatch = useAppDispatch();
  const inventory = useAppSelector((state: RootState) => state.inventory.inventory);
  const [formValues, setFormValues] = useState<any>({
    productId: "",
    quantity: 1,
    unitOfMeasure: "",
  });
  const options = pipe(
     find(propEq(pathOr("", ["productId"], formValues), "id")) as any,
     propOr([], "productMeasurements"),
     map(propOr("", "unitOfMeasure")),
     uniq,
     map((unitOfMeasure) => ({ value: unitOfMeasure, label: unitOfMeasure })),
  )(inventory);

  const ingredientId = pathOr("", ["id"], ingredient);

  const handleInputChange = (val: any, name: string) => {
    if (name === 'productId') {
      setFormValues({
        ...formValues,
        [name]: val,
        product: head(inventory.filter((inv: any) => pathOr('', ['id'], inv) === val))
      })
    } else {
      setFormValues({
        ...formValues,
        [name]: val
      })
    }

  };

  const onFinish = async () => {
    if (!formValues.productId || !formValues.quantity || !formValues.unitOfMeasure) {
      message.error('please provide the required fields to add ingredient')
      return
    }
    if (type === 'menuItem') {
      if (ingredientId) {
        dispatch(updateMenuItemIngredient(formValues));
      } else {
        dispatch(addMenuItemIngredient(formValues));
      }
    } else {
      if (ingredientId) {
        dispatch(updateMenuModifierItemIngredient(formValues))
      } else {
        dispatch(addMenuModifierItemIngredient(formValues));
      }
    }
    clearAndClose();
  };

  const clearAndClose = () => {
    setFormValues({
      productId: "",
      quantity: 1,
      unitOfMeasure: "",
    })
    dispatch(setMenuItemIngredient(null));
    dispatch(setMenuModifierItemIngredient(null));
    handleClose();
  }

  useEffect(() => {
    if (ingredientId) {
      setFormValues({
        id: pathOr('', ['id'], ingredient),
        createdAt: pathOr('', ['createdAt'], ingredient),
        productId: pathOr('', ["productId"], ingredient),
        quantity: pathOr(0, ["quantity"], ingredient),
        unitOfMeasure: pathOr('', ["unitOfMeasure"], ingredient),
      });
    }
  }, [ingredient, ingredientId]);

  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">
           {ingredientId ? "Edit ingredient" : "Add ingredient"}
         </h3>

         <div className="flex flex-col space-y-4">
           <div>
             <span>Ingredient<span className="text-red-500">*</span></span>
             <Select
              showSearch
              className="w-full"
              optionFilterProp="children"
              value={pathOr('', ['productId'], formValues)}
              options={inventory.map((product: any) => ({
                value: pathOr('', ['id'], product),
                label: pathOr('', ['name'], product),
              }))}
              onChange={(val) => handleInputChange(val, 'productId')}
              filterOption={(input, option) => toLower(
                pathOr('', ['label'], option)).includes(toLower(input))}
              filterSort={(optionA, optionB) =>
                toLower(pathOr('', ['label'], optionA)).localeCompare(
                  toLower(pathOr('', ['label'], optionB)))
              }
             />
           </div>

           <div>
             <span>Quantity<span className="text-red-500">*</span></span>
             <InputNumber
                className="w-full"
                value={pathOr(1, ['quantity'], formValues)}
                required
                onChange={(val) => handleInputChange(Number(val), 'quantity')}
             />
           </div>

           <div>
             <span>Unit Of Measure<span className="text-red-500">*</span></span>
             <Select
                className="w-full"
                value={pathOr('', ['unitOfMeasure'], formValues)}
                options={options}
                onChange={(val) => handleInputChange(val, 'unitOfMeasure')}
             />
           </div>

           <div>
             <Space>
               <Button
                type="primary"
                onClick={onFinish}
                className="bg-blue-750"
               >
                 {ingredientId ? "Update Ingredient" : "Add Ingredient"}
               </Button>
               <Button type="default" onClick={clearAndClose}>
                 Cancel
               </Button>
             </Space>
           </div>
         </div>
       </div>
     </ParentModal>
  );
};

export default IngredientModal;
