import React from 'react';
import { Select, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { paymentMethods, diningOptions } from '../../../../constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { pathOr } from 'ramda';
import { fetchOrdersByLocationAPI } from 'api/orders';
import { RootState } from 'store';
import { updateFilters } from 'store/orders/orders';

interface FiltersProps {
  visible: boolean;
}

const Filters: React.FC<FiltersProps> = ({ visible }) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state: RootState) => state.orders.currentPage);
  const totalPerPage = useAppSelector((state: RootState) => state.orders.totalPerPage);
  const filters = useAppSelector((state: RootState) => state.orders.filters);
  const employees = useAppSelector((state: any) => state.employees.employees);
  const orderStages = useAppSelector((state: any) => state.orders.orderStages);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateFilters({ 
      keyword: e.target.value
    }));
  };
  
  const handleFilterChange = (value: string | number, field: string) => {
    dispatch(updateFilters({ 
      [field]: value
    }));
  };

  if (!visible) {
    return null;
  }

  const onFilter = async() => {
    await dispatch(fetchOrdersByLocationAPI({ 
      ...filters,
      page: currentPage,
      perPage: totalPerPage,
    }));
  }

  const onResetFilters = () => {
    dispatch(updateFilters({
      type: '',
      paymentMethod: '',
      paymentStatus: '',
      serverId: '',
      stage: '',
      keyword: ''
    }))
    dispatch(fetchOrdersByLocationAPI({ 
      page: currentPage,
      perPage: totalPerPage,
      ...filters,
      type: '',
      paymentMethod: '',
      paymentStatus: '',
      serverId: '',
      stage: '',
      keyword: ''
    }));
  }
  return (
    <div className="flex gap-4 flex-wrap items-end mb-6">
       <div className="flex-col">
        <span className="block mb-1">Search</span>
        <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={filters.keyword}
            onChange={onSearchChange}
            placeholder="Search by order number..."
          />
       </div>
      <div className="flex-col">
        <span className="block mb-1">Payment Status</span>
        <Select
          defaultValue="Payment Status"
          value={filters.paymentStatus}
          style={{ width: 150 }}
          className="h-10"
          onChange={(value) => handleFilterChange(value, 'paymentStatus')}
          options={['', 'Paid', 'Unpaid'].map((status) => {
            if (!status) {
              return {
                label: 'None',
                value: '',
              }
            }
            return {
              label: status,
              value: status,
            }
          })}
        />
      </div>
      
      <div className="flex-col">
        <span className="block mb-1">Payment Method</span>
        <Select
          defaultValue="Payment Method"
          style={{ width: 150 }}
          className="h-10"
          value={filters.paymentMethod}
          onChange={(value) => handleFilterChange(value, 'paymentMethod')} 
          options={paymentMethods}
        />
      </div>

      <div>
        <span className="block mb-1">Server</span>
        <Select
          defaultValue="Server"
          style={{ width: 150 }}
          className="h-10"
          value={filters.serverId}
          onChange={(value) => handleFilterChange(value, 'serverId')}
          options={['', ...employees].map((employee: any) => {
            if (!employee) {
              return {
                label: 'None',
                value: ''
              }
            }
            return {
              label: `${pathOr('', ['user', 'profile', 'firstname'], employee)}
              ${pathOr('', ['user', 'profile', 'lastname'], employee)}`,
              value: pathOr('', ['userId'], employee)
            }
          })} 
        />
      </div>
      
      <div>
        <span className="block mb-1">Stage</span>
        <Select
          defaultValue="Stage"
          style={{ width: 150 }}
          className="h-10"
          value={filters.stage}
          onChange={(value) => handleFilterChange(value, 'stage')}
          options={['', ...orderStages].map((orderStage: {name: string; value: string}) => {
            if (!orderStage) {
              return {
                label: 'None',
                value: ''
              }
            }
            return {
              label: pathOr('', ['name'], orderStage),
              value: pathOr('', ['value'], orderStage)
            }
          })}
        />
      </div>

      <div>
        <span className="block mb-1">Dining Option</span>
        <Select
          defaultValue="Dining Option"
          style={{ width: 150 }}
          className="h-10"
          value={filters.type}
          onChange={(value) => handleFilterChange(value, 'type')}
          options={diningOptions}
        />
      </div>

      <Button onClick={onResetFilters} className="h-10">
        Reset Filters
      </Button>
  
      <Button onClick={onFilter} className="bg-blue-750 h-10" type="primary" icon={<SearchOutlined />}>
        Search
      </Button>
    </div>
  );
};

export default Filters;
