import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Name'),
  phoneNumber: Joi.string().trim().required().label('PhoneNumber'),
  email: Joi.string().trim().required().label('Email'),
  locations: Joi.array().items(
    Joi.string()
 ).required().label('Locations'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
