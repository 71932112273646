import {ApiResponse, client, getAuthorizationHeader} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const fetchCurrentUserAPI = createAsyncThunk(
   'auth/current-user',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/users/current`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to current user`);
     }
   }
)

export const updateUserDetailsApi = async (userProfileInfo: any): Promise<ApiResponse> =>{
  try {
    const response = await client(`/users`, {
      method: "PUT",
      headers: getAuthorizationHeader(),
      data: userProfileInfo
    });

    return response.data;
  } catch (error: any) {
    throw new Error(`Error during updating user: ${error.message}`);
  }
}
