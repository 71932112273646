import React, { useState, useEffect } from "react";
import { Tabs, Button } from "antd";
import type { TabsProps } from "antd/lib";
import {PlusOutlined, DeleteOutlined, EditOutlined, FolderOpenOutlined} from "@ant-design/icons";
import TableDisplay from "./components/table-display";
import FloorForm from "./components/floor-form";
import TableForm from "./components/table-form";
import {archiveFloorAPI, deleteFloorAPI, fetchFloorsByLocationAPI} from "../../../api/floors";
import {head, pathOr} from "ramda";
import DeleteModal from "components/modal/DeleteModal";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {setFloor, setTable} from "../../../store/floors/floors";
import withPermissions from "components/hoc";
import ProtectedWrapper from "components/protected-wrapper";
import { 
  CAN_ARCHIVE_FLOOR_AND_TABLE,
  CAN_CREATE_FLOOR_AND_TABLE,
  CAN_DELETE_FLOOR_AND_TABLE,
  CAN_UPDATE_FLOOR_AND_TABLE
 } from "permissions";

const Floors = () => {
  const dispatch = useAppDispatch();
  const floors = useAppSelector((state: any) => state.floors.floors);
  const floor = useAppSelector((state: any) => state.floors.floor);
  const [currentTab, setCurrentTab] = useState('');
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    dispatch(fetchFloorsByLocationAPI());
  }, [dispatch]);

  const handleEditFloor = () => {
    setMode("edit");
    setModalState({ isOpen: true, type: "FloorForm" });
  };

  const handleCreateFloor = () => {
    setMode("create");
    setModalState({ isOpen: true, type: "FloorForm" });
  };

  const handleDeleteFloor = () => {
    setModalState({ isOpen: true, type: "deleteModal" });
  };

  const handleArchiveFloor = () => {
    setModalState({ isOpen: true, type: "archiveModal" });
  }

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };

  const handleCreateTable = () => {
    setMode("create");
    setModalState({ isOpen: true, type: "TableForm" });
  };
  const handleEditTable = (tab: any) => {
    setMode("edit");
    dispatch(setTable(tab));
    setModalState({ isOpen: true, type: "TableForm" });
  };

  const onDeleteFloor = async () => {
    await dispatch(deleteFloorAPI({ floorId: pathOr('', ['id'], floor) }))
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchFloorsByLocationAPI());
    handleClose()
  };

  const onArchiveFloor = async () => {
    await dispatch(archiveFloorAPI({ floorId: pathOr('', ['id'], floor) }))
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchFloorsByLocationAPI());
    handleClose()
  };

  const onChangeTab = (key: string) => {
    setCurrentTab(key);
    dispatch(setFloor(
       head(floors.filter((floor: any) => pathOr(null, ['id'], floor) === key))));
  };

  const tabsData: TabsProps["items"] = pathOr([], [], floors).map((floor) => {
    return {
      key: pathOr('', ['id'], floor),
      label: <span className="capitalize">{pathOr("", ["name"], floor)}</span>,
      children: (
        <div key={pathOr('', ['id'], floor)} className="flex flex-wrap justify-center">
          {pathOr([], ['tables'], floor).map((table: any) => (
            <TableDisplay
              key={pathOr('', ['id'], table)}
              {...table}
              onClick={() => handleEditTable(table)}
            />
          ))}
        </div>
      ),
    };
  });
  return (
    <div className="h-full overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Floor Management</p>
          <p className="text-sm text-gray-750">Management table layout.</p>
        </div>
        <div>
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_FLOOR_AND_TABLE]}>
            <Button
              onClick={() => handleCreateFloor()}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Add Floor
            </Button>
          </ProtectedWrapper>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="">
          <div className="p-4 flex gap-4 justify-end flex-wrap">
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_FLOOR_AND_TABLE]}>
              <Button icon={<PlusOutlined />} onClick={() => handleCreateTable()}>
                Add Table
              </Button>
            </ProtectedWrapper>
            
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_FLOOR_AND_TABLE]}>
              <Button icon={<EditOutlined />} onClick={() => handleEditFloor()}>
                Edit Floor
              </Button>
            </ProtectedWrapper>

            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_ARCHIVE_FLOOR_AND_TABLE]}>
              <Button
                icon={<FolderOpenOutlined />}
                onClick={handleArchiveFloor}>
                Archive Floor
              </Button>
            </ProtectedWrapper>
           
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_DELETE_FLOOR_AND_TABLE]}>
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={handleDeleteFloor}>
                Delete Floor
              </Button>
            </ProtectedWrapper>
          </div>
          <div className="grow">
            <Tabs defaultActiveKey={"1"} items={tabsData} onChange={onChangeTab} />
          </div>
        </div>
      </div>
      <TableForm
        isOpen={modalState.isOpen && modalState.type === "TableForm"}
        handleClose={handleClose}
        mode={mode}
      />
      <FloorForm
        isOpen={modalState.isOpen && modalState.type === "FloorForm"}
        handleClose={handleClose}
        mode={mode}
      />
      <DeleteModal
        isOpen={modalState.isOpen && modalState.type === "deleteModal"}
        handleClose={handleClose}
        cancelDelete={handleClose}
        confirmDelete={onDeleteFloor}
        title={`Delete floor`}
        prompt={`Are you sure you want to delete ${pathOr("", ["name"], floor)}`}
      />
      <DeleteModal
         isOpen={modalState.isOpen && modalState.type === "archiveModal"}
         handleClose={handleClose}
         cancelDelete={handleClose}
         confirmDelete={onArchiveFloor}
         title={`Archive floor`}
         deletePrompt="Archive"
         prompt={`Are you sure you want to archive ${pathOr("", ["name"], floor)}`}
      />
    </div>
  );
};

export default withPermissions(Floors);
