import { Button, Input, message } from "antd";
import { pathOr } from "ramda";
import { RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import RegisterSessionForm from "./register-session-form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createRegisterAPI, updateRegisterAPI } from "api/registers/registers";
import { getCookie } from "helpers/utilities";
import { updateRegisterSessionAPI } from "api/registers/register-sessions";

const RegisterDetails = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  
  const dispatch = useAppDispatch();
  const register = useAppSelector((state: RootState) => state.registers.register);
  const isLoading = useAppSelector((state: RootState) => state.registers.isLoading);
  const sessions = useAppSelector((state: RootState) => state.registers.registerSessions);
  const sessionsLoading =  useAppSelector((state: RootState) => state.registers.registerSessionsLoading);
  const [modalState, setModalState] = useState({ 
    isOpen: false,
    type: null
  });

  const closeRegister = async () => {
    const session = pathOr(null, [0], sessions);

    if (!session || pathOr(null, ['closedAt'], session)) {
      message.error('register must be open in order to be closed');
      return;
    }

    await dispatch(updateRegisterSessionAPI({ 
      sessionId: pathOr('', ['id'], session),
      session: {
        closedAt: new Date(),
      }
    }));
  }

  useEffect(() => {
    if (pathOr(null, ['name'], register)) {
      setName(pathOr('', ['name'], register));
    }
  }, [register])

  const closeModal = () => {
    setModalState({ isOpen: false, type: null });
  };

  const onFinish = async () => {
    if (!name) {
      message.error('please provide a register name');
      return;
    }

    const payload = {
      name,
    }

    if (id) {
      await dispatch(updateRegisterAPI({ register: payload, registerId: id }));
    } else {
      await dispatch(createRegisterAPI({ register: {
        name,
        locationId: getCookie('currentLocation'),
        restaurantId: getCookie('currentRestaurant'),
      } }));
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-end">
        <div className="p-2">
          <Button
            onClick={onFinish}
            loading={isLoading}
            type="primary"
            className="bg-blue-750 w-[150px]">
            {pathOr(null, ['id'], register) ? "Update register" : "Create register"}
          </Button>
        </div>
        {pathOr(null, ['status'], register) === 'CLOSED' ? (
          <div className="p-2">
            <Button
              onClick={() => setModalState({ isOpen: true, type: 'create' })}
              className="w-[150px]">
              Open register
            </Button>
          </div>
        ) : pathOr(null, ['status'], register) === 'OPENED'
         || pathOr(null, ['status'], register) === 'SUSPENDED' ? (
          <div className="p-2">
            <Button
              onClick={closeRegister}
              loading={sessionsLoading}
              className="w-[150px]">
              Close register
            </Button>
          </div>
        ) : null}
      </div>
      <div className="mt-5 flex flex-col space-y-4">
        <div className="w-full">
          <span>
            Name<span className="text-red-500">*</span>
          </span>
          <Input
            name="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <RegisterSessionForm
        isOpen={modalState.isOpen}
        handleClose={closeModal}
      />
    </div>
  )
}

export default RegisterDetails;