import { getCookie, getTimeframeDates, removeUndefinedOrNull } from "helpers/utilities";
import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { length, pathOr } from "ramda";
import { pickFields } from "utils/is-not-empty";

export const bulkOrderUpdateAPI = createAsyncThunk(
  'orders/bulk-order-update',
  async (
    payload: {
      stage?: string;
      isCanceled?: boolean;
      serverId?: string;
      registerId?: string;
      orders: React.Key[];
    },
    thunkAPI
  ) => {
    try {
      const selectedOrders = pathOr(
        [],
        ['orders', 'orders'],
        thunkAPI.getState()
      )
        .filter((order) => payload.orders.includes(pathOr('', ['id'], order)))
        .map((order) => {
          return removeUndefinedOrNull(
            pickFields(order, ['id', 'locationId', 'restaurantId', 'stage'])
          );
        });
      if (length(selectedOrders) < 1) {
        throw new Error('please select orders');
      }
      const response = await client({
        url: `/bulk-update-orders`,
        method: 'PUT',
        headers: getAuthorizationHeader(),
        data: {
          ...payload,
          orders: selectedOrders,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  }
);

export const fetchOrdersByRestaurantAPI = createAsyncThunk(
  "orders/fetch-orders-by-restaurant",
  async (_, thunkAPI) => {
    try {
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/orders`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchOrdersByLocationAPI = createAsyncThunk(
  "orders/fetch-orders-by-location",
  async (payload: { 
    refresh?: boolean; 
    page?: number; 
    perPage?: number;
    type?: string,
    paymentMethod?: string,
    paymentStatus?: any,
    serverId?: string,
    stage?: string,
    fromDate?: string,
    toDate?: string,
    keyword?: string,
  }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      if (payload.keyword) {
        params.append("keyword", payload.keyword);
      }
      if (payload.type) {
        params.append("type", payload.type);
      }
      if (payload.paymentMethod) {
        params.append("paymentMethod", payload.paymentMethod);
      }
      if (payload.paymentStatus === 'Paid' || payload.paymentStatus === 'Unpaid') {
        params.append("isPaid", payload.paymentStatus === 'Paid' ? 'true' : 'false');
      }
      if (payload.serverId) {
        params.append("serverId", payload.serverId);
      }
      if (payload.stage) {
        params.append("stage", payload.stage);
      }
      const { fromDate, toDate } = getTimeframeDates('this_month');
      params.append("fromDate", payload.fromDate ? payload.fromDate : fromDate);
      params.append("toDate", payload.toDate ? payload.toDate : toDate);
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/orders?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createOrderAPI = createAsyncThunk(
  "orders/create-order",
  async (payload: { navigationCallback: any; order: any }, thunkAPI) => {
    try {
      const response = await client(`/orders`, {
        method: "POST",
        data: {
          ...payload.order,
        },
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createOrderPaymentAPI = createAsyncThunk(
  "orders/create-order-payment",
  async (payload: { orderPayment: any; orderId: string }, thunkAPI) => {
    try {
      const response = await client(`/orders/:orderId/payment`, {
        method: "POST",
        data: {
          ...payload.orderPayment,
        },
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateOrderAPI = createAsyncThunk(
  "orders/update-order",
  async (payload: { navigationCallback: any; order: any; id: string }, thunkAPI) => {
    try {
      const response = await client(`/orders/${payload.id}`, {
        method: "PUT",
        data: {
          ...payload.order,
        },
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchOrderByIdAPI = createAsyncThunk(
  "orders/fetch-order-by-id",
  async ({ orderId }: { orderId: string }, thunkAPI) => {
    try {
      const response = await client(`/orders/${orderId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
