import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { pathOr } from 'ramda';
import {
  createPermissionSetAPI,
  deletePermissionSetAPI,
  fetchPermissionSetAPI, fetchPermissionSetsByBusinessAPI,
  fetchPermissionSetsByLocationAPI,
  updatePermissionSetAPI
} from "../../api/permissions";
import {PERMISSION_SETS_ROUTE} from "../../constants";
import { 
  CAN_ACCESS_DASHBOARD,
  CAN_ACCESS_POINT_OF_SALE,
  CAN_ARCHIVE_CATEGORY,
  CAN_ARCHIVE_EMPLOYEE,
  CAN_ARCHIVE_FLOOR_AND_TABLE, 
  CAN_ARCHIVE_INVENTORY,
  CAN_ARCHIVE_LOCATION,
  CAN_ARCHIVE_MENU,
  CAN_ARCHIVE_RESTAURANT,
  CAN_ARCHIVE_SUPPLIER,
  CAN_CREATE_CATEGORY,
  CAN_CREATE_EMPLOYEE,
  CAN_CREATE_EXPENSE,
  CAN_CREATE_FLOOR_AND_TABLE,
  CAN_CREATE_INVENTORY,
  CAN_CREATE_LOCATION, CAN_CREATE_MENU, CAN_CREATE_ORDER, 
  CAN_CREATE_PERMISSION_SET, CAN_CREATE_PURCHASE, CAN_CREATE_RESERVATION, CAN_CREATE_RESTAURANT, 
  CAN_CREATE_SUPPLIER, CAN_DELETE_CATEGORY, CAN_DELETE_EXPENSE, CAN_DELETE_FLOOR_AND_TABLE, CAN_DELETE_INVENTORY, 
  CAN_DELETE_LOCATION, CAN_DELETE_MENU, CAN_DELETE_PERMISSION_SET, CAN_DELETE_PURCHASE, 
  CAN_DELETE_RESTAURANT, CAN_DELETE_SUPPLIER, CAN_REFUND_ORDER, CAN_PAY_ORDER, 
  CAN_PAY_RESERVATION, 
  CAN_REFUND_RESERVATION, 
  CAN_UN_ARCHIVE_CATEGORY, 
  CAN_UN_ARCHIVE_EMPLOYEE, CAN_UN_ARCHIVE_FLOOR_AND_TABLE, CAN_UN_ARCHIVE_INVENTORY, 
  CAN_UN_ARCHIVE_LOCATION, CAN_UN_ARCHIVE_MENU, 
  CAN_UN_ARCHIVE_RESTAURANT, CAN_UN_ARCHIVE_SUPPLIER, CAN_UPDATE_CATEGORY, CAN_UPDATE_EMPLOYEE, 
  CAN_UPDATE_EXPENSE, CAN_UPDATE_FLOOR_AND_TABLE, CAN_UPDATE_INVENTORY, 
  CAN_UPDATE_LOCATION, CAN_UPDATE_MENU, CAN_UPDATE_ORDER, CAN_UPDATE_PERMISSION_SET, 
  CAN_UPDATE_PURCHASE, CAN_UPDATE_RESERVATION, CAN_UPDATE_RESTAURANT, CAN_UPDATE_SUPPLIER, CAN_VIEW_BUSINESS, 
  CAN_VIEW_CATEGORIES, 
  CAN_VIEW_CUSTOMER_REPORT, CAN_VIEW_DASHBOARD, CAN_VIEW_EMPLOYEE_REPORT, 
  CAN_VIEW_EMPLOYEES, CAN_VIEW_EMPLOYEES_CREATED_ORDERS, CAN_VIEW_EMPLOYEES_MENU_ITEMS_SOLD, 
  CAN_VIEW_EMPLOYEES_SERVED_ORDERS, CAN_VIEW_CLOSE_OF_DAY_REPORT, CAN_VIEW_EXPENSES, 
  CAN_VIEW_EXPENSES_REPORT, CAN_VIEW_FLOORS_AND_TABLES, CAN_VIEW_INVENTORY, 
  CAN_VIEW_INVENTORY_OVERTIME_REPORT, CAN_VIEW_INVENTORY_REPORT, CAN_VIEW_LOCATION_STATS_REPORT, 
  CAN_VIEW_LOCATIONS, CAN_VIEW_MENU_ITEMS_REPORT, CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT, 
  CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT, CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT, 
  CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT, CAN_VIEW_MENUS, CAN_VIEW_ORDERS,
  CAN_VIEW_ORDERS_REPORT, CAN_VIEW_PERMISSION_SETS, CAN_VIEW_PURCHASES, 
  CAN_VIEW_PURCHASES_REPORT, CAN_VIEW_RESERVATIONS, CAN_VIEW_RESTAURANTS, CAN_VIEW_SALES_REPORT, CAN_VIEW_SUPPLIERS
 } from 'permissions';

interface PermissionState {
  permissionSets: any[];
  permission: any;
  permissionSet: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: PermissionState = {
  permissionSets: [],
  permission: null,
  status: 'idle',
  permissionSet: {
    permissions: {
      [CAN_VIEW_BUSINESS]: false,
      [CAN_ACCESS_DASHBOARD]: false,
      [CAN_VIEW_DASHBOARD]: false,
      [CAN_ACCESS_POINT_OF_SALE]: false,
      [CAN_VIEW_CATEGORIES]: false,
      [CAN_CREATE_CATEGORY]: false,
      [CAN_UPDATE_CATEGORY]: false,
      [CAN_DELETE_CATEGORY]: false,
      [CAN_ARCHIVE_CATEGORY]: false,
      [CAN_UN_ARCHIVE_CATEGORY]: false,
      [CAN_VIEW_INVENTORY]: false,
      [CAN_CREATE_INVENTORY]: false,
      [CAN_UPDATE_INVENTORY]: false,
      [CAN_DELETE_INVENTORY]: false,
      [CAN_ARCHIVE_INVENTORY]: false,
      [CAN_UN_ARCHIVE_INVENTORY]: false,
      [CAN_VIEW_SUPPLIERS]: false,
      [CAN_CREATE_SUPPLIER]: false,
      [CAN_UPDATE_SUPPLIER]: false,
      [CAN_DELETE_SUPPLIER]: false,
      [CAN_ARCHIVE_SUPPLIER]: false,
      [CAN_UN_ARCHIVE_SUPPLIER]: false,
      [CAN_VIEW_MENUS]: false,
      [CAN_CREATE_MENU]: false,
      [CAN_UPDATE_MENU]: false,
      [CAN_DELETE_MENU]: false,
      [CAN_ARCHIVE_MENU]: false,
      [CAN_UN_ARCHIVE_MENU]: false,
      [CAN_VIEW_ORDERS]: false,
      [CAN_CREATE_ORDER]: false,
      [CAN_UPDATE_ORDER]: false,
      [CAN_PAY_ORDER]: false,
      [CAN_REFUND_ORDER]: false,
      [CAN_VIEW_RESERVATIONS]: false,
      [CAN_CREATE_RESERVATION]: false,
      [CAN_UPDATE_RESERVATION]: false,
      [CAN_PAY_RESERVATION]: false,
      [CAN_REFUND_RESERVATION]: false,
      [CAN_VIEW_EXPENSES_REPORT]: false,
      [CAN_VIEW_PURCHASES_REPORT]: false,
      [CAN_VIEW_MENU_ITEMS_REPORT]: false,
      [CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT]: false,
      [CAN_VIEW_LOCATION_STATS_REPORT]: false,
      [CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT]: false,
      [CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT]: false,
      [CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT]: false,
      [CAN_VIEW_SALES_REPORT]: false,
      [CAN_VIEW_INVENTORY_OVERTIME_REPORT]: false,
      [CAN_VIEW_ORDERS_REPORT]: false,
      [CAN_VIEW_INVENTORY_REPORT]: false,
      [CAN_VIEW_EMPLOYEE_REPORT]: false,
      [CAN_VIEW_CUSTOMER_REPORT]: false,
      [CAN_VIEW_CLOSE_OF_DAY_REPORT]: false,
      [CAN_VIEW_EMPLOYEES]: false,
      [CAN_CREATE_EMPLOYEE]: false,
      [CAN_UPDATE_EMPLOYEE]: false,
      [CAN_ARCHIVE_EMPLOYEE]: false,
      [CAN_UN_ARCHIVE_EMPLOYEE]: false,
      [CAN_VIEW_EMPLOYEES_SERVED_ORDERS]: false,
      [CAN_VIEW_EMPLOYEES_CREATED_ORDERS]: false,
      [CAN_VIEW_EMPLOYEES_MENU_ITEMS_SOLD]: false,
      [CAN_VIEW_PURCHASES]: false,
      [CAN_CREATE_PURCHASE]: false,
      [CAN_UPDATE_PURCHASE]: false,
      [CAN_DELETE_PURCHASE]: false,
      [CAN_VIEW_EXPENSES]: false,
      [CAN_CREATE_EXPENSE]: false,
      [CAN_UPDATE_EXPENSE]: false,
      [CAN_DELETE_EXPENSE]: false,
      [CAN_VIEW_FLOORS_AND_TABLES]: false,
      [CAN_CREATE_FLOOR_AND_TABLE]: false,
      [CAN_UPDATE_FLOOR_AND_TABLE]: false,
      [CAN_DELETE_FLOOR_AND_TABLE]: false,
      [CAN_ARCHIVE_FLOOR_AND_TABLE]: false,
      [CAN_UN_ARCHIVE_FLOOR_AND_TABLE]: false,
      [CAN_VIEW_RESTAURANTS]: false,
      [CAN_CREATE_RESTAURANT]: false,
      [CAN_UPDATE_RESTAURANT]: false,
      [CAN_DELETE_RESTAURANT]: false,
      [CAN_ARCHIVE_RESTAURANT]: false,
      [CAN_UN_ARCHIVE_RESTAURANT]: false,
      [CAN_VIEW_LOCATIONS]: false,
      [CAN_CREATE_LOCATION]: false,
      [CAN_UPDATE_LOCATION]: false,
      [CAN_DELETE_LOCATION]: false,
      [CAN_ARCHIVE_LOCATION]: false,
      [CAN_UN_ARCHIVE_LOCATION]: false,
      [CAN_VIEW_PERMISSION_SETS]: false,
      [CAN_CREATE_PERMISSION_SET]: false,
      [CAN_UPDATE_PERMISSION_SET]: false,
      [CAN_DELETE_PERMISSION_SET]: false,
    }
  },
  error: null,
  isLoading: false,
  isSubmitting: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    fetchPermissionsSuccess: (state, action: PayloadAction<Permissions[]>) => {
      state.status = 'succeeded';
      state.permissionSets = action.payload;
    },
    fetchPermissionsFailure: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchPermissionByIdSuccess: (state, action) => {
      state.status = 'succeeded';
      state.permission = action.payload;
    },
    fetchPermissionByIdFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updatePermissionSet: (state, action) => {
      state.permissionSet = {
        ...state.permissionSet,
        ...action.payload,
      }
    },
    updatePermissions: (state, action) => {
      state.permissionSet = {
        ...state.permissionSet,
        permissions: {
          ...state.permissionSet.permissions,
          ...action.payload
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createPermissionSetAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.isLoading = false;
      window.location.href = PERMISSION_SETS_ROUTE;
    });
    builder.addCase(createPermissionSetAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createPermissionSetAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePermissionSetAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deletePermissionSetAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deletePermissionSetAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePermissionSetAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(updatePermissionSetAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updatePermissionSetAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPermissionSetAPI.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.permissionSet = pathOr(null, ['permissionSet'], action.payload)
      state.isLoading = false;
    });
    builder.addCase(fetchPermissionSetAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchPermissionSetAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPermissionSetsByBusinessAPI.fulfilled, (state, action) => {
      state.permissionSets = pathOr([], ['permissionSets'], action.payload)
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchPermissionSetsByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchPermissionSetsByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPermissionSetsByLocationAPI.fulfilled, (state, action) => {
      state.permissionSets = pathOr([], [], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchPermissionSetsByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchPermissionSetsByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  }
});

export const {
  fetchPermissionsSuccess,
  setSubmitting,
  fetchPermissionByIdSuccess,
  fetchPermissionByIdFailure,
  fetchPermissionsFailure,
  setLoading,
  setCurrentPage,
  setTotalPerPage,
  updatePermissionSet,
  updatePermissions
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
