import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { pathOr } from "ramda";
import {
  archiveMenuGroupAPI,
  createMenuGroupAPI,
  deleteMenuGroupAPI,
  fetchMenuGroupAPI,
  fetchMenuGroupsByLocationAPI,
  fetchMenuGroupsByMenuAPI,
  fetchMenuGroupsByRestaurantAPI,
  updateMenuGroupAPI,
} from "../../api/menu-groups";

interface MenuGroupsState {
  menuGroups: any;
  menuGroup: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  ingredient: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: MenuGroupsState = {
  menuGroups: [],
  menuGroup: null,
  status: "idle",
  error: null,
  isLoading: false,
  isSubmitting: false,
  ingredient: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const menuGroupsSlice = createSlice({
  name: "menuGroups",
  initialState,
  reducers: {
    setMenuGroup: (state, action) => {
      state.menuGroup = action.payload;
    },
    updateMenuGroup: (state, action) => {
      state.menuGroup = {
        ...state.menuGroup,
        ...action.payload
      };
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenuGroupsByRestaurantAPI.fulfilled, (state, action) => {
      state.menuGroups = pathOr([], ["menuGroups"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupsByRestaurantAPI.rejected, (state, action) => {
      state.menuGroups = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupsByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuGroupsByLocationAPI.fulfilled, (state, action) => {
      state.menuGroups = pathOr([], ["menuGroups"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupsByLocationAPI.rejected, (state, action) => {
      state.menuGroups = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupsByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuGroupsByMenuAPI.fulfilled, (state, action) => {
      state.menuGroups = pathOr([], ["menuGroups"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupsByMenuAPI.rejected, (state, action) => {
      state.menuGroups = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupsByMenuAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuGroupAPI.fulfilled, (state, action) => {
      state.menuGroup = pathOr(null, ["menuGroup"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupAPI.rejected, (state, action) => {
      state.menuGroup = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchMenuGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createMenuGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuGroup = pathOr(null, ["menuGroup"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(createMenuGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createMenuGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateMenuGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.menuGroup = pathOr(null, ["menuGroup"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updateMenuGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateMenuGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteMenuGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteMenuGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveMenuGroupAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuGroupAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(archiveMenuGroupAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setMenuGroup,
  updateMenuGroup,
  setTotalPerPage,
  setCurrentPage
} = menuGroupsSlice.actions;

export default menuGroupsSlice.reducer;
