import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Image, Upload } from 'antd';
import { head, pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { UploadFile } from "antd/lib";
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMenuItemImageFiles, updateMenuItem } from 'store/menus/menu-items';
import { deleteFile } from 'utils/aws';

const { Dragger } = Upload;

const MenuItemImages = () => {
  const dispatch = useAppDispatch();
  const menuItem = useAppSelector((state: RootState) => state.menuItems.menuItem);
  const menuItemImageFiles = useAppSelector((state: RootState) => state.menuItems.menuItemImageFiles);
  const imageURLs = pathOr([], ['imageURLs'], menuItem);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  useEffect(() => {
    if (menuItem) {
      setImagePreviews(pathOr([], ['imageURLs'], menuItem));
    }
  }, [menuItem])

  const handleBeforeUpload = (file: File): boolean => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        setImagePreviews((prev) => [...prev, e.target.result as string]);
      }
    };
    reader.readAsDataURL(file);
    return false;
  };
  const handleFileChange: UploadProps['onChange'] = ({ fileList }) => {
    dispatch(setMenuItemImageFiles(fileList));
  };
  const removeFile = async (fileIndex: number) => {
    // remove from file
    const files = menuItemImageFiles.filter((_: UploadFile, idx: number) => idx !== fileIndex);
    dispatch(setMenuItemImageFiles(files));

    // remove preview
    const image = head(imagePreviews.filter((_: string, idx: number) => idx === fileIndex));
    if (image.includes('amazonaws')) {
      // delete from amazon s3
      await deleteFile(image);

      // update menu item
      dispatch(
        updateMenuItem({
          imageURLs: imageURLs.filter((_: string, idx: number) => idx !== fileIndex)
        }),
      );
    }

    // reset previews
    const previews = imagePreviews.filter((_: string, idx: number) => idx !== fileIndex);
    setImagePreviews(previews);
  }
  return (
    <div>
      <Dragger
        showUploadList={false}
        multiple={true}
        beforeUpload={handleBeforeUpload}
        fileList={menuItemImageFiles}
        onChange={(info) => handleFileChange(info)}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Lower file sizes are recommended for faster load up speeds for clients on low networks.
        </p>
      </Dragger>
      <div className="mt-5">
        <div className="flex flex-row space-x-2 items-center">
          {imagePreviews.map((preview, fileIndex) => {
            return (
              <div className="relative w-[150px] h-[150px]">
                <Image key={fileIndex} src={preview} className="rounded-md" />
                <div className="absolute top-1 right-1">
                  <div
                    onClick={() => removeFile(fileIndex)}
                    className="cursor-pointer rounded-md 
                    bg-red-500 w-[25px] h-[25px] flex justify-center items-center">
                    <CloseCircleOutlined className="text-white" />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default MenuItemImages;
