import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCookie} from "../helpers/utilities";
import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import { pathOr } from "ramda";

export const fetchMenusByRestaurantAPI = createAsyncThunk(
   'menus/fetch-menu-by-restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/menus`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchMenusByLocationAPI = createAsyncThunk(
   'menus/fetch-menu-by-location',
   async (
      payload: { refresh?: boolean, page?: number, perPage?: number }, thunkAPI
   ) => {
     try {
       const params = new URLSearchParams();
       if (payload.refresh) {
        params.append('refresh', String(pathOr(false, ['refresh'], payload)));
       }
       params.append('page', String(pathOr(1, ['page'], payload)));
       params.append('perPage', String(pathOr(10, ['perPage'], payload)));
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/menus?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const createMenuAPI = createAsyncThunk(
   'menus/create-menu',
   async (
      payload: {menu: any}, thunkAPI
   ) => {
     try {
       const response = await client(`/menus`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: payload.menu,
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const updateMenuAPI = createAsyncThunk(
   'menus/update-menu',
   async (
      payload: {menu: any, menuId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menus/${payload.menuId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: payload.menu,
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchMenuAPI = createAsyncThunk(
   'menus/fetch-menu',
   async (
      payload: {menuId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menus/${payload.menuId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const deleteMenuAPI = createAsyncThunk(
   'menus/delete-menu',
   async (
      payload: {menuId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menus/${payload.menuId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const archiveMenuAPI = createAsyncThunk(
   'menus/archive-menu',
   async (
      payload: {menuId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/menus/${payload.menuId}/archive`, {
         method: 'PATCH',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);
