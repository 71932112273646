import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pathOr } from "ramda";
import {
  fetchRegisterAPI,
  fetchRegistersAPI,
  updateRegisterAPI,
  createRegisterAPI,
} from "../../api/registers/registers";
import { 
  createRegisterSessionAPI,
  fetchRegisterSessionAPI,
  fetchRegisterSessionsAPI,
  updateRegisterSessionAPI
 } from "api/registers/register-sessions";
import { createRegisterTransactionAPI, fetchRegisterTransactionAPI, fetchRegisterTransactionsAPI, updateRegisterTransactionAPI } from "api/registers/register-transactions";

interface RegisterProps {
  registers: any[];
  currentRegister: any;
  isLoading: boolean;
  isSubmitting: boolean;
  register: any;
  successMessage: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  errors: any;
  registerSessions: any[];
  registerSessionsLoading: boolean;
  registerSession: any;
  registerTransactionsLoading: boolean;
  registerTransaction: any;
  registerTransactions: any[];
}

const initialState: RegisterProps = {
  registers: [],
  currentRegister: null,
  register: null,
  isLoading: false,
  isSubmitting: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  errors: null,
  successMessage: null,
  registerSessions: [],
  registerSessionsLoading: false,
  registerSession: null,
  registerTransactionsLoading: false,
  registerTransaction: null,
  registerTransactions: [],
};

const registersSlice = createSlice({
  name: "registers",
  initialState,
  reducers: {
    setRegister: (state, action: PayloadAction<any>) => {
      state.register = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    updateRegister: (state, action) => {
      state.register = {
        ...state.register,
        ...action.payload,
      };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRegisterSession: (state, action) => {
      state.registerSession = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRegistersAPI.fulfilled, (state, action) => {
      state.registers = pathOr([], ['registers'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(10, ["meta", "perPage"], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchRegistersAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRegistersAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRegisterAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.register = pathOr([], ['register'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(createRegisterAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createRegisterAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateRegisterAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.register = pathOr(null, ['register'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(updateRegisterAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateRegisterAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRegisterAPI.fulfilled, (state, action) => {
      state.register = pathOr(null, ['register'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchRegisterAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRegisterAPI.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(createRegisterSessionAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.register = pathOr(state.register, ['registerSession', 'register'], action.payload);
      state.registerSession = pathOr(null, ['registerSession'], action.payload);
      state.registerSessionsLoading = false;
    });
    builder.addCase(createRegisterSessionAPI.rejected, (state, action) => {
      state.registerSessionsLoading = false;
    });
    builder.addCase(createRegisterSessionAPI.pending, (state, action) => {
      state.registerSessionsLoading = true;
    });

    builder.addCase(updateRegisterSessionAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.registerSession = pathOr(null, ['registerSession'], action.payload);
      state.registerSessionsLoading = false;
    });
    builder.addCase(updateRegisterSessionAPI.rejected, (state, action) => {
      state.registerSessionsLoading = false;
    });
    builder.addCase(updateRegisterSessionAPI.pending, (state, action) => {
      state.registerSessionsLoading = true;
    });

    builder.addCase(fetchRegisterSessionAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.registerSession = pathOr(null, ['registerSession'], action.payload);
      state.registerSessionsLoading = false;
    });
    builder.addCase(fetchRegisterSessionAPI.rejected, (state, action) => {
      state.registerSessionsLoading = false;
    });
    builder.addCase(fetchRegisterSessionAPI.pending, (state, action) => {
      state.registerSessionsLoading = true;
    });

    builder.addCase(fetchRegisterSessionsAPI.fulfilled, (state, action) => {
      state.registerSessions = pathOr([], ['registerSessions'], action.payload);
      state.registerSessionsLoading = false;
    });
    builder.addCase(fetchRegisterSessionsAPI.rejected, (state, action) => {
      state.registerSessionsLoading = false;
    });
    builder.addCase(fetchRegisterSessionsAPI.pending, (state, action) => {
      state.registerSessionsLoading = true;
    });

    builder.addCase(createRegisterTransactionAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.registerTransaction = pathOr(null, ['registerTransaction'], action.payload);
      state.registerTransactionsLoading = false;
    });
    builder.addCase(createRegisterTransactionAPI.rejected, (state, action) => {
      state.registerTransactionsLoading = false;
    });
    builder.addCase(createRegisterTransactionAPI.pending, (state, action) => {
      state.registerTransactionsLoading = true;
    });

    builder.addCase(updateRegisterTransactionAPI.fulfilled, (state, action) => {
      state.successMessage = pathOr('', ['message'], action.payload);
      state.registerTransaction = pathOr(null, ['registerTransaction'], action.payload);
      state.registerTransactionsLoading = false;
    });
    builder.addCase(updateRegisterTransactionAPI.rejected, (state, action) => {
      state.registerTransactionsLoading = false;
    });
    builder.addCase(updateRegisterTransactionAPI.pending, (state, action) => {
      state.registerTransactionsLoading = true;
    });

    builder.addCase(fetchRegisterTransactionAPI.fulfilled, (state, action) => {
      state.registerTransaction = pathOr(null, ['registerTransaction'], action.payload);
      state.registerTransactionsLoading = false;
    });
    builder.addCase(fetchRegisterTransactionAPI.rejected, (state, action) => {
      state.registerTransactionsLoading = false;
    });
    builder.addCase(fetchRegisterTransactionAPI.pending, (state, action) => {
      state.registerTransactionsLoading = true;
    });

    builder.addCase(fetchRegisterTransactionsAPI.fulfilled, (state, action) => {
      state.registerTransactions = pathOr([], ['registerTransactions'], action.payload);
      state.registerTransactionsLoading = false;
    });
    builder.addCase(fetchRegisterTransactionsAPI.rejected, (state, action) => {
      state.registerTransactionsLoading = false;
    });
    builder.addCase(fetchRegisterTransactionsAPI.pending, (state, action) => {
      state.registerTransactionsLoading = true;
    });
  }
});

export const { 
  setRegister,
  setTotalPerPage,
  setCurrentPage,
  updateRegister,
  setSuccessMessage,
  setRegisterSession
 } = registersSlice.actions;
export default registersSlice.reducer;
