import {Breadcrumb, Button, message, Tabs} from "antd";
import {Link} from "react-router-dom";
import IngredientForm from "../../components/ingredient-form";
import {useAppDispatch, useAppSelector} from "../../../../../store/hooks";
import {length, pathOr} from "ramda";
import {MENU_GROUPS_ROUTE, MENUS_ROUTE} from "../../../../../constants";
import {
  createMenuItemAPI,
  updateMenuItemAPI,
} from "../../../../../api/menu-items";
import {getCookie} from "../../../../../helpers/utilities";
import {pickFields, removeUndefinedOrNull} from "../../../../../utils/is-not-empty";
import MenuItemDetails from "views/admin/menus/menu-items/components/menu-item-details";
import {validateSchema} from "views/admin/menus/menu-items/validations/schema";
import {setErrors, setMenuItemLoading} from "../../../../../store/menus/menu-items";
import MenuItemImages from "./menu-item-images";
import { RootState } from "store";
import { uploadFiles } from "utils/aws";


const MenuItemForm = () => {
  const dispatch = useAppDispatch();
  const folderName = useAppSelector((state: RootState) => pathOr('', ['folderName'], state.auth.business));
  const isLoading = useAppSelector((state: RootState) => state.menuItems.isLoading);
  const menuItem = useAppSelector((state: RootState) => state.menuItems.menuItem);
  const menuGroup = useAppSelector((state: RootState) => state.menuGroups.menuGroup);
  const menuItemImageFiles = useAppSelector((state: RootState) => state.menuItems.menuItemImageFiles);
  const menuGroupId = pathOr("", ["id"], menuGroup);

  const onChange = (key: string) => {};

  const onFinish = async () => {

    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");

    const menuItemObj = {
      ...pickFields(menuItem, [
        'name',
        'description',
        'imageURLs',
        'price',
        'calories',
        'isAvailable',
        'showOnWeb',
        'isArchived',
        'preparationTimeInMinutes',
        'servingSize',
        'allergenInformation',
        'nutritionalInformation',
      ]),
      ingredients: pickFields(pathOr([], ['ingredients'], menuItem), [
        'productId',
        'quantity',
        'unitOfMeasure',
      ]),
      menuItemModifierGroups: pickFields(pathOr([], ['menuItemModifierGroups'], menuItem), [
        'menuModifierGroupId'
      ]),
    }
    const menuItemPayload = menuItemObj;
    if (length(menuItemImageFiles) > 0) {
      dispatch(setMenuItemLoading(true));
      const uploadedImageUrls = await uploadFiles(menuItemImageFiles, folderName);
      menuItemPayload['imageURLs'] = [
        ...pathOr([], ['imageURLs'], menuItemPayload),
        ...uploadedImageUrls
      ];
      dispatch(setMenuItemLoading(false));
    }
    const {errors} = validateSchema(menuItemPayload);
    dispatch(setErrors(errors))
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return;
    }

    if (!pathOr(null, ['id'], menuItem)) {
      const obj = {
        ...menuItemPayload,
        restaurantId,
        locationId
      }
      await dispatch(createMenuItemAPI({ menuItem: obj, menuGroupId }));
    } else {
      await dispatch(updateMenuItemAPI({
        menuItem: menuItemPayload,
        menuItemId: pathOr(null, ['id'], menuItem)
      }))
    }
  };

  const items = [
    {
      key: "1",
      label: "Item details",
      children: <MenuItemDetails />,
    },
    {
      key: "2",
      label: "Ingredients",
      children: <IngredientForm type="menuItem" />,
    },
    {
      key: "3",
      label: "Images",
      children: <MenuItemImages />,
    },
  ];
  return (
     <div>
       <div className="flex items-center justify-between mb-6">
         <div>
           <p className="text-lg font-bold">Menu Item</p>
           <Breadcrumb
              items={[
                {
                  title: <Link to={MENUS_ROUTE}>Menus</Link>
                },
                {
                  title: <Link
                     to={`${MENUS_ROUTE}/${pathOr('', ['menuId'], menuGroup)}/menu-groups`}>
                    {pathOr('', ['menu', 'name'], menuGroup)}
                  </Link>
                },
                {
                  title: <Link
                     to={`${MENU_GROUPS_ROUTE}/${pathOr('', ['id'], menuGroup)}/menu-items`}>
                    {pathOr('', ['name'], menuGroup)}
                  </Link>
                },
                {
                  title: <p>{pathOr(null, ['name'], menuItem) ?
                     pathOr(null, ['name'], menuItem) : 'Menu Item'}</p>
                }
              ]}
           />
         </div>
         <div className="p-2">
           <Button loading={isLoading} type="primary" onClick={onFinish} className="bg-blue-750">
             {pathOr(null, ['id'], menuItem) ? "Update Menu Item" : "Create Menu Item"}
           </Button>
         </div>
       </div>
       <div className="bg-white p-5 rounded-xl">
         <Tabs type="card" defaultActiveKey="1" items={items} onChange={onChange} />
       </div>
     </div>
  );
};

export default MenuItemForm;
