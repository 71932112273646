import { LeftOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { pathOr } from 'ramda';
import React, { memo, useMemo } from 'react';
import {useNavigate} from "react-router-dom";
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

interface WithPermissionsProps {
  permissionsNeeded: string[];
  shouldRenderNull?: boolean;
}

export const hasPermission = (permissionSet: any, permissionsNeeded: string[]): boolean => {
  if (pathOr(false, ['isAccountOwner'], permissionSet)) {
    return true;
  }
  return permissionsNeeded.every(permission => permissionSet && permissionSet.permissions[permission]);
}

const withPermissions = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const WithPermissions: React.FC<P & WithPermissionsProps> = (props) => {
    const navigate = useNavigate();
    const { permissionsNeeded, shouldRenderNull, ...restProps } = props;
    const permissionSet = useAppSelector((state: RootState) => pathOr(null, [
      'employee',
      'permissionSet'
    ], state.auth.user))

    const isLoading = useAppSelector((state: RootState) => state.auth.isLoading)

    const hasPermission = useMemo(() => {
      if (pathOr(false, ['isAccountOwner'], permissionSet)) {
        return true;
      }

      return permissionsNeeded.every(permission => permissionSet && permissionSet.permissions[permission]);
    }, [permissionSet, permissionsNeeded]);

    if (hasPermission) {
      return <WrappedComponent {...(restProps as P)} />;
    }

    if (shouldRenderNull) {
      return <div />
    }

    if (isLoading) {
      return (
        <div className='m-5'>
          <Spin size="large" />
        </div>
      )
    }

    return (
      <div>
        <div>
          <span>You do not have the necessary permissions to view this content.</span>
        </div>

        <div>
          <Button onClick={() => navigate(-1)} icon={<LeftOutlined />}>Go Back</Button>
        </div>
      </div>
      );
  };

  return memo(WithPermissions);
};



export default withPermissions;
