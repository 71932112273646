import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../helpers/utilities";
import { pathOr } from "ramda";

export const fetchCustomersByLocationAPI = createAsyncThunk(
  "customers/fetch-by-location",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      const locationId = getCookie("currentLocation");
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(50, ["perPage"], payload)));
      params.append("locationId", locationId);
      const response = await client(`/customers?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch customers`);
    }
  },
);

export const createCustomerAPI = createAsyncThunk(
  "customers/create-customer",
  async (payload: { customer: any }, thunkAPI) => {
    try {
      const response = await client(`/customers`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.customer,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateCustomerAPI = createAsyncThunk(
  "customers/update-customer",
  async (payload: { customer: any; customerId: string }, thunkAPI) => {
    try {
      const response = await client(`/customers/${payload.customerId}/customer-update`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: {
          ...payload.customer,
        },
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchCustomerAPI = createAsyncThunk(
  "customers/fetch-customer",
  async (payload: { customerId: string }, thunkAPI) => {
    try {
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/customers/${payload.customerId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);