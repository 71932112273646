import React, { FC, useEffect, useState } from "react";
import {Button, Form} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { FormInput } from "components/formInput/FormInput";
import { FormSelect } from "components/formSelect/FormSelect";
import FormInputRenderer from "components/formInputRenderer/FormInputRenderer";
import { TableTypes } from "types/AllTypes";
import { tableStatus } from "../../../../constants";
import {
  getCookie,
  handleValidationErrors,
  validateFormFieldsTwo,
  removeUndefinedOrNull,
} from "helpers/utilities";
import {createTableAPI, deleteTableAPI, fetchFloorsByLocationAPI, updateTableAPI} from "../../../../api/floors";
import { pathOr } from "ramda";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

interface TableFormProps extends modalProps {
  mode: "create" | "edit";
  isOpen: boolean;
  handleClose: () => void;
}

const TableForm: FC<TableFormProps> = ({ mode, isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const floor = useAppSelector((state: any) => state.floors.floor);
  const table = useAppSelector((state: any) => state.floors.table);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<TableTypes | any>({
    tableNumber: "",
    seatCapacity: "",
    status: "",
  });

  const handleInputChange = (name: keyof TableTypes, type?: string | undefined) => (value: any) => {
    const processedValue = type === "number" ? parseFloat(value) || 0 : value;
    setFormValues((prev: any) => ({ ...prev, [name]: processedValue }));
  };

  const onFinish = async () => {
    const inputConfigs = [
      { name: "tableNumber", isRequired: true },
      { name: "seatCapacity", isRequired: false },
      { name: "status", isRequired: false },
    ];
    const newErrors = validateFormFieldsTwo(formValues, inputConfigs);

    if (handleValidationErrors(form, newErrors)) {
      return;
    }

    const floorId = pathOr("", ["id"], floor);
    const tableId = pathOr("", ["id"], table);
    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");
    const createFormValues = {
      ...formValues,
      restaurantId,
      locationId,
    };

    const updatedFormValues = {
      ...formValues,
    };

    if (mode === "create") {
      await dispatch(createTableAPI({ table: removeUndefinedOrNull(createFormValues), floorId }))
    } else if (mode === "edit") {
      await dispatch(updateTableAPI({tableId, table: removeUndefinedOrNull(updatedFormValues)}));
    }
    await dispatch(fetchFloorsByLocationAPI());
    handleClose();
  };

  const onDelete = async () => {
    const tableId = pathOr("", ["id"], table);
    await dispatch(deleteTableAPI({ tableId }));
    await dispatch(fetchFloorsByLocationAPI());
    handleClose();
  }

  useEffect(() => {
    if (table && mode === "edit") {
      setFormValues({
        tableNumber: pathOr("", ["tableNumber"], table),
        seatCapacity: pathOr("", ["seatCapacity"], table),
        status: pathOr("", ["status"], table),
      });
    } else {
      setFormValues({ tableNumber: "", seatCapacity: "", status: "" });
    }
  }, [table, mode]);

  const renderFormInputs = (
     inputs: {
       label: string;
       name: keyof TableTypes;
       required?: boolean;
       type?: string;
       styling?: string;
     }[],
  ) =>
     inputs.map((input) => (
        <FormInputRenderer
           key={input.name}
           label={input.label}
           name={input.name as string}
           required={input.required}
           styling={input.styling}
           type={input.type}
           value={formValues[input.name]}
           onChange={handleInputChange(input.name, input.type)}
        />
     ));

  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">
           {mode === "edit" ? "Edit Table" : "Create New Table"}
         </h3>
         <Form layout="vertical" form={form} className="w-full">
           <div className="text-left w-full pt-3">
             <FormInput
                label="Table Number"
                name="tableNumber"
                value={formValues.tableNumber}
                required
                onChange={handleInputChange("tableNumber")}
             />
           </div>
           <div className="flex flex-wrap">
             <div className="pt-3 md:pr-4 w-full md:w-1/2">
               <FormSelect
                  label="Status"
                  name="status"
                  value={formValues.status}
                  onChange={handleInputChange("status")}
                  options={tableStatus}
               />
             </div>
             {renderFormInputs([
               {
                 label: "Seat Capacity",
                 name: "seatCapacity",
                 type: "number",
                 styling: "w-full md:w-1/2 p-2",
               },
             ])}
           </div>
           <div className="mt-5 space-x-3 flex">
             <Button className="bg-blue-750" type="primary" onClick={onFinish}>{mode === "edit" ? "Update" : "Submit"}</Button>
             <Button danger onClick={onDelete}>Delete</Button>
             <Button onClick={handleClose}>Cancel</Button>
           </div>
         </Form>
       </div>
     </ParentModal>
  );
};

export default TableForm;
