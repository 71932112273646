import { getCookie } from "helpers/utilities";
import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchCategoriesByRestaurantAPI = createAsyncThunk(
  "categories/fetch-categories-by-restaurant",
  async (_, thunkAPI) => {
    try {
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/categories`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchCategoriesByLocationAPI = createAsyncThunk(
  "categories/fetch-categories-by-location",
  async (payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/categories?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createCategoryAPI = createAsyncThunk(
  "categories/create-category",
  async (payload: { category: any }, thunkAPI) => {
    try {
      const response = await client(`/categories`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: payload.category,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateCategoryAPI = createAsyncThunk(
  "categories/update-category",
  async (payload: { category: any; categoryId: string }, thunkAPI) => {
    try {
      const response = await client(`/categories/${payload.categoryId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.category,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchCategoryAPI = createAsyncThunk(
  "categories/fetch-category",
  async (payload: { categoryId: string }, thunkAPI) => {
    try {
      const response = await client(`/categories/${payload.categoryId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const archiveCategoryAPI = createAsyncThunk(
  "categories/archive-category",
  async (payload: { categoryId: string }, thunkAPI) => {
    try {
      const response = await client(`/categories/${payload.categoryId}/archive`, {
        method: "PATCH",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteCategoryAPI = createAsyncThunk(
  "categories/delete-category",
  async (payload: { categoryId: string }, thunkAPI) => {
    try {
      const response = await client(`/categories/${payload.categoryId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
