import {Input, InputNumber, Space, Switch} from "antd";
import React from "react";
import { pathOr} from "ramda";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {updateMenuModifierItem} from "../../../../../store/menus/menu-modifier-items";

const MenuModifierItemDetails = () => {
  const dispatch = useAppDispatch();
  const menuModifierItem = useAppSelector((state: any) => state.menuModifierItems.menuModifierItem);
  const errors = useAppSelector((state: any) => state.menuModifierItems.errors);

  const handleInputChange = (value: any, name: string) => {
    dispatch(updateMenuModifierItem({
      [name]: value
    }))
  };

  const handleShowOnWeb = () => {
    dispatch(updateMenuModifierItem({
      showOnWeb: !pathOr(false, ['showOnWeb'], menuModifierItem),
    }))
  }

  const handleIsAvailable = () => {
    dispatch(updateMenuModifierItem({
      isAvailable: !pathOr(false, ['isAvailable'], menuModifierItem),
    }))
  }

  const handleIsArchived = () => {
    dispatch(updateMenuModifierItem({
      isArchived: !pathOr(false, ['isArchived'], menuModifierItem),
    }))
  }

  return (
     <div>
       <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-end">
          <Space size="middle">
            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Show on web</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['showOnWeb'], menuModifierItem)}
                    onChange={handleShowOnWeb} 
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Available</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['isAvailable'], menuModifierItem)}
                    onChange={handleIsAvailable} 
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Archived</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['isArchived'], menuModifierItem)}
                    onChange={handleIsArchived} 
                  />
                </div>
              </div>
            </div>
          </Space>
         </div>
         <div>
           <span>Name<span className="text-red-500">*</span></span>
           <Input
              name="name"
              className="w-full"
              required
              status={pathOr(null, ['Modifier item name'], errors) ? 'error' : ''}
              onChange={(e) => handleInputChange(e.target.value, 'name')}
              value={pathOr('', ['name'], menuModifierItem)}
           />
           {
             pathOr(null, ['Modifier item name'], errors) ? <span className="text-red-500">
                 {pathOr('', ['Modifier item name', 0, 'message'], errors)}
               </span> : null
           }
         </div>
         <div>
           <span>Price<span className="text-red-500">*</span></span>
           <InputNumber
              name="price"
              className="w-full"
              required
              status={pathOr(null, ['Modifier item price'], errors) ? 'error' : ''}
              onChange={(val) => handleInputChange(Number(val), 'price')}
              value={pathOr('', ['price'], menuModifierItem)}
           />
           {
             pathOr(null, ['Modifier item price'], errors) ? <span className="text-red-500">
                 {pathOr('', ['Modifier item price', 0, 'message'], errors)}
               </span> : null
           }
         </div>
         <div>
           <span>Description</span>
           <Input.TextArea
              rows={4}
              value={pathOr('', ['description'], menuModifierItem)}
              status={pathOr(null, ['Modifier item description'], errors) ? 'error' : ''}
              onChange={(e) => handleInputChange(e.target.value, 'description')}
           />
           {
             pathOr(null, ['Modifier item description'], errors) ? <span className="text-red-500">
                 {pathOr('', ['Modifier item description', 0, 'message'], errors)}
               </span> : null
           }
         </div>
       </div>
     </div>
  );
};

export default MenuModifierItemDetails;
