import { getCookie } from "../../helpers/utilities";
import { client, getAuthorizationHeader } from "../apiUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchOrdersReportByLocationAPI = createAsyncThunk(
  "orders-report/location",
  async (payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    fromDate?: any;
    toDate?: any
   }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      if (payload.fromDate && payload.toDate) {
        params.append("fromDate", payload.fromDate);
        params.append("toDate", payload.toDate);
      }
      params.append("id", getCookie('currentLocation'));
      params.append("type", "location");
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(`/orders-report?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch orders`);
    }
  },
);

export const fetchOrdersReportByRestaurantAPI = createAsyncThunk(
  "orders-report/restaurant",
  async (_, thunkAPI) => {
    try {
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/orders-report`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch orders`);
    }
  },
);

export const fetchOrdersReportByBusinessAPI = createAsyncThunk(
  "orders-report/business",
  async (_, thunkAPI) => {
    try {
      const response = await client(`/orders-report`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch orders`);
    }
  },
);

export const fetchOrdersByRestaurantLocationsAPI = createAsyncThunk(
  "orders-report/restaurant-locations",
  async (_, thunkAPI) => {
    try {
      const restaurantId = getCookie("currentRestaurant");
      const response = await client(`/restaurants/${restaurantId}/compare/orders-report`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      throw new Error(`failed to fetch orders`);
    }
  },
);
