import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { 
  createExpenseAPI,
  deleteExpensesAPI,
  fetchExpensesByRestaurantAPI,
  fetchExpenseByIdAPI,
  updateExpensesAPI,
  fetchExpensesByLocationAPI
} from '../../api/expenses';
import { pathOr } from 'ramda';
import { EXPENSES_ROUTE } from '../../constants';
import dayjs from 'dayjs';

interface Expenses {
}

interface ExpenseState {
  expenses: any;
  expense: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  isSubmitting: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: ExpenseState = {
  expenses: [],
  expense: {
    expenseDate: dayjs().format('YYYY-MM-DD'),
    amountPaid: 0,
    amountDue: 0,
  },
  status: 'idle',
  error: null,
  isLoading: false,
  isSubmitting: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    fetchExpensesSuccess: (state, action: PayloadAction<Expenses[]>) => {
      state.status = 'succeeded';
      state.expenses = action.payload;
    },
    fetchExpensesFailure: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchExpenseByIdSuccess: (state, action) => {
      state.status = 'succeeded';
      state.expense = action.payload;
    },
    fetchExpenseByIdFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateExpense: (state, action) => {
      state.expense = {
        ...state.expense,
        ...action.payload,
      }
    }
  },
  extraReducers:(builder) => {
    builder.addCase(fetchExpensesByRestaurantAPI.fulfilled, (state,action) =>{
      state.expenses = pathOr([], ["expenses"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesByRestaurantAPI.rejected, (state, action) => {
      state.expenses = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpensesByLocationAPI.fulfilled, (state,action) =>{
      state.expenses = pathOr([], ["expenses"], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesByLocationAPI.rejected, (state, action) => {
      state.expenses = [];
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchExpensesByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpenseByIdAPI.fulfilled, (state, action) => {
      state.expense = pathOr(null, ["expense"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchExpenseByIdAPI.rejected, (state, action) => {
      state.expense = null;
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(fetchExpenseByIdAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createExpenseAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.expense = pathOr(null, ["expense"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
      window.location.href = EXPENSES_ROUTE;
    });
    builder.addCase(createExpenseAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(createExpenseAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateExpensesAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.expense = pathOr(null, ["expense"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(updateExpensesAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(updateExpensesAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteExpensesAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(deleteExpensesAPI.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false;
    });
    builder.addCase(deleteExpensesAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { 
  fetchExpensesSuccess,
  setSubmitting,
  fetchExpenseByIdSuccess,
  fetchExpenseByIdFailure,
  fetchExpensesFailure,
  setLoading,
  setCurrentPage,
  setTotalPerPage,
  updateExpense,
 } = expensesSlice.actions;

export default expensesSlice.reducer;
