import { createSlice } from '@reduxjs/toolkit';
import {head, pathOr} from 'ramda';
import {
  archiveFloorAPI,
  createFloorAPI, createTableAPI,
  deleteFloorAPI, deleteTableAPI,
  fetchFloorsByLocationAPI,
  fetchTablesByLocationAPI,
  updateFloorAPI, updateTableAPI
} from "../../api/floors";
import {message} from "antd";

interface FloorsState {
  floors: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  floor: any;
  tables: any[];
  table: any;
}

const initialState: FloorsState = {
  floors: [],
  floor: null,
  status: 'idle',
  error: null,
  isLoading: false,
  tables: [],
  table: null,
};

const floorsSlice = createSlice({
  name: 'floors',
  initialState,
  reducers: {
    clearFloorErrorsAndSuccesses: (state) => {
      state.error = null;
      state.status = 'succeeded';
    },
    setFloor: (state, action) => {
      state.floor = action.payload;
    },
    setTable: (state, action) => {
      state.table = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTablesByLocationAPI.fulfilled, (state, action) => {
      state.tables = pathOr([], ['tables'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchTablesByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchTablesByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createFloorAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(createFloorAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createFloorAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateFloorAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(updateFloorAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateFloorAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFloorsByLocationAPI.fulfilled, (state, action) => {
      state.floors = pathOr([], ['floors'], action.payload);
      state.floor = head(state.floors);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchFloorsByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchFloorsByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFloorAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deleteFloorAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteFloorAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveFloorAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(archiveFloorAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveFloorAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createTableAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(createTableAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createTableAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateTableAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(updateTableAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateTableAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteTableAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deleteTableAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteTableAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
   setTable,
   setFloor,
} = floorsSlice.actions;

export default floorsSlice.reducer;
