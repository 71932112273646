import React, {useEffect} from 'react';
import ProductForm from '../components/product-form';
import {useAppDispatch} from "../../../../store/hooks";
import {fetchCategoriesByLocationAPI} from "../../../../api/categories";
import withPermissions from 'components/hoc';

const CreateProduct: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesByLocationAPI({}));
  }, [dispatch])

  return <ProductForm mode="create" />;
};

export default withPermissions(CreateProduct);
