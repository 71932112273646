import React, {useEffect, useState} from "react";
import ParentModal from "components/modal/Modal";
import {Button, Input, InputNumber, message, Select, Space} from "antd";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {head, pathOr} from "ramda";
import {addOrderItem, selectOrderItem, updateOrderItem} from "../../../../store/orders/orders";

const MenuItemModal = ({
    isOpen,
    onClose,
}: { isOpen: boolean; onClose: () => void; }) => {
  const dispatch = useAppDispatch();
  const selectedOrderItem = useAppSelector((state: any) =>
     pathOr(null, ['selectedOrderItem'], state.orders));
  const [selectedItem, setSelectedItem] = useState('');
  const [value, setValue] = useState<any>('');
  const [quantity, setQuantity] = useState<any>('1');
  const [kitchenNote, setKitchenNote] = useState<any>();
  const [discountAmount, setDiscountAmount] = useState<any>('0');
  const menuItems = useAppSelector((state: any) => state.menuItems.menuItems);
  const currencySymbol = useAppSelector((state: any) =>
     pathOr('', ['currentLocation', 'currencySymbol'], state.auth));

  useEffect(() => {
    if (selectedOrderItem) {
      setSelectedItem(pathOr(null, ['menuItem'], selectedOrderItem));
      setValue(pathOr('', ['menuItem', 'id'], selectedOrderItem));
      setQuantity( pathOr(1, ['quantity'], selectedOrderItem));
      setKitchenNote(pathOr('', ['kitchenNote'], selectedOrderItem));
      setDiscountAmount(pathOr(0, ['discountAmount'], selectedOrderItem));
    }
  }, [selectedOrderItem])

  const handleSelect = (val: any) => {
    setValue(val);
    const item = head(menuItems.filter((menuItem: any) =>
       pathOr(null, ['id'], menuItem) === val));
    setSelectedItem(item)
  }

  const confirm = () => {
    const cost = (Number(quantity) *
       pathOr(0, ['price'], selectedItem) - Number(discountAmount));
    if (cost < 0) {
      return message.error('total cost must not be less than zero');
    }

    if (selectedOrderItem) {
      dispatch(updateOrderItem({
        ...selectedOrderItem,
        quantity: Number(quantity),
        discountAmount: Number(discountAmount),
      }))
      cancel();
      return
    }
    dispatch(addOrderItem({
      quantity: Number(quantity),
      menuItem: selectedItem,
      discountAmount: Number(discountAmount),
      kitchenNote,
    }))
    cancel()
  }

  const cancel = () => {
    dispatch(selectOrderItem(null));
    setQuantity(1)
    setSelectedItem(null);
    setDiscountAmount(0);
    setKitchenNote('');
    setValue('');
    onClose();
  }
  return (
     <ParentModal isOpen={isOpen} handleClose={onClose}>
       <div className="w-full">
         <div>
           <h3 className="text-2xl font-medium">{selectedOrderItem ? `Update 
           ${pathOr('', ['menuItem', 'name'], selectedOrderItem)}` : 'Add Menu Item'}</h3>
         </div>
         <div className="mt-5 flex flex-col space-y-2">
           {!selectedOrderItem ? (
              <div className="w-full">
               <span>Menu Item</span>
               <Select
                  showSearch
                  placeholder="Search Menu Items to Select"
                  onSelect={handleSelect}
                  value={value}
                  options={menuItems.map((menuItem: any) => {
                    return {
                      label: `${pathOr('', ['name'], menuItem)} (
                     ${pathOr('', ['menuGroup', 'name'], menuItem)})`,
                      value: pathOr('', ['id'], menuItem),
                    }
                  })}
                  filterOption={(input, option) => (
                     pathOr('', ['label'], option) ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                     (pathOr('', ['label'], optionA) ?? '').toLowerCase().localeCompare(
                        (pathOr('', ['label'], optionB) ?? '').toLowerCase())
                  }
                  className="w-full"
               />
             </div>
           ) : null}

           <div className="w-full">
             <span>Quantity</span>
             <InputNumber value={quantity} onChange={(val) => setQuantity(val)} className="w-full" />
           </div>

           <div className="w-full">
             <span>Discount</span>
             <InputNumber value={discountAmount} onChange={(val) => setDiscountAmount(val)} className="w-full" />
           </div>

           <div className="w-full">
             <span>Kitchen Note</span>
             <Input value={kitchenNote} onChange={(e) =>
                setKitchenNote(e.target.value)} />
           </div>
         </div>

         <div className="mt-5 space-x-3 flex justify-between">
           <div>
             <Button>
               Price: {currencySymbol} {(Number(quantity) *
                pathOr(0, ['price'], selectedItem) - Number(discountAmount)).toLocaleString()}
             </Button>
           </div>
           <div>
             <Space>
               <Button onClick={cancel}>Cancel</Button>
               <Button onClick={confirm} type="primary" className="bg-blue-750" >
                 {selectedOrderItem ? 'Update' : 'Add'} Menu Item</Button>
             </Space>
           </div>
         </div>
       </div>
     </ParentModal>
  )
}

export default MenuItemModal
