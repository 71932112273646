import moment from "moment";
import {getCookie} from "../../helpers/utilities";
import {client, getAuthorizationHeader} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {pathOr} from 'ramda';

export const fetchInventoryReportByLocationAPI = createAsyncThunk(
   'inventory-report/location',
   async (
    payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("id", getCookie('currentLocation'));
      params.append("type", "location");
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
       const response = await client(`/inventory-reports?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch inventory`);
     }
   }
);

export const fetchInventoryOverTimeReportByLocationAPI = createAsyncThunk(
  'inventory-overtime-report/location',
  async (
   payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    fromDate: any;
    toDate: any
   }, thunkAPI
  ) => {
    try {
     const params = new URLSearchParams();
     if (payload.refresh) {
       params.append("refresh", String(pathOr(false, ["refresh"], payload)));
     }
     params.append("fromDate", payload.fromDate);
     params.append("toDate", payload.toDate);
     params.append("page", String(pathOr(1, ["page"], payload)));
     params.append("id", getCookie('currentLocation'));
     params.append("type", "location");
     params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(`/inventory-reports/inventory-overtime/report?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch inventory report`);
    }
  }
);

export const fetchInventoryReportByRestaurantAPI = createAsyncThunk(
   'inventory-reports/restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/inventory-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch inventory`);
     }
   }
)

export const fetchInventoryReportByBusinessAPI = createAsyncThunk(
   'inventory-reports/business',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/inventory-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch inventory`);
     }
   }
)
