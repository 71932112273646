import React, { useEffect, useState } from "react";
import { Button, Checkbox, InputNumber, message, Select } from "antd";
import {
  getCookie,
  removeUndefinedOrNull,
} from "helpers/utilities";
import { useParams } from "react-router-dom";
import { pathOr, map, length } from "ramda";
import { currencies } from "currencies";
import {
  paymentMethods,
  paymentStatuses,
  PURCHASES_ROUTE,
  unitsOfMeasure,
} from "../../../../constants";
import { useAppDispatch, useAppSelector } from "store/hooks";
import dayjs from "dayjs";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {createPurchaseAPI, fetchPurchaseAPI, updatePurchaseAPI} from '../../../../api/purchases';
import { fetchSuppliersByLocationAPI } from "../../../../api/suppliers";
import { Link } from "react-router-dom";
import { Breadcrumb, DatePicker, Input } from "antd/lib";
import { RootState } from "store";
import { pickFields } from "utils/is-not-empty";
import { validateSchema } from "../validations/schema";
import { updatePurchase } from "store/restaurants/purchases";
import { fetchInventoryByLocationAPI } from "api/inventory";

interface PurchasesFormProps {
  mode: "create" | "edit";
}

const PurchaseForm: React.FC<PurchasesFormProps> = ({ mode }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const suppliers = useAppSelector((state: RootState) => state.suppliers.suppliers);
  const currencyName = useAppSelector((state: RootState) => pathOr('', 
  ['currencyName'], state.auth.currentLocation));
  const purchase = useAppSelector((state: RootState) => state.purchases.purchase);
  const inventory = useAppSelector((state: RootState) => state.inventory.inventory);
  const isLoading = useAppSelector((state: RootState) => state.purchases.isLoading);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (mode === "edit") {
      dispatch(fetchPurchaseAPI({purchaseId: id}));
    }
    dispatch(fetchSuppliersByLocationAPI({}));
    dispatch(fetchInventoryByLocationAPI({perPage: 1000}));
  }, [mode, id, dispatch]);

  const handleCheckBox = (event: CheckboxChangeEvent) => {
    dispatch(updatePurchase({
      isApproved: event.target.checked
    }));
  }

  const onCurrencyChange = (selectedCurrencyName: string): void => {
    const selectedCurrency = currencies.find(currency => currency.currencyName === selectedCurrencyName);
    dispatch(updatePurchase({
      currencyName: selectedCurrencyName,
      currencySymbol: selectedCurrency.currencySymbol,
      currencyIsoCode: selectedCurrency.currencyIsoCode
    }));
  };

  const handleChange = (value: any, type: string) => {
    if (type === 'purchasesDate') {
      const formattedDate = value ? value.format("YYYY-MM-DD") : null;
      dispatch(
        updatePurchase({
          purchasesDate: formattedDate
        })
      )
    } else if (type === 'totalAmount') {
      dispatch(
        updatePurchase({
          totalAmount: Number(value)
        })
      )
      dispatch(
        updatePurchase({
          amountDue: Number(value) - Number(pathOr(0, ['amountPaid'], purchase))
        })
      )
    } else if (type === 'amountPaid') {
      dispatch(
        updatePurchase({
          amountPaid: Number(value)
        })
      )
      dispatch(
        updatePurchase({
          amountDue: Number(pathOr(0, ['totalAmount'], purchase)) - Number(value)
        })
      )
    } else {
      dispatch(
        updatePurchase({
          [type]: value
        })
      )
    }
  };

  const onFinish = async () => {
    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");

    const purchasesObj = {
      ...pickFields(purchase, [
       'name',
       'isComplete',
       'purchaseDate',
       'quantity',
       'productId',
       'unitOfMeasure',
       'amountPaid',
       'amountDue',
       'totalAmount',
       'currencyName',
       'currencySymbol',
       'currencyIsoCode',
       'description',
       'paymentStatus',
       'supplierId',
       'paymentMethod'
      ]),
    };

    const purchasePayload = removeUndefinedOrNull(purchasesObj);
    const { errors } = validateSchema(purchasePayload);
    setErrors(errors);

    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check the errors and try again');
      return
    }

    if (!id) {
      const obj = {
        ...purchasePayload,
        restaurantId,
        locationId,
      };
      await dispatch(createPurchaseAPI({purchase: obj}));
    } else {
      await dispatch(updatePurchaseAPI({purchaseId: id, purchase: purchasePayload}));
    }
  };

  const breadcrumbItems = [
    { title: <Link to={PURCHASES_ROUTE}>Purchase</Link> },
    { title: <span>{mode === "edit" ? `Update Purchase` : "Create Purchase"}</span> },
  ];

  return (
    <div className="h-full overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Purchase</p>
          <p className="text-base text-gray-750">
            <Breadcrumb items={breadcrumbItems} />
          </p>
        </div>
        <div>
          <div className="flex flex-wrap -m-2">
            <div className="p-2">
              <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={!purchase || length(Object.keys(purchase)) < 1}
                className="bg-blue-750 w-[200px]">
                {mode === "edit" ? "Update Purchase" : "Create Purchase"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row justify-end items-end">
            <Checkbox
              onChange={(event) => handleCheckBox(event)}
              checked={pathOr(false, ['isComplete'], purchase)}
              className="text-base">
              <span className="text-base">Is Complete</span>
            </Checkbox>
          </div>
          <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>Purchase Date</span>
              <DatePicker 
                value={purchase && purchase.purchaseDate ? dayjs(purchase.purchaseDate) : null}
                className="w-full h-10 mb-0"
                format={"DD-MM-YYYY"}
                onChange={(date) => handleChange(date, 'purchaseDate')}
              />
            </div>

            <div className="w-full md:w-[32%]">
              <span>
                Name<span className="text-red-500">*</span>
              </span>
              <Input
                name="name"
                required
                status={pathOr(null, ["Name"], errors) ? "error" : ""}
                value={pathOr("", ["name"], purchase)}
                onChange={(e) => handleChange(e.target.value, "name")}
              />
              {pathOr(null, ["Name"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Name", 0, "message"], errors)}</span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Supplier</span>
              <Select 
                options={suppliers.map((supplier: any) => {
                  return {
                    label: pathOr('', ['name'], supplier),
                    value: pathOr('', ['id'], supplier)
                  }
                })}
                className="w-full"
                value={pathOr('', ['supplierId'], purchase)}
                onChange={(val) => handleChange(val, 'supplierId')}
              />
              {pathOr(null, ["Supplier"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Supplier", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>

          <div className="flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>Total Amount<span className="text-red-500">*</span></span>
              <InputNumber
                name="totalAmount"
                required
                className="w-full"
                status={pathOr(null, ["Total amount"], errors) ? "error" : ""}
                value={pathOr("", ["totalAmount"], purchase)}
                onChange={(val) => handleChange(Number(val), "totalAmount")}
              />
              {pathOr(null, ["Total amount"], errors) ? (
                <span className="text-red-500">
                  {pathOr("", ["Total amount", 0, "message"], errors)}
                </span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Amount Paid</span>
              <InputNumber
                name="amountPaid"
                required
                className="w-full"
                status={pathOr(null, ["Amount paid"], errors) ? "error" : ""}
                value={pathOr("", ["amountPaid"], purchase)}
                onChange={(val) => handleChange(Number(val), "amountPaid")}
              />
              {pathOr(null, ["Amount paid"], errors) ? (
                <span className="text-red-500">
                  {pathOr("", ["Amount paid", 0, "message"], errors)}
                </span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Amount Due</span>
              <InputNumber
                name="amountDue"
                className="w-full"
                status={pathOr(null, ["Amount due"], errors) ? "error" : ""}
                value={pathOr("", ["amountDue"], purchase)}
                onChange={(val) => handleChange(Number(val), "amountDue")}
                disabled
              />
              {pathOr(null, ["Amount due"], errors) ? (
                <span className="text-red-500">
                  {pathOr("", ["Amount due", 0, "message"], errors)}
                </span>
              ) : null}
            </div>
          </div>

          <div className="flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>Inventory</span>
              <Select 
                options={inventory.map((inv: any) => ({
                  value: inv.id,
                  label: inv.name,
                }))}
                value={pathOr('', ['productId'], purchase)}
                className="w-full"
                status={pathOr(null, ["Inventory"], errors) ? "error" : ""}
                onChange={(val) => handleChange(val, 'productId')}
              />
              {pathOr(null, ["Inventory"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Inventory", 0, "message"], errors)}</span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Unit Of Measure<span className="text-red-500">*</span></span>
              <Select 
                options={unitsOfMeasure.map((unit: any) => ({
                  value: unit,
                  label: unit,
                }))}
                value={pathOr('', ['unitOfMeasure'], purchase)}
                className="w-full"
                onChange={(val) => handleChange(val, 'unitOfMeasure')}
              />
              {pathOr(null, ["Unit of measure"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Unit of measure", 0, "message"], errors)}</span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Quantity<span className="text-red-500">*</span></span>
              <InputNumber
                name="quantiity"
                className="w-full"
                status={pathOr(null, ["Quantity"], errors) ? "error" : ""}
                value={pathOr("", ["quantity"], purchase)}
                onChange={(val) => handleChange(Number(val), "quantity")}
              />
              {pathOr(null, ["Quantity"], errors) ? (
                <span className="text-red-500">
                  {pathOr("", ["Quantity", 0, "message"], errors)}
                </span>
              ) : null}
            </div>
          </div>

          <div className="flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>Currency</span>
              <Select 
                options={map(
                  (currency) => ({ value: currency.currencyName, label: currency.currencyName }),
                  currencies,
                )}
                className="w-full"
                value={pathOr(currencyName, ['currencyName'], purchase)}
                status={pathOr(null, ["Currency name"], errors) ? "error" : ""}
                onChange={onCurrencyChange}
              />
              {pathOr(null, ["Currency name"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Currency name", 0, "message"], errors)}</span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Payment Method</span>
              <Select 
               options={paymentMethods}
                className="w-full"
                value={pathOr('', ['paymentMethod'], purchase)}
                status={pathOr(null, ["Payment method"], errors) ? "error" : ""}
                onChange={(val) => handleChange(val, 'paymentMethod')}
              />
              {pathOr(null, ["Payment method"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Payment method", 0, "message"], errors)}</span>
              ) : null}
            </div>

            <div className="w-full md:w-[32%]">
              <span>Payment Status</span>
              <Select 
                options={paymentStatuses}
                className="w-full"
                value={pathOr('', ['paymentStatus'], purchase)}
                onChange={(val) => handleChange(val, 'paymentStatus')}
              />
              {pathOr(null, ["Payment status"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Payment status", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>

          <div className="w-full">
            <span>Description</span>
            <Input.TextArea
              rows={4}
              name="description"
              className="w-full"
              status={pathOr(null, ["Description"], errors) ? "error" : ""}
              value={pathOr("", ["description"], purchase)}
              onChange={(e) => handleChange(e.target.value, "description")}
            />
            {pathOr(null, ["Description"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Description", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseForm;

