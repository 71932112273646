import Joi from "joi";
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Modifier item name'),
  price: Joi.number().positive().allow(0).required().label('Modifier item price'),
  description: Joi.string().trim().allow(null).optional().label('Modifier item description'),
  showOnWeb: Joi.boolean().optional().label('Modifier item shown on web'),
  isAvailable: Joi.boolean().optional().label('Modifier item availability'),
  isArchived: Joi.boolean().optional().label('Modifier item archived'),
  ingredients: Joi.array().items(
     Joi.object({
       productId: Joi.string().trim().required().label('Ingredient product id'),
       quantity: Joi.number().positive().required().label('Ingredient quantity'),
       unitOfMeasure: Joi.string().trim().required().label('Ingredient unit of measure'),
     })
  ).optional().label('Ingredients')
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
