import { createSlice } from '@reduxjs/toolkit';
import {bulkOrderUpdateAPI, createOrderAPI, createOrderPaymentAPI, fetchOrderByIdAPI, fetchOrdersByLocationAPI, updateOrderAPI} from '../../api/orders';
import {head, length, pathOr} from 'ramda';
import moment from "moment";
import {getCookie} from "../../helpers/utilities";
import {mkOrderStages} from "../../utils/order-stages";
import {getAdjacentNodes, traverseBF} from "../../utils/tree";
import { message } from 'antd';
import { ORDERS_ROUTE } from '../../constants';
import { removeUndefinedOrNull } from 'utils/is-not-empty';

interface OrdersState {
  orders: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  selectedOrderItem: any;
  order: any;
  selectedOrderModifierItem: any;
  diningOptions: string[];
  selectedOrderStage: any;
  currentOrderStages: any;
  initialOrderStage: string;
  orderStages: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  filters: {
    type: string,
    paymentMethod: string,
    paymentStatus: string,
    serverId: string,
    stage: string,
    fromDate: string,
    toDate: string,
    keyword: string,
  };
}

const initialState: OrdersState = {
  orders: [],
  status: 'idle',
  error: null,
  isLoading: false,
  order: null,
  selectedOrderItem: null,
  selectedOrderModifierItem: null,
  diningOptions: ['DINE_IN', 'DELIVERY', 'TAKE_OUT'],
  selectedOrderStage: null,
  currentOrderStages: [],
  initialOrderStage: null,
  orderStages: [],
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  filters: {
    type: '',
    paymentMethod: '',
    paymentStatus: '',
    serverId: '',
    stage: '',
    fromDate: '',
    toDate: '',
    keyword: ''
  }
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    clearOrderErrorsAndSuccesses: (state) => {
      state.error = null;
      state.status = 'succeeded';
    },
    fetchOrderStages: (state) => {
      const stages = traverseBF(mkOrderStages());
      if (stages && length(stages) > 0) {
        const fetchedOrderStages = stages.map((stage) => {
          return {
            name: pathOr('', ['data', 'name'], stage),
            value: pathOr('', ['data', 'value'], stage),
          };
        });
        state.orderStages = fetchedOrderStages;
        state.selectedOrderStage = head(fetchedOrderStages);
        state.initialOrderStage = pathOr(
           '',
           ['name'],
           head(fetchedOrderStages)
        );
      }
    },
    fetchInitialOrderStage: (state) => {
      const stages = traverseBF(mkOrderStages());
      if (stages && length(stages) > 0) {
        const fetchedOrderStages = stages.map((stage) => {
          return {
            name: pathOr('', ['data', 'name'], stage),
            value: pathOr('', ['data', 'value'], stage),
          };
        });
        state.initialOrderStage = pathOr(
           '',
           ['name'],
           head(fetchedOrderStages)
        );
      }
    },
    fetchCurrentOrderStages: (state) => {
      const currentOrderStage = pathOr(null, ['stage'], state.order);
      if (currentOrderStage) {
        const stages = getAdjacentNodes(mkOrderStages(), currentOrderStage);
        state.currentOrderStages = stages.map((stage: any) => {
          return {
            name: pathOr('', ['data', 'name'], stage),
            value: pathOr('', ['data', 'value'], stage),
            children: pathOr([], ['children'], stage),
            parent: pathOr(null, ['parent'], stage),
          };
        });
      }
    },
    selectOrderModifierItem: (state, action) => {
      state.selectedOrderModifierItem = action.payload;
    },
    setDiscount: (state, action) => {
      state.order = {
        ...state.order,
        discountAmount: pathOr(0, ['discountAmount'], action.payload),
        discountPercentage: pathOr(0, ['discountPercentage'], action.payload),
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    setDiningOption: (state, action) => {
      state.order = {
        ...state.order,
        type: action.payload,
      };
    },
    setServer: (state, action) => {
      state.order = {
        ...state.order,
        serverId: pathOr('', ['id'], action.payload),
        server: action.payload,
      };
    },
    setDeliveryRider: (state, action) => {
      state.order = {
        ...state.order,
        delivery: {
          ...pathOr({}, ['delivery'], state.order),
          deliveryRiderId: pathOr('', ['id'], action.payload),
          deliveryRider: action.payload,
        },
      };
    },
    setTable: (state, action) => {
      state.order = {
        ...state.order,
        tableId: pathOr('', ['id'], action.payload),
        table: action.payload,
        floorId: pathOr('', ['floorId'], action.payload),
      };
    },
    setPaymentStatus: (state, action) => {
      state.order = {
        ...state.order,
        isPaid: pathOr(false, ['isPaid'], action.payload),
      };
    },
    setCustomer: (state, action) => {
      state.order = {
        ...state.order,
        customerId: pathOr('', ['id'], action.payload),
        customer: action.payload,
      };
    },
    setOrder: (state, action) => {
      state.order = action.payload;
      if (action.payload === null || action.payload === undefined) {
        state.selectedOrderItem = null;
      }
    },
    updateOrder: (state, action) => {
      state.order = {
        ...state.order,
        ...action.payload,
      }
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    setDeliveryInformation: (state, action) => {
      state.order = {
        ...state.order,
        delivery: {
          ...pathOr({}, ['delivery'], state.order),
          ...action.payload,
        },
      };
    },
    syncCurrentTransaction: (state) => {
      const payments = pathOr([], ['transaction', 'payments'], state.order);
      const refunds = pathOr([], ['transaction', 'refunds'], state.order);
      const totalAmount = pathOr(0, ['totalAmount'], state.order);
      const totalAmountPaid = payments.reduce((sum, obj) => {
        return sum + pathOr(0, ['amountPaid'], obj);
      }, 0);
      const totalAmountRefunded = refunds.reduce((sum, obj) => {
        return sum + pathOr(0, ['amountRefunded'], obj);
      }, 0);
      const amountRefundable = (totalAmountPaid - totalAmountRefunded);
      const totalAmountDue = amountRefundable >= totalAmount ? 0 :
       totalAmount - amountRefundable;
      const isPaid = totalAmountDue === 0 ? true : false;
      const paymentMethods = [...new Set(payments.map((payment) => payment.paymentMethod))];
      const change = pathOr(0, [length(payments) - 1, 'change'], payments);
      state.order = {
        ...state.order,
        isPaid,
        transaction: {
          ...pathOr({}, ['transaction'], state.order),
          amountPaid: totalAmountPaid,
          amountDue: totalAmountDue,
          amountRefunded: totalAmountRefunded,
          amountRefundable,
          paymentMethods,
          payments,
          refunds,
          change,
        }
      }
    },
    createRefund: (state, action) => {
      const refunds = pathOr([], ['transaction', 'refunds'], state.order);
      refunds.push(
        removeUndefinedOrNull({
          id: moment().valueOf(),
          refundMethod: pathOr('CASH', ['refundMethod'], action.payload),
          amountRefunded: pathOr(0, ['amountRefunded'], action.payload),
          transactionId: pathOr('', ['transactionId'], action.payload),
          createdAt: new Date(),
          new: true,
        })
      )
      state.order = {
        ...state.order,
        transaction: {
          ...pathOr({}, ['transaction'], state.order),
          refunds
        }
      }
      ordersSlice.caseReducers.syncCurrentTransaction(state);
    },
    createPayment: (state, action) => {
      const totalAmount = pathOr(0, ['totalAmount'], state.order);
      const payments = pathOr([], ['transaction', 'payments'], state.order);
      const amountRefunded = pathOr(0, ['transaction', 'amountRefunded'], state.order);
      const amountPaid = pathOr(0, ['amountPaid'], action.payload);
      const totalAmountPaid = amountPaid + payments.reduce((sum, obj) => {
        return sum + pathOr(0, ['amountPaid'], obj);
      }, 0);
      const change = (totalAmountPaid - amountRefunded) > totalAmount ?
       totalAmountPaid - (totalAmount + amountRefunded) : 0;
      const finalAmountPaid = change > 0 ? amountPaid - change : amountPaid;
      payments.push(
        removeUndefinedOrNull({
          id: moment().valueOf(),
          paymentMethod: pathOr('CASH', ['paymentMethod'], action.payload),
          amountPaid: finalAmountPaid,
          transactionId: pathOr('', ['transactionId'], action.payload),
          createdAt: new Date(),
          change,
          new: true,
        })
      )
      state.order = {
        ...state.order,
        transaction: {
          ...pathOr({}, ['transaction'], state.order),
          payments
        }
      }
      ordersSlice.caseReducers.syncCurrentTransaction(state);
    },
    removePayment: (state, action) => {
      const paymentId = action.payload;
      const payments = pathOr([], ['transaction', 'payments'], state.order);
      const newPayments = payments.filter((payment) => pathOr(null, ['id'], payment) !== paymentId);
      if (length(newPayments) < 1) {
        ordersSlice.caseReducers.deleteTransaction(state);
      } else {
        state.order = {
          ...state.order,
          transaction: {
            ...pathOr({}, ['transaction'], state.order),
            payments: newPayments,
          }
        }
        ordersSlice.caseReducers.syncCurrentTransaction(state);
      }
    },
    removeRefund: (state, action) => {
      const refundId = action.payload;
      const refunds = pathOr([], ['transaction', 'refunds'], state.order);
      const newRefunds = refunds.filter((payment) => pathOr(null, ['id'], payment) !== refundId);
      state.order = {
        ...state.order,
        transaction: {
          ...pathOr({}, ['transaction'], state.order),
          refunds: newRefunds,
        }
      }
      ordersSlice.caseReducers.syncCurrentTransaction(state);
    },
    deleteTransaction: (state) => {
      const obj = {
        ...state.order,
        isPaid: false,
      };
      delete obj['transaction'];
      state.order = obj;
    },
    calculateOrderPricing: (state) => {
      const orderItems = pathOr([], ['orderItems'], state.order);
      const orderItemAndModifierItemDiscountAmount = orderItems.reduce((sum: number, obj: any) => {
        let sumOfOrderModifiers = 0;
        const orderModifierItems = pathOr([], ['orderModifierItems'], obj);
        if (length(orderModifierItems) > 0) {
          sumOfOrderModifiers = orderModifierItems.reduce(
             (sum2: number, obj2) => {
               return sum2 + pathOr(0, ['discountAmount'], obj2);
             },
             0
          );
        }
        return (
           sum +
           sumOfOrderModifiers +
           pathOr(0, ['discountAmount'], obj)
        );
      }, 0);
      const originalDiscountAmount = pathOr(0, ['discountAmount'], state.order);
      const tipAmount = pathOr(0, ['tipAmount'], state.order);
      const taxAmount = pathOr(0, ['taxAmount'], state.order);
      const subAmount = orderItems.reduce((sum: number, obj: any) => {
        let sumOfOrderModifiers = 0;
        const orderModifierItems = pathOr([], ['orderModifierItems'], obj);
        if (length(orderModifierItems) > 0) {
          sumOfOrderModifiers = orderModifierItems.reduce(
             (sum2: number, obj2) => {
               return sum2 + pathOr(0, ['menuModifierItem', 'price'], obj2) *
                  pathOr(1, ['quantity'], obj2);
             },
             0
          );
        }
        return (
           sum +
           sumOfOrderModifiers +
           pathOr(0, ['menuItem', 'price'], obj) * obj.quantity
        );
      }, 0);
      const totalDiscountAmount = originalDiscountAmount + orderItemAndModifierItemDiscountAmount;
      const totalAmount = subAmount + tipAmount + taxAmount - totalDiscountAmount;
      state.order = {
        ...state.order,
        subAmount,
        totalAmount,
        tipAmount,
        totalDiscountAmount,
      };
      ordersSlice.caseReducers.syncCurrentTransaction(state);
    },
    addSelectedOrderModifierItemQuantity: (state, action) => {
      const orderModifierItem = action.payload;
      const orderItems = pathOr([], ['orderItems'], state.order);
      const items = orderItems.map((orderItem) => {
        if (pathOr(null, ['id'], orderItem) ===
           pathOr('', ['orderItemId'], orderModifierItem)) {
          const modifierItems = pathOr([],
             ['orderModifierItems'], orderItem).map((modifierItem) => {
               if (pathOr('', ['id'], modifierItem) === pathOr('',
                  ['id'], orderModifierItem)) {
                 return {
                   ...modifierItem,
                   quantity: pathOr(0, ['quantity'], orderModifierItem) + 1,
                 }
               }
            return {
              ...modifierItem,
            }
          })
          return {
            ...orderItem,
            orderModifierItems: modifierItems
          }
        } else {
          return {
            ...orderItem
          }
        }
      })

      state.order = {
        ...state.order,
        orderItems: items,
      }

      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    removeSelectedOrderModifierItemQuantity: (state, action) => {
      const orderModifierItem = action.payload;
      const orderItems = pathOr([], ['orderItems'], state.order);
      const items = orderItems.map((orderItem) => {
        if (pathOr(null, ['id'], orderItem) ===
           pathOr('', ['orderItemId'], orderModifierItem)) {
          const modifierItems = pathOr([],
             ['orderModifierItems'], orderItem).map((modifierItem) => {
            if (pathOr('', ['id'], modifierItem) === pathOr('',
               ['id'], orderModifierItem)) {
              const calc = pathOr(1, ['quantity'], orderModifierItem) - 1;
              if (calc === 0) {
                ordersSlice.caseReducers.removeOrderItem(state, {
                  type: 'removeModifierItem',
                  payload: modifierItem,
                });
                return undefined
              }
              return {
                ...modifierItem,
                quantity: pathOr(0, ['quantity'], orderModifierItem) - 1,
              }
            }
              return {
                ...modifierItem,
              }
          })
          return {
            ...orderItem,
            orderModifierItems: modifierItems.filter((modifierItem) => modifierItem !== undefined)
          }
        } else {
          return {
            ...orderItem
          }
        }
      })

      state.order = {
        ...state.order,
        orderItems: items,
      }
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    addModifierItem: (state, action) => {
      const orderItems = pathOr([], ['orderItems'], state.order);
      const items = orderItems.map((orderItem: any) => {
        if (orderItem.id === state.selectedOrderItem.id) {
          const id = moment().valueOf();
          const modifierItems = [
            ...pathOr([], ['orderModifierItems'], state.selectedOrderItem),
            {
              id,
              key: id,
              quantity: pathOr(1, ['quantity'], action.payload),
              orderItemId: pathOr('', ['id'], state.selectedOrderItem),
              menuModifierItemId: pathOr('', ['menuModifierItem', 'id'], action.payload),
              menuModifierItem: {
                ...pathOr({}, ['menuModifierItem'], action.payload),
              },
            },
          ];
          state.selectedOrderItem = {
            ...state.selectedOrderItem,
            orderModifierItems: modifierItems,
          };
          return {
            ...orderItem,
            orderModifierItems: modifierItems,
          };
        }
        return {
          ...orderItem,
        };
      });
      state.order = {
        ...state.order,
        orderItems: items,
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    removeModifierItem: (state, action) => {
      const orderItems = pathOr([], ['orderItems'], state.order);
      const modifierItem = action.payload;
      const orderModifierItems = pathOr(
         [],
         ['orderModifierItems'],
         state.selectedOrderItem
      );
      const index = orderModifierItems.findIndex(
         (modifier) =>
            pathOr('', ['menuModifierItemId'], modifier) ===
            pathOr('', ['id'], modifierItem)
      );

      if (index !== -1) {
        orderModifierItems.splice(index, 1);
      }

      // update current order item
      state.selectedOrderItem = {
        ...state.selectedOrderItem,
        orderModifierItems,
      };

      const items = orderItems.map((orderItem: any) => {
        if (orderItem.id === state.selectedOrderItem.id) {
          return {
            ...state.selectedOrderItem,
          };
        }
        return {
          ...orderItem,
        };
      });

      state.order = {
        ...state.order,
        orderItems: items,
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    updateOrderItem: (state, action) => {
      const orderItemId: any = pathOr(0, ['id'], action.payload);
      const orderItems = pathOr([], ['orderItems'], state.order);
      const items = orderItems.map((orderItem: any) => {
        if (orderItem.id === orderItemId) {
          state.selectedOrderItem = {
            ...orderItem,
            ...action.payload,
          };
          return {
            ...orderItem,
            ...action.payload,
          };
        }
        return {
          ...orderItem,
        };
      });
      state.order = {
        ...state.order,
        orderItems: items,
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    addOrderItem: (state, action) => {
      const id = moment().valueOf();
      const orderItems = [
        ...pathOr([], ['orderItems'], state.order),
        {
          id,
          key: id,
          quantity: pathOr(1, ['quantity'], action.payload),
          menuItemId: pathOr('', ['menuItem', 'id'], action.payload),
          menuItem: {
            ...pathOr({}, ['menuItem'], action.payload),
          },
          kitchenNote: pathOr('', ['kitchenNote'], action.payload),
          discountAmount: pathOr(0, ['discountAmount'], action.payload),
        },
      ];
      // get the subtotal
      state.order = {
        ...state.order,
        locationId: getCookie('currentLocation'),
        orderItems,
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    setKitchenNoteOnOrderItem: (state, action) => {
      const orderItemId: any = pathOr(0, ['id'], action.payload);
      const kitchenNote = pathOr('', ['kitchenNote'], action.payload);
      const orderItems = pathOr([], ['orderItems'], state.order);
      const items = orderItems.map((orderItem: any) => {
        if (orderItem.id === orderItemId) {
          state.selectedOrderItem = {
            ...orderItem,
            kitchenNote,
          };
          return {
            ...orderItem,
            kitchenNote,
          };
        }
        return {
          ...orderItem,
        };
      });
      state.order = {
        ...state.order,
        orderItems: items,
      };
    },
    removeOrderItem: (state, action) => {
      const orderItemId = action.payload;
      const orderItems = pathOr([], ['orderItems'], state.order);
      const newOrderItems = orderItems.filter(
         (orderItem) => orderItemId !== pathOr(null, ['id'], orderItem)
      );
      state.order = {
        ...state.order,
        orderItems: newOrderItems,
      };
      state.selectedOrderItem = null;
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    selectOrderItem: (state, action) => {
      state.selectedOrderItem = action.payload;
    },
    addSelectedOrderItemQuantity: (state, action) => {
      const orderItemId = action.payload;
      const orderItems = pathOr([], ['orderItems'], state.order);
      const currentQuantity = pathOr(0, ['quantity'], state.selectedOrderItem);
      state.selectedOrderItem = {
        ...state.selectedOrderItem,
        quantity: currentQuantity + 1,
      };
      const items = orderItems.map((orderItem: any) => {
        if (orderItem.id === orderItemId) {
          return {
            ...orderItem,
            quantity: currentQuantity + 1,
          };
        }
        return {
          ...orderItem,
        };
      });
      state.order = {
        ...state.order,
        orderItems: items,
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
    removeSelectedOrderItemQuantity: (state, action) => {
      const orderItemId = action.payload;
      const orderItems = pathOr([], ['orderItems'], state.order);
      const currentQuantity = pathOr(0, ['quantity'], state.selectedOrderItem);
      const calc = currentQuantity - 1;

      if (calc === 0) {
        ordersSlice.caseReducers.removeOrderItem(state, {
          type: 'removeOrderItem',
          payload: orderItemId,
        });
        return;
      }
      state.selectedOrderItem = {
        ...state.selectedOrderItem,
        quantity: currentQuantity - 1,
      };
      const items = orderItems.map((orderItem: any) => {
        if (orderItem.id === orderItemId) {
          return {
            ...orderItem,
            quantity: currentQuantity - 1,
          };
        }
        return {
          ...orderItem,
        };
      });
      state.order = {
        ...state.order,
        orderItems: items,
      };
      ordersSlice.caseReducers.calculateOrderPricing(state);
    },
  },
  extraReducers: (builder) => {
    // createOrderAPI
    builder.addCase(fetchOrdersByLocationAPI.fulfilled, (state, action) => {
      state.orders = pathOr([], ['orders'], action.payload).flatMap((order) => {
        return {
          ...order,
          key: pathOr('', ['id'], order)
        }
      });
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrdersByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createOrderPaymentAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(createOrderPaymentAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createOrderPaymentAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createOrderAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.order = null;
      state.isLoading = false;
      window.location.href = ORDERS_ROUTE;
    });
    builder.addCase(createOrderAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createOrderAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrderAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.order = null;
      state.isLoading = false;
      window.location.href = ORDERS_ROUTE;
    });
    builder.addCase(updateOrderAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateOrderAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(bulkOrderUpdateAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload));
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(bulkOrderUpdateAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(bulkOrderUpdateAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrderByIdAPI.fulfilled, (state, action) => {
      state.order = {
        ...pathOr({}, ['order'], action.payload),
        orderItems: pathOr([], ['order', 'orderItems'], action.payload).map((orderItem, index) => {
          return {
            key: index.toString(),
            ...orderItem,
          }
        })
      };
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchOrderByIdAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchOrderByIdAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const {
  setOrder,
  addOrderItem,
  addSelectedOrderItemQuantity,
  selectOrderItem,
  removeOrderItem,
  removeSelectedOrderItemQuantity,
  addSelectedOrderModifierItemQuantity,
  removeSelectedOrderModifierItemQuantity,
  addModifierItem,
  removeModifierItem,
  setDeliveryInformation,
  clearOrderErrorsAndSuccesses,
  selectOrderModifierItem,
  updateOrderItem,
  fetchOrderStages,
  updateOrder,
  setCurrentPage,
  setTotalPerPage,
  updateFilters,
  createPayment,
  removePayment,
  deleteTransaction,
  removeRefund,
  createRefund,
} = ordersSlice.actions;

export default ordersSlice.reducer;
