import React from 'react';
import { FormInput } from 'components/formInput/FormInput';
import { FormSelect } from 'components/formSelect/FormSelect';

interface FormInputProps {
  label: string;
  name: string;
  required?: boolean;
  styling?: string;
  value?: any;
  type?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  isSelect?: boolean; // New prop to determine whether to render FormSelect
  isTextarea?: boolean; // New prop to determine whether to render textarea
  options?: Array<{ value: string; label: string }>; // Options for FormSelect
}

const FormInputRenderer: React.FC<FormInputProps> = ({
  label,
  value,
  type,
  name,
  required,
  styling,
  onChange,
  isSelect,
  isTextarea,
  options,
  placeholder
}) => (
  <div className={`p-2 w-full ${styling || 'sm:w-1/2 md:w-1/3'}`}>
    {isSelect ? (
      <FormSelect
        label={label}
        value={value}
        name={name}
        required={required}
        onChange={onChange}
        options={options}
      />
    ) : isTextarea ? (
      <FormInput
        label={label}
        value={value}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        type="textarea"
      />
    ) : (
      <FormInput
        label={label}
        value={value}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        type={type}
      />
    )}
  </div>
);

export default FormInputRenderer;
