import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import { Link } from "react-router-dom";
import DeleteModal from "components/modal/DeleteModal";
import { pathOr } from "ramda";
import CategoryFilters from "../components/filters";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MENU_MODIFIER_GROUPS_ROUTE } from "../../../../constants";
import MenuModifierGroupForm from "views/admin/menus/menu-modifier-groups/components/menu-modifier-group-form";
import { setMenuModifierGroup } from "../../../../store/menus/menu-modifier-groups";
import {
  archiveMenuModifierGroupAPI,
  deleteMenuModifierGroupAPI,
  fetchMenuModifierGroupsByLocationAPI,
} from "../../../../api/menu-modifier-groups";
import { fetchMenusByLocationAPI } from "../../../../api/menus";
import { setCurrentPage, setTotalPerPage } from "../../../../store/menus/menu-modifier-groups";
import { RootState } from "store";
import withPermissions from "components/hoc";

interface DataItem {
  id: string;
  name: string;
  categoryCode: string;
  description: string;
  createdBy: string;
  imageUrl: any;
  // You might have additional properties here
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}
const MenuModifierGroups: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const menuModifierGroups = useAppSelector(
    (state: any) => state.menuModifierGroups.menuModifierGroups,
  );
  const menuModifierGroup = useAppSelector(
    (state: any) => state.menuModifierGroups.menuModifierGroup,
  );
  const isLoading = useAppSelector((state: RootState) => state.menuModifierGroups.isLoading);
  const [filters, setFilters] = useState<Record<string, string | number>>({});
  const currentPage = useAppSelector((state: RootState) => state.menuModifierGroups.currentPage);
  const total = useAppSelector((state: RootState) => state.menuModifierGroups.total);
  const totalPerPage = useAppSelector((state: RootState) => state.menuModifierGroups.totalPerPage);
  const [searchText, setSearchText] = useState("");
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });

  useEffect(() => {
    dispatch(fetchMenuModifierGroupsByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    dispatch(fetchMenusByLocationAPI({}));
  }, [dispatch]);

  const columns: TableColumn[] = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["name"], a).localeCompare(pathOr("", ["name"], b)),
      render: (text, record: DataItem) => (
        <Space>
          <Link
            to={`${MENU_MODIFIER_GROUPS_ROUTE}/${record.id}/menu-modifier-items`}
            onClick={(e) => e.stopPropagation()}
            className="font-medium capitalize text-[15px]">
            {pathOr("", ["name"], record)}
          </Link>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: DataItem, b: DataItem) =>
        pathOr("", ["description"], a).localeCompare(pathOr("", ["description"], b)),
      render: (text, record: DataItem) => (
        <p className="font-medium text-sm text-gray-750">{pathOr("", ["description"], record)}</p>
      ),
    },
    {
      title: "Menu",
      dataIndex: "menu",
      key: "menu",
      render: (text, record: DataItem) => (
        <p className="font-medium text-sm text-gray-750">{pathOr("", ["menu", "name"], record)}</p>
      ),
    },
    {
      title: "Show On Web",
      dataIndex: "showOnWeb",
      key: "showOnWeb",
      render: (text, record: DataItem) => {
        return (
          <p className="capitalize font-medium text-[15px] text-gray-750">
            {String(pathOr(false, ["showOnWeb"], record))}
          </p>
        );
      },
    },
    {
      title: 'Is Archived',
      dataIndex: 'isArchived',
      key: 'isArchived',
      render: (text, record: DataItem) => <p className="font-medium capitalize text-[15px] text-gray-750">
        {String(pathOr('',['isArchived'],record))}
      </p>,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record: DataItem) => (
        <Space size="middle">
          <Button
            onClick={() =>
              navigate(`${MENU_MODIFIER_GROUPS_ROUTE}/${record.id}/menu-modifier-items`)
            }
            icon={<EyeOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
          />
          <Button
            icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "edit")}
          />
          <Button
            icon={<FolderOpenOutlined style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "archive")}
          />
          <Button
            icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
            type="link"
            onClick={() => openModal(record, "delete")}
          />
        </Space>
      ),
      width: "10%",
    },
  ];

  const openModal = (record: any, type: "create" | "edit" | "delete" | "archive") => {
    dispatch(setMenuModifierGroup(record));
    setModalState({ isOpen: true, type });
  };

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };

  const deleteMenuModifierGroup = async () => {
    await dispatch(
      deleteMenuModifierGroupAPI({
        menuModifierGroupId: pathOr("", ["id"], menuModifierGroup),
      }),
    );
    setModalState({ isOpen: false, type: null });
    await dispatch(
      fetchMenuModifierGroupsByLocationAPI({ page: currentPage, perPage: totalPerPage }),
    );
  };

  const archiveMenuModifierGroup = async () => {
    await dispatch(
      archiveMenuModifierGroupAPI({
        menuModifierGroupId: pathOr("", ["id"], menuModifierGroup),
      }),
    );
    setModalState({ isOpen: false, type: null });
    await dispatch(
      fetchMenuModifierGroupsByLocationAPI({ page: currentPage, perPage: totalPerPage }),
    );
  };

  const handleFilterChange = (newFilters: Record<string, string | number>) => {
    setFilters({ ...filters, ...newFilters });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText("");
  };

  const onRefresh = async () => {
    await dispatch(
      fetchMenuModifierGroupsByLocationAPI({
        refresh: true,
        page: currentPage,
        perPage: totalPerPage,
      }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchMenuModifierGroupsByLocationAPI({ page: current, perPage: size }));
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap">
        <div>
          <p className="text-lg font-bold">Menu Modifier Groups</p>
          <p className="text-sm text-gray-750">View Modifiers</p>
        </div>
        <div>
          <Button
            onClick={() => openModal(null, "create")}
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            icon={<PlusOutlined />}>
            Create Modifier Group
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex items-center md:justify-between mb-6 flex-wrap">
          <Button
            className="bg-blue-750 basis-9 h-9 mr-4"
            type="primary"
            onClick={toggleFilters}
            icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
          />
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="basis-full md:basis-auto md:flex-grow md:justify-end my-4 flex-wrap">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
          </Space>
        </div>
        <CategoryFilters onFilterChange={handleFilterChange} visible={filtersVisible} />
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={menuModifierGroups}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <MenuModifierGroupForm
          isOpen={modalState.isOpen && ["create", "edit"].includes(modalState.type)}
          handleClose={handleClose}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteMenuModifierGroup}
          title={"Delete Menu Modifier Group"}
          prompt={`Are you sure you want to delete menu modifier group`}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveMenuModifierGroup}
          title={"Archive Menu Modifier Group"}
          prompt={`Are you sure you want to archive menu modifier group`}
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default withPermissions(MenuModifierGroups);
