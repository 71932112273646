import { createSlice } from '@reduxjs/toolkit';
import {pathOr} from 'ramda';
import {
  fetchPurchasesReportByBusinessAPI,
  fetchPurchasesReportByLocationAPI,
  fetchPurchasesReportByRestaurantAPI
} from "../../api/reports/purchases";

interface PurchasesReportsState {
  purchasesReport: any[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
  purchasesGraphData: number[];
  filters: {
    fromDate: string,
    toDate: string,
  };
}

const initialState: PurchasesReportsState = {
  purchasesReport: [],
  status: 'idle',
  error: null,
  isLoading: false,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1,
  purchasesGraphData: [],
  filters: {
    fromDate: '',
    toDate: ''
  },
};

const purchasesReportsSlice = createSlice({
  name: 'purchases-reports',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPurchasesReportByLocationAPI.fulfilled, (state, action) => {
      state.purchasesReport = pathOr([], ['purchasesReport'], action.payload);
      state.totalPages = pathOr(1, ["meta", "totalPages"], action.payload);
      state.currentPage = pathOr(1, ["meta", "page"], action.payload);
      state.total = pathOr(0, ["meta", "total"], action.payload);
      state.totalPerPage = pathOr(30, ["meta", "perPage"], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesReportByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesReportByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPurchasesReportByRestaurantAPI.fulfilled, (state, action) => {
      state.purchasesReport = pathOr([], ['purchasesReport'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesReportByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesReportByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPurchasesReportByBusinessAPI.fulfilled, (state, action) => {
      state.purchasesReport = pathOr([], ['purchasesReport'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesReportByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchPurchasesReportByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { setTotalPerPage, setCurrentPage, updateFilters } = purchasesReportsSlice.actions;


export default purchasesReportsSlice.reducer;
