import { createSlice } from '@reduxjs/toolkit';
import {
  archiveCategoryAPI,
  createCategoryAPI, deleteCategoryAPI,
  fetchCategoriesByLocationAPI,
  fetchCategoryAPI,
  updateCategoryAPI
} from '../../api/categories';
import { pathOr } from 'ramda';
import {message} from "antd";

interface CategoriesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  isLoading: boolean;
  categories: any[];
  category: any;
  totalPerPage: number;
  totalPages: number;
  total: number;
  currentPage: number;
}

const initialState: CategoriesState = {
  status: 'idle',
  error: null,
  isLoading: false,
  categories: [],
  category: null,
  totalPerPage: 10,
  totalPages: 1,
  total: 0,
  currentPage: 1
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    updateCategory: (state, action) => {
      state.category = {
        ...state.category,
        ...action.payload
      }
    },
    setTotalPerPage: (state, action) => {
      state.totalPerPage = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriesByLocationAPI.fulfilled, (state, action) => {
      state.categories = pathOr([], ['categories'], action.payload);
      state.totalPages = pathOr(1, ['meta', 'totalPages'], action.payload);
      state.currentPage = pathOr(1, ['meta', 'page'], action.payload);
      state.total = pathOr(0, ['meta', 'total'], action.payload);
      state.totalPerPage = pathOr(10, ['meta', 'perPage'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchCategoriesByLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchCategoriesByLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategoryAPI.fulfilled, (state, action) => {
      state.category = pathOr(null, ['category'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(fetchCategoryAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchCategoryAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCategoryAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.category = pathOr([], ['category'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(createCategoryAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createCategoryAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCategoryAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.category = pathOr([], ['category'], action.payload);
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(updateCategoryAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateCategoryAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCategoryAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(deleteCategoryAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCategoryAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveCategoryAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.status = 'succeeded';
      state.isLoading = false;
    });
    builder.addCase(archiveCategoryAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveCategoryAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  }
});

export const {
  setCategory,
  updateCategory,
  setTotalPerPage,
  setCurrentPage
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
