import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Input, message, Select } from "antd";
import { CustomerType } from "types/AllTypes";
import {
  getCookie,
  removeUndefinedOrNull,
} from "helpers/utilities";
import { useNavigate, useParams } from "react-router-dom";
import { length, pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {CUSTOMERS_ROUTE} from "../../../../constants";
import {createCustomerAPI, fetchCustomerAPI, updateCustomerAPI} from "../../../../api/customers";
import {fetchLocationsByRestaurantAPI} from "../../../../api/locations";
import { Link } from "react-router-dom";
import { pickFields } from "utils/is-not-empty";
import { validateSchema } from "../validations/schema";
import { clearSuccessAndError } from "store/customers/customers";

interface CustomerFormProps {
  mode: "create" | "edit";
}

const CustomerForm: React.FC<CustomerFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: any) => state.customers.loading);
  const success = useAppSelector((state: any) => state.customers.success);
  const error = useAppSelector((state: any) => state.customers.error);
  const customer = useAppSelector((state: any) => state.customers.customer);
  const locations = useAppSelector((state: any) =>
     pathOr([], ['locations'], state.locations));
  const [errors, setErrors] = useState<any>({});
  const [formValues, setFormValues] = useState<CustomerType | any>({
    name: "",
    phoneNumber: "",
    email: "",
    locations: [],
  });

  useEffect(() => {
    if (success) {
      dispatch(clearSuccessAndError());
      navigate(CUSTOMERS_ROUTE);
    }

    if (error) {
      dispatch(clearSuccessAndError());
    }
  }, [success, error, dispatch, navigate])

  useEffect(() => {
    dispatch(fetchLocationsByRestaurantAPI());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomerAPI({customerId: id}));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && customer) {
      setFormValues({
        name: pathOr("", ["name"], customer),
        phoneNumber: pathOr("", ["phoneNumber"], customer),
        email: pathOr("", ["email"], customer),
        locations: pathOr([], ["locations"], customer)
      });
    }
  }, [customer, id]);

  const handleChange = (val: any, key: string) => {
    if (key === 'locations') {
      setFormValues({
        ...formValues,
        locations: locations.filter(
          (location) => val.includes(pathOr('', ['id'], location))).map(
            (location) => {
              return {
                id: pathOr('', ['id'], location),
                name: pathOr('', ['name'], location)
              }
            }),
      })
    } else {
      setFormValues({
        ...formValues,
        [key]: val,
      })
    }
  };

  const onFinish = async () => {
    setErrors({});
    const payload = {
      ...formValues,
      locations: pathOr([], ['locations'], formValues).map((location) => {
        return pathOr('', ['id'], location)
      })
    }
    const customerPayload: any = removeUndefinedOrNull({
      ...pickFields(payload, [
      "name",
      "phoneNumber",
      "email",
      "locations",
    ])});
    const { errors: errorsFromSchema } = validateSchema(customerPayload);
    setErrors(errorsFromSchema);
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return
    }
    if (id) {
      await dispatch(updateCustomerAPI({customerId: id, customer: customerPayload}));
    } else {
      await dispatch(createCustomerAPI({customer: {
        ...customerPayload,
        restaurantId: getCookie("currentRestaurant")
      }}));
    }
  };

  const breadcrumbItems = [
    { title: <Link to={CUSTOMERS_ROUTE}>Customers</Link> },
    { title: <span>{mode === "edit" ? `Edit customer` : "Add customer"}</span> },
  ];
  return (
     <div className="h-full overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Customers</p>
          <p className="text-base text-gray-750">
            <Breadcrumb items={breadcrumbItems} />
          </p>
        </div>
        <div>
          <div className="flex flex-wrap -m-2">
            <div className="p-2">
              <Button
                onClick={onFinish}
                loading={loading}
                type="primary"
                disabled={loading}
                className="bg-blue-750 w-[150px]">
                {id ? "Update customer" : "Create customer"}
              </Button>
            </div>
          </div>
        </div>
      </div>
       <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-col space-y-4">
          <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
            <div className="w-full md:w-[32%]">
              <span>
                Name<span className="text-red-500">*</span>
              </span>
              <Input
                name="name"
                required
                status={pathOr(null, ["Name"], errors) ? "error" : ""}
                value={pathOr("", ["name"], formValues)}
                onChange={(e) => handleChange(e.target.value, "name")}
              />
              {pathOr(null, ["Name"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Name", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Phone number<span className="text-red-500">*</span>
              </span>
              <Input
                name="phonenumber"
                required
                status={pathOr(null, ["PhoneNumber"], errors) ? "error" : ""}
                value={pathOr("", ["phoneNumber"], formValues)}
                onChange={(e) => handleChange(e.target.value, "phoneNumber")}
              />
              {pathOr(null, ["PhoneNumber"], errors) ? (
                <span className="text-red-500">{pathOr("", ["PhoneNumber", 0, "message"], errors)}</span>
              ) : null}
            </div>
            <div className="w-full md:w-[32%]">
              <span>
                Email<span className="text-red-500">*</span>
              </span>
              <Input
                name="email"
                required
                status={pathOr(null, ["email"], errors) ? "error" : ""}
                value={pathOr("", ["email"], formValues)}
                onChange={(e) => handleChange(e.target.value, "email")}
              />
              {pathOr(null, ["Email"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Email", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>

          <div className="mt-3 flex flex-wrap flex-row items-start justify-between">
            <div className="w-full">
              <span>
                Locations<span className="text-red-500">*</span>
              </span>
              <Select
                mode="tags"
                className="w-full"
                allowClear
                status={pathOr(null, ["Locations"], errors) ? "error" : ""}
                value={pathOr([], ['locations'], formValues).map((location) => {
                  return {
                    value: pathOr('', ['id'], location),
                    label: pathOr('', ['name'], location).toUpperCase(),
                  }
                })}
                onChange={(val) => handleChange(val, "locations")}
                options={locations.map((location: any) => ({
                  value: pathOr('', ['id'], location),
                  label: pathOr('', ['name'], location).toUpperCase() })
                )}
              />
              {pathOr(null, ["Locations"], errors) ? (
                <span className="text-red-500">{pathOr("", ["Locations", 0, "message"], errors)}</span>
              ) : null}
            </div>
          </div>
        </div>
       </div>
     </div>
  );
};

export default CustomerForm;
