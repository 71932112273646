import {Input, InputNumber, Select} from "antd";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {pathOr} from "ramda";
import {updateOrder} from "../../../store/orders/orders";

const OrderDeliveryForm = () => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: any) => state.orders.order);
  const employees = useAppSelector((state: any) => state.employees.employees);

  const onChange = (val: any, type: string) => {
    dispatch(updateOrder({
      delivery: {
        ...pathOr({}, ['delivery'], order),
        [type]: val,
      }
    }))
  }

  return (
     <div>
       <div className="w-full">
         <div className="flex flex-col space-y-4">
           <div className="w-full flex flex-row items-center justify-between">
             <div style={{width: '32%'}}>
               <span>Deliver Rider</span>
               <Select
                  className="w-full"
                  onSelect={(val) => onChange(val, 'deliveryRiderId')}
                  value={pathOr('', ['delivery', 'deliveryRiderId'], order)}
                  options={employees.map((employee: any) => {
                    return {
                      label: `${pathOr('', ['user', 'profile', 'firstname'], employee)} 
                   ${pathOr('', ['user', 'profile', 'lastname'], employee)} (
                   ${pathOr('', ['role'], employee)}
                   )`,
                      value: `${pathOr('', ['user', 'id'], employee)}`,
                    }
                  })} />
             </div>

             <div style={{width: '32%'}}>
               <span>Cost Of Delivery</span>
               <InputNumber
                  onChange={(val) => onChange(Number(val), 'costOfDelivery')}
                  value={pathOr(0, ['delivery', 'costOfDelivery'], order)}
                  className="w-full"
               />
             </div>

             <div style={{width: '32%'}}>
               <span>Distance Between</span>
               <InputNumber
                  onChange={(val) => onChange(Number(val), 'distanceBetween')}
                  value={pathOr(0, ['delivery', 'distanceBetween'], order)}
                  className="w-full"
               />
             </div>
           </div>

           <div className="w-full flex flex-row items-center justify-between">
             <div style={{width: '32%'}}>
               <span>Recipient Name</span>
               <Input
                  value={pathOr('', ['delivery', 'recipientName'], order)}
                  onChange={(e) => onChange(e.target.value, 'recipientName')}
               />
             </div>

             <div style={{width: '32%'}}>
               <span>Recipient Phone Number</span>
               <Input
                  value={pathOr('', ['delivery', 'recipientPhoneNumber'], order)}
                  onChange={(e) => onChange(e.target.value, 'recipientPhoneNumber')}
               />
             </div>

             <div style={{width: '32%'}}>
               <span>Recipient Email</span>
               <Input
                  value={pathOr('', ['delivery', 'recipientEmail'], order)}
                  onChange={(e) => onChange(e.target.value, 'recipientEmail')}
               />
             </div>
           </div>

           <div className="w-full flex flex-row items-center justify-between">
             <div style={{width: '32%'}}>
               <span>Street Address</span>
               <Input
                  value={pathOr('', ['delivery', 'streetAddress'], order)}
                  onChange={(e) => onChange(e.target.value, 'streetAddress')}
               />
             </div>

             <div style={{width: '32%'}}>
               <span>City</span>
               <Input
                  value={pathOr('', ['delivery', 'city'], order)}
                  onChange={(e) => onChange(e.target.value, 'city')}
               />
             </div>

             <div style={{width: '32%'}}>
               <span>Town</span>
               <Input
                  value={pathOr('', ['delivery', 'town'], order)}
                  onChange={(e) => onChange(e.target.value, 'town')}
               />
             </div>
           </div>

           <div>
             <div>
               <span>Zipcode</span>
               <Input
                  value={pathOr('', ['delivery', 'zipcode'], order)}
                  onChange={(e) => onChange(e.target.value, 'zipcode')}
               />
             </div>
           </div>
         </div>
       </div>
     </div>
  );
};

export default OrderDeliveryForm;
