import { getCookie } from "helpers/utilities";
import {client, getAuthorizationHeader, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const fetchTablesByLocationAPI = createAsyncThunk(
   'floors/fetch-tables-by-location',
   async (
      _, thunkAPI
   ) => {
     try {
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/tables`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const fetchFloorsByLocationAPI = createAsyncThunk(
   'floors/fetch-floors-by-location',
   async (
      _, thunkAPI
   ) => {
     try {
       const locationId = getCookie('currentLocation');
       const response = await client(`/locations/${locationId}/floors`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI);
     }
   }
);

export const createFloorAPI = createAsyncThunk(
   'floors/create-floor',
   async (
      payload: { floor: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/floors`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.floor
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateFloorAPI = createAsyncThunk(
   'floors/update-floor',
   async (
      payload: { floorId: string; floor: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/floors/${payload.floorId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.floor
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const deleteFloorAPI = createAsyncThunk(
   'floors/delete-floor',
   async (
      payload: { floorId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/floors/${payload.floorId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const archiveFloorAPI = createAsyncThunk(
   'floors/archive-floor',
   async (
      payload: { floorId: string}, thunkAPI
   ) => {
     try {
       const response = await client(`/floors/${payload.floorId}/archive`, {
         method: 'PATCH',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const createTableAPI = createAsyncThunk(
   'floors/create-table',
   async (
      payload: { table: any; floorId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/floors/${payload.floorId}/tables`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.table
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateTableAPI = createAsyncThunk(
   'floors/update-table',
   async (
      payload: { tableId: string; table: any }, thunkAPI
   ) => {
     try {
       const response = await client(`/tables/${payload.tableId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.table
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const deleteTableAPI = createAsyncThunk(
   'floors/delete-table',
   async (
      payload: { tableId: string; }, thunkAPI
   ) => {
     try {
       const response = await client(`/tables/${payload.tableId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

