import { FC, useState } from "react";
import ParentModal, { modalProps } from "components/modal/Modal";
import { Button, Select } from "antd";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { length, pathOr } from "ramda";
import { bulkOrderUpdateAPI } from "api/orders";

interface OrderStageProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedOrders: React.Key[];
  onOrderUpdate: () => void;
}

const MoveOrderStageModal: FC<OrderStageProps> = ({ isOpen, handleClose, selectedOrders, onOrderUpdate }) => {
  const [stage, setStage] = useState('');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: any) => state.orders.isLoading);
  const orderStages = useAppSelector((state: any) => state.orders.orderStages);
  const onFinish = async () => {
    await dispatch(
      bulkOrderUpdateAPI({
        stage,
        orders: selectedOrders,
      })
    );
    onOrderUpdate();
  };
  const onChange = (stg: string) => {
    setStage(stg);
  }
  return (
    <ParentModal isOpen={isOpen} handleClose={handleClose}>
      <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">Move Order Stages</h3>
          <div className="w-full">
           <div className="text-left w-full pt-3">
             <span>Stage<span className="text-red-500">*</span></span>
             <Select
              defaultValue="Stage"
              className="w-full"
              value={stage}
              onChange={onChange}
              options={orderStages.map((orderStage: {name: string; value: string}) => {
                if (!orderStage) {
                  return {
                    label: 'None',
                    value: ''
                  }
                }
                return {
                  label: pathOr('', ['name'], orderStage),
                  value: pathOr('', ['value'], orderStage)
                }
              })}
            />
           </div>
           <div className="mt-5 space-x-3 flex justify-end">
             <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={isLoading || length(selectedOrders) < 1 || !stage}
                className="bg-blue-750">
               Confirm
             </Button>
             <Button onClick={handleClose} type="default">
               Cancel
             </Button>
           </div>
         </div>
       </div>
    </ParentModal>
  )
}

export default MoveOrderStageModal;
