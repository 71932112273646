import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  createLocationAPI, fetchLocationsByRestaurantAPI,
} from "../../api/locations";
import { pathOr } from "ramda";
import {
  archiveLocationAPI,
  deleteLocationAPI, fetchLocationAPI,
  fetchLocationsByBusinessAPI,
  updateLocationAPI
} from "../../api/locations";
import {SETTINGS_ROUTE} from "../../constants";

interface LocationProps {
  locations: any[];
  currentLocation: any;
  isLoading: boolean;
  isSubmitting: boolean;
  location: any;
}

const initialState: LocationProps = {
  locations: [],
  currentLocation: null,
  location: null,
  isLoading: false,
  isSubmitting: false,
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<any>) => {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocationsByBusinessAPI.fulfilled, (state, action) => {
      state.locations = pathOr([], ['locations'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchLocationsByBusinessAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLocationsByBusinessAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLocationsByRestaurantAPI.fulfilled, (state, action) => {
      state.locations = pathOr([], ['locations'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchLocationsByRestaurantAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLocationsByRestaurantAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createLocationAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.location = pathOr([], ['location'], action.payload);
      state.isLoading = false;
      window.location.href = SETTINGS_ROUTE;
    });
    builder.addCase(createLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateLocationAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.location = pathOr([], ['location'], action.payload);
      state.isLoading = false;
      window.location.href = SETTINGS_ROUTE;
    });
    builder.addCase(updateLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteLocationAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.isLoading = false;
    });
    builder.addCase(deleteLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(archiveLocationAPI.fulfilled, (state, action) => {
      message.success(pathOr('', ['message'], action.payload))
      state.isLoading = false;
    });
    builder.addCase(archiveLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(archiveLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLocationAPI.fulfilled, (state, action) => {
      state.location = pathOr(null, ['location'], action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchLocationAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLocationAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  }
});

export const { setLocation} = locationsSlice.actions;
export default locationsSlice.reducer;
