import Joi from "joi";
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string().trim().required().label('Menu item name'),
  price: Joi.number().positive().allow(0).required().label('Menu item price'),
  description: Joi.string().trim().allow(null).optional().label('Menu item description'),
  calories: Joi.number().positive().allow(null).optional().label('Menu item calories'),
  isAvailable: Joi.boolean().optional().label('Menu item availability'),
  isArchived: Joi.boolean().optional().label('Menu item archived'),
  showOnWeb: Joi.boolean().optional().label('Menu item shown on web'),
  servingSize: Joi.string().trim().allow(null).optional().label('Menu item serving size'),
  preparationTimeInMinutes: Joi.number().positive().allow(null).optional().label('Menu item preparation time'),
  allergenInformation: Joi.string().trim().allow(null).optional().label('Menu item allergen information'),
  nutritionalInformation: Joi.string().trim().allow(null).optional().label('Menu item nutritional information'),
  imageURLs: Joi.array().items(Joi.string()).optional().label('Menu Item Image URLs'),
  menuItemModifierGroups: Joi.array().items(
     Joi.object({ menuModifierGroupId: Joi.string().required() })
  ).optional().label('Menu item modifier groups'),
  ingredients: Joi.array().items(
     Joi.object({
       productId: Joi.string().trim().required().label('Ingredient product id'),
       quantity: Joi.number().positive().required().label('Ingredient quantity'),
       unitOfMeasure: Joi.string().trim().required().label('Ingredient unit of measure'),
     })
  ).optional().label('Ingredients')
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
