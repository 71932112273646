import Card from "components/card";
import ReactApexChart from "react-apexcharts";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchSalesReportByLocationAPI } from "api/reports/sales";
import { AppDispatch, RootState } from "store";
import { getTimeframeDates } from "helpers/utilities";
import { MdOutlineCalendarToday } from "react-icons/md";
  
const SalesOvertime = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const salesReportGraphSeries = useAppSelector(
    (state: RootState) => state.salesReports.salesReportGraphSeries);

  const chartSeries = salesReportGraphSeries;
  const chartOptions: any = {
    legend: {
      show: true,
    },
    theme: {
      mode: "light",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    chart: {
      id: 'time-series-chart',
      zoom: {
        enabled: false,
        type: 'x',
        autoScaleYaxis: true,
      },
      toolbar: {
        tools: {
          download: false,
        },
        autoSelected: 'zoom',
      },
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
        backgroundColor: "#000000",
      },
      theme: "dark",
      y: {
        formatter: (val: number) => {
          return `${val.toLocaleString()}`;
        },
      },
      x: {
        format: "dd, dddd MMMM, yyyy",
      },
    },
  };

  useEffect(() => {
    const { fromDate, toDate } = getTimeframeDates('this_month');
    dispatch(fetchSalesReportByLocationAPI({ fromDate, toDate, refresh: true, groupBy: 'day' }));
  }, [dispatch])

  return (
      <Card extra="!p-[20px]">
        <div className="flex h-full w-full flex-col">
          <div className="flex justify-between">
            <button className="linear mt-1 flex items-center
             justify-center gap-2 rounded-lg bg-lightPrimary p-2
              text-gray-600 transition duration-200 hover:cursor-pointer
               hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700
                dark:hover:opacity-90 dark:active:opacity-80">
              <MdOutlineCalendarToday />
              <span className="text-sm font-medium text-gray-600">This month</span>
            </button>
          </div>
          <div>
          <h4 className="mt-2 text-lg font-bold text-navy-700 dark:text-white">
            Monthly Sales
          </h4>
          </div>
          <div className="h-full w-full">
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="line"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </Card>
  );
};
  
export default SalesOvertime;
  