import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnType } from "antd/lib/table";
import { AppDispatch, RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pathOr } from "ramda";
import { fetchInventoryByLocationAPI } from "api/inventory";
import { Link } from "react-router-dom";
import { INVENTORY_ROUTE } from "../../../../constants";

interface DataItem {
  id: string;
  name: string;
  quantityInStock: string;
  unitOfMeasure: string;
  categoryId: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const columns: TableColumn[] = [
  {
    title: 'Product name',
    dataIndex: 'name',
    key: 'name',
    className: 'whitespace-nowrap',
    sorter: (a: DataItem, b: DataItem) => pathOr('',['name'],a).localeCompare(pathOr('',['name'],b)),
    render: (text, record: DataItem) => (
      <Link to={`${INVENTORY_ROUTE}/${record.id}/edit`}>
        <p className="font-medium text-[15px] capitalize">{pathOr('',['name'],record)}</p>
      </Link>
    ),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantityInStock',
    key: 'quantityInStock',
    sorter: (a: DataItem, b: DataItem) => pathOr('',['quantityInStock'],a).localeCompare(pathOr('',['quantityInStock'],b)),
    render: (text, record: DataItem) => <p className="font-medium text-[15px] text-gray-750">{pathOr('',['quantityInStock'],record)}</p>,
  },
  {
    title: 'Re-order Level',
    dataIndex: 'reOrderLevel',
    key: 'reOrderLevel',
    sorter: (a: DataItem, b: DataItem) => pathOr('',['reOrderLevel'],a).localeCompare(pathOr('',['reOrderLevel'],b)),
    render: (text, record: DataItem) => <p className="font-medium text-[15px] text-gray-750">{pathOr('',['reOrderLevel'],record)}</p>,
  },
  {
    title: 'Unit',
    dataIndex: 'unitOfMeasure',
    key: 'unitOfMeasure',
    sorter: (a: DataItem, b: DataItem) => pathOr('',['unitOfMeasure'],a).localeCompare(pathOr('',['unitOfMeasure'],b)),
    render: (text, record: DataItem) => <p className="font-medium capitalize text-[15px] text-gray-750">{pathOr('',['unitOfMeasure'],record)}</p>,
  },
];


const InventoryStockLevels = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const inventory = useAppSelector(
    (state: RootState) => state.inventory.inventory);

  useEffect(() => {
    dispatch(fetchInventoryByLocationAPI({ 
      page: 1,
      perPage: 10,
      quantityInStock: 'sort_low_to_high',
    }));
  }, [dispatch]);

  return (
    <div className="bg-white p-4 rounded-xl">
      <div>
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Inventory Stock Levels
        </h4>
      </div>
      <div className="overflow-x-scroll no-scroll mt-5">
        <Table 
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={inventory}
          pagination={false}
          className="h-[320px]"
        />
      </div>
    </div>
  );
};

export default InventoryStockLevels;
