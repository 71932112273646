import { createSlice } from "@reduxjs/toolkit";
import { pathOr } from "ramda";
import { fetchLocationStatsAPI } from "api/reports/stats";

interface StatsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  isLoading: boolean;
  stats: any;
}

const initialState: StatsState = {
  status: "idle",
  error: null,
  isLoading: false,
  stats: null,
};

const statsReportsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocationStatsAPI.fulfilled, (state, action) => {
      state.stats = pathOr(null, ["stats"], action.payload);
      state.status = "succeeded";
      state.isLoading = false;
    });
    builder.addCase(fetchLocationStatsAPI.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchLocationStatsAPI.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export default statsReportsSlice.reducer;
