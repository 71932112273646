export const currencies = [
    {
      currencyName: "Afghan Afghani",
      currencySymbol: "؋",
      currencyIsoCode: "AFN"
    },
    {
      currencyName: "Albanian Lek",
      currencySymbol: "L",
      currencyIsoCode: "ALL"
    },
    {
      currencyName: "Algerian Dinar",
      currencySymbol: "د.ج",
      currencyIsoCode: "DZD"
    },
    {
      currencyName: "Angolan Kwanza",
      currencySymbol: "Kz",
      currencyIsoCode: "AOA"
    },
    {
      currencyName: "Argentine Peso",
      currencySymbol: "$",
      currencyIsoCode: "ARS"
    },
    {
      currencyName: "Armenian Dram",
      currencySymbol: "֏",
      currencyIsoCode: "AMD"
    },
    {
      currencyName: "Aruban Florin",
      currencySymbol: "ƒ",
      currencyIsoCode: "AWG"
    },
    {
      currencyName: "Australian Dollar",
      currencySymbol: "$",
      currencyIsoCode: "AUD"
    },
    {
      currencyName: "Azerbaijani Manat",
      currencySymbol: "₼",
      currencyIsoCode: "AZN"
    },
    {
      currencyName: "Bahamian Dollar",
      currencySymbol: "$",
      currencyIsoCode: "BSD"
    },
    {
      currencyName: "Bahraini Dinar",
      currencySymbol: ".د.ب",
      currencyIsoCode: "BHD"
    },
    {
      currencyName: "Bangladeshi Taka",
      currencySymbol: "৳",
      currencyIsoCode: "BDT"
    },
    {
      currencyName: "Barbadian Dollar",
      currencySymbol: "Bds$",
      currencyIsoCode: "BBD"
    },
    {
      currencyName: "Belarusian Ruble",
      currencySymbol: "Br",
      currencyIsoCode: "BYN"
    },
    {
      currencyName: "Belize Dollar",
      currencySymbol: "BZ$",
      currencyIsoCode: "BZD"
    },
    {
      currencyName: "Bermudian Dollar",
      currencySymbol: "$",
      currencyIsoCode: "BMD"
    },
    {
      currencyName: "Bhutanese Ngultrum",
      currencySymbol: "Nu.",
      currencyIsoCode: "BTN"
    },
    {
      currencyName: "Bitcoin",
      currencySymbol: "₿",
      currencyIsoCode: "BTC"
    },
    {
      currencyName: "Bolivian Boliviano",
      currencySymbol: "Bs.",
      currencyIsoCode: "BOB"
    },
    {
      currencyName: "Bosnia-Herzegovina Convertible Mark",
      currencySymbol: "KM",
      currencyIsoCode: "BAM"
    },
    {
      currencyName: "Botswana Pula",
      currencySymbol: "P",
      currencyIsoCode: "BWP"
    },
    {
      currencyName: "Brazilian Real",
      currencySymbol: "R$",
      currencyIsoCode: "BRL"
    },
    {
      currencyName: "Brunei Dollar",
      currencySymbol: "B$",
      currencyIsoCode: "BND"
    },
    {
      currencyName: "Bulgarian Lev",
      currencySymbol: "лв",
      currencyIsoCode: "BGN"
    },
    {
      currencyName: "Burundian Franc",
      currencySymbol: "FBu",
      currencyIsoCode: "BIF"
    },
    {
      currencyName: "Cambodian Riel",
      currencySymbol: "៛",
      currencyIsoCode: "KHR"
    },
    {
      currencyName: "Canadian Dollar",
      currencySymbol: "$",
      currencyIsoCode: "CAD"
    },
    {
      currencyName: "Cape Verdean Escudo",
      currencySymbol: "$",
      currencyIsoCode: "CVE"
    },
    {
      currencyName: "Cayman Islands Dollar",
      currencySymbol: "CI$",
      currencyIsoCode: "KYD"
    },
    {
      currencyName: "Central African CFA Franc",
      currencySymbol: "FCFA",
      currencyIsoCode: "XAF"
    },
    {
      currencyName: "CFP Franc",
      currencySymbol: "₣",
      currencyIsoCode: "XPF"
    },
    {
      currencyName: "Chilean Peso",
      currencySymbol: "$",
      currencyIsoCode: "CLP"
    },
    {
      currencyName: "Chinese Yuan",
      currencySymbol: "¥",
      currencyIsoCode: "CNY"
    },
    {
      currencyName: "Colombian Peso",
      currencySymbol: "$",
      currencyIsoCode: "COP"
    },
    {
      currencyName: "Comorian Franc",
      currencySymbol: "CF",
      currencyIsoCode: "KMF"
    },
    {
      currencyName: "Congolese Franc",
      currencySymbol: "FC",
      currencyIsoCode: "CDF"
    },
    {
      currencyName: "Costa Rican Colón",
      currencySymbol: "₡",
      currencyIsoCode: "CRC"
    },
    {
      currencyName: "Croatian Kuna",
      currencySymbol: "kn",
      currencyIsoCode: "HRK"
    },
    {
      currencyName: "Cuban Peso",
      currencySymbol: "$",
      currencyIsoCode: "CUP"
    },
    {
      currencyName: "Czech Koruna",
      currencySymbol: "Kč",
      currencyIsoCode: "CZK"
    },
    {
      currencyName: "Danish Krone",
      currencySymbol: "kr",
      currencyIsoCode: "DKK"
    },
    {
      currencyName: "Djiboutian Franc",
      currencySymbol: "Fdj",
      currencyIsoCode: "DJF"
    },
    {
      currencyName: "Dominican Peso",
      currencySymbol: "RD$",
      currencyIsoCode: "DOP"
    },
    {
      currencyName: "East Caribbean Dollar",
      currencySymbol: "EC$",
      currencyIsoCode: "XCD"
    },
    {
      currencyName: "Egyptian Pound",
      currencySymbol: "E£",
      currencyIsoCode: "EGP"
    },
    {
      currencyName: "Eritrean Nakfa",
      currencySymbol: "Nfk",
      currencyIsoCode: "ERN"
    },
    {
      currencyName: "Ethiopian Birr",
      currencySymbol: "Br",
      currencyIsoCode: "ETB"
    },
    {
      currencyName: "Euro",
      currencySymbol: "€",
      currencyIsoCode: "EUR"
    },
    {
      currencyName: "Falkland Islands Pound",
      currencySymbol: "FK£",
      currencyIsoCode: "FKP"
    },
    {
      currencyName: "Fijian Dollar",
      currencySymbol: "$",
      currencyIsoCode: "FJD"
    },
    {
      currencyName: "Gambian Dalasi",
      currencySymbol: "D",
      currencyIsoCode: "GMD"
    },
    {
      currencyName: "Georgian Lari",
      currencySymbol: "ლ",
      currencyIsoCode: "GEL"
    },
    {
      currencyName: "Ghanaian Cedi",
      currencySymbol: "GH₵",
      currencyIsoCode: "GHS"
    },
    {
      currencyName: "Gibraltar Pound",
      currencySymbol: "£",
      currencyIsoCode: "GIP"
    },
    {
      currencyName: "Guatemalan Quetzal",
      currencySymbol: "Q",
      currencyIsoCode: "GTQ"
    },
    {
      currencyName: "Guinean Franc",
      currencySymbol: "FG",
      currencyIsoCode: "GNF"
    },
    {
      currencyName: "Guyanaese Dollar",
      currencySymbol: "$",
      currencyIsoCode: "GYD"
    },
    {
      currencyName: "Haitian Gourde",
      currencySymbol: "G",
      currencyIsoCode: "HTG"
    },
    {
      currencyName: "Honduran Lempira",
      currencySymbol: "L",
      currencyIsoCode: "HNL"
    },
    {
      currencyName: "Hong Kong Dollar",
      currencySymbol: "HK$",
      currencyIsoCode: "HKD"
    },
    {
      currencyName: "Hungarian Forint",
      currencySymbol: "Ft",
      currencyIsoCode: "HUF"
    },
    {
      currencyName: "Icelandic Króna",
      currencySymbol: "kr",
      currencyIsoCode: "ISK"
    },
    {
      currencyName: "Indian Rupee",
      currencySymbol: "₹",
      currencyIsoCode: "INR"
    },
    {
      currencyName: "Indonesian Rupiah",
      currencySymbol: "Rp",
      currencyIsoCode: "IDR"
    },
    {
      currencyName: "Iranian Rial",
      currencySymbol: "﷼",
      currencyIsoCode: "IRR"
    },
    {
      currencyName: "Iraqi Dinar",
      currencySymbol: "ع.د",
      currencyIsoCode: "IQD"
    },
    {
      currencyName: "Israeli Shekel",
      currencySymbol: "₪",
      currencyIsoCode: "ILS"
    },
    {
      currencyName: "Jamaican Dollar",
      currencySymbol: "J$",
      currencyIsoCode: "JMD"
    },
    {
      currencyName: "Japanese Yen",
      currencySymbol: "¥",
      currencyIsoCode: "JPY"
    },
    {
      currencyName: "Jordanian Dinar",
      currencySymbol: "JD",
      currencyIsoCode: "JOD"
    },
    {
      currencyName: "Kazakhstani Tenge",
      currencySymbol: "₸",
      currencyIsoCode: "KZT"
    },
    {
      currencyName: "Kenyan Shilling",
      currencySymbol: "KSh",
      currencyIsoCode: "KES"
    },
    {
      currencyName: "Kuwaiti Dinar",
      currencySymbol: "د.ك",
      currencyIsoCode: "KWD"
    },
    {
      currencyName: "Kyrgyzstani Som",
      currencySymbol: "сом",
      currencyIsoCode: "KGS"
    },
    {
      currencyName: "Laotian Kip",
      currencySymbol: "₭",
      currencyIsoCode: "LAK"
    },
    {
      currencyName: "Lebanese Pound",
      currencySymbol: "ل.ل",
      currencyIsoCode: "LBP"
    },
    {
      currencyName: "Lesotho Loti",
      currencySymbol: "M",
      currencyIsoCode: "LSL"
    },
    {
      currencyName: "Liberian Dollar",
      currencySymbol: "L$",
      currencyIsoCode: "LRD"
    },
    {
      currencyName: "Libyan Dinar",
      currencySymbol: "ل.د",
      currencyIsoCode: "LYD"
    },
    {
      currencyName: "Macanese Pataca",
      currencySymbol: "MOP$",
      currencyIsoCode: "MOP"
    },
    {
      currencyName: "Macedonian Denar",
      currencySymbol: "ден",
      currencyIsoCode: "MKD"
    },
    {
      currencyName: "Malagasy Ariary",
      currencySymbol: "Ar",
      currencyIsoCode: "MGA"
    },
    {
      currencyName: "Malawian Kwacha",
      currencySymbol: "MK",
      currencyIsoCode: "MWK"
    },
    {
      currencyName: "Malaysian Ringgit",
      currencySymbol: "RM",
      currencyIsoCode: "MYR"
    },
    {
      currencyName: "Maldivian Rufiyaa",
      currencySymbol: "Rf",
      currencyIsoCode: "MVR"
    },
    {
      currencyName: "Mauritanian Ouguiya",
      currencySymbol: "UM",
      currencyIsoCode: "MRU"
    },
    {
      currencyName: "Mauritian Rupee",
      currencySymbol: "₨",
      currencyIsoCode: "MUR"
    },
    {
      currencyName: "Mexican Peso",
      currencySymbol: "$",
      currencyIsoCode: "MXN"
    },
    {
      currencyName: "Moldovan Leu",
      currencySymbol: "L",
      currencyIsoCode: "MDL"
    },
    {
      currencyName: "Mongolian Tugrik",
      currencySymbol: "₮",
      currencyIsoCode: "MNT"
    },
    {
      currencyName: "Moroccan Dirham",
      currencySymbol: "د.م.",
      currencyIsoCode: "MAD"
    },
    {
      currencyName: "Mozambican Metical",
      currencySymbol: "MT",
      currencyIsoCode: "MZN"
    },
    {
      currencyName: "Myanmar Kyat",
      currencySymbol: "K",
      currencyIsoCode: "MMK"
    },
    {
      currencyName: "Namibian Dollar",
      currencySymbol: "N$",
      currencyIsoCode: "NAD"
    },
    {
      currencyName: "Nepalese Rupee",
      currencySymbol: "₨",
      currencyIsoCode: "NPR"
    },
    {
      currencyName: "Netherlands Antillean Guilder",
      currencySymbol: "ƒ",
      currencyIsoCode: "ANG"
    },
    {
      currencyName: "New Taiwan Dollar",
      currencySymbol: "NT$",
      currencyIsoCode: "TWD"
    },
    {
      currencyName: "New Zealand Dollar",
      currencySymbol: "$",
      currencyIsoCode: "NZD"
    },
    {
      currencyName: "Nicaraguan Córdoba",
      currencySymbol: "C$",
      currencyIsoCode: "NIO"
    },
    {
      currencyName: "Nigerian Naira",
      currencySymbol: "₦",
      currencyIsoCode: "NGN"
    },
    {
      currencyName: "North Korean Won",
      currencySymbol: "₩",
      currencyIsoCode: "KPW"
    },
    {
      currencyName: "Norwegian Krone",
      currencySymbol: "kr",
      currencyIsoCode: "NOK"
    },
    {
      currencyName: "Omani Rial",
      currencySymbol: "ر.ع.",
      currencyIsoCode: "OMR"
    },
    {
      currencyName: "Pakistani Rupee",
      currencySymbol: "₨",
      currencyIsoCode: "PKR"
    },
    {
      currencyName: "Panamanian Balboa",
      currencySymbol: "B/.",
      currencyIsoCode: "PAB"
    },
    {
      currencyName: "Papua New Guinean Kina",
      currencySymbol: "K",
      currencyIsoCode: "PGK"
    },
    {
      currencyName: "Paraguayan Guarani",
      currencySymbol: "₲",
      currencyIsoCode: "PYG"
    },
    {
      currencyName: "Peruvian Nuevo Sol",
      currencySymbol: "S/.",
      currencyIsoCode: "PEN"
    },
    {
      currencyName: "Philippine Peso",
      currencySymbol: "₱",
      currencyIsoCode: "PHP"
    },
    {
      currencyName: "Polish Zloty",
      currencySymbol: "zł",
      currencyIsoCode: "PLN"
    },
    {
      currencyName: "Qatari Riyal",
      currencySymbol: "ر.ق",
      currencyIsoCode: "QAR"
    },
    {
      currencyName: "Romanian Leu",
      currencySymbol: "lei",
      currencyIsoCode: "RON"
    },
    {
      currencyName: "Russian Ruble",
      currencySymbol: "₽",
      currencyIsoCode: "RUB"
    },
    {
      currencyName: "Rwandan Franc",
      currencySymbol: "RF",
      currencyIsoCode: "RWF"
    },
    {
      currencyName: "Saint Helena Pound",
      currencySymbol: "£",
      currencyIsoCode: "SHP"
    },
    {
      currencyName: "Samoan Tala",
      currencySymbol: "WS$",
      currencyIsoCode: "WST"
    },
    {
      currencyName: "São Tomé and Príncipe Dobra",
      currencySymbol: "Db",
      currencyIsoCode: "STN"
    },
    {
      currencyName: "Saudi Riyal",
      currencySymbol: "ر.س",
      currencyIsoCode: "SAR"
    },
    {
      currencyName: "Serbian Dinar",
      currencySymbol: "дин",
      currencyIsoCode: "RSD"
    },
    {
      currencyName: "Seychellois Rupee",
      currencySymbol: "₨",
      currencyIsoCode: "SCR"
    },
    {
      currencyName: "Sierra Leonean Leone",
      currencySymbol: "Le",
      currencyIsoCode: "SLL"
    },
    {
      currencyName: "Silver Ounce",
      currencySymbol: "XAG",
      currencyIsoCode: null
    },
    {
      currencyName: "Singapore Dollar",
      currencySymbol: "S$",
      currencyIsoCode: "SGD"
    },
    {
      currencyName: "Solomon Islands Dollar",
      currencySymbol: "SI$",
      currencyIsoCode: "SBD"
    },
    {
      currencyName: "Somali Shilling",
      currencySymbol: "Sh.so.",
      currencyIsoCode: "SOS"
    },
    {
      currencyName: "South African Rand",
      currencySymbol: "R",
      currencyIsoCode: "ZAR"
    },
    {
      currencyName: "South Korean Won",
      currencySymbol: "₩",
      currencyIsoCode: "KRW"
    },
    {
      currencyName: "South Sudanese Pound",
      currencySymbol: "£",
      currencyIsoCode: "SSP"
    },
    {
      currencyName: "Sri Lankan Rupee",
      currencySymbol: "₨",
      currencyIsoCode: "LKR"
    },
    {
      currencyName: "Sudanese Pound",
      currencySymbol: "£",
      currencyIsoCode: "SDG"
    },
    {
      currencyName: "Surinamese Dollar",
      currencySymbol: "$",
      currencyIsoCode: "SRD"
    },
    {
      currencyName: "Swazi Lilangeni",
      currencySymbol: "L",
      currencyIsoCode: "SZL"
    },
    {
      currencyName: "Swedish Krona",
      currencySymbol: "kr",
      currencyIsoCode: "SEK"
    },
    {
      currencyName: "Swiss Franc",
      currencySymbol: "CHF",
      currencyIsoCode: "CHF"
    },
    {
      currencyName: "Syrian Pound",
      currencySymbol: "£S",
      currencyIsoCode: "SYP"
    },
    {
      currencyName: "Tajikistani Somoni",
      currencySymbol: "SM",
      currencyIsoCode: "TJS"
    },
    {
      currencyName: "Tanzanian Shilling",
      currencySymbol: "TSh",
      currencyIsoCode: "TZS"
    },
    {
      currencyName: "Thai Baht",
      currencySymbol: "฿",
      currencyIsoCode: "THB"
    },
    {
      currencyName: "Tongan Pa'anga",
      currencySymbol: "T$",
      currencyIsoCode: "TOP"
    },
    {
      currencyName: "Trinidad and Tobago Dollar",
      currencySymbol: "TT$",
      currencyIsoCode: "TTD"
    },
    {
      currencyName: "Tunisian Dinar",
      currencySymbol: "د.ت",
      currencyIsoCode: "TND"
    },
    {
      currencyName: "Turkish Lira",
      currencySymbol: "₺",
      currencyIsoCode: "TRY"
    },
    {
      currencyName: "Turkmenistani Manat",
      currencySymbol: "m",
      currencyIsoCode: "TMT"
    },
    {
      currencyName: "Ugandan Shilling",
      currencySymbol: "USh",
      currencyIsoCode: "UGX"
    },
    {
      currencyName: "Ukrainian Hryvnia",
      currencySymbol: "₴",
      currencyIsoCode: "UAH"
    },
    {
      currencyName: "United Arab Emirates Dirham",
      currencySymbol: "د.إ",
      currencyIsoCode: "AED"
    },
    {
      currencyName: "Uruguayan Peso",
      currencySymbol: "$",
      currencyIsoCode: "UYU"
    },
    {
      currencyName: "Uzbekistan Som",
      currencySymbol: "soʻm",
      currencyIsoCode: "UZS"
    },
    {
      currencyName: "Vanuatu Vatu",
      currencySymbol: "VT",
      currencyIsoCode: "VUV"
    },
    {
      currencyName: "Venezuelan Bolívar",
      currencySymbol: "Bs.",
      currencyIsoCode: "VES"
    },
    {
      currencyName: "Vietnamese Dong",
      currencySymbol: "₫",
      currencyIsoCode: "VND"
    },
    {
      currencyName: "Yemeni Rial",
      currencySymbol: "﷼",
      currencyIsoCode: "YER"
    },
    {
      currencyName: "Zambian Kwacha",
      currencySymbol: "ZK",
      currencyIsoCode: "ZMW"
    },
    {
      currencyName: "Zimbabwean Dollar",
      currencySymbol: "Z$",
      currencyIsoCode: "ZWL"
    },
  {
    currencyName: "Afghan Afghani",
    currencySymbol: "؋",
    currencyIsoCode: "AFN"
  },
  {
    currencyName: "Albanian Lek",
    currencySymbol: "L",
    currencyIsoCode: "ALL"
  },
  {
    currencyName: "Algerian Dinar",
    currencySymbol: "د.ج",
    currencyIsoCode: "DZD"
  },
  {
    currencyName: "Angolan Kwanza",
    currencySymbol: "Kz",
    currencyIsoCode: "AOA"
  },
  {
    currencyName: "Argentine Peso",
    currencySymbol: "$",
    currencyIsoCode: "ARS"
  },
  {
    currencyName: "Armenian Dram",
    currencySymbol: "֏",
    currencyIsoCode: "AMD"
  },
  {
    currencyName: "Aruban Florin",
    currencySymbol: "ƒ",
    currencyIsoCode: "AWG"
  },
  {
    currencyName: "Australian Dollar",
    currencySymbol: "$",
    currencyIsoCode: "AUD"
  },
  {
    currencyName: "Azerbaijani Manat",
    currencySymbol: "₼",
    currencyIsoCode: "AZN"
  },
  {
    currencyName: "Bahamian Dollar",
    currencySymbol: "$",
    currencyIsoCode: "BSD"
  },
  {
    currencyName: "Bahraini Dinar",
    currencySymbol: ".د.ب",
    currencyIsoCode: "BHD"
  },
  {
    currencyName: "Bangladeshi Taka",
    currencySymbol: "৳",
    currencyIsoCode: "BDT"
  },
  {
    currencyName: "Barbadian Dollar",
    currencySymbol: "Bds$",
    currencyIsoCode: "BBD"
  },
  {
    currencyName: "Belarusian Ruble",
    currencySymbol: "Br",
    currencyIsoCode: "BYN"
  },
  {
    currencyName: "Belize Dollar",
    currencySymbol: "BZ$",
    currencyIsoCode: "BZD"
  },
  {
    currencyName: "Bermudian Dollar",
    currencySymbol: "$",
    currencyIsoCode: "BMD"
  },
  {
    currencyName: "Bhutanese Ngultrum",
    currencySymbol: "Nu.",
    currencyIsoCode: "BTN"
  },
  {
    currencyName: "Bitcoin",
    currencySymbol: "₿",
    currencyIsoCode: "BTC"
  },
  {
    currencyName: "Bolivian Boliviano",
    currencySymbol: "Bs.",
    currencyIsoCode: "BOB"
  },
  {
    currencyName: "Bosnia-Herzegovina Convertible Mark",
    currencySymbol: "KM",
    currencyIsoCode: "BAM"
  },
  {
    currencyName: "Botswana Pula",
    currencySymbol: "P",
    currencyIsoCode: "BWP"
  },
  {
    currencyName: "Brazilian Real",
    currencySymbol: "R$",
    currencyIsoCode: "BRL"
  },
  {
    currencyName: "Brunei Dollar",
    currencySymbol: "B$",
    currencyIsoCode: "BND"
  },
  {
    currencyName: "Bulgarian Lev",
    currencySymbol: "лв",
    currencyIsoCode: "BGN"
  },
  {
    currencyName: "Burundian Franc",
    currencySymbol: "FBu",
    currencyIsoCode: "BIF"
  },
  {
    currencyName: "Cambodian Riel",
    currencySymbol: "៛",
    currencyIsoCode: "KHR"
  },
  {
    currencyName: "Canadian Dollar",
    currencySymbol: "$",
    currencyIsoCode: "CAD"
  },
  {
    currencyName: "Cape Verdean Escudo",
    currencySymbol: "$",
    currencyIsoCode: "CVE"
  },
  {
    currencyName: "Cayman Islands Dollar",
    currencySymbol: "CI$",
    currencyIsoCode: "KYD"
  },
  {
    currencyName: "Central African CFA Franc",
    currencySymbol: "FCFA",
    currencyIsoCode: "XAF"
  },
  {
    currencyName: "CFP Franc",
    currencySymbol: "₣",
    currencyIsoCode: "XPF"
  },
  {
    currencyName: "Chilean Peso",
    currencySymbol: "$",
    currencyIsoCode: "CLP"
  },
  {
    currencyName: "Chinese Yuan",
    currencySymbol: "¥",
    currencyIsoCode: "CNY"
  },
  {
    currencyName: "Colombian Peso",
    currencySymbol: "$",
    currencyIsoCode: "COP"
  },
  {
    currencyName: "Comorian Franc",
    currencySymbol: "CF",
    currencyIsoCode: "KMF"
  },
  {
    currencyName: "Congolese Franc",
    currencySymbol: "FC",
    currencyIsoCode: "CDF"
  },
  {
    currencyName: "Costa Rican Colón",
    currencySymbol: "₡",
    currencyIsoCode: "CRC"
  },
  {
    currencyName: "Croatian Kuna",
    currencySymbol: "kn",
    currencyIsoCode: "HRK"
  },
  {
    currencyName: "Cuban Peso",
    currencySymbol: "$",
    currencyIsoCode: "CUP"
  },
  {
    currencyName: "Czech Koruna",
    currencySymbol: "Kč",
    currencyIsoCode: "CZK"
  },
  {
    currencyName: "Danish Krone",
    currencySymbol: "kr",
    currencyIsoCode: "DKK"
  },
  {
    currencyName: "Djiboutian Franc",
    currencySymbol: "Fdj",
    currencyIsoCode: "DJF"
  },
  {
    currencyName: "Dominican Peso",
    currencySymbol: "RD$",
    currencyIsoCode: "DOP"
  },
  {
    currencyName: "East Caribbean Dollar",
    currencySymbol: "EC$",
    currencyIsoCode: "XCD"
  },
  {
    currencyName: "Egyptian Pound",
    currencySymbol: "E£",
    currencyIsoCode: "EGP"
  },
  {
    currencyName: "Eritrean Nakfa",
    currencySymbol: "Nfk",
    currencyIsoCode: "ERN"
  },
  {
    currencyName: "Ethiopian Birr",
    currencySymbol: "Br",
    currencyIsoCode: "ETB"
  },
  {
    currencyName: "Euro",
    currencySymbol: "€",
    currencyIsoCode: "EUR"
  },
  {
    currencyName: "Falkland Islands Pound",
    currencySymbol: "FK£",
    currencyIsoCode: "FKP"
  },
  {
    currencyName: "Fijian Dollar",
    currencySymbol: "$",
    currencyIsoCode: "FJD"
  },
  {
    currencyName: "Gambian Dalasi",
    currencySymbol: "D",
    currencyIsoCode: "GMD"
  },
  {
    currencyName: "Georgian Lari",
    currencySymbol: "ლ",
    currencyIsoCode: "GEL"
  },
  {
    currencyName: "Ghanaian Cedi",
    currencySymbol: "GH₵",
    currencyIsoCode: "GHS"
  },
  {
    currencyName: "Gibraltar Pound",
    currencySymbol: "£",
    currencyIsoCode: "GIP"
  },
  {
    currencyName: "Guatemalan Quetzal",
    currencySymbol: "Q",
    currencyIsoCode: "GTQ"
  },
  {
    currencyName: "Guinean Franc",
    currencySymbol: "FG",
    currencyIsoCode: "GNF"
  },
  {
    currencyName: "Guyanaese Dollar",
    currencySymbol: "$",
    currencyIsoCode: "GYD"
  },
  {
    currencyName: "Haitian Gourde",
    currencySymbol: "G",
    currencyIsoCode: "HTG"
  },
  {
    currencyName: "Honduran Lempira",
    currencySymbol: "L",
    currencyIsoCode: "HNL"
  },
  {
    currencyName: "Hong Kong Dollar",
    currencySymbol: "HK$",
    currencyIsoCode: "HKD"
  },
  {
    currencyName: "Hungarian Forint",
    currencySymbol: "Ft",
    currencyIsoCode: "HUF"
  },
  {
    currencyName: "Icelandic Króna",
    currencySymbol: "kr",
    currencyIsoCode: "ISK"
  },
  {
    currencyName: "Indian Rupee",
    currencySymbol: "₹",
    currencyIsoCode: "INR"
  },
  {
    currencyName: "Indonesian Rupiah",
    currencySymbol: "Rp",
    currencyIsoCode: "IDR"
  },
  {
    currencyName: "Iranian Rial",
    currencySymbol: "﷼",
    currencyIsoCode: "IRR"
  },
  {
    currencyName: "Iraqi Dinar",
    currencySymbol: "ع.د",
    currencyIsoCode: "IQD"
  },
  {
    currencyName: "Israeli Shekel",
    currencySymbol: "₪",
    currencyIsoCode: "ILS"
  },
  {
    currencyName: "Jamaican Dollar",
    currencySymbol: "J$",
    currencyIsoCode: "JMD"
  },
  {
    currencyName: "Japanese Yen",
    currencySymbol: "¥",
    currencyIsoCode: "JPY"
  },
  {
    currencyName: "Jordanian Dinar",
    currencySymbol: "JD",
    currencyIsoCode: "JOD"
  },
  {
    currencyName: "Kazakhstani Tenge",
    currencySymbol: "₸",
    currencyIsoCode: "KZT"
  },
  {
    currencyName: "Kenyan Shilling",
    currencySymbol: "KSh",
    currencyIsoCode: "KES"
  },
  {
    currencyName: "Kuwaiti Dinar",
    currencySymbol: "د.ك",
    currencyIsoCode: "KWD"
  },
  {
    currencyName: "Kyrgyzstani Som",
    currencySymbol: "сом",
    currencyIsoCode: "KGS"
  },
  {
    currencyName: "Laotian Kip",
    currencySymbol: "₭",
    currencyIsoCode: "LAK"
  },
  {
    currencyName: "Lebanese Pound",
    currencySymbol: "ل.ل",
    currencyIsoCode: "LBP"
  },
  {
    currencyName: "Lesotho Loti",
    currencySymbol: "M",
    currencyIsoCode: "LSL"
  },
  {
    currencyName: "Liberian Dollar",
    currencySymbol: "L$",
    currencyIsoCode: "LRD"
  },
  {
    currencyName: "Libyan Dinar",
    currencySymbol: "ل.د",
    currencyIsoCode: "LYD"
  },
  {
    currencyName: "Macanese Pataca",
    currencySymbol: "MOP$",
    currencyIsoCode: "MOP"
  },
  {
    currencyName: "Macedonian Denar",
    currencySymbol: "ден",
    currencyIsoCode: "MKD"
  },
  {
    currencyName: "Malagasy Ariary",
    currencySymbol: "Ar",
    currencyIsoCode: "MGA"
  },
  {
    currencyName: "Malawian Kwacha",
    currencySymbol: "MK",
    currencyIsoCode: "MWK"
  },
  {
    currencyName: "Malaysian Ringgit",
    currencySymbol: "RM",
    currencyIsoCode: "MYR"
  },
  {
    currencyName: "Maldivian Rufiyaa",
    currencySymbol: "Rf",
    currencyIsoCode: "MVR"
  },
  {
    currencyName: "Mauritanian Ouguiya",
    currencySymbol: "UM",
    currencyIsoCode: "MRU"
  },
  {
    currencyName: "Mauritian Rupee",
    currencySymbol: "₨",
    currencyIsoCode: "MUR"
  },
  {
    currencyName: "Mexican Peso",
    currencySymbol: "$",
    currencyIsoCode: "MXN"
  },
  {
    currencyName: "Moldovan Leu",
    currencySymbol: "L",
    currencyIsoCode: "MDL"
  },
  {
    currencyName: "Mongolian Tugrik",
    currencySymbol: "₮",
    currencyIsoCode: "MNT"
  },
  {
    currencyName: "Moroccan Dirham",
    currencySymbol: "د.م.",
    currencyIsoCode: "MAD"
  },
  {
    currencyName: "Mozambican Metical",
    currencySymbol: "MT",
    currencyIsoCode: "MZN"
  },
  {
    currencyName: "Myanmar Kyat",
    currencySymbol: "K",
    currencyIsoCode: "MMK"
  },
  {
    currencyName: "Namibian Dollar",
    currencySymbol: "N$",
    currencyIsoCode: "NAD"
  },
  {
    currencyName: "Nepalese Rupee",
    currencySymbol: "₨",
    currencyIsoCode: "NPR"
  },
  {
    currencyName: "Netherlands Antillean Guilder",
    currencySymbol: "ƒ",
    currencyIsoCode: "ANG"
  },
  {
    currencyName: "New Taiwan Dollar",
    currencySymbol: "NT$",
    currencyIsoCode: "TWD"
  },
  {
    currencyName: "New Zealand Dollar",
    currencySymbol: "$",
    currencyIsoCode: "NZD"
  },
  {
    currencyName: "Nicaraguan Córdoba",
    currencySymbol: "C$",
    currencyIsoCode: "NIO"
  },
  {
    currencyName: "Nigerian Naira",
    currencySymbol: "₦",
    currencyIsoCode: "NGN"
  },
  {
    currencyName: "North Korean Won",
    currencySymbol: "₩",
    currencyIsoCode: "KPW"
  },
  {
    currencyName: "Norwegian Krone",
    currencySymbol: "kr",
    currencyIsoCode: "NOK"
  },
  {
    currencyName: "Omani Rial",
    currencySymbol: "ر.ع.",
    currencyIsoCode: "OMR"
  },
  {
    currencyName: "Pakistani Rupee",
    currencySymbol: "₨",
    currencyIsoCode: "PKR"
  },
  {
    currencyName: "Panamanian Balboa",
    currencySymbol: "B/.",
    currencyIsoCode: "PAB"
  },
  {
    currencyName: "Papua New Guinean Kina",
    currencySymbol: "K",
    currencyIsoCode: "PGK"
  },
  {
    currencyName: "Paraguayan Guarani",
    currencySymbol: "₲",
    currencyIsoCode: "PYG"
  },
  {
    currencyName: "Peruvian Nuevo Sol",
    currencySymbol: "S/.",
    currencyIsoCode: "PEN"
  },
  {
    currencyName: "Philippine Peso",
    currencySymbol: "₱",
    currencyIsoCode: "PHP"
  },
  {
    currencyName: "Polish Zloty",
    currencySymbol: "zł",
    currencyIsoCode: "PLN"
  },
  {
    currencyName: "Qatari Riyal",
    currencySymbol: "ر.ق",
    currencyIsoCode: "QAR"
  },
  {
    currencyName: "Romanian Leu",
    currencySymbol: "lei",
    currencyIsoCode: "RON"
  },
  {
    currencyName: "Russian Ruble",
    currencySymbol: "₽",
    currencyIsoCode: "RUB"
  },
  {
    currencyName: "Rwandan Franc",
    currencySymbol: "RF",
    currencyIsoCode: "RWF"
  },
  {
    currencyName: "Saint Helena Pound",
    currencySymbol: "£",
    currencyIsoCode: "SHP"
  },
  {
    currencyName: "Samoan Tala",
    currencySymbol: "WS$",
    currencyIsoCode: "WST"
  },
  {
    currencyName: "São Tomé and Príncipe Dobra",
    currencySymbol: "Db",
    currencyIsoCode: "STN"
  },
  {
    currencyName: "Saudi Riyal",
    currencySymbol: "ر.س",
    currencyIsoCode: "SAR"
  },
  {
    currencyName: "Serbian Dinar",
    currencySymbol: "дин",
    currencyIsoCode: "RSD"
  },
  {
    currencyName: "Seychellois Rupee",
    currencySymbol: "₨",
    currencyIsoCode: "SCR"
  },
  {
    currencyName: "Sierra Leonean Leone",
    currencySymbol: "Le",
    currencyIsoCode: "SLL"
  },
  {
    currencyName: "Silver Ounce",
    currencySymbol: "XAG",
    currencyIsoCode: null
  },
  {
    currencyName: "Singapore Dollar",
    currencySymbol: "S$",
    currencyIsoCode: "SGD"
  },
  {
    currencyName: "Solomon Islands Dollar",
    currencySymbol: "SI$",
    currencyIsoCode: "SBD"
  },
  {
    currencyName: "Somali Shilling",
    currencySymbol: "Sh.so.",
    currencyIsoCode: "SOS"
  },
  {
    currencyName: "South African Rand",
    currencySymbol: "R",
    currencyIsoCode: "ZAR"
  },
  {
    currencyName: "South Korean Won",
    currencySymbol: "₩",
    currencyIsoCode: "KRW"
  },
  {
    currencyName: "South Sudanese Pound",
    currencySymbol: "£",
    currencyIsoCode: "SSP"
  },
  {
    currencyName: "Sri Lankan Rupee",
    currencySymbol: "₨",
    currencyIsoCode: "LKR"
  },
  {
    currencyName: "Sudanese Pound",
    currencySymbol: "£",
    currencyIsoCode: "SDG"
  },
  {
    currencyName: "Surinamese Dollar",
    currencySymbol: "$",
    currencyIsoCode: "SRD"
  },
  {
    currencyName: "Swazi Lilangeni",
    currencySymbol: "L",
    currencyIsoCode: "SZL"
  },
  {
    currencyName: "Swedish Krona",
    currencySymbol: "kr",
    currencyIsoCode: "SEK"
  },
  {
    currencyName: "Swiss Franc",
    currencySymbol: "CHF",
    currencyIsoCode: "CHF"
  },
  {
    currencyName: "Syrian Pound",
    currencySymbol: "£S",
    currencyIsoCode: "SYP"
  },
  {
    currencyName: "Tajikistani Somoni",
    currencySymbol: "SM",
    currencyIsoCode: "TJS"
  },
  {
    currencyName: "Tanzanian Shilling",
    currencySymbol: "TSh",
    currencyIsoCode: "TZS"
  },
  {
    currencyName: "Thai Baht",
    currencySymbol: "฿",
    currencyIsoCode: "THB"
  },
  {
    currencyName: "Tongan Pa'anga",
    currencySymbol: "T$",
    currencyIsoCode: "TOP"
  },
  {
    currencyName: "Trinidad and Tobago Dollar",
    currencySymbol: "TT$",
    currencyIsoCode: "TTD"
  },
  {
    currencyName: "Tunisian Dinar",
    currencySymbol: "د.ت",
    currencyIsoCode: "TND"
  },
  {
    currencyName: "Turkish Lira",
    currencySymbol: "₺",
    currencyIsoCode: "TRY"
  },
  {
    currencyName: "Turkmenistani Manat",
    currencySymbol: "m",
    currencyIsoCode: "TMT"
  },
  {
    currencyName: "Ugandan Shilling",
    currencySymbol: "USh",
    currencyIsoCode: "UGX"
  },
  {
    currencyName: "Ukrainian Hryvnia",
    currencySymbol: "₴",
    currencyIsoCode: "UAH"
  },
  {
    currencyName: "United Arab Emirates Dirham",
    currencySymbol: "د.إ",
    currencyIsoCode: "AED"
  },
  {
    currencyName: "Uruguayan Peso",
    currencySymbol: "$",
    currencyIsoCode: "UYU"
  },
  {
    currencyName: "Uzbekistan Som",
    currencySymbol: "soʻm",
    currencyIsoCode: "UZS"
  },
  {
    currencyName: "Vanuatu Vatu",
    currencySymbol: "VT",
    currencyIsoCode: "VUV"
  },
  {
    currencyName: "Venezuelan Bolívar",
    currencySymbol: "Bs.",
    currencyIsoCode: "VES"
  },
  {
    currencyName: "Vietnamese Dong",
    currencySymbol: "₫",
    currencyIsoCode: "VND"
  },
  {
    currencyName: "Yemeni Rial",
    currencySymbol: "﷼",
    currencyIsoCode: "YER"
  },
  {
    currencyName: "Zambian Kwacha",
    currencySymbol: "ZK",
    currencyIsoCode: "ZMW"
  },
  {
    currencyName: "Zimbabwean Dollar",
    currencySymbol: "Z$",
    currencyIsoCode: "ZWL"
  }
]
  