import React, { useEffect, useState } from "react";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Table, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setRestaurant } from "../../../../store/restaurants/restaurants";
import { RootState } from "store";
import DeleteModal from "components/modal/DeleteModal";
import { pathOr } from "ramda";
import {
  fetchRestaurantTableColumns,
  restaurantExpandedRowsRender,
} from "views/admin/business/restaurants/components/tables";
import {
  archiveRestaurantAPI,
  fetchRestaurantsByBusinessAPI,
} from "../../../../api/restaurants";
import { RESTAURANTS_ROUTE } from "../../../../constants";
import { setLocation } from "../../../../store/locations/locations";
import { archiveLocationAPI, deleteLocationAPI } from "../../../../api/locations";
import { setCurrentPage, setTotalPerPage } from "store/restaurants/restaurants";

const Restaurants = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state: RootState) => state.restaurants.isLoading);
  const isSubmitting = useAppSelector((state: RootState) => state.restaurants.isSubmitting);
  const restaurants = useAppSelector((state: RootState) => state.restaurants.restaurants);
  const restaurant = useAppSelector((state: RootState) => state.restaurants.restaurant);
  const currentPage = useAppSelector((state: RootState) => state.restaurants.currentPage);
  const total = useAppSelector((state: RootState) => state.restaurants.total);
  const totalPerPage = useAppSelector((state: RootState) => state.restaurants.totalPerPage);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });
  useEffect(() => {
    dispatch(fetchRestaurantsByBusinessAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const handleClose = () => {
    setModalState({ isOpen: false, type: null });
  };
  const openModal = (restaurantItem: any, type: "delete" | "archive") => {
    dispatch(setRestaurant(restaurantItem));
    setModalState({ isOpen: true, type });
  };

  const openLocationsModal = (locationItem: any, type: "un-archive-location" | "archive-location") => {
    dispatch(setLocation(locationItem));
    setModalState({ isOpen: true, type });
  };

  const archiveRestaurant = async () => {
    await dispatch(archiveRestaurantAPI({ restaurantId: pathOr("", ["id"], restaurant) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchRestaurantsByBusinessAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const archiveLocation = async () => {
    await dispatch(archiveLocationAPI({ locationId: pathOr("", ["id"], restaurant) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchRestaurantsByBusinessAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const deleteLocation = async () => {
    await dispatch(deleteLocationAPI({ locationId: pathOr("", ["id"], restaurant) }));
    setModalState({ isOpen: false, type: null });
    await dispatch(fetchRestaurantsByBusinessAPI({ page: currentPage, perPage: totalPerPage }));
  };

  const onRefresh = async () => {
    await dispatch(
      fetchRestaurantsByBusinessAPI({ refresh: true, page: currentPage, perPage: totalPerPage }),
    );
  };

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchRestaurantsByBusinessAPI({ page: current, perPage: size }));
  };

  return (
    <div>
      <div className="flex flex-wrap justify-between items-center mb-6">
        <div className="mb-2">
          <p className="text-base font-medium">Restaurants</p>
          <p className="text-sm text-gray-750">
            View and update your restaurant settings and information.
          </p>
        </div>
        <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
          <Button onClick={() => navigate(`${RESTAURANTS_ROUTE}/create`)} icon={<PlusOutlined />}>
            Create Restaurant
          </Button>
          <Button onClick={onRefresh} icon={<ReloadOutlined />}>
            Refresh
          </Button>
        </Space>
      </div>
      <div>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={restaurants.map((restaurant: any) => {
            return {
              ...restaurant,
              key: pathOr("", ["id"], restaurant),
            };
          })}
          columns={fetchRestaurantTableColumns({ handleModalOpen: openModal })}
          expandable={{
            expandedRowRender: (record: any) =>
              restaurantExpandedRowsRender(record, {
                openLocationsModal: openLocationsModal,
              }),
          }}
          loading={isLoading}
          pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onChange: onPageSizeChange,
            onShowSizeChange: onPageSizeChange,
            pageSizeOptions: [10, 20, 30],
          }}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveRestaurant}
          isDeleting={isSubmitting}
          title={"Archive Restaurant"}
          prompt={`Are you sure you want to archive restaurant`}
          deletePrompt="Archive"
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "delete-location"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={deleteLocation}
          isDeleting={isSubmitting}
          title={"Delete Location"}
          prompt={`Are you sure you want to delete location`}
        />
        <DeleteModal
          isOpen={modalState.isOpen && modalState.type === "archive-location"}
          handleClose={handleClose}
          cancelDelete={handleClose}
          confirmDelete={archiveLocation}
          isDeleting={isSubmitting}
          title={"Archive Location"}
          prompt={`Are you sure you want to archive location`}
          deletePrompt="Archive"
        />
      </div>
    </div>
  );
};

export default Restaurants;
