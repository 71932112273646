import {getAuthorizationHeader, client, handleAxiosError} from "./apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getCookie} from "../helpers/utilities";


export const createLocationAPI = createAsyncThunk(
   'locations/create-location',
   async (
      payload: { location: any; restaurantId?: string }, thunkAPI
   ) => {
     try {
       const restaurantId = payload.restaurantId ? payload.restaurantId : getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/locations`, {
         method: 'POST',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.location,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const updateLocationAPI = createAsyncThunk(
   'locations/update-location',
   async (
      payload: { location: any; locationId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/locations/${payload.locationId}`, {
         method: 'PUT',
         headers: getAuthorizationHeader(),
         data: {
           ...payload.location,
         }
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const deleteLocationAPI = createAsyncThunk(
   'locations/delete-location',
   async (
      payload: { locationId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/locations/${payload.locationId}`, {
         method: 'DELETE',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const archiveLocationAPI = createAsyncThunk(
   'locations/archive-location',
   async (
      payload: { locationId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/locations/${payload.locationId}/archive`, {
         method: 'PATCH',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchLocationAPI = createAsyncThunk(
   'locations/fetch-location',
   async (
      payload: { locationId: string }, thunkAPI
   ) => {
     try {
       const response = await client(`/locations/${payload.locationId}`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchLocationsByBusinessAPI = createAsyncThunk(
   'locations/fetch-locations-by-business',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/business/locations`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);

export const fetchLocationsByRestaurantAPI = createAsyncThunk(
   'locations/fetch-locations-by-restaurant',
   async (
      _, thunkAPI
   ) => {
     const currentRestaurantId = getCookie('currentRestaurant');
     try {
       const response = await client(`/restaurants/${currentRestaurantId}/locations`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       return handleAxiosError(error, thunkAPI)
     }
   }
);
