import Chart from "react-apexcharts";
import Card from "components/card";
import { MdBarChart } from "react-icons/md";
import { AppDispatch, RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { pathOr, replace } from "ramda";
import { useEffect } from "react";
import { fetchSalesByPaymentMethodAPI } from "api/reports/sales";
import { Spin } from "antd";

const SalesByPaymentMethod = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const currencySymbol = useAppSelector(
    (state: RootState) => pathOr('', ['currencySymbol'], state.auth.currentLocation));
  const salesByPaymentMethod = useAppSelector(
    (state: RootState) => state.salesReports.salesByPaymentMethod);
  const salesByPaymentMethodLoading = useAppSelector(
    (state: RootState) => state.salesReports.salesByPaymentMethodLoading);

  useEffect(() => {
    dispatch(fetchSalesByPaymentMethodAPI({}));
  }, [dispatch])

  const chartOptions: any = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
      theme: "dark",
      y: {
        formatter: function (val: number) {
          return `${currencySymbol} ${val.toLocaleString()}`;
        }
      }
    },
    xaxis: {
      categories: salesByPaymentMethod.map(
        (sale) => replace(/_/g, ' ', pathOr('', ['paymentMethod'], sale))),
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#4318FF",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(67, 24, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: "40px",
      },
    },
  }

  const chartSeries = [
    {
      name: "Gross Sales",
      data: salesByPaymentMethod.map((sale) => pathOr(0, ['grossSales'], sale)),
    }
  ]; 
  
  return (
    <Card extra="pb-7 p-[20px]">
      <div className="mb-auto flex items-center justify-between px-6">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Sales By Payment Method
        </h4>
      </div>

      <div className="h-[300px] w-full pt-10 pb-0">
        { salesByPaymentMethodLoading ? (
            <div>
              <Spin size="large" />
            </div>
          ) : (
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            width="100%"
            height="100%"
          />
        ) }
      </div>
    </Card>
  );
};

export default SalesByPaymentMethod;
