import React from "react";
import SignupForm from "./components/signup-form";

const SignUp: React.FC = () => {
  return (
    <div className="flex justify-center flex-col">
      <div className="px-4 md:px-20 pt-10">
        <div className="w-full mx-auto mb-4 signup-steps">
          <SignupForm />,
        </div>
      </div>
    </div>
  );
};

export default SignUp;
