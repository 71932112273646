import moment from "moment";
import {getCookie} from "../../helpers/utilities";
import {client, getAuthorizationHeader} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchMenuItemsSoldByEmployeesAPI = createAsyncThunk(
  'menu-items-sold-by-employees-report/location',
  async (
   payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI
  ) => {
    try {
     const params = new URLSearchParams();
     if (payload.refresh) {
       params.append("refresh", String(pathOr(false, ["refresh"], payload)));
     }
     const fromDate = moment.utc().subtract(5, 'days').format('YYYY-MM-DD');
     const toDate = moment.utc().add(1, 'days').format('YYYY-MM-DD');
     params.append("fromDate", fromDate);
     params.append("toDate", toDate);
     params.append("page", String(pathOr(1, ["page"], payload)));
     params.append("id", getCookie('currentLocation'));
     params.append("type", "location");
     params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(`/employees-reports/menu-items-sold-overtime/report?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch menu items overtime report`);
    }
  }
);

export const fetchMenuItemsSoldByEmployeeAPI = createAsyncThunk(
  'menu-items-sold-by-employee-report/business',
  async (
   payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    employeeId: string;
    fromDate?: string;
    toDate?: string;
  }, thunkAPI
  ) => {
    try {
      const params = new URLSearchParams();
      const employeeId = payload.employeeId;
      const fromDate = payload.fromDate;
      const toDate = payload.toDate;
      
      if (payload.refresh) {
      params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }

      if (fromDate && toDate) {
      params.append("fromDate", fromDate);
      params.append("toDate", toDate);
      }
      
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));

      const response = await client(`/employees/${employeeId}/menu-items-sold?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch menu items sold by employee`);
    }
  }
);

export const fetchOrdersServedByEmployeeAPI = createAsyncThunk(
  'orders-served-by-employee-report/business',
  async (
   payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    employeeId: string;
    fromDate?: string;
    toDate?: string;
  }, thunkAPI
  ) => {
    try {
      const params = new URLSearchParams();
      const employeeId = payload.employeeId;
      const fromDate = payload.fromDate;
      const toDate = payload.toDate;
      
      if (payload.refresh) {
      params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }

      if (fromDate && toDate) {
      params.append("fromDate", fromDate);
      params.append("toDate", toDate);
      }
      
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));

      const response = await client(`/employees/${employeeId}/served-orders?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch menu items sold by employee`);
    }
  }
);

export const fetchOrdersCreatedByEmployeeAPI = createAsyncThunk(
  'orders-created-by-employee-report/business',
  async (
   payload: { 
    refresh?: boolean;
    page?: number;
    perPage?: number;
    employeeId: string;
    fromDate?: string;
    toDate?: string;
  }, thunkAPI
  ) => {
    try {
      const params = new URLSearchParams();
      const employeeId = payload.employeeId;
      const fromDate = payload.fromDate;
      const toDate = payload.toDate;
      
      if (payload.refresh) {
      params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }

      if (fromDate && toDate) {
      params.append("fromDate", fromDate);
      params.append("toDate", toDate);
      }
      
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));

      const response = await client(`/employees/${employeeId}/created-orders?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch menu items sold by employee`);
    }
  }
);

export const fetchEmployeesReportByLocationAPI = createAsyncThunk(
   'employees-report/location',
   async (
    payload: { refresh?: boolean; page?: number; perPage?: number }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("id", getCookie('currentLocation'));
      params.append("type", "location");
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
       const response = await client(`/employees-reports?` + params.toString
       (), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch employees`);
     }
   }
);

export const fetchEmployeesReportByRestaurantAPI = createAsyncThunk(
   'employees-report/restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/employees-reports`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch employees`);
     }
   }
)

export const fetchEmployeesReportByBusinessAPI = createAsyncThunk(
   'employees-report/business',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/employees-reports`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch employees`);
     }
   }
)

export const fetchEmployeeReportById = createAsyncThunk(
   'employees/fetch-employee-report-by-id',
   async (
      payload: {
        employeeId: string;
        refresh?: boolean;
        page?: number;
        perPage?: number;
      }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      const refresh = payload.refresh
      if (refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
       const response = await client(`/employees-reports/${payload.employeeId}?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch employee report`);
     }
   }
)
