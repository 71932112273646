import {getCookie} from "../../helpers/utilities";
import {client, getAuthorizationHeader} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";


export const fetchLocationStatsAPI = createAsyncThunk(
   'location-stats/location',
   async (
    payload: { refresh?: boolean; fromDate?: string; toDate?: string; }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      const fromDate = payload.fromDate;
      const toDate = payload.toDate;
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      if (fromDate && toDate) {
        params.append('fromDate', fromDate);
        params.append('toDate', toDate);
      }
      const locationId = getCookie('currentLocation')
      const response = await client(`/locations/${locationId}/stats?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
     } catch (error) {
       throw new Error(`failed to fetch location statistics`);
     }
   }
);
