import React, { useEffect, useState } from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PrinterOutlined,
  FilterFilled,
  SearchOutlined,
  CloseOutlined,
  PlusOutlined, FolderOpenOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import type { TableRowSelection } from 'antd/es/table/interface';
import type { ColumnType } from 'antd/lib/table';
import DeleteModal from 'components/modal/DeleteModal';
import { Link, useNavigate } from 'react-router-dom';
import { pathOr } from 'ramda';
import {INVENTORY_ROUTE} from '../../../constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {archiveProductAPI, deleteProductAPI, fetchInventoryByLocationAPI, unArchiveProductAPI} from '../../../api/inventory';
import {setProduct, setCurrentPage, setTotalPerPage} from "../../../store/inventory/inventory";
import { RootState } from 'store';
import withPermissions from 'components/hoc';
import ProtectedWrapper from 'components/protected-wrapper';
import { CAN_ARCHIVE_INVENTORY, CAN_CREATE_INVENTORY, CAN_DELETE_INVENTORY, CAN_UPDATE_INVENTORY } from 'permissions';

interface DataItem {
  id: string;
  name: string;
  quantityInStock: string;
  unitOfMeasure: string;
  categoryId: string;
}

interface TableColumn extends ColumnType<DataItem> {
  dataIndex: string;
}

const Inventory: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const products = useAppSelector((state: any) => state.inventory.inventory);
  const product = useAppSelector((state: any) => state.inventory.product);
  const isLoading = useAppSelector((state: any) => state.inventory.isLoading);
  const [searchText, setSearchText] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
  });
  const currentPage = useAppSelector((state: RootState) => state.inventory.currentPage);
  const total = useAppSelector((state: RootState) => state.inventory.total);
  const totalPerPage = useAppSelector((state: RootState) => state.inventory.totalPerPage);

  useEffect(() => {
    dispatch(fetchInventoryByLocationAPI({ page: currentPage, perPage: totalPerPage }));
  }, [currentPage, dispatch, totalPerPage]);

  const openModal = (product: any, type: 'archive' | 'delete' | 'unArchive') => {
    dispatch(setProduct(product));
    setModalState({ isOpen: true, type });
  };

  const deleteProduct = async () => {
    await dispatch(deleteProductAPI(
       {productId: pathOr('', ['id'], product)}
    ));
    await dispatch(fetchInventoryByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    closeModal();
  }

  const archiveProduct = async () => {
    await dispatch(archiveProductAPI(
       {productId: pathOr('', ['id'], product)}
    ));
    await dispatch(fetchInventoryByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    closeModal();
  }

  const unArchiveProduct = async () => {
    await dispatch(unArchiveProductAPI(
      {productId: pathOr('', ['id'], product)}
    ));
    await dispatch(fetchInventoryByLocationAPI({ page: currentPage, perPage: totalPerPage }));
    closeModal();
  }

  const closeModal = () => {
    dispatch(setProduct(null));
    setModalState({ isOpen: false, type: null })
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
    // Optionally clear the search when opening/closing filters
    if (filtersVisible) setSearchText('');
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    // You can also implement on-the-fly searching by triggering your search logic here
  };

  const onRefresh = async () => {
    await dispatch(fetchInventoryByLocationAPI({ refresh: true, page: currentPage, perPage: totalPerPage }))
  }

  const onPageSizeChange = async (current: number, size: number) => {
    dispatch(setTotalPerPage(size));
    dispatch(setCurrentPage(current));
    await dispatch(fetchInventoryByLocationAPI({ page: current, perPage: size }));
  }

  const rowSelection: TableRowSelection<DataItem> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns: TableColumn[] = [
    {
      title: 'Product name',
      dataIndex: 'name',
      key: 'name',
      className: 'whitespace-nowrap',
      sorter: (a: DataItem, b: DataItem) => pathOr('',['name'],a).localeCompare(pathOr('',['name'],b)),
      render: (text, record: DataItem) => (
        <Link to={`${INVENTORY_ROUTE}/${record.id}/edit`}>
          <p className="font-medium text-[15px] capitalize">{pathOr('',['name'],record)}</p>
        </Link>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantityInStock',
      key: 'quantityInStock',
      sorter: (a: DataItem, b: DataItem) => pathOr('',['quantityInStock'],a).localeCompare(pathOr('',['quantityInStock'],b)),
      render: (text, record: DataItem) => <p className="font-medium text-[15px] text-gray-750">{pathOr('',['quantityInStock'],record)}</p>,
    },
    {
      title: 'Unit',
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
      sorter: (a: DataItem, b: DataItem) => pathOr('',['unitOfMeasure'],a).localeCompare(pathOr('',['unitOfMeasure'],b)),
      render: (text, record: DataItem) => <p className="font-medium capitalize text-[15px] text-gray-750">{pathOr('',['unitOfMeasure'],record)}</p>,
    },
    {
      title: 'Is Archived',
      dataIndex: 'isArchived',
      key: 'isArchived',
      render: (text, record: DataItem) => <p className="font-medium capitalize text-[15px] text-gray-750">
        {String(pathOr('',['isArchived'],record))}
      </p>,
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a: DataItem, b: DataItem) => pathOr('', ['location', 'name'], a).localeCompare(
         pathOr('', ['location', 'name'], b)
      ),
      render: (text, record: DataItem) => (
         <p className="font-medium text-base text-gray-750">{pathOr('', ['location', 'name'], record)}</p>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record: DataItem) => {
        const isArchived = pathOr(false, ['isArchived'], record);
        return (
          <Space size="middle">
            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_UPDATE_INVENTORY]}>
              <Button
                icon={<EditOutlined className="text-gray-750" style={{ fontSize: 20 }} />}
                type="link"
                onClick={() => navigate(`${INVENTORY_ROUTE}/${record.id}/edit`)}
              />
            </ProtectedWrapper>

            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_ARCHIVE_INVENTORY]}>
              <Button
                icon={<FolderOpenOutlined  style={{ fontSize: 20 }} />}
                type="link"
                onClick={() => openModal(record, isArchived ? 'unArchive' : 'archive')}
              />
            </ProtectedWrapper>

            <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_DELETE_INVENTORY]}>
              <Button
                icon={<DeleteOutlined className="text-red-500" style={{ fontSize: 20 }} />}
                type="link"
                onClick={() => openModal(record, 'delete')}
              />
            </ProtectedWrapper>
          </Space>
        );
      },
      width: '10%',
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap">
        <div>
          <p className="text-lg font-bold">Inventory</p>
          <p className="text-base text-gray-750">Manage your Inventory</p>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(setProduct(null));
              navigate(`${INVENTORY_ROUTE}/create`)
            }}
            className="bg-blue-750 basis-9 h-9"
            type="primary"
            icon={<PlusOutlined />}>
            Add Product
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl">
        <div className="flex items-center md:justify-between mb-6 flex-wrap">
          <ProtectedWrapper shouldRenderNull permissionsNeeded={[CAN_CREATE_INVENTORY]}>
            <Button
              className="bg-blue-750 basis-9 h-9 mr-4"
              type="primary"
              onClick={toggleFilters}
              icon={filtersVisible ? <CloseOutlined /> : <FilterFilled />}
            />
          </ProtectedWrapper>
          
          <Input
            className="flex-none w-48 h-10"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search..."
          />
          <Space className="flex-wrap my-4 basis-full md:basis-auto md:flex-grow md:justify-end">
            <Button icon={<DownloadOutlined />}>Export to PDF</Button>
            <Button icon={<PrinterOutlined />}>Print</Button>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>Refresh</Button>
          </Space>
        </div>
        {/* <PurchaseOrderFilters onFilterChange={handleFilterChange} visible={filtersVisible} /> */}
        <Table
           scroll={{ x: 'max-content' }}
           columns={columns}
           dataSource={products}
           loading={isLoading}
           rowSelection={rowSelection}
           pagination={{
            current: currentPage,
            pageSize: totalPerPage,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: () => `Total ${total} items`,
            onShowSizeChange: onPageSizeChange,
              onChange: onPageSizeChange,
              pageSizeOptions: [10, 20, 30]
          }}
        />
      </div>
      <DeleteModal
        isOpen={modalState.isOpen && modalState.type === 'delete'}
        handleClose={closeModal}
        cancelDelete={closeModal}
        confirmDelete={deleteProduct}
      />
      <DeleteModal
         isOpen={modalState.isOpen && modalState.type === 'archive'}
         handleClose={closeModal}
         cancelDelete={closeModal}
         confirmDelete={archiveProduct}
         prompt={'Are you sure you want to archive product'}
         deletePrompt="Archive"
      />
      <DeleteModal
         isOpen={modalState.isOpen && modalState.type === 'unArchive'}
         handleClose={closeModal}
         cancelDelete={closeModal}
         confirmDelete={unArchiveProduct}
         prompt={'Are you sure you want to un archive product'}
         deletePrompt="UnArchive"
      />
    </div>
  );
};

export default withPermissions(Inventory);
