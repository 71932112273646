import {getCookie} from "../../helpers/utilities";
import {client, getAuthorizationHeader} from "../apiUtils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import { pathOr } from "ramda";

export const fetchSalesReportByLocationAPI = createAsyncThunk(
   'sales-report/location',
   async (
    payload: { 
      refresh?: boolean;
      page?: number;
      perPage?: number;
      fromDate?: string;
      toDate?: string;
      groupBy?: string
    }, thunkAPI
   ) => {
     try {
      const params = new URLSearchParams();
      const fromDate = payload.fromDate;
      const toDate = payload.toDate;
      const groupBy = payload.groupBy;
      const page = payload.page;
      const perPage = payload.perPage;

      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      if (fromDate && toDate) {
        params.append("fromDate", fromDate);
        params.append('toDate', toDate);
      }
      if (groupBy) {
        params.append('groupBy', groupBy);
      }

      if (page && perPage) {
        params.append("page", String(pathOr(1, ["page"], payload)));
        params.append("perPage", String(pathOr(30, ["perPage"], payload)));
      }

      params.append("id", getCookie('currentLocation'));
      params.append("type", "location");
    
       const response = await client(`/sales-report?` + params.toString(), {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch sales`);
     }
   }
);

export const fetchSalesByTimeframeAPI = createAsyncThunk(
  'sales-by-timeframe-report/location',
  async (
   payload: { refresh?: boolean; timeframe: string }, thunkAPI
  ) => {
    try {
     const params = new URLSearchParams();
     if (payload.refresh) {
       params.append("refresh", String(pathOr(false, ["refresh"], payload)));
     }
     params.append('timeframe', payload.timeframe);
     params.append("id", getCookie('currentLocation'));
     params.append("type", "location");
      const response = await client(`/sales-report/timeframe?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch sale report`);
    }
  }
);

export const fetchSalesByDiningMethodAPI = createAsyncThunk(
  'sales-by-dining-method-report/location',
  async (
   payload: { refresh?: boolean; }, thunkAPI
  ) => {
    try {
     const params = new URLSearchParams();
     if (payload.refresh) {
       params.append("refresh", String(pathOr(false, ["refresh"], payload)));
     }
     params.append("id", getCookie('currentLocation'));
     params.append("type", "location");

      const response = await client(`/sales-report/dining-method?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch sale report`);
    }
  }
);

export const fetchSalesByPaymentMethodAPI = createAsyncThunk(
  'sales-by-payment-method-report/location',
  async (
   payload: { refresh?: boolean; }, thunkAPI
  ) => {
    try {
     const params = new URLSearchParams();
     if (payload.refresh) {
       params.append("refresh", String(pathOr(false, ["refresh"], payload)));
     }
     params.append("id", getCookie('currentLocation'));
     params.append("type", "location");

      const response = await client(`/sales-report/payment-method?` + params.toString(), {
        method: 'GET',
        headers: getAuthorizationHeader(),
      });
      return response.data
    } catch (error) {
      throw new Error(`failed to fetch sale report`);
    }
  }
);

export const fetchSalesReportByRestaurantAPI = createAsyncThunk(
   'sales-report/restaurant',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/sales-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch sales`);
     }
   }
)

export const fetchSalesReportByBusinessAPI = createAsyncThunk(
   'sales-report/business',
   async (
      _, thunkAPI
   ) => {
     try {
       const response = await client(`/sales-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch sales`);
     }
   }
)

export const fetchSalesByRestaurantLocationsAPI = createAsyncThunk(
   'sales-report/restaurant-locations',
   async (
      _, thunkAPI
   ) => {
     try {
       const restaurantId = getCookie('currentRestaurant');
       const response = await client(`/restaurants/${restaurantId}/compare/sales-report`, {
         method: 'GET',
         headers: getAuthorizationHeader(),
       });
       return response.data
     } catch (error) {
       throw new Error(`failed to fetch sales`);
     }
   }
)
