import { Input, InputNumber, Select, Space, Switch } from "antd";
import { head, pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { updateMenuItem } from "../../../../../store/menus/menu-items";

const MenuItemDetails = () => {
  const dispatch = useAppDispatch();
  const menuItem = useAppSelector((state: any) => state.menuItems.menuItem);
  const errors = useAppSelector((state: any) => state.menuItems.errors);
  const menuModifierGroups = useAppSelector(
    (state: any) => state.menuModifierGroups.menuModifierGroups,
  );

  const handleInputChange = (value: any, name: string) => {
    dispatch(
      updateMenuItem({
        [name]: value,
      }),
    );
  };

  const handleShowOnWeb = () => {
    dispatch(updateMenuItem({
      showOnWeb: !pathOr(false, ['showOnWeb'], menuItem),
    }))
  }

  const handleIsAvailable = () => {
    dispatch(updateMenuItem({
      isAvailable: !pathOr(false, ['isAvailable'], menuItem),
    }))
  }

  const handleIsArchived = () => {
    dispatch(updateMenuItem({
      isArchived: !pathOr(false, ['isArchived'], menuItem),
    }))
  }

  const handleSelect = (value: any, name: string) => {
    const items = pathOr([], ["menuItemModifierGroups"], menuItem);
    const group = head(
      menuModifierGroups.filter(
        (menuModifierGroup: any) => pathOr("", ["id"], menuModifierGroup) === value,
      ),
    );
    dispatch(
      updateMenuItem({
        [name]: [
          ...items,
          {
            menuModifierGroupId: value,
            menuModifierGroup: group,
          },
        ],
      }),
    );
  };

  const handleDeselect = (value: any, name: string) => {
    const items = pathOr([], ["menuItemModifierGroups"], menuItem).filter(
      (group) => pathOr("", ["menuModifierGroupId"], group) !== value,
    );
    dispatch(
      updateMenuItem({
        [name]: items,
      }),
    );
  };

  return (
    <div>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row justify-end">
          <Space size="middle">
            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Show on web</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['showOnWeb'], menuItem)}
                    onChange={handleShowOnWeb} 
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Available</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['isAvailable'], menuItem)}
                    onChange={handleIsAvailable} 
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Archived</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['isArchived'], menuItem)}
                    onChange={handleIsArchived} 
                  />
                </div>
              </div>
            </div>
          </Space>
        </div>
        <div className="flex flex-wrap flex-row justify-between items-start">
          <div className="w-full md:w-[48%]">
            <span>
              Name<span className="text-red-500">*</span>
            </span>
            <Input
              name="name"
              className="w-full"
              required
              status={pathOr(null, ["Menu item name"], errors) ? "error" : ""}
              onChange={(e) => handleInputChange(e.target.value, "name")}
              value={pathOr("", ["name"], menuItem)}
            />
            {pathOr(null, ["Menu item name"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Menu item name", 0, "message"], errors)}
              </span>
            ) : null}
          </div>

          <div className="w-full md:w-[48%]">
            <span>
              Price<span className="text-red-500">*</span>
            </span>
            <InputNumber
              name="price"
              className="w-full"
              required
              status={pathOr(null, ["Menu item price"], errors) ? "error" : ""}
              onChange={(val) => handleInputChange(Number(val), "price")}
              value={pathOr("", ["price"], menuItem)}
            />
            {pathOr(null, ["Menu item price"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Menu item price", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
        </div>
        <div>
          <span>Menu Modifier Groups</span>
          <Select
            value={pathOr([], ["menuItemModifierGroups"], menuItem).map((menuItemModifierGroup) => {
              return {
                label: pathOr("", ["menuModifierGroup", "name"], menuItemModifierGroup),
                value: pathOr("", ["menuModifierGroupId"], menuItemModifierGroup),
              };
            })}
            mode="multiple"
            allowClear
            className="w-full"
            onDeselect={(value) => handleDeselect(value, "menuItemModifierGroups")}
            onSelect={(value) => handleSelect(value, "menuItemModifierGroups")}
            status={pathOr(null, ["Menu item modifier groups"], errors) ? "error" : ""}
            options={menuModifierGroups.map((menuModifierGroup: any) => {
              return {
                label: pathOr("", ["name"], menuModifierGroup),
                value: pathOr("", ["id"], menuModifierGroup),
              };
            })}
          />
          {pathOr(null, ["Menu item modifier groups"], errors) ? (
            <span className="text-red-500">
              {pathOr("", ["Menu item modifier groups", 0, "message"], errors)}
            </span>
          ) : null}
        </div>
        <div className="flex flex-wrap flex-row justify-between items-start">
          <div className='w-full md:w-[48%] lg:w-[32%]'>
            <span>Calories</span>
            <Input
              name="calories"
              className="w-full"
              required
              status={pathOr(null, ["Menu item calories"], errors) ? "error" : ""}
              onChange={(e) => handleInputChange(e.target.value, "name")}
              value={pathOr("", ["calories"], menuItem)}
            />
            {pathOr(null, ["Menu item calories"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Menu item calories", 0, "message"], errors)}
              </span>
            ) : null}
          </div>

          <div className='w-full md:w-[48%] lg:w-[32%]'>
            <span>Serving Size</span>
            <Input
              name="servingSize"
              className="w-full"
              status={pathOr(null, ["Menu item serving size"], errors) ? "error" : ""}
              onChange={(e) => handleInputChange(e.target.value, "servingSize")}
              value={pathOr("", ["servingSize"], menuItem)}
            />
            {pathOr(null, ["Menu item serving size"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Menu item serving size", 0, "message"], errors)}
              </span>
            ) : null}
          </div>

          <div className='w-full md:w-[48%] lg:w-[32%]'>
            <span>Preparation Time In Minutes</span>
            <Input
              name="preparationTimeInMinutes"
              className="w-full"
              status={pathOr(null, ["Menu item preparation time"], errors) ? "error" : ""}
              onChange={(e) => handleInputChange(e.target.value, "preparationTimeInMinutes")}
              value={pathOr("", ["preparationTimeInMinutes"], menuItem)}
            />
            {pathOr(null, ["Menu item preparation time"], errors) ? (
              <span className="text-red-500">
                {pathOr("", ["Menu item preparation time", 0, "message"], errors)}
              </span>
            ) : null}
          </div>
        </div>
        <div>
          <span>Description</span>
          <Input.TextArea
            rows={4}
            status={pathOr(null, ["Menu item description"], errors) ? "error" : ""}
            value={pathOr("", ["description"], menuItem)}
            onChange={(e) => handleInputChange(e.target.value, "description")}
          />
          {pathOr(null, ["Menu item description"], errors) ? (
            <span className="text-red-500">
              {pathOr("", ["Menu item description", 0, "message"], errors)}
            </span>
          ) : null}
        </div>
        <div>
          <span>Allergen Information</span>
          <Input.TextArea
            rows={4}
            status={pathOr(null, ["Menu item allergen information"], errors) ? "error" : ""}
            value={pathOr("", ["allergenInformation"], menuItem)}
            onChange={(e) => handleInputChange(e.target.value, "allergenInformation")}
          />
          {pathOr(null, ["Menu item allergen information"], errors) ? (
            <span className="text-red-500">
              {pathOr("", ["Menu item allergen information", 0, "message"], errors)}
            </span>
          ) : null}
        </div>
        <div>
          <span>Nutritional Information</span>
          <Input.TextArea
            rows={4}
            status={pathOr(null, ["Menu item nutritional information"], errors) ? "error" : ""}
            value={pathOr("", ["nutritionalInformation"], menuItem)}
            onChange={(e) => handleInputChange(e.target.value, "nutritionalInformation")}
          />
          {pathOr(null, ["Menu item nutritional information"], errors) ? (
            <span className="text-red-500">
              {pathOr("", ["Menu item nutritional information", 0, "message"], errors)}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MenuItemDetails;
