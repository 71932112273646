import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import store from './store'
import 'antd/dist/reset.css';
import "./index.css";
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import App from "./App";
import { AuthStateProvider } from "./store/auth/authContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
    <MantineProvider>
      <AuthStateProvider>
        <App />
      </AuthStateProvider>
      </MantineProvider>
    </BrowserRouter>
  </Provider>
);
