import { Spin } from "antd";
import { fetchWeeklyPurchasesAndExpensesReportGraphAPI } from "api/reports/others";
import Chart from "react-apexcharts";
import Card from "components/card";
import { getTimeframeDates } from "helpers/utilities";
import { pathOr } from "ramda";
import { useEffect } from "react";
import { AppDispatch, RootState } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";

const ExpensesAndPurchasesOvertime = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const currencySymbol = useAppSelector(
    (state: RootState) => pathOr('', ['currencySymbol'], state.auth.currentLocation));
  const chartSeries = useAppSelector(
    (state: RootState) => state.others.weeklyExpensesAndPurchasesChartSeries);
  const chartCategories = useAppSelector(
    (state: RootState) => state.others.weeklyExpensesAndPurchasesChartCategories);
  const weeklyExpensesAndPurchasesLoading = useAppSelector(
    (state: RootState) => state.others.weeklyExpensesAndPurchasesLoading);

  useEffect(() => {
    const { fromDate, toDate } = getTimeframeDates('this_week');
    dispatch(fetchWeeklyPurchasesAndExpensesReportGraphAPI({
        fromDate,
        toDate,
        refresh: true,
        groupBy: 'day'
      })
    );
  }, [dispatch])

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '20px',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: chartCategories,
    },
    yaxis: {
      title: {
        text: `${currencySymbol} (thousands)`
      },
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return `${currencySymbol} ${(val * 1000).toLocaleString()}`;
        }
      }
    }
  }
  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
      <div className="mb-auto flex items-center justify-between px-6">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          Weekly Purchases & Expenses
        </h4>
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
        { weeklyExpensesAndPurchasesLoading ? (
          <div>
            <Spin size="large" />
          </div>
        ) : (
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            width="100%"
            height="100%"
          />
        ) }
        </div>
      </div>
    </Card>
  );
};

export default ExpensesAndPurchasesOvertime;
