import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import {
  createUpdateOrderItemColumns,
  createUpdateOrderItemExpandedRowRender,
} from "views/admin/orders/components/table-columns";
import MenuItemModal from "views/admin/orders/components/menu-item-modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { pathOr } from "ramda";
import ModifierItemModal from "views/admin/orders/components/modifier-item-modal";
import {
  addSelectedOrderItemQuantity,
  addSelectedOrderModifierItemQuantity,
  removeModifierItem,
  removeOrderItem,
  removeSelectedOrderItemQuantity,
  removeSelectedOrderModifierItemQuantity,
  selectOrderItem,
  selectOrderModifierItem,
} from "../../../store/orders/orders";
import { RootState } from "store";

const OrderItemsView = () => {
  const dispatch = useAppDispatch();
  const currencySymbol = useAppSelector((state: RootState) =>
    pathOr("", ["currentLocation", "currencySymbol"], state.auth),
  );
  const orderItems = useAppSelector((state: any) => pathOr([], ["orderItems"], state.orders.order));
  const [modal, setModal] = useState<string>("");
  const isLoading = useAppSelector((state: RootState) => state.orders.isLoading);
  const closeModal = () => {
    setModal("");
  };

  const openModifierModal = (orderItem: any) => {
    dispatch(selectOrderItem(orderItem));
    setModal("modifierItem");
  };

  const openToEditMenuItemModal = (orderItem: any) => {
    dispatch(selectOrderItem(orderItem));
    setModal("menuItem");
  };

  const openToEditModifierItemModal = (orderModifierItem: any) => {
    dispatch(selectOrderModifierItem(orderModifierItem));
    setModal("modifierItem");
  };

  const addOrderItemQty = (orderItem: any) => {
    dispatch(selectOrderItem(orderItem));
    dispatch(addSelectedOrderItemQuantity(pathOr("", ["id"], orderItem)));
  };
  const removeOrderItemQty = (orderItem: any) => {
    dispatch(selectOrderItem(orderItem));
    dispatch(removeSelectedOrderItemQuantity(pathOr("", ["id"], orderItem)));
  };
  const removeOrderItemFunc = (orderItem: any) => {
    dispatch(selectOrderItem(orderItem));
    dispatch(removeOrderItem(pathOr("", ["id"], orderItem)));
  };
  const addOrderModifierItemQty = (orderModifierItem: any) => {
    dispatch(addSelectedOrderModifierItemQuantity(orderModifierItem));
  };
  const removeOrderModifierItemQty = (orderModifierItem: any) => {
    dispatch(removeSelectedOrderModifierItemQuantity(orderModifierItem));
  };
  const removeOrderModifierItem = (orderModifierItem: any) => {
    dispatch(removeModifierItem(orderModifierItem));
  };

  return (
    <div>
      <div className="w-full mb-6">
        <div className="flex flex-row items-end justify-end">
          <div>
            <Button
              onClick={() => setModal("menuItem")}
              className="bg-blue-750 basis-9 h-9"
              type="primary"
              icon={<PlusOutlined />}>
              Add Menu Item
            </Button>
          </div>
        </div>

        <div className="mt-5 overflow-x-scroll no-scroll">
          <Table
            scroll={{ x: "max-content" }}
            columns={createUpdateOrderItemColumns({
              currencySymbol,
              openModifierModal: openModifierModal,
              addOrderItemQty,
              removeOrderItemQty,
              removeOrderItem: removeOrderItemFunc,
              openToEditMenuItemModal: openToEditMenuItemModal,
            })}
            expandable={{
              expandedRowRender: (record: any) =>
                createUpdateOrderItemExpandedRowRender(record, {
                  currencySymbol,
                  addOrderModifierItemQty,
                  removeOrderModifierItemQty,
                  removeOrderModifierItem,
                  openToEditModifierItemModal: openToEditModifierItemModal,
                }),
            }}
            dataSource={orderItems}
            loading={isLoading}
          />
        </div>
      </div>
      <MenuItemModal isOpen={modal === "menuItem"} onClose={closeModal} />
      <ModifierItemModal isOpen={modal === "modifierItem"} onClose={closeModal} />
    </div>
  );
};

export default OrderItemsView;
