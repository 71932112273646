import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie } from "../helpers/utilities";
import { client, getAuthorizationHeader, handleAxiosError } from "./apiUtils";
import { pathOr } from "ramda";

export const fetchMenuGroupsByRestaurantAPI = createAsyncThunk(
  "menu-groups/fetch-menu-group-by-restaurant",
  async (_, thunkAPI) => {
    try {
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/menu-groups`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuGroupsByLocationAPI = createAsyncThunk(
  "menu-groups/fetch-menu-group-by-location",
  async (_, thunkAPI) => {
    try {
      const locationId = getCookie("currentLocation");
      const response = await client(`/locations/${locationId}/menu-groups`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuGroupsByMenuAPI = createAsyncThunk(
  "menu-groups/fetch-menu-group-by-menu",
  async (
    payload: { menuId: string; refresh?: boolean; page?: number; perPage?: number },
    thunkAPI,
  ) => {
    try {
      const params = new URLSearchParams();
      if (payload.refresh) {
        params.append("refresh", String(pathOr(false, ["refresh"], payload)));
      }
      params.append("page", String(pathOr(1, ["page"], payload)));
      params.append("perPage", String(pathOr(10, ["perPage"], payload)));
      const response = await client(`/menus/${payload.menuId}/menu-groups?` + params.toString(), {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const createMenuGroupAPI = createAsyncThunk(
  "menu-groups/create-menu-group",
  async (payload: { menuGroup: any; menuId: string }, thunkAPI) => {
    try {
      const response = await client(`/menus/${payload.menuId}/menu-groups`, {
        method: "POST",
        headers: getAuthorizationHeader(),
        data: payload.menuGroup,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const updateMenuGroupAPI = createAsyncThunk(
  "menu-groups/update-menu-group",
  async (payload: { menuGroup: any; menuGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-groups/${payload.menuGroupId}`, {
        method: "PUT",
        headers: getAuthorizationHeader(),
        data: payload.menuGroup,
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const fetchMenuGroupAPI = createAsyncThunk(
  "menu-groups/fetch-menu-group",
  async (payload: { menuGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-groups/${payload.menuGroupId}`, {
        method: "GET",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const deleteMenuGroupAPI = createAsyncThunk(
  "menu-groups/delete-menu-group",
  async (payload: { menuGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-groups/${payload.menuGroupId}`, {
        method: "DELETE",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);

export const archiveMenuGroupAPI = createAsyncThunk(
  "menu-groups/archive-menu-group",
  async (payload: { menuGroupId: string }, thunkAPI) => {
    try {
      const response = await client(`/menu-groups/${payload.menuGroupId}/archive`, {
        method: "PATCH",
        headers: getAuthorizationHeader(),
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error, thunkAPI);
    }
  },
);
