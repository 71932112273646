import React, { useEffect } from "react";
import { Checkbox, Flex, Spin, Breadcrumb, Button, Input, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useParams } from "react-router-dom";
import { pathOr } from "ramda";
import {PERMISSION_SETS_ROUTE} from "../../../../constants";
import {
  createPermissionSetAPI,
  fetchPermissionSetAPI,
  updatePermissionSetAPI
} from "../../../../api/permissions";
import { permissionList } from "permissions";
import { Link } from "react-router-dom";
import { RootState } from "store";
import { updatePermissions, updatePermissionSet } from "store/permissions/permissions";
import { deleteFields, pickFields } from "utils/is-not-empty";

interface PermissionFormProps {
  mode: 'create' | 'edit';
}

const hasCheckedAll = (permissions: any, permissionsArray: string[]) => {
  return permissionsArray.every(permission => permissions[permission]);
}

const PermissionForm: React.FC<PermissionFormProps> = ({ mode }) =>  {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const permissionSet = useAppSelector((state: RootState) => state.permissions.permissionSet);
  const isLoading = useAppSelector((state: RootState) => state.permissions.isLoading);

  useEffect(() => {
    if (mode === 'edit') {
      dispatch(fetchPermissionSetAPI({permissionSetId: id}));
    }
  }, [mode, id, dispatch]);

  const handleName = (value: string) => {
    dispatch(
      updatePermissionSet({
        name: value
      })
    )
  }

  const handleCheckAll = (permissions: string[]) => {
    permissions.forEach((permission: string) => {
      dispatch(
        updatePermissions({
          [permission]: pathOr(false, ['permissions', permission], permissionSet) ? false : true
        })
      )
    })
  }

  const handlePermissionChange = (permission: string) => {
    dispatch(
      updatePermissions({
        [permission]: pathOr(false, ['permissions', permission], permissionSet) ? false : true
      })
    )
  }

  const onFinish = async () => {
    if (!pathOr(null, ['name'], permissionSet)) {
      message.error('please provide a name for this permission set');
      return;
    }
    const permissions = {
      ...deleteFields(permissionSet.permissions, ['id', 'permissionSetId'])
    }
    const permissionsObj = {
      ...pickFields(permissionSet, [
       'name',
       'permissions',
      ]),
    };
    permissionsObj['permissions'] = permissions;
    if (mode === 'create') {
      await dispatch(createPermissionSetAPI({permissionSet: permissionsObj}));
    } else {
      await dispatch(updatePermissionSetAPI({
        permissionSetId: id,
        permissionSet: permissionsObj
      }));
    }
  };

  const breadcrumbItems = [
    { title: <Link to={PERMISSION_SETS_ROUTE}>Permission Sets</Link> },
    { title: <span>{mode === "edit" ? `Update permission set` : "Create permission set"}</span> },
  ];

  return (
    <div className="h-[90vh] overflow-y-scroll no-scroll">
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Permission Sets</p>
          <p className="text-base text-gray-750">
            <Breadcrumb items={breadcrumbItems} />
          </p>
        </div>
        <div>
          <div className="flex flex-wrap -m-2">
            <div className="p-2">
              <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                className="bg-blue-750 w-[200px]">
                {mode === "edit" ? "Update Permission Set" : "Create Permission Set"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-xl mb-6">
        <div className="flex items-center justify-center space-x-4 mb-6">
          {isLoading ? (
            <Flex align="center" justify='center' className='pt-7'>
              <Spin size="large" />
            </Flex>
          ) : (
            <div>
              <div>
                <span>
                  Name<span className="text-red-500">*</span>
                </span>
                <Input
                  name="name"
                  value={pathOr('', ['name'], permissionSet)}
                  onChange={(e) => handleName(e.target.value)}
                />
              </div>
              <div className="flex flex-wrap -m-2 mt-5">
                {permissionList.map((permission: { 
                  title: string;
                  permissions: string[]; 
                  options: { label: string; value: string }[] }) => (
                  <div key={permission.title} className="p-2 w-full sm:w-1/2 md:w-1/3">
                    <div className="flex flex-row items-center justify-between space-x-4 bg-gray-100 p-2">
                      <div>
                        <p className="text-lg">{permission.title}</p>
                      </div>

                      <div>
                        <Checkbox 
                          checked={
                            hasCheckedAll(
                              pathOr({}, ['permissions'], permissionSet),
                              permission.permissions
                            )
                          }
                          onChange={() => handleCheckAll(permission.permissions)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col p-2">
                      {permission.options.map((option: any) => (
                        <div key={option.value} className="min-w-[50%] mb-3">
                          <Checkbox
                            onChange={() => handlePermissionChange(option.value)}
                            checked={pathOr(false, ["permissions", option.value], permissionSet)}
                          >
                            {option.label}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PermissionForm;
