import { Node, Tree } from './tree';

export const mkOrderStages = () => {
  const tree = new Tree({
    name: 'Placed',
    value: 'Placed',
  });

  tree._root.children.push(
     new Node({
       name: 'Canceled',
       value: 'Canceled',
     })
  );
  tree._root.children[0].parent = tree;

  tree._root.children.push(
     new Node({
       name: 'Sent to kitchen',
       value: 'Sent to kitchen',
     })
  );
  tree._root.children[1].parent = tree;

  tree._root.children[1].children.push(
     new Node({
       name: 'Canceled',
       value: 'Canceled',
     })
  );

  tree._root.children[1].children.push(
     new Node({
       name: 'Ready',
       value: 'Ready',
     })
  );

  tree._root.children[1].children[1].children.push(
     new Node({
       name: 'Served to table',
       value: 'Served to table',
     })
  );

  tree._root.children[1].children[1].children.push(
     new Node({
       name: 'Rider picked up',
       value: 'Rider picked up',
     })
  );

  tree._root.children[1].children[1].children.push(
     new Node({
       name: 'Takeout',
       value: 'Takeout',
     })
  );

  tree._root.children[1].children[1].children[0].children.push(
     new Node({
       name: 'Completed',
       value: 'Completed',
     })
  );

  tree._root.children[1].children[1].children[0].children.push(
     new Node({
       name: 'Returned',
       value: 'Returned',
     })
  );

  tree._root.children[1].children[1].children[1].children.push(
     new Node({
       name: 'Delivered',
       value: 'Delivered',
     })
  );

  tree._root.children[1].children[1].children[1].children.push(
     new Node({
       name: 'Returned',
       value: 'Returned',
     })
  );

  tree._root.children[1].children[1].children[2].children.push(
     new Node({
       name: 'Completed',
       value: 'Completed',
     })
  );

  tree._root.children[1].children[1].children[2].children.push(
     new Node({
       name: 'Returned',
       value: 'Returned',
     })
  );

  tree._root.children[1].parent = tree;
  return tree._root;
};
