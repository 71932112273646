export const CAN_VIEW_BUSINESS = 'canViewBusiness';
export const CAN_UPDATE_BUSINESS = 'canUpdateBusiness';

export const CAN_ACCESS_DASHBOARD = 'canAccessDashboard';
export const CAN_VIEW_DASHBOARD = 'canViewDashboard';
export const CAN_ACCESS_POINT_OF_SALE = 'canAccessPointOfSale';

export const CAN_VIEW_CATEGORIES = 'canViewCategories';
export const CAN_CREATE_CATEGORY = 'canCreateCategory';
export const CAN_UPDATE_CATEGORY = 'canUpdateCategory';
export const CAN_DELETE_CATEGORY = 'canDeleteCategory';
export const CAN_ARCHIVE_CATEGORY = 'canArchiveCategory';
export const CAN_UN_ARCHIVE_CATEGORY = 'canUnArchiveCategory';

export const CAN_VIEW_INVENTORY = 'canViewInventory';
export const CAN_CREATE_INVENTORY = 'canCreateInventory';
export const CAN_UPDATE_INVENTORY = 'canUpdateInventory';
export const CAN_DELETE_INVENTORY = 'canDeleteInventory';
export const CAN_ARCHIVE_INVENTORY = 'canArchiveInventory';
export const CAN_UN_ARCHIVE_INVENTORY = 'canUnArchiveInventory';

export const CAN_VIEW_SUPPLIERS = 'canViewSuppliers';
export const CAN_CREATE_SUPPLIER = 'canCreateSupplier';
export const CAN_UPDATE_SUPPLIER = 'canUpdateSupplier';
export const CAN_DELETE_SUPPLIER = 'canDeleteSupplier';
export const CAN_ARCHIVE_SUPPLIER = 'canArchiveSupplier';
export const CAN_UN_ARCHIVE_SUPPLIER = 'canUnArchiveSupplier';

export const CAN_VIEW_MENUS = 'canViewMenus';
export const CAN_CREATE_MENU = 'canCreateMenu';
export const CAN_UPDATE_MENU = 'canUpdateMenu';
export const CAN_DELETE_MENU = 'canDeleteMenu';
export const CAN_ARCHIVE_MENU = 'canArchiveMenu';
export const CAN_UN_ARCHIVE_MENU = 'canUnArchiveMenu';

export const CAN_VIEW_ORDERS = 'canViewOrders';
export const CAN_CREATE_ORDER = 'canCreateOrder';
export const CAN_UPDATE_ORDER = 'canUpdateOrder';
export const CAN_PAY_ORDER = 'canPayOrder';
export const CAN_REFUND_ORDER = 'canIssueRefund';

export const CAN_VIEW_EXPENSES_REPORT = 'canViewExpensesReport';
export const CAN_VIEW_PURCHASES_REPORT = 'canViewPurchasesReport';
export const CAN_VIEW_MENU_ITEMS_REPORT = 'canViewMenuItemsReport';
export const CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT = 'canViewMenuModifierItemsReport';
export const CAN_VIEW_LOCATION_STATS_REPORT = 'canViewLocationStatsReport';
export const CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT = 'canViewMenuModifierItemsSoldOvertimeReport';
export const CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT = 'canViewMenuItemsSoldOvertimeReport';
export const CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT = 'canViewMenuItemsSoldByEmployeesReport';
export const CAN_VIEW_SALES_REPORT = 'canViewSalesReport';
export const CAN_VIEW_INVENTORY_OVERTIME_REPORT = 'canViewInventoryOvertimeReport';
export const CAN_VIEW_ORDERS_REPORT = 'canViewOrdersReport';
export const CAN_VIEW_INVENTORY_REPORT = 'canViewInventoryReport';
export const CAN_VIEW_EMPLOYEE_REPORT = 'canViewEmployeeReport';
export const CAN_VIEW_CUSTOMER_REPORT = 'canViewCustomerReport';
export const CAN_VIEW_CLOSE_OF_DAY_REPORT = 'canViewCloseOfDayReport';

export const CAN_VIEW_EMPLOYEES = 'canViewEmployees';
export const CAN_CREATE_EMPLOYEE = 'canCreateEmployee';
export const CAN_UPDATE_EMPLOYEE = 'canUpdateEmployee';
export const CAN_ARCHIVE_EMPLOYEE = 'canArchiveEmployee';
export const CAN_UN_ARCHIVE_EMPLOYEE = 'canUnArchiveEmployee';
export const CAN_VIEW_EMPLOYEES_SERVED_ORDERS = 'canViewEmployeesServedOrders';
export const CAN_VIEW_EMPLOYEES_CREATED_ORDERS = 'canViewEmployeesCreatedOrders';
export const CAN_VIEW_EMPLOYEES_MENU_ITEMS_SOLD = 'canViewEmployeesMenuItemsSold';

export const CAN_VIEW_PURCHASES = 'canViewPurchases';
export const CAN_CREATE_PURCHASE = 'canCreatePurchase';
export const CAN_UPDATE_PURCHASE = 'canUpdatePurchase';
export const CAN_DELETE_PURCHASE = 'canDeletePurchase';

export const CAN_VIEW_EXPENSES = 'canViewExpenses';
export const CAN_CREATE_EXPENSE = 'canCreateExpense';
export const CAN_UPDATE_EXPENSE = 'canUpdateExpense';
export const CAN_DELETE_EXPENSE = 'canDeleteExpense';

export const CAN_VIEW_FLOORS_AND_TABLES = 'canViewFloorsAndTables';
export const CAN_CREATE_FLOOR_AND_TABLE = 'canCreateFloorAndTable';
export const CAN_UPDATE_FLOOR_AND_TABLE = 'canUpdateFloorAndTable';
export const CAN_DELETE_FLOOR_AND_TABLE = 'canDeleteFloorAndTable';
export const CAN_ARCHIVE_FLOOR_AND_TABLE = 'canArchiveFloorAndTable';
export const CAN_UN_ARCHIVE_FLOOR_AND_TABLE = 'canUnArchiveFloorAndTable';

export const CAN_VIEW_RESTAURANTS = 'canViewRestaurants';
export const CAN_CREATE_RESTAURANT = 'canCreateRestaurant';
export const CAN_UPDATE_RESTAURANT = 'canUpdateRestaurant';
export const CAN_DELETE_RESTAURANT = 'canDeleteRestaurant';
export const CAN_ARCHIVE_RESTAURANT = 'canArchiveRestaurant';
export const CAN_UN_ARCHIVE_RESTAURANT = 'canUnArchiveRestaurant';

export const CAN_VIEW_LOCATIONS = 'canViewLocations';
export const CAN_CREATE_LOCATION = 'canCreateLocation';
export const CAN_UPDATE_LOCATION = 'canUpdateLocation';
export const CAN_DELETE_LOCATION = 'canDeleteLocation';
export const CAN_ARCHIVE_LOCATION = 'canArchiveLocation';
export const CAN_UN_ARCHIVE_LOCATION = 'canUnArchiveLocation';

export const CAN_VIEW_PERMISSION_SETS = 'canViewPermissionSets';
export const CAN_CREATE_PERMISSION_SET = 'canCreatePermissionSet';
export const CAN_UPDATE_PERMISSION_SET = 'canUpdatePermissionSet';
export const CAN_DELETE_PERMISSION_SET = 'canDeletePermissionSet';

export const CAN_VIEW_RESERVATIONS = 'canViewReservations';
export const CAN_CREATE_RESERVATION = 'canCreateReservation';
export const CAN_UPDATE_RESERVATION = 'canUpdateReservation';
export const CAN_PAY_RESERVATION = 'canPayReservation';
export const CAN_REFUND_RESERVATION = 'canRefundReservation';

export const CAN_VIEW_CUSTOMERS = 'canViewCustomers';
export const CAN_CREATE_CUSTOMER = 'canCreateCustomer';
export const CAN_UPDATE_CUSTOMER = 'canUpdateCustomer';
export const CAN_ARCHIVE_CUSTOMER = 'canArchiveCustomer';

export const permissionList = [
  {
    title: "Dashboard",
    options: [
      { label: "Access Dashboard Console", value: CAN_ACCESS_DASHBOARD },
      { label: "View Dashboard Analytics", value: CAN_VIEW_DASHBOARD }
    ],
    permissions: [CAN_ACCESS_DASHBOARD, CAN_VIEW_DASHBOARD]
  },
  {
    title: "Point of Sale",
    options: [
      { label: "Access Point of Sale", value: CAN_ACCESS_POINT_OF_SALE },
    ],
    permissions: [CAN_ACCESS_POINT_OF_SALE]
  },
  {
    title: "Categories",
    options: [
      { label: "View Categories", value: CAN_VIEW_CATEGORIES, },
      { label: "Create Category", value: CAN_CREATE_CATEGORY, },
      { label: "Update Category", value: CAN_UPDATE_CATEGORY, },
      { label: "Archive Category", value: CAN_ARCHIVE_CATEGORY, },
      { label: "UnArchive Category", value: CAN_UN_ARCHIVE_CATEGORY, },
      { label: "Delete Category", value: CAN_DELETE_CATEGORY, }
    ],
    permissions: [
      CAN_VIEW_CATEGORIES,
      CAN_CREATE_CATEGORY,
      CAN_UPDATE_CATEGORY,
      CAN_ARCHIVE_CATEGORY,
      CAN_UN_ARCHIVE_CATEGORY,
      CAN_DELETE_CATEGORY
    ]
  },
  {
    title: "Inventory Management",
    options: [
      { label: "View Inventory", value: CAN_VIEW_INVENTORY, },
      { label: "Create Inventory", value: CAN_CREATE_INVENTORY, },
      { label: "Update Inventory", value: CAN_UPDATE_INVENTORY, },
      { label: "Archive Inventory", value: CAN_ARCHIVE_INVENTORY, },
      { label: "UnArchive Inventory", value: CAN_UN_ARCHIVE_INVENTORY, },
      { label: "Delete Inventory", value: CAN_DELETE_INVENTORY, }
    ],
    permissions: [
      CAN_VIEW_INVENTORY,
      CAN_CREATE_INVENTORY,
      CAN_UPDATE_INVENTORY,
      CAN_DELETE_INVENTORY,
      CAN_ARCHIVE_INVENTORY,
      CAN_UN_ARCHIVE_INVENTORY
    ]
  },
  {
    title: "Suppliers",
    options: [
      { label: "View Suppliers", value: CAN_VIEW_SUPPLIERS, },
      { label: "Create Supplier", value: CAN_CREATE_SUPPLIER, },
      { label: "Update Supplier", value: CAN_UPDATE_SUPPLIER, },
      { label: "Archive Supplier", value: CAN_ARCHIVE_SUPPLIER, },
      { label: "UnArchive Supplier", value: CAN_UN_ARCHIVE_SUPPLIER, },
      { label: "Delete Supplier", value: CAN_DELETE_SUPPLIER, }
    ],
    permissions: [
      CAN_VIEW_SUPPLIERS,
      CAN_CREATE_SUPPLIER,
      CAN_UPDATE_SUPPLIER,
      CAN_DELETE_SUPPLIER,
      CAN_ARCHIVE_SUPPLIER,
      CAN_UN_ARCHIVE_SUPPLIER
    ]
  },
  {
    title: "Menus",
    options: [
      { label: "View Menus", value: CAN_VIEW_MENUS, },
      { label: "Create Menu", value: CAN_CREATE_MENU, },
      { label: 'Update Menu', value: CAN_UPDATE_MENU },
      { label: "Delete Menu", value: CAN_DELETE_MENU, },
      { label: "Archive Menu", value: CAN_ARCHIVE_MENU, },
      { label: "UnArchive Menu", value: CAN_UN_ARCHIVE_MENU, }
    ],
    permissions: [
      CAN_VIEW_MENUS,
      CAN_CREATE_MENU,
      CAN_UPDATE_MENU,
      CAN_DELETE_MENU,
      CAN_ARCHIVE_MENU,
      CAN_UN_ARCHIVE_MENU
    ]
  },
  {
    title: "Orders",
    options: [
      { label: "View Orders", value: CAN_VIEW_ORDERS, },
      { label: "Create Order", value: CAN_CREATE_ORDER, },
      { label: "Update Order", value: CAN_UPDATE_ORDER, },
      { label: "Pay Order", value: CAN_PAY_ORDER, },
      { label: "Issue Refund", value: CAN_REFUND_ORDER, },
    ],
    permissions: [
      CAN_VIEW_ORDERS,
      CAN_CREATE_ORDER,
      CAN_UPDATE_ORDER,
      CAN_PAY_ORDER,
      CAN_REFUND_ORDER
    ]
  },
  {
    title: "Reservations",
    options: [
      { label: "View Reservations", value: CAN_VIEW_RESERVATIONS, },
      { label: "Create Reservation", value: CAN_CREATE_RESERVATION, },
      { label: "Update Reservation", value: CAN_UPDATE_RESERVATION, },
      { label: "Pay Reservation", value: CAN_PAY_RESERVATION, },
      { label: "Refund Reservation", value: CAN_REFUND_RESERVATION, },
    ],
    permissions: [
      CAN_VIEW_RESERVATIONS,
      CAN_CREATE_RESERVATION,
      CAN_UPDATE_RESERVATION,
      CAN_PAY_RESERVATION,
      CAN_REFUND_RESERVATION
    ]
  },
  {
    title: "Customers",
    options: [
      { label: "View Customers", value: CAN_VIEW_CUSTOMERS, },
      { label: "Create Customer", value: CAN_CREATE_CUSTOMER, },
      { label: "Update Customer", value: CAN_UPDATE_CUSTOMER, },
      { label: "Archive Customer", value: CAN_ARCHIVE_CUSTOMER, },
    ],
    permissions: [
      CAN_VIEW_CUSTOMERS,
      CAN_CREATE_CUSTOMER,
      CAN_UPDATE_CUSTOMER,
      CAN_ARCHIVE_CUSTOMER,
    ]
  },
  {
    title: "Employees",
    options: [
      { label: "View Employees", value: CAN_VIEW_EMPLOYEES, },
      { label: "View Employeees Served Orders", value: CAN_VIEW_EMPLOYEES_SERVED_ORDERS, },
      { label: "View Employeees Created Orders", value: CAN_VIEW_EMPLOYEES_CREATED_ORDERS, },
      { label: "View Employeees Menu Items Sold", value: CAN_VIEW_EMPLOYEES_MENU_ITEMS_SOLD, },
      { label: "Create Employee", value: CAN_CREATE_EMPLOYEE, },
      { label: "Update Employee", value: CAN_UPDATE_EMPLOYEE, },
      { label: "Archive Employee", value: CAN_ARCHIVE_EMPLOYEE, },
      { label: "UnArchive Employee", value: CAN_UN_ARCHIVE_EMPLOYEE, }
    ],
    permissions: [
      CAN_VIEW_EMPLOYEES,
      CAN_CREATE_EMPLOYEE,
      CAN_UPDATE_EMPLOYEE,
      CAN_ARCHIVE_EMPLOYEE,
      CAN_UN_ARCHIVE_EMPLOYEE,
      CAN_VIEW_EMPLOYEES_CREATED_ORDERS,
      CAN_VIEW_EMPLOYEES_MENU_ITEMS_SOLD,
      CAN_VIEW_EMPLOYEES_SERVED_ORDERS
    ]
  },
  {
    title: "Purchases",
    options: [
      { label: "View Purchases", value: CAN_VIEW_PURCHASES, },
      { label: "Create Purchase", value: CAN_CREATE_PURCHASE, },
      { label: "Update Purchase", value: CAN_UPDATE_PURCHASE, },
      { label: "Delete Purchase", value: CAN_DELETE_PURCHASE, },
    ],
    permissions: [
      CAN_VIEW_PURCHASES,
      CAN_CREATE_PURCHASE,
      CAN_UPDATE_PURCHASE,
      CAN_DELETE_PURCHASE
    ]
  },
  {
    title: "Expenses",
    options: [
      { label: "View Expenses", value: CAN_VIEW_EXPENSES },
      { label: "Create Expense", value: CAN_CREATE_EXPENSE, },
      { label: "Update Expense", value: CAN_UPDATE_EXPENSE, },
      { label: "Delete Expense", value: CAN_DELETE_EXPENSE, },
    ],
    permissions: [
      CAN_VIEW_EXPENSES,
      CAN_CREATE_EXPENSE,
      CAN_UPDATE_EXPENSE,
      CAN_DELETE_EXPENSE
    ]
  },
  {
    title: "Floors and Tables",
    options: [
      { label: "View Floors and Tables", value: CAN_VIEW_FLOORS_AND_TABLES, },
      { label: "Create Floor and Table", value: CAN_CREATE_FLOOR_AND_TABLE, },
      { label: "Update Floor and Table", value: CAN_UPDATE_FLOOR_AND_TABLE, },
      { label: "Delete Floor and Table", value: CAN_DELETE_FLOOR_AND_TABLE, },
      { label: "Archive Floor and Table", value: CAN_ARCHIVE_FLOOR_AND_TABLE, },
      { label: "UnArchive Floor and Table", value: CAN_UN_ARCHIVE_FLOOR_AND_TABLE, },
    ],
    permissions: [
      CAN_VIEW_FLOORS_AND_TABLES,
      CAN_CREATE_FLOOR_AND_TABLE,
      CAN_UPDATE_FLOOR_AND_TABLE,
      CAN_DELETE_FLOOR_AND_TABLE,
      CAN_ARCHIVE_FLOOR_AND_TABLE,
      CAN_UN_ARCHIVE_FLOOR_AND_TABLE
    ]
  },
  {
    title: "Restaurants",
    options: [
      { label: "View Restaurants", value: CAN_VIEW_RESTAURANTS, },
      { label: "Create Restaurant", value: CAN_CREATE_RESTAURANT, },
      { label: "Update Restaurant", value: CAN_UPDATE_RESTAURANT, },
      { label: "Delete Restaurant", value: CAN_DELETE_RESTAURANT, },
      { label: "Archive Restaurant", value: CAN_ARCHIVE_RESTAURANT, },
      { label: "UnArchive Restaurant", value: CAN_UN_ARCHIVE_RESTAURANT, }
    ],
    permissions: [
      CAN_VIEW_RESTAURANTS,
      CAN_CREATE_RESTAURANT,
      CAN_UPDATE_RESTAURANT,
      CAN_DELETE_RESTAURANT,
      CAN_ARCHIVE_RESTAURANT,
      CAN_UN_ARCHIVE_RESTAURANT,
    ]
  },
  {
    title: "Locations",
    options: [
      { label: "View Locations", value: CAN_VIEW_LOCATIONS, },
      { label: "Create Location", value: CAN_CREATE_LOCATION, },
      { label: "Update Location", value: CAN_UPDATE_LOCATION, },
      { label: "Delete Location", value: CAN_DELETE_LOCATION, },
      { label: "Archive Location", value: CAN_ARCHIVE_LOCATION, },
      { label: "UnArchive Location", value: CAN_UN_ARCHIVE_LOCATION, }
    ],
    permissions: [
      CAN_VIEW_LOCATIONS,
      CAN_CREATE_LOCATION,
      CAN_UPDATE_LOCATION,
      CAN_DELETE_LOCATION,
      CAN_ARCHIVE_LOCATION,
      CAN_UN_ARCHIVE_LOCATION
    ]
  },
  {
    title: "Business Operations",
    options: [
      { label: "View Business", value: CAN_VIEW_BUSINESS, },
    ],
    permissions: [
      CAN_VIEW_BUSINESS,
    ]
  },
  {
    title: "Permission Sets",
    options: [
      { label: "View Permission Sets", value: CAN_VIEW_PERMISSION_SETS, },
      { label: "Create Permission Set", value: CAN_CREATE_PERMISSION_SET, },
      { label: "Update Permission Set", value: CAN_UPDATE_PERMISSION_SET, },
      { label: "Delete Permission Set", value: CAN_DELETE_PERMISSION_SET, }
    ],
    permissions: [
      CAN_VIEW_PERMISSION_SETS,
      CAN_CREATE_PERMISSION_SET,
      CAN_UPDATE_PERMISSION_SET,
      CAN_DELETE_PERMISSION_SET,
    ]
  },
  {
    title: "Reports",
    options: [
      { label: "View Expenses Report", value: CAN_VIEW_EXPENSES_REPORT, },
      { label: "View Purchases Report", value: CAN_VIEW_PURCHASES_REPORT, },
      { label: "View Menu Items Report", value: CAN_VIEW_MENU_ITEMS_REPORT, },
      { label: "View Menu Modifier Items Report", value: CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT, },
      { label: "View Location Stats Report", value: CAN_VIEW_LOCATION_STATS_REPORT, },
      { label: "View Modifier Items Sold Overtime", value: CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT, },
      { label: "View Menu Items Sold Overtime Report", value: CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT, },
      { label: "View Menu Items Sold By Employees Report", value: CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT, },
      { label: "View Sales Report", value: CAN_VIEW_SALES_REPORT, },
      { label: "View Inventory Overtime Report", value: CAN_VIEW_INVENTORY_OVERTIME_REPORT, },
      { label: "View Orders Report", value: CAN_VIEW_ORDERS_REPORT, },
      { label: "View Close Of Day Report", value: CAN_VIEW_CLOSE_OF_DAY_REPORT, },
      { label: "Inventory Report", value: CAN_VIEW_INVENTORY_REPORT, },
      { label: "Employee Report", value: CAN_VIEW_EMPLOYEE_REPORT, },
      { label: "Customer Report", value: CAN_VIEW_CUSTOMER_REPORT, }
    ],
    permissions: [
      CAN_VIEW_EXPENSES_REPORT,
      CAN_VIEW_PURCHASES_REPORT,
      CAN_VIEW_MENU_ITEMS_REPORT,
      CAN_VIEW_MENU_MODIFIER_ITEMS_REPORT,
      CAN_VIEW_LOCATION_STATS_REPORT,
      CAN_VIEW_MENU_MODIFIER_ITEMS_SOLD_OVERTIME_REPORT,
      CAN_VIEW_MENU_ITEMS_SOLD_OVERTIME_REPORT,
      CAN_VIEW_MENU_ITEMS_SOLD_BY_EMPLOYEES_REPORT,
      CAN_VIEW_SALES_REPORT,
      CAN_VIEW_INVENTORY_OVERTIME_REPORT,
      CAN_VIEW_ORDERS_REPORT,
      CAN_VIEW_INVENTORY_REPORT,
      CAN_VIEW_EMPLOYEE_REPORT,
      CAN_VIEW_CUSTOMER_REPORT,
      CAN_VIEW_CLOSE_OF_DAY_REPORT,
    ]
  },
];