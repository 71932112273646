export const countries: any = [
  {"name": "Afghanistan", "code": "AF", "timezone": "Asia/Kabul"},
  {"name": "Albania", "code": "AL", "timezone": "Europe/Tirane"},
  {"name": "Algeria", "code": "DZ", "timezone": "Africa/Algiers"},
  {"name": "Andorra", "code": "AD", "timezone": "Europe/Andorra"},
  {"name": "Angola", "code": "AO", "timezone": "Africa/Luanda"},
  {"name": "Antigua and Barbuda", "code": "AG", "timezone": "America/Antigua"},
  {"name": "Argentina", "code": "AR", "timezone": "America/Argentina/Buenos_Aires"},
  {"name": "Armenia", "code": "AM", "timezone": "Asia/Yerevan"},
  {"name": "Australia", "code": "AU", "timezone": "Australia/Sydney"},
  {"name": "Austria", "code": "AT", "timezone": "Europe/Vienna"},
  {"name": "Azerbaijan", "code": "AZ", "timezone": "Asia/Baku"},
  {"name": "Bahamas", "code": "BS", "timezone": "America/Nassau"},
  {"name": "Bahrain", "code": "BH", "timezone": "Asia/Bahrain"},
  {"name": "Bangladesh", "code": "BD", "timezone": "Asia/Dhaka"},
  {"name": "Barbados", "code": "BB", "timezone": "America/Barbados"},
  {"name": "Belarus", "code": "BY", "timezone": "Europe/Minsk"},
  {"name": "Belgium", "code": "BE", "timezone": "Europe/Brussels"},
  {"name": "Belize", "code": "BZ", "timezone": "America/Belize"},
  {"name": "Benin", "code": "BJ", "timezone": "Africa/Porto-Novo"},
  {"name": "Bhutan", "code": "BT", "timezone": "Asia/Thimphu"},
  {"name": "Bolivia", "code": "BO", "timezone": "America/La_Paz"},
  {"name": "Bosnia and Herzegovina", "code": "BA", "timezone": "Europe/Sarajevo"},
  {"name": "Botswana", "code": "BW", "timezone": "Africa/Gaborone"},
  {"name": "Brazil", "code": "BR", "timezone": "America/Sao_Paulo"},
  {"name": "Brunei", "code": "BN", "timezone": "Asia/Brunei"},
  {"name": "Bulgaria", "code": "BG", "timezone": "Europe/Sofia"},
  {"name": "Burkina Faso", "code": "BF", "timezone": "Africa/Ouagadougou"},
  {"name": "Burundi", "code": "BI", "timezone": "Africa/Bujumbura"},
  {"name": "Cabo Verde", "code": "CV", "timezone": "Atlantic/Cape_Verde"},
  {"name": "Cambodia", "code": "KH", "timezone": "Asia/Phnom_Penh"},
  {"name": "Cameroon", "code": "CM", "timezone": "Africa/Douala"},
  {"name": "Canada", "code": "CA", "timezone": "America/Toronto"},
  {"name": "Central African Republic", "code": "CF", "timezone": "Africa/Bangui"},
  {"name": "Chad", "code": "TD", "timezone": "Africa/Ndjamena"},
  {"name": "Chile", "code": "CL", "timezone": "America/Santiago"},
  {"name": "China", "code": "CN", "timezone": "Asia/Shanghai"},
  {"name": "Colombia", "code": "CO", "timezone": "America/Bogota"},
  {"name": "Comoros", "code": "KM", "timezone": "Indian/Comoro"},
  {"name": "Congo (Congo-Brazzaville)", "code": "CG", "timezone": "Africa/Brazzaville"},
  {"name": "Congo (Congo-Kinshasa)", "code": "CD", "timezone": "Africa/Kinshasa"},
  {"name": "Costa Rica", "code": "CR", "timezone": "America/Costa_Rica"},
  {"name": "Croatia", "code": "HR", "timezone": "Europe/Zagreb"},
  {"name": "Cuba", "code": "CU", "timezone": "America/Havana"},
  {"name": "Cyprus", "code": "CY", "timezone": "Asia/Nicosia"},
  {"name": "Czech Republic", "code": "CZ", "timezone": "Europe/Prague"},
  {"name": "Denmark", "code": "DK", "timezone": "Europe/Copenhagen"},
  {"name": "Djibouti", "code": "DJ", "timezone": "Africa/Djibouti"},
  {"name": "Dominica", "code": "DM", "timezone": "America/Dominica"},
  {"name": "Dominican Republic", "code": "DO", "timezone": "America/Santo_Domingo"},
  {"name": "Ecuador", "code": "EC", "timezone": "America/Guayaquil"},
  {"name": "Egypt", "code": "EG", "timezone": "Africa/Cairo"},
  {"name": "El Salvador", "code": "SV", "timezone": "America/El_Salvador"},
  {"name": "Equatorial Guinea", "code": "GQ", "timezone": "Africa/Malabo"},
  {"name": "Eritrea", "code": "ER", "timezone": "Africa/Asmara"},
  {"name": "Estonia", "code": "EE", "timezone": "Europe/Tallinn"},
  {"name": "Eswatini", "code": "SZ", "timezone": "Africa/Mbabane"},
  {"name": "Ethiopia", "code": "ET", "timezone": "Africa/Addis_Ababa"},
  {"name": "Fiji", "code": "FJ", "timezone": "Pacific/Fiji"},
  {"name": "Finland", "code": "FI", "timezone": "Europe/Helsinki"},
  {"name": "France", "code": "FR", "timezone": "Europe/Paris"},
  {"name": "Gabon", "code": "GA", "timezone": "Africa/Libreville"},
  {"name": "Gambia", "code": "GM", "timezone": "Africa/Banjul"},
  {"name": "Georgia", "code": "GE", "timezone": "Asia/Tbilisi"},
  {"name": "Germany", "code": "DE", "timezone": "Europe/Berlin"},
  {"name": "Ghana", "code": "GH", "timezone": "Africa/Accra"},
  {"name": "Greece", "code": "GR", "timezone": "Europe/Athens"},
  {"name": "Grenada", "code": "GD", "timezone": "America/Grenada"},
  {"name": "Guatemala", "code": "GT", "timezone": "America/Guatemala"},
  {"name": "Guinea", "code": "GN", "timezone": "Africa/Conakry"},
  {"name": "Guinea-Bissau", "code": "GW", "timezone": "Africa/Bissau"},
  {"name": "Guyana", "code": "GY", "timezone": "America/Guyana"},
  {"name": "Haiti", "code": "HT", "timezone": "America/Port-au-Prince"},
  {"name": "Honduras", "code": "HN", "timezone": "America/Tegucigalpa"},
  {"name": "Hungary", "code": "HU", "timezone": "Europe/Budapest"},
  {"name": "Iceland", "code": "IS", "timezone": "Atlantic/Reykjavik"},
  {"name": "India", "code": "IN", "timezone": "Asia/Kolkata"},
  {"name": "Indonesia", "code": "ID", "timezone": "Asia/Jakarta"},
  {"name": "Iran", "code": "IR", "timezone": "Asia/Tehran"},
  {"name": "Iraq", "code": "IQ", "timezone": "Asia/Baghdad"},
  {"name": "Ireland", "code": "IE", "timezone": "Europe/Dublin"},
  {"name": "Israel", "code": "IL", "timezone": "Asia/Jerusalem"},
  {"name": "Italy", "code": "IT", "timezone": "Europe/Rome"},
  {"name": "Jamaica", "code": "JM", "timezone": "America/Jamaica"},
  {"name": "Japan", "code": "JP", "timezone": "Asia/Tokyo"},
  {"name": "Jordan", "code": "JO", "timezone": "Asia/Amman"},
  {"name": "Kazakhstan", "code": "KZ", "timezone": "Asia/Almaty"},
  {"name": "Kenya", "code": "KE", "timezone": "Africa/Nairobi"},
  {"name": "Kiribati", "code": "KI", "timezone": "Pacific/Tarawa"},
  {"name": "Kuwait", "code": "KW", "timezone": "Asia/Kuwait"},
  {"name": "Kyrgyzstan", "code": "KG", "timezone": "Asia/Bishkek"},
  {"name": "Laos", "code": "LA", "timezone": "Asia/Vientiane"},
  {"name": "Latvia", "code": "LV", "timezone": "Europe/Riga"},
  {"name": "Lebanon", "code": "LB", "timezone": "Asia/Beirut"},
  {"name": "Lesotho", "code": "LS", "timezone": "Africa/Maseru"},
  {"name": "Liberia", "code": "LR", "timezone": "Africa/Monrovia"},
  {"name": "Libya", "code": "LY", "timezone": "Africa/Tripoli"},
  {"name": "Liechtenstein", "code": "LI", "timezone": "Europe/Vaduz"},
  {"name": "Lithuania", "code": "LT", "timezone": "Europe/Vilnius"},
  {"name": "Luxembourg", "code": "LU", "timezone": "Europe/Luxembourg"},
  {"name": "Madagascar", "code": "MG", "timezone": "Indian/Antananarivo"},
  {"name": "Malawi", "code": "MW", "timezone": "Africa/Blantyre"},
  {"name": "Malaysia", "code": "MY", "timezone": "Asia/Kuala_Lumpur"},
  {"name": "Maldives", "code": "MV", "timezone": "Indian/Maldives"},
  {"name": "Mali", "code": "ML", "timezone": "Africa/Bamako"},
  {"name": "Malta", "code": "MT", "timezone": "Europe/Malta"},
  {"name": "Marshall Islands", "code": "MH", "timezone": "Pacific/Majuro"},
  {"name": "Mauritania", "code": "MR", "timezone": "Africa/Nouakchott"},
  {"name": "Mauritius", "code": "MU", "timezone": "Indian/Mauritius"},
  {"name": "Mexico", "code": "MX", "timezone": "America/Mexico_City"},
  {"name": "Micronesia", "code": "FM", "timezone": "Pacific/Pohnpei"},
  {"name": "Moldova", "code": "MD", "timezone": "Europe/Chisinau"},
  {"name": "Monaco", "code": "MC", "timezone": "Europe/Monaco"},
  {"name": "Mongolia", "code": "MN", "timezone": "Asia/Ulaanbaatar"},
  {"name": "Montenegro", "code": "ME", "timezone": "Europe/Podgorica"},
  {"name": "Morocco", "code": "MA", "timezone": "Africa/Casablanca"},
  {"name": "Mozambique", "code": "MZ", "timezone": "Africa/Maputo"},
  {"name": "Myanmar", "code": "MM", "timezone": "Asia/Yangon"},
  {"name": "Namibia", "code": "NA", "timezone": "Africa/Windhoek"},
  {"name": "Nauru", "code": "NR", "timezone": "Pacific/Nauru"},
  {"name": "Nepal", "code": "NP", "timezone": "Asia/Kathmandu"},
  {"name": "Netherlands", "code": "NL", "timezone": "Europe/Amsterdam"},
  {"name": "New Zealand", "code": "NZ", "timezone": "Pacific/Auckland"},
  {"name": "Nicaragua", "code": "NI", "timezone": "America/Managua"},
  {"name": "Niger", "code": "NE", "timezone": "Africa/Niamey"},
  {"name": "Nigeria", "code": "NG", "timezone": "Africa/Lagos"},
  {"name": "North Macedonia", "code": "MK", "timezone": "Europe/Skopje"},
  {"name": "Norway", "code": "NO", "timezone": "Europe/Oslo"},
  {"name": "Oman", "code": "OM", "timezone": "Asia/Muscat"},
  {"name": "Pakistan", "code": "PK", "timezone": "Asia/Karachi"},
  {"name": "Palau", "code": "PW", "timezone": "Pacific/Palau"},
  {"name": "Panama", "code": "PA", "timezone": "America/Panama"},
  {"name": "Papua New Guinea", "code": "PG", "timezone": "Pacific/Port_Moresby"},
  {"name": "Paraguay", "code": "PY", "timezone": "America/Asuncion"},
  {"name": "Peru", "code": "PE", "timezone": "America/Lima"},
  {"name": "Philippines", "code": "PH", "timezone": "Asia/Manila"},
  {"name": "Poland", "code": "PL", "timezone": "Europe/Warsaw"},
  {"name": "Portugal", "code": "PT", "timezone": "Europe/Lisbon"},
  {"name": "Qatar", "code": "QA", "timezone": "Asia/Qatar"},
  {"name": "Romania", "code": "RO", "timezone": "Europe/Bucharest"},
  {"name": "Russia", "code": "RU", "timezone": "Europe/Moscow"},
  {"name": "Rwanda", "code": "RW", "timezone": "Africa/Kigali"},
  {"name": "Saint Kitts and Nevis", "code": "KN", "timezone": "America/St_Kitts"},
  {"name": "Saint Lucia", "code": "LC", "timezone": "America/St_Lucia"},
  {"name": "Saint Vincent and the Grenadines", "code": "VC", "timezone": "America/St_Vincent"},
  {"name": "Samoa", "code": "WS", "timezone": "Pacific/Apia"},
  {"name": "San Marino", "code": "SM", "timezone": "Europe/San_Marino"},
  {"name": "Sao Tome and Principe", "code": "ST", "timezone": "Africa/Sao_Tome"},
  {"name": "Saudi Arabia", "code": "SA", "timezone": "Asia/Riyadh"},
  {"name": "Senegal", "code": "SN", "timezone": "Africa/Dakar"},
  {"name": "Serbia", "code": "RS", "timezone": "Europe/Belgrade"},
  {"name": "Seychelles", "code": "SC", "timezone": "Indian/Mahe"},
  {"name": "Sierra Leone", "code": "SL", "timezone": "Africa/Freetown"},
  {"name": "Singapore", "code": "SG", "timezone": "Asia/Singapore"},
  {"name": "Slovakia", "code": "SK", "timezone": "Europe/Bratislava"},
  {"name": "Slovenia", "code": "SI", "timezone": "Europe/Ljubljana"},
  {"name": "Solomon Islands", "code": "SB", "timezone": "Pacific/Guadalcanal"},
  {"name": "Somalia", "code": "SO", "timezone": "Africa/Mogadishu"},
  {"name": "South Africa", "code": "ZA", "timezone": "Africa/Johannesburg"},
  {"name": "South Korea", "code": "KR", "timezone": "Asia/Seoul"},
  {"name": "South Sudan", "code": "SS", "timezone": "Africa/Juba"},
  {"name": "Spain", "code": "ES", "timezone": "Europe/Madrid"},
  {"name": "Sri Lanka", "code": "LK", "timezone": "Asia/Colombo"},
  {"name": "Sudan", "code": "SD", "timezone": "Africa/Khartoum"},
  {"name": "Suriname", "code": "SR", "timezone": "America/Paramaribo"},
  {"name": "Sweden", "code": "SE", "timezone": "Europe/Stockholm"},
  {"name": "Switzerland", "code": "CH", "timezone": "Europe/Zurich"},
  {"name": "Syria", "code": "SY", "timezone": "Asia/Damascus"},
  {"name": "Taiwan", "code": "TW", "timezone": "Asia/Taipei"},
  {"name": "Tajikistan", "code": "TJ", "timezone": "Asia/Dushanbe"},
  {"name": "Tanzania", "code": "TZ", "timezone": "Africa/Dar_es_Salaam"},
  {"name": "Thailand", "code": "TH", "timezone": "Asia/Bangkok"},
  {"name": "Timor-Leste", "code": "TL", "timezone": "Asia/Dili"},
  {"name": "Togo", "code": "TG", "timezone": "Africa/Lome"},
  {"name": "Tonga", "code": "TO", "timezone": "Pacific/Tongatapu"},
  {"name": "Trinidad and Tobago", "code": "TT", "timezone": "America/Port_of_Spain"},
  {"name": "Tunisia", "code": "TN", "timezone": "Africa/Tunis"},
  {"name": "Turkey", "code": "TR", "timezone": "Europe/Istanbul"},
  {"name": "Turkmenistan", "code": "TM", "timezone": "Asia/Ashgabat"},
  {"name": "Tuvalu", "code": "TV", "timezone": "Pacific/Funafuti"},
  {"name": "Uganda", "code": "UG", "timezone": "Africa/Kampala"},
  {"name": "Ukraine", "code": "UA", "timezone": "Europe/Kyiv"},
  {"name": "United Arab Emirates", "code": "AE", "timezone": "Asia/Dubai"},
  {"name": "United Kingdom", "code": "GB", "timezone": "Europe/London"},
  {"name": "United States", "code": "US", "timezone": "America/New_York"},
  {"name": "Uruguay", "code": "UY", "timezone": "America/Montevideo"},
  {"name": "Uzbekistan", "code": "UZ", "timezone": "Asia/Tashkent"},
  {"name": "Vanuatu", "code": "VU", "timezone": "Pacific/Efate"},
  {"name": "Vatican City", "code": "VA", "timezone": "Europe/Vatican"},
  {"name": "Venezuela", "code": "VE", "timezone": "America/Caracas"},
  {"name": "Vietnam", "code": "VN", "timezone": "Asia/Ho_Chi_Minh"},
  {"name": "Yemen", "code": "YE", "timezone": "Asia/Aden"},
  {"name": "Zambia", "code": "ZM", "timezone": "Africa/Lusaka"},
  {"name": "Zimbabwe", "code": "ZW", "timezone": "Africa/Harare"}
];

export const currencies: any = {
  "USD": {
    "symbol": "$",
    "name": "US Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "USD",
    "name_plural": "US dollars"
  },
  "CAD": {
    "symbol": "CA$",
    "name": "Canadian Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CAD",
    "name_plural": "Canadian dollars"
  },
  "EUR": {
    "symbol": "€",
    "name": "Euro",
    "symbol_native": "€",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EUR",
    "name_plural": "euros"
  },
  "AED": {
    "symbol": "AED",
    "name": "United Arab Emirates Dirham",
    "symbol_native": "د.إ.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AED",
    "name_plural": "UAE dirhams"
  },
  "AFN": {
    "symbol": "Af",
    "name": "Afghan Afghani",
    "symbol_native": "؋",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "AFN",
    "name_plural": "Afghan Afghanis"
  },
  "ALL": {
    "symbol": "ALL",
    "name": "Albanian Lek",
    "symbol_native": "Lek",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ALL",
    "name_plural": "Albanian lekë"
  },
  "AMD": {
    "symbol": "AMD",
    "name": "Armenian Dram",
    "symbol_native": "դր.",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "AMD",
    "name_plural": "Armenian drams"
  },
  "ARS": {
    "symbol": "AR$",
    "name": "Argentine Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ARS",
    "name_plural": "Argentine pesos"
  },
  "AUD": {
    "symbol": "AU$",
    "name": "Australian Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AUD",
    "name_plural": "Australian dollars"
  },
  "AZN": {
    "symbol": "man.",
    "name": "Azerbaijani Manat",
    "symbol_native": "ман.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "AZN",
    "name_plural": "Azerbaijani manats"
  },
  "BAM": {
    "symbol": "KM",
    "name": "Bosnia-Herzegovina Convertible Mark",
    "symbol_native": "KM",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BAM",
    "name_plural": "Bosnia-Herzegovina convertible marks"
  },
  "BDT": {
    "symbol": "Tk",
    "name": "Bangladeshi Taka",
    "symbol_native": "৳",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BDT",
    "name_plural": "Bangladeshi takas"
  },
  "BGN": {
    "symbol": "BGN",
    "name": "Bulgarian Lev",
    "symbol_native": "лв.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BGN",
    "name_plural": "Bulgarian leva"
  },
  "BHD": {
    "symbol": "BD",
    "name": "Bahraini Dinar",
    "symbol_native": "د.ب.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "BHD",
    "name_plural": "Bahraini dinars"
  },
  "BIF": {
    "symbol": "FBu",
    "name": "Burundian Franc",
    "symbol_native": "FBu",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "BIF",
    "name_plural": "Burundian francs"
  },
  "BND": {
    "symbol": "BN$",
    "name": "Brunei Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BND",
    "name_plural": "Brunei dollars"
  },
  "BOB": {
    "symbol": "Bs",
    "name": "Bolivian Boliviano",
    "symbol_native": "Bs",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BOB",
    "name_plural": "Bolivian bolivianos"
  },
  "BRL": {
    "symbol": "R$",
    "name": "Brazilian Real",
    "symbol_native": "R$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BRL",
    "name_plural": "Brazilian reals"
  },
  "BWP": {
    "symbol": "BWP",
    "name": "Botswanan Pula",
    "symbol_native": "P",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BWP",
    "name_plural": "Botswanan pulas"
  },
  "BYN": {
    "symbol": "Br",
    "name": "Belarusian Ruble",
    "symbol_native": "руб.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BYN",
    "name_plural": "Belarusian rubles"
  },
  "BZD": {
    "symbol": "BZ$",
    "name": "Belize Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "BZD",
    "name_plural": "Belize dollars"
  },
  "CDF": {
    "symbol": "CDF",
    "name": "Congolese Franc",
    "symbol_native": "FrCD",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CDF",
    "name_plural": "Congolese francs"
  },
  "CHF": {
    "symbol": "CHF",
    "name": "Swiss Franc",
    "symbol_native": "CHF",
    "decimal_digits": 2,
    "rounding": 0.05,
    "code": "CHF",
    "name_plural": "Swiss francs"
  },
  "CLP": {
    "symbol": "CL$",
    "name": "Chilean Peso",
    "symbol_native": "$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "CLP",
    "name_plural": "Chilean pesos"
  },
  "CNY": {
    "symbol": "CN¥",
    "name": "Chinese Yuan",
    "symbol_native": "CN¥",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CNY",
    "name_plural": "Chinese yuan"
  },
  "COP": {
    "symbol": "CO$",
    "name": "Colombian Peso",
    "symbol_native": "$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "COP",
    "name_plural": "Colombian pesos"
  },
  "CRC": {
    "symbol": "₡",
    "name": "Costa Rican Colón",
    "symbol_native": "₡",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "CRC",
    "name_plural": "Costa Rican colóns"
  },
  "CVE": {
    "symbol": "CV$",
    "name": "Cape Verdean Escudo",
    "symbol_native": "CV$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CVE",
    "name_plural": "Cape Verdean escudos"
  },
  "CZK": {
    "symbol": "Kč",
    "name": "Czech Republic Koruna",
    "symbol_native": "Kč",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "CZK",
    "name_plural": "Czech Republic korunas"
  },
  "DJF": {
    "symbol": "Fdj",
    "name": "Djiboutian Franc",
    "symbol_native": "Fdj",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "DJF",
    "name_plural": "Djiboutian francs"
  },
  "DKK": {
    "symbol": "Dkr",
    "name": "Danish Krone",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DKK",
    "name_plural": "Danish kroner"
  },
  "DOP": {
    "symbol": "RD$",
    "name": "Dominican Peso",
    "symbol_native": "RD$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DOP",
    "name_plural": "Dominican pesos"
  },
  "DZD": {
    "symbol": "DA",
    "name": "Algerian Dinar",
    "symbol_native": "د.ج.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "DZD",
    "name_plural": "Algerian dinars"
  },
  "EEK": {
    "symbol": "Ekr",
    "name": "Estonian Kroon",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EEK",
    "name_plural": "Estonian kroons"
  },
  "EGP": {
    "symbol": "EGP",
    "name": "Egyptian Pound",
    "symbol_native": "ج.م.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "EGP",
    "name_plural": "Egyptian pounds"
  },
  "ERN": {
    "symbol": "Nfk",
    "name": "Eritrean Nakfa",
    "symbol_native": "Nfk",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ERN",
    "name_plural": "Eritrean nakfas"
  },
  "ETB": {
    "symbol": "Br",
    "name": "Ethiopian Birr",
    "symbol_native": "Br",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ETB",
    "name_plural": "Ethiopian birrs"
  },
  "GBP": {
    "symbol": "£",
    "name": "British Pound Sterling",
    "symbol_native": "£",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GBP",
    "name_plural": "British pounds sterling"
  },
  "GEL": {
    "symbol": "GEL",
    "name": "Georgian Lari",
    "symbol_native": "GEL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GEL",
    "name_plural": "Georgian laris"
  },
  "GHS": {
    "symbol": "GH₵",
    "name": "Ghanaian Cedi",
    "symbol_native": "GH₵",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GHS",
    "name_plural": "Ghanaian cedis"
  },
  "GNF": {
    "symbol": "FG",
    "name": "Guinean Franc",
    "symbol_native": "FG",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "GNF",
    "name_plural": "Guinean francs"
  },
  "GTQ": {
    "symbol": "GTQ",
    "name": "Guatemalan Quetzal",
    "symbol_native": "Q",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "GTQ",
    "name_plural": "Guatemalan quetzals"
  },
  "HKD": {
    "symbol": "HK$",
    "name": "Hong Kong Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HKD",
    "name_plural": "Hong Kong dollars"
  },
  "HNL": {
    "symbol": "HNL",
    "name": "Honduran Lempira",
    "symbol_native": "L",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HNL",
    "name_plural": "Honduran lempiras"
  },
  "HRK": {
    "symbol": "kn",
    "name": "Croatian Kuna",
    "symbol_native": "kn",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "HRK",
    "name_plural": "Croatian kunas"
  },
  "HUF": {
    "symbol": "Ft",
    "name": "Hungarian Forint",
    "symbol_native": "Ft",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "HUF",
    "name_plural": "Hungarian forints"
  },
  "IDR": {
    "symbol": "Rp",
    "name": "Indonesian Rupiah",
    "symbol_native": "Rp",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IDR",
    "name_plural": "Indonesian rupiahs"
  },
  "ILS": {
    "symbol": "₪",
    "name": "Israeli New Sheqel",
    "symbol_native": "₪",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ILS",
    "name_plural": "Israeli new sheqels"
  },
  "INR": {
    "symbol": "Rs",
    "name": "Indian Rupee",
    "symbol_native": "টকা",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "INR",
    "name_plural": "Indian rupees"
  },
  "IQD": {
    "symbol": "IQD",
    "name": "Iraqi Dinar",
    "symbol_native": "د.ع.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IQD",
    "name_plural": "Iraqi dinars"
  },
  "IRR": {
    "symbol": "IRR",
    "name": "Iranian Rial",
    "symbol_native": "﷼",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "IRR",
    "name_plural": "Iranian rials"
  },
  "ISK": {
    "symbol": "Ikr",
    "name": "Icelandic Króna",
    "symbol_native": "kr",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ISK",
    "name_plural": "Icelandic krónur"
  },
  "JMD": {
    "symbol": "J$",
    "name": "Jamaican Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "JMD",
    "name_plural": "Jamaican dollars"
  },
  "JOD": {
    "symbol": "JD",
    "name": "Jordanian Dinar",
    "symbol_native": "د.أ.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "JOD",
    "name_plural": "Jordanian dinars"
  },
  "JPY": {
    "symbol": "¥",
    "name": "Japanese Yen",
    "symbol_native": "￥",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "JPY",
    "name_plural": "Japanese yen"
  },
  "KES": {
    "symbol": "Ksh",
    "name": "Kenyan Shilling",
    "symbol_native": "Ksh",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KES",
    "name_plural": "Kenyan shillings"
  },
  "KHR": {
    "symbol": "KHR",
    "name": "Cambodian Riel",
    "symbol_native": "៛",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KHR",
    "name_plural": "Cambodian riels"
  },
  "KMF": {
    "symbol": "CF",
    "name": "Comorian Franc",
    "symbol_native": "FC",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "KMF",
    "name_plural": "Comorian francs"
  },
  "KRW": {
    "symbol": "₩",
    "name": "South Korean Won",
    "symbol_native": "₩",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "KRW",
    "name_plural": "South Korean won"
  },
  "KWD": {
    "symbol": "KD",
    "name": "Kuwaiti Dinar",
    "symbol_native": "د.ك.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "KWD",
    "name_plural": "Kuwaiti dinars"
  },
  "KZT": {
    "symbol": "KZT",
    "name": "Kazakhstani Tenge",
    "symbol_native": "тңг.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "KZT",
    "name_plural": "Kazakhstani tenges"
  },
  "LBP": {
    "symbol": "L.L.",
    "name": "Lebanese Pound",
    "symbol_native": "ل.ل.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "LBP",
    "name_plural": "Lebanese pounds"
  },
  "LKR": {
    "symbol": "SLRs",
    "name": "Sri Lankan Rupee",
    "symbol_native": "SL Re",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LKR",
    "name_plural": "Sri Lankan rupees"
  },
  "LTL": {
    "symbol": "Lt",
    "name": "Lithuanian Litas",
    "symbol_native": "Lt",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LTL",
    "name_plural": "Lithuanian litai"
  },
  "LVL": {
    "symbol": "Ls",
    "name": "Latvian Lats",
    "symbol_native": "Ls",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "LVL",
    "name_plural": "Latvian lati"
  },
  "LYD": {
    "symbol": "LD",
    "name": "Libyan Dinar",
    "symbol_native": "د.ل.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "LYD",
    "name_plural": "Libyan dinars"
  },
  "MAD": {
    "symbol": "MAD",
    "name": "Moroccan Dirham",
    "symbol_native": "د.م.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MAD",
    "name_plural": "Moroccan dirhams"
  },
  "MDL": {
    "symbol": "MDL",
    "name": "Moldovan Leu",
    "symbol_native": "MDL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MDL",
    "name_plural": "Moldovan lei"
  },
  "MGA": {
    "symbol": "MGA",
    "name": "Malagasy Ariary",
    "symbol_native": "MGA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MGA",
    "name_plural": "Malagasy Ariaries"
  },
  "MKD": {
    "symbol": "MKD",
    "name": "Macedonian Denar",
    "symbol_native": "MKD",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MKD",
    "name_plural": "Macedonian denari"
  },
  "MMK": {
    "symbol": "MMK",
    "name": "Myanma Kyat",
    "symbol_native": "K",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MMK",
    "name_plural": "Myanma kyats"
  },
  "MOP": {
    "symbol": "MOP$",
    "name": "Macanese Pataca",
    "symbol_native": "MOP$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MOP",
    "name_plural": "Macanese patacas"
  },
  "MUR": {
    "symbol": "MURs",
    "name": "Mauritian Rupee",
    "symbol_native": "MURs",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "MUR",
    "name_plural": "Mauritian rupees"
  },
  "MXN": {
    "symbol": "MX$",
    "name": "Mexican Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MXN",
    "name_plural": "Mexican pesos"
  },
  "MYR": {
    "symbol": "RM",
    "name": "Malaysian Ringgit",
    "symbol_native": "RM",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MYR",
    "name_plural": "Malaysian ringgits"
  },
  "MZN": {
    "symbol": "MTn",
    "name": "Mozambican Metical",
    "symbol_native": "MTn",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "MZN",
    "name_plural": "Mozambican meticals"
  },
  "NAD": {
    "symbol": "N$",
    "name": "Namibian Dollar",
    "symbol_native": "N$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NAD",
    "name_plural": "Namibian dollars"
  },
  "NGN": {
    "symbol": "₦",
    "name": "Nigerian Naira",
    "symbol_native": "₦",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NGN",
    "name_plural": "Nigerian nairas"
  },
  "NIO": {
    "symbol": "C$",
    "name": "Nicaraguan Córdoba",
    "symbol_native": "C$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NIO",
    "name_plural": "Nicaraguan córdobas"
  },
  "NOK": {
    "symbol": "Nkr",
    "name": "Norwegian Krone",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NOK",
    "name_plural": "Norwegian kroner"
  },
  "NPR": {
    "symbol": "NPRs",
    "name": "Nepalese Rupee",
    "symbol_native": "नेरू",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NPR",
    "name_plural": "Nepalese rupees"
  },
  "NZD": {
    "symbol": "NZ$",
    "name": "New Zealand Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "NZD",
    "name_plural": "New Zealand dollars"
  },
  "OMR": {
    "symbol": "OMR",
    "name": "Omani Rial",
    "symbol_native": "ر.ع.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "OMR",
    "name_plural": "Omani rials"
  },
  "PAB": {
    "symbol": "B/.",
    "name": "Panamanian Balboa",
    "symbol_native": "B/.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PAB",
    "name_plural": "Panamanian balboas"
  },
  "PEN": {
    "symbol": "S/.",
    "name": "Peruvian Nuevo Sol",
    "symbol_native": "S/.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PEN",
    "name_plural": "Peruvian nuevos soles"
  },
  "PHP": {
    "symbol": "₱",
    "name": "Philippine Peso",
    "symbol_native": "₱",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PHP",
    "name_plural": "Philippine pesos"
  },
  "PKR": {
    "symbol": "PKRs",
    "name": "Pakistani Rupee",
    "symbol_native": "₨",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "PKR",
    "name_plural": "Pakistani rupees"
  },
  "PLN": {
    "symbol": "zł",
    "name": "Polish Zloty",
    "symbol_native": "zł",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "PLN",
    "name_plural": "Polish zlotys"
  },
  "PYG": {
    "symbol": "₲",
    "name": "Paraguayan Guarani",
    "symbol_native": "₲",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "PYG",
    "name_plural": "Paraguayan guaranis"
  },
  "QAR": {
    "symbol": "QR",
    "name": "Qatari Rial",
    "symbol_native": "ر.ق.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "QAR",
    "name_plural": "Qatari rials"
  },
  "RON": {
    "symbol": "RON",
    "name": "Romanian Leu",
    "symbol_native": "RON",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RON",
    "name_plural": "Romanian lei"
  },
  "RSD": {
    "symbol": "din.",
    "name": "Serbian Dinar",
    "symbol_native": "дин.",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "RSD",
    "name_plural": "Serbian dinars"
  },
  "RUB": {
    "symbol": "RUB",
    "name": "Russian Ruble",
    "symbol_native": "₽.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "RUB",
    "name_plural": "Russian rubles"
  },
  "RWF": {
    "symbol": "RWF",
    "name": "Rwandan Franc",
    "symbol_native": "FR",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "RWF",
    "name_plural": "Rwandan francs"
  },
  "SAR": {
    "symbol": "SR",
    "name": "Saudi Riyal",
    "symbol_native": "ر.س.‏",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SAR",
    "name_plural": "Saudi riyals"
  },
  "SDG": {
    "symbol": "SDG",
    "name": "Sudanese Pound",
    "symbol_native": "SDG",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SDG",
    "name_plural": "Sudanese pounds"
  },
  "SEK": {
    "symbol": "Skr",
    "name": "Swedish Krona",
    "symbol_native": "kr",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SEK",
    "name_plural": "Swedish kronor"
  },
  "SGD": {
    "symbol": "S$",
    "name": "Singapore Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "SGD",
    "name_plural": "Singapore dollars"
  },
  "SOS": {
    "symbol": "Ssh",
    "name": "Somali Shilling",
    "symbol_native": "Ssh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "SOS",
    "name_plural": "Somali shillings"
  },
  "SYP": {
    "symbol": "SY£",
    "name": "Syrian Pound",
    "symbol_native": "ل.س.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "SYP",
    "name_plural": "Syrian pounds"
  },
  "THB": {
    "symbol": "฿",
    "name": "Thai Baht",
    "symbol_native": "฿",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "THB",
    "name_plural": "Thai baht"
  },
  "TND": {
    "symbol": "DT",
    "name": "Tunisian Dinar",
    "symbol_native": "د.ت.‏",
    "decimal_digits": 3,
    "rounding": 0,
    "code": "TND",
    "name_plural": "Tunisian dinars"
  },
  "TOP": {
    "symbol": "T$",
    "name": "Tongan Paʻanga",
    "symbol_native": "T$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TOP",
    "name_plural": "Tongan paʻanga"
  },
  "TRY": {
    "symbol": "TL",
    "name": "Turkish Lira",
    "symbol_native": "TL",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TRY",
    "name_plural": "Turkish Lira"
  },
  "TTD": {
    "symbol": "TT$",
    "name": "Trinidad and Tobago Dollar",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TTD",
    "name_plural": "Trinidad and Tobago dollars"
  },
  "TWD": {
    "symbol": "NT$",
    "name": "New Taiwan Dollar",
    "symbol_native": "NT$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "TWD",
    "name_plural": "New Taiwan dollars"
  },
  "TZS": {
    "symbol": "TSh",
    "name": "Tanzanian Shilling",
    "symbol_native": "TSh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "TZS",
    "name_plural": "Tanzanian shillings"
  },
  "UAH": {
    "symbol": "₴",
    "name": "Ukrainian Hryvnia",
    "symbol_native": "₴",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "UAH",
    "name_plural": "Ukrainian hryvnias"
  },
  "UGX": {
    "symbol": "USh",
    "name": "Ugandan Shilling",
    "symbol_native": "USh",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "UGX",
    "name_plural": "Ugandan shillings"
  },
  "UYU": {
    "symbol": "$U",
    "name": "Uruguayan Peso",
    "symbol_native": "$",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "UYU",
    "name_plural": "Uruguayan pesos"
  },
  "UZS": {
    "symbol": "UZS",
    "name": "Uzbekistan Som",
    "symbol_native": "UZS",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "UZS",
    "name_plural": "Uzbekistan som"
  },
  "VEF": {
    "symbol": "Bs.F.",
    "name": "Venezuelan Bolívar",
    "symbol_native": "Bs.F.",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "VEF",
    "name_plural": "Venezuelan bolívars"
  },
  "VND": {
    "symbol": "₫",
    "name": "Vietnamese Dong",
    "symbol_native": "₫",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "VND",
    "name_plural": "Vietnamese dong"
  },
  "XAF": {
    "symbol": "FCFA",
    "name": "CFA Franc BEAC",
    "symbol_native": "FCFA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "XAF",
    "name_plural": "CFA francs BEAC"
  },
  "XOF": {
    "symbol": "CFA",
    "name": "CFA Franc BCEAO",
    "symbol_native": "CFA",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "XOF",
    "name_plural": "CFA francs BCEAO"
  },
  "YER": {
    "symbol": "YR",
    "name": "Yemeni Rial",
    "symbol_native": "ر.ي.‏",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "YER",
    "name_plural": "Yemeni rials"
  },
  "ZAR": {
    "symbol": "R",
    "name": "South African Rand",
    "symbol_native": "R",
    "decimal_digits": 2,
    "rounding": 0,
    "code": "ZAR",
    "name_plural": "South African rand"
  },
  "ZMK": {
    "symbol": "ZK",
    "name": "Zambian Kwacha",
    "symbol_native": "ZK",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ZMK",
    "name_plural": "Zambian kwachas"
  },
  "ZWL": {
    "symbol": "ZWL$",
    "name": "Zimbabwean Dollar",
    "symbol_native": "ZWL$",
    "decimal_digits": 0,
    "rounding": 0,
    "code": "ZWL",
    "name_plural": "Zimbabwean Dollar"
  }
};

