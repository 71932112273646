import React, { useEffect } from "react";
import MenuItemForm from "../components/menu-item-form";
import { fetchInventoryByLocationAPI } from "../../../../../api/inventory";
import { fetchMenuModifierGroupsByLocationAPI } from "../../../../../api/menu-modifier-groups";
import { fetchMenuGroupAPI } from "../../../../../api/menu-groups";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { useParams } from "react-router-dom";
import { fetchMenuItemAPI } from "../../../../../api/menu-items";
import { pathOr } from "ramda";
import withPermissions from "components/hoc";

const EditMenuItem: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const menuItem = useAppSelector((state: any) => state.menuItems.menuItem);

  useEffect(() => {
    dispatch(fetchInventoryByLocationAPI({ 
      page: 1,
      perPage: 1000,
      isArchived: false,
      refresh: true
    }));
    dispatch(fetchMenuModifierGroupsByLocationAPI({ 
      page: 1,
      perPage: 1000,
      isArchived: false
    }));
    if (id) {
      dispatch(fetchMenuItemAPI({ menuItemId: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (menuItem && pathOr(null, ["id"], menuItem)) {
      dispatch(fetchMenuGroupAPI({ menuGroupId: pathOr("", ["menuGroupId"], menuItem) }));
    }
  }, [dispatch, menuItem]);

  return <MenuItemForm />;
};

export default withPermissions(EditMenuItem);
