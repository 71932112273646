import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, message, Space, Tabs } from "antd";
import { generateOrderTabs } from "views/admin/orders/components/table-columns";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { length, pathOr } from "ramda";
import { fetchOrderStages } from "../../../store/orders/orders";
import { fetchEmployeesByLocationAPI } from "../../../api/employees";
import { fetchTablesByLocationAPI } from "../../../api/floors";
import { pickFields, removeUndefinedOrNull } from "../../../utils/is-not-empty";
import { Link, useNavigate } from "react-router-dom";
import { createOrderAPI, updateOrderAPI } from "../../../api/orders";
import { getCookie } from "../../../helpers/utilities";
import { fetchMenuItemsByLocationAPI } from "../../../api/menu-items";
import { fetchMenuModifierItemsByLocationAPI } from "../../../api/menu-modifier-items";
import { ORDERS_ROUTE } from "../../../constants";
import withPermissions from "components/hoc";

const CreateUpdateOrderView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const order = useAppSelector((state: any) => state.orders.order);
  const loading = useAppSelector((state: any) => state.orders.loading);
  const currencySymbol = useAppSelector((state: any) =>
    pathOr("", ["currentLocation", "currencySymbol"], state.auth),
  );

  const [activeKey, setActiveKey] = useState<string>("orderDetails");

  useEffect(() => {
    dispatch(fetchOrderStages());
    dispatch(fetchEmployeesByLocationAPI({ page: 1, perPage: 1000 }));
    dispatch(fetchTablesByLocationAPI());
    dispatch(fetchMenuItemsByLocationAPI());
    dispatch(fetchMenuModifierItemsByLocationAPI());
  }, [dispatch]);

  const totalAmount = pathOr(0, ["totalAmount"], order);
  const totalDiscountAmount = pathOr(0, ["totalDiscountAmount"], order);
  const subAmount = pathOr(0, ["subAmount"], order);
  const onChange = (key: string) => {
    setActiveKey(key);
  };
  const handleSubmit = async () => {
    if (!pathOr(null, ["type"], order)) {
      message.error("please choose dining option");
      return;
    }

    if (!pathOr(null, ["stage"], order)) {
      message.error("please select order stage");
      return;
    }

    if (length(pathOr([], ["orderItems"], order)) < 1) {
      message.error("please add some items to this order");
      return;
    }

    const orderObject: any = removeUndefinedOrNull({
      stage: pathOr("", ["stage"], order),
      type: pathOr("", ["type"], order),
      subAmount: pathOr(0, ["subAmount"], order),
      tipAmount: pathOr(0, ["tipAmount"], order),
      discountAmount: pathOr(0, ["discountAmount"], order),
      totalAmount: pathOr(0, ["totalAmount"], order),
      totalDiscountAmount: pathOr(0, ["totalDiscountAmount"], order),
      totalDiscountPercentage: pathOr(0, ["totalDiscountPercentage"], order),
      isPaid: pathOr(false, ["isPaid"], order),
      isCanceled: pathOr(false, ['isCanceled'], order),
      orderItems: pathOr([], ["orderItems"], order).map((orderItem) => {
        return removeUndefinedOrNull({
          menuItemId: pathOr("", ["menuItemId"], orderItem),
          quantity: pathOr(1, ["quantity"], orderItem),
          kitchenNote: pathOr("", ["kitchenNote"], orderItem),
          discountAmount: pathOr(0, ["discountAmount"], orderItem),
          discountPercentage: pathOr(0, ["discountPercentage"], orderItem),
          orderModifierItems: pathOr([], ["orderModifierItems"], orderItem).map(
            (orderModifierItem) => {
              return removeUndefinedOrNull({
                discountAmount: pathOr(0, ["discountAmount"], orderModifierItem),
                discountPercentage: pathOr(0, ["discountPercentage"], orderModifierItem),
                quantity: pathOr(1, ["quantity"], orderModifierItem),
                menuModifierItemId: pathOr("", ["menuModifierItemId"], orderModifierItem),
              });
            },
          ),
        });
      }),
    });

    if (pathOr(null, ["tableId"], order)) {
      orderObject.tableId = order.tableId;
    }

    if (pathOr(null, ["serverId"], order)) {
      orderObject.serverId = order.serverId;
    }

    if (pathOr(null, ["delivery"], order)) {
      orderObject.delivery = removeUndefinedOrNull({
        city: pathOr('', ['delivery', 'city'], order),
        deliveryRiderId: pathOr('', ['delivery', 'deliveryRiderId'], order),
        timeOfDelivery: pathOr('', ['delivery', 'timeOfDelivery'], order),
        distanceBetween: pathOr(0, ['delivery', 'distanceBetween'], order),
        costOfDelivery: pathOr(0, ['delivery', 'costOfDelivery'], order),
        streetAddress: pathOr('', ['delivery', 'streetAddress'], order),
        town: pathOr('', ['delivery', 'town'], order),
        zipcode: pathOr('0000', ['delivery', 'zipcode'], order),
        recipientName: pathOr('', ['delivery', 'recipientName'], order),
        recipientPhoneNumber: pathOr('', ['delivery', 'recipientPhoneNumber'], order),
        recipientEmail: pathOr('', ['delivery', 'recipientEmail'], order),
      });
    }

    if (pathOr(null, ['transaction'], order)) {
      orderObject.transaction = {
        ...removeUndefinedOrNull(pickFields(order.transaction, [
          'amountPaid',
          'paymentMethods',
          'amountDue',
          'amountRefunded',
          'change',
          'amountRefundable'
        ])),
        payments: pathOr([], ['transaction', 'payments'], order).map((payment) => {
          return removeUndefinedOrNull(
            pickFields(payment, ['amountPaid', 'paymentMethod', 'change', 'transactionId', 'new'])
          )
        }),
        refunds: pathOr([], ['transaction', 'refunds'], order).map((refund) => {
          return removeUndefinedOrNull(
            pickFields(refund, [
              'amountRefunded',
              'refundMethod',
              'transactionId',
              'reason',
              'recipientEmail',
              'recipientPhoneNumber',
              'recipientName',
              'new'
            ])
          )
        }),
      };
    }

    if (!pathOr(null, ["id"], order) && !pathOr(null, ["createdAt"], order)) {
      dispatch(
        createOrderAPI({
          order: {
            ...orderObject,
            locationId: getCookie("currentLocation"),
            restaurantId: getCookie("currentRestaurant"),
          },
          navigationCallback: () => navigate(`/admin/orders`),
        }),
      );
      return;
    } else {
      dispatch(
        updateOrderAPI({
          id: pathOr("", ["id"], order),
          order: orderObject,
          navigationCallback: () => navigate(`/admin/orders`),
        }),
      );
      return;
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">
            {pathOr("", ["id"], order) && pathOr("", ["createdAt"], order)
              ? `Update #${pathOr("", ["orderNumber"], order)}`
              : "Create Order"}
          </p>
          <Breadcrumb
            items={[
              {
                title: <Link to={ORDERS_ROUTE}>Orders</Link>,
              },
              {
                title: (
                  <p>
                    {pathOr("", ["id"], order)
                      ? `${pathOr("", ["orderNumber"], order)}`
                      : "Create Order"}
                  </p>
                ),
              },
            ]}
          />
        </div>

        <div>
          <Button loading={loading} onClick={handleSubmit} className="bg-blue-750 basis-9 h-9" type="primary">
            {`${pathOr("", ["createdAt"], order) ? "Update Order" : "Create Order"}`}
          </Button>
        </div>
      </div>
      <div className="bg-white p-5 rounded-xl">
        <div className="flex flex-row justify-end">
          <Space className="flex-wrap">
            <div>
              <Button>
                Discount: {currencySymbol} {totalDiscountAmount.toLocaleString()}
              </Button>
            </div>

            <div>
              <Button>
                Subtotal: {currencySymbol} {subAmount.toLocaleString()}
              </Button>
            </div>

            <div>
              <Button>
                Total: {currencySymbol} {totalAmount.toLocaleString()}
              </Button>
            </div>
          </Space>
        </div>
        <Tabs
          type="card"
          activeKey={activeKey}
          defaultActiveKey="orderDetails"
          items={generateOrderTabs(order)}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default withPermissions(CreateUpdateOrderView);
