import {Tabs, Breadcrumb, Button, message} from "antd";
import React, { useEffect } from "react";
import {getCookie} from "helpers/utilities";
import { useParams } from "react-router-dom";
import {length, pathOr} from "ramda";

import ProductDetails from "./product-details";
import ProductMeasurement from "./product-measurement";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";
import { Link } from "react-router-dom";
import {INVENTORY_ROUTE} from "../../../../constants";
import {createProductAPI, fetchProductAPI, updateProductAPI} from "../../../../api/inventory";
import {validateSchema} from "views/admin/inventory/validations/schema";
import {setErrors} from "../../../../store/inventory/inventory";
import {pickFields, removeUndefinedOrNull} from "../../../../utils/is-not-empty";

interface ProductFormProps {
  mode: "create" | "edit";
}

const ProductForm: React.FC<ProductFormProps> = ({ mode }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const product = useAppSelector((state: RootState) => state.inventory.product);
  const isLoading = useAppSelector((state: RootState) => state.inventory.isLoading);

  useEffect(() => {
    if (id) {
      dispatch(fetchProductAPI({productId: id}));
    }
  }, [id, dispatch]);

  const onFinish = async () => {
    dispatch(setErrors(null))
    const productMeasurements = pathOr([], ['productMeasurements'], product);
    const productObj = {
      ...pickFields(product, [
        'name',
        'supplierId',
        'costPrice',
        'sellingPrice',
        'costPrice',
        'manufactureDate',
        'expiryDate',
        'quantityInStock',
        'unitOfMeasure',
        'reOrderLevel',
        'imageURL',
        'barcode',
        'sku',
        'isPerishable',
        'isTrackable',
        'dimensions',
        'categoryId',
      ]),
      productMeasurements: pickFields(productMeasurements, [
        'unitOfMeasure',
        'quantityToMakeTrackableUnit',
      ]),
    };

    const productPayload = removeUndefinedOrNull(productObj);
    const { errors } = validateSchema(productPayload);
    dispatch(setErrors(errors));
    if (length(Object.keys(errors)) > 0) {
      message.error('something went wrong, please check form and fix errors');
      return
    }

    const restaurantId = getCookie("currentRestaurant");
    const locationId = getCookie("currentLocation");
    const locations = [
      {
        restaurantId,
        locationId,
      }
    ];
    if (!id) {
      const obj = {
        ...productPayload,
        locations,
      };
      await dispatch(createProductAPI({product: obj}));
    } else {
      await dispatch(updateProductAPI({productId: id, product: productPayload}));
    }
  };

  const items = [
    {
      key: "1",
      label: "Product details",
      children: <ProductDetails />,
    },
    {
      key: "2",
      label: "Product measurements",
      children: <ProductMeasurement />,
    },
  ];

  const breadcrumbItems = [
    { title: <Link to={INVENTORY_ROUTE}>Inventory</Link> },
    { title: <span>{mode === "edit" ? `Edit product` : "Add product"}</span> },
  ];

  const onChange = (key: string) => {};
  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <div>
          <p className="text-lg font-bold">Inventory</p>
          <p className="text-base text-gray-750">
            <Breadcrumb items={breadcrumbItems} />
          </p>
        </div>
        <div>
          <div className="flex flex-wrap -m-2">
            <div className="p-2">
              <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={!product || length(Object.keys(product)) < 1}
                className="bg-blue-750 w-[200px]">
                {mode === "edit" ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-3 rounded-xl">
        <Tabs type="card" defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
};

export default ProductForm;
