import Joi from 'joi';
import {groupBy, pathOr} from "ramda";

export const schema = Joi.object({
  name: Joi.string()
     .trim()
     .required()
     .label('Restaurant name'),
  type: Joi.string().trim().required().label('Restaurant type'),
  website: Joi.string().trim().uri().optional().label('Restaurant website'),
  orderStageToTrackInventory: Joi.string().trim().optional().label('Restaurant order stage to track inventory'),
});

export const validateSchema = (data: any) => {
  const { error } = schema.validate(data, { abortEarly: false });
  const groupByLabel = groupBy((item) => {
    return pathOr('', ['context', 'label'], item);
  });
  return  {
    errors: groupByLabel(pathOr([], ['details'], error))
  }
}
