import React, { FC } from "react";
import {Button, Input, Switch} from "antd";
import ParentModal, { modalProps } from "components/modal/Modal";
import { getCookie } from "../../../../../helpers/utilities";
import { pathOr } from "ramda";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {updateMenuGroup} from "../../../../../store/menus/menu-groups";
import {pickFields} from "../../../../../utils/is-not-empty";
import {createMenuGroupAPI, fetchMenuGroupsByMenuAPI, updateMenuGroupAPI} from "../../../../../api/menu-groups";
import {useParams} from "react-router-dom";

interface MenuGroupFormProps extends modalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const MenuGroupForm: FC<MenuGroupFormProps> = ({ isOpen, handleClose }) => {
  const dispatch = useAppDispatch();
  const {id} = useParams()
  const menuGroup = useAppSelector((state: any) => state.menuGroups.menuGroup);
  const isLoading = useAppSelector((state: any) => state.menuGroups.isLoading);

  const handleInputChange = (val: string, name: string) => {
    dispatch(updateMenuGroup({
      [name]: val,
    }))
  }

  const handleShowOnWeb = () => {
    dispatch(updateMenuGroup({
      showOnWeb: !pathOr(false, ['showOnWeb'], menuGroup),
    }))
  }

  const onFinish = async () => {
    const restaurantId = getCookie('currentRestaurant');
    const locationId = getCookie('currentLocation');
    const menuGroupObj = {
      ...pickFields(menuGroup, ['name', 'description', 'showOnWeb']),
    }
    const menuGroupPayload = menuGroupObj;
    if (!pathOr(null, ['id'], menuGroup)) {
      const obj = {
        ...menuGroupPayload,
        locationId,
        restaurantId,
      }
      await dispatch(createMenuGroupAPI({ menuGroup: obj, menuId: id }));
    } else {
      await dispatch(updateMenuGroupAPI({
        menuGroupId: pathOr('', ['id'], menuGroup),
        menuGroup: menuGroupPayload
      }));
    }
    await dispatch(fetchMenuGroupsByMenuAPI({ menuId: id }));
    handleClose();
  };

  return (
     <ParentModal isOpen={isOpen} handleClose={handleClose}>
       <div className="text-left text-gray-750">
         <h3 className="text-2xl font-medium">{pathOr('', ['id'], menuGroup)
            ? "Edit Menu Group" : "Create Menu Group"}</h3>
          <div className="w-full">
            <div className="flex justify-end items-end">
              <div className="flex flex-row items-center space-x-4">
                <div>
                  <span>Show on web</span>
                </div>
                <div>
                  <Switch
                    value={pathOr(false, ['showOnWeb'], menuGroup)}
                    onChange={handleShowOnWeb} 
                  />
                </div>
              </div>
            </div>
           <div className="text-left w-full pt-3">
             <span>Menu Group Name<span className="text-red-500">*</span></span>
             <Input
                name="name"
                className="w-full"
                value={pathOr('', ['name'], menuGroup)}
                required
                onChange={(e) => handleInputChange(e.target.value,"name")}
             />
           </div>
           <div className="text-left w-full pt-3">
             <span>Menu Group Description</span>
             <Input
                name="name"
                className="w-full"
                value={pathOr('', ['description'], menuGroup)}
                required
                onChange={(e) =>
                   handleInputChange(e.target.value,"description")}
             />
           </div>
           <div className="mt-5 space-x-3 flex justify-end">
             <Button
                onClick={onFinish}
                loading={isLoading}
                type="primary"
                disabled={isLoading}
                className="bg-blue-750">
               {pathOr('', ['id'], menuGroup) ? "Update" : "Create"}
             </Button>
             <Button onClick={handleClose} type="default">
               Cancel
             </Button>
           </div>
         </div>
       </div>
     </ParentModal>
  );
};

export default MenuGroupForm;
