import { Form, Select } from 'antd';
import React from 'react';

interface FormSelectProps {
  label?: string;
  name: string;
  options?: Array<{ value: string; label: string }> | any;
  required?: boolean;
  message?: string;
  className?: any;
  selectClassName?: any;
  placeholder?: string;
  value?: any;
  mode?:"multiple" | "tags";
  onChange?: (value: any) => void;
  onDeselect?: (value: any) => void;
  disabled?: boolean;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  selectClassName = "h-10",
  className = "mb-2",
  label,
  name,
  options,
  required = false,
  message = 'This field is required',
  value,
  mode,
  placeholder,
  onChange,
  onDeselect,
  disabled
}) => {
  const onSearch = (value: string) => {};

  const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
  (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form.Item
      name={name}
      rules={[{ required, message }]}
      className={className}
    >
      <div className='text-[15px]' style={{ paddingBottom: '2px' }}>{label} {required &&
          <span className='text-red-500'>*</span>}</div>
      <Select
        showSearch
        mode={mode}
        disabled={disabled}
        onDeselect={onDeselect}
        placeholder={label ? `Choose ${label}` : placeholder}
        className={selectClassName}
        value={value}
        onSearch={onSearch}
        filterOption={filterOption}
        defaultValue={value}
        onChange={(selectedValue) => {
          onChange(selectedValue as string);
        }}
      >
        {options?.map((option: any, index: number) => (
          <Select.Option key={`${option.value}_${index}`} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
